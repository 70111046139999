import React, { useState, useEffect } from "react";
import "./LifestyleAdvice.css";
import Vegitable from "../../../images/vegetables10.png";
import Expand from "../../../images/Group 38.png";
import DeDown from "../../../images/Group 39.png";
import { Buffer } from "buffer";
import { publicEncrypt, constants } from "crypto-browserify";
import Config from "../../../Config/config";
import axios from "axios";

const LifestyleAdvice = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [visitHistory, setVisitHistory] = useState(null);
  const [recomendation, setrecomendation] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const UserID = JSON.parse(sessionStorage.getItem("UserID"));
      const PATIENT_ID = JSON.parse(sessionStorage.getItem("PATIENT_ID"));
      const publicKey = sessionStorage.getItem("RegpublicKey")
        ? sessionStorage.getItem("RegpublicKey")
        : JSON.parse(sessionStorage.getItem("publicKey"));
      const token = sessionStorage.getItem("Regtoken")
        ? sessionStorage.getItem("Regtoken")
        : JSON.parse(sessionStorage.getItem("token"));
      const padding = constants.RSA_PKCS1_PADDING;
      const dataBuffer = Buffer.from(UserID.toString(), "utf-8");
      const encrypted = publicEncrypt(
        { key: publicKey, padding: padding },
        dataBuffer
      ).toString("base64");

      let requestData = JSON.stringify({
        userId: UserID,
        id: PATIENT_ID,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${Config.apiURL}${Config.routes.getPatientVisitsAPI}?userId=${UserID}&id=${PATIENT_ID}`,
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "x-caller": encrypted,
        },
        data: requestData,
      };

      try {
        const response = await axios.request(config);
        setVisitHistory(response.data.data);

        const visitdata = response.data.data?.[0]?.VISIT_DATA;
        const visitData = JSON.parse(visitdata ?? '{}');
        const personalHistoryData = visitData?.personalHistory ?? '{}';
        const examinationHistoryData = visitData?.examinationHistory ?? '{}';

        const personalHistory = JSON.parse(personalHistoryData);
        const examinationHistory = JSON.parse(examinationHistoryData);

        const smoking = personalHistory?.isSmoking;
        const drinking = personalHistory?.isDrinking;
        const physicalActivity = personalHistory?.isPhysicalActivity;
        const bmi = examinationHistory?.bmi;

        //console.log("Smoking:", smoking);
        //console.log("Drinking:", drinking);
        //console.log("Physical Activity:", physicalActivity);
        //console.log("bmi", bmi);
        let requestbody = JSON.stringify({
          'ALCOHOL': drinking === 0 ? 1 : 0,
          'TOBACCO': smoking === 0 ? 1 : 0,
          'PHYSICAL_ACTIVITY': physicalActivity === 0 ? 1 : 0,
          'HIGH_BMI': (bmi ?? 0) >= 22 ? 1 : 0,
          'DRUG_RECOMMENDATION': 1
        });

        let configs = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${Config.apiURL}${Config.routes.getRecommendationsAPI}`,
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "x-caller": encrypted,
          },
          data: requestbody,
        };
        // console.log("i came here")
        try {
          const response = await axios.request(configs);
          setrecomendation(response.data.data);
        } catch (error) {
          console.log(error);
        }



      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);
  //console.log(" qwertyutr", recomendation)
  const recordset = recomendation.recordset || [];
  //console.log("recordset", recordset)



  const handleClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="total-accordion">
      {recordset && recordset.map((item, index) => (
        <div key={index} className="accordion-item">
          <div
            className={`accordion-heading ${activeIndex === index ? "active" : ""
              }`}
            onClick={() => handleClick(index)}
          >
            <div className="image-heading">
              <div className="accordion-image">
                <img src={Vegitable}></img>
              </div>
              <div className="expand">
                <div>
                  <h3 className="heading1">{item.ADVICE_CATEGORY}</h3>
                </div>
              </div>
              <div className="expandimages">
                {activeIndex === index ? (
                  <img src={Expand} alt="Expand" />
                ) : (
                  <img src={DeDown} alt="BackArrow" />
                )}
              </div>
            </div>

            <p className="sub-heading">{item.ADVICE}</p>
          </div>

          {activeIndex === index && (
            <div className="accordion-expansion">
              <p><div dangerouslySetInnerHTML={{ __html: item.ADVICE_DETAILS }} /></p>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default LifestyleAdvice;
