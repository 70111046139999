import React, { useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import Navbar from "../components/modules/Navbar";
import TestVisits from "../components/modules/TestVisits";
import "../pages/Patients.css";
import TestProfile from "../images/testVisitsProfile.svg";
import AddDocument from "../images/addDocument.png";
import Graphs from "../images/graph.svg";
import ConsolidatedReport from "../images/consolidatedReport.svg";
import Emergency from "../images/emergency.svg";
import InitialAssesments from "../images/initialAssesment.svg";
import ReviewAssessments from "../images/reviewsnassessments.svg";
import Arrow from "../images/arrow.svg";
import downArrow from "../images/downArrow.svg";
import QuestionsAnswers from "../components/modules/QuestionsAnswers";
import MedicalTab from "../components/modules/Visits/MedicalTab";
import PersonalHistoryTab from "../components/modules/Visits/PersonalHistory";
import leftArrow from "../images/leftArrow.svg";
import { useNavigate } from "react-router-dom";
import DrugHistory from "../components/modules/Visits/DrugHistoryTab";
import ExaminationTab from "../components/modules/Visits/Examination";
import Config from "../Config/config";
import { publicEncrypt, constants } from "crypto-browserify";
import { Buffer } from "buffer";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import PatientsInformation from "./patientsInformation";
import TopNavBar from "../components/modules/TopNavBar";

const TestVisit = () => {
  const [selectedOptions, setSelectedOptions] = useState({});
  const [activeSection, setActiveSection] = useState("Medical");
  var [selectedPatientHistory, setSelectedPatientHistory] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [popupMessage, setpopupMessage] = useState("");
  const [history, setHistory] = useState({
    MedicalHistory: "",
    DrugHistory: "",
    PersonalHistory: "",
    ExaminationHistory: "",
  });

  const UserID = sessionStorage.getItem("RegUserId")
    ? JSON.parse(sessionStorage.getItem("RegUserId"))
    : sessionStorage.getItem("UserID");

  const publicKey = sessionStorage.getItem("RegpublicKey")
    ? sessionStorage.getItem("RegpublicKey")
    : JSON.parse(sessionStorage.getItem("publicKey"));
  const token = sessionStorage.getItem("Regtoken")
    ? sessionStorage.getItem("Regtoken")
    : JSON.parse(sessionStorage.getItem("token"));

  const padding = constants.RSA_PKCS1_PADDING;
  const data = Buffer.from(UserID.toString(), "utf-8");
  const encrypted = publicEncrypt(
    { key: publicKey, padding: padding },
    data
  ).toString("base64");

  const PATIENT_ID = JSON.parse(sessionStorage.getItem("PATIENT_ID"));

  const handleOptionSelection = (option, question) => {
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      [question]: option, //Use question as the key to store selected option
    }));
  };

  const navigate = useNavigate();

  const handleSelectedPatientHistory = async () => {
    setSelectedPatientHistory(
      JSON.parse(sessionStorage.getItem("selectedPatientHistory"))
    );
    // const visitdata = JSON.parse(sessionStorage.getItem("visitData"));
    // await fetchData();
    // setHistory((prevValues) => ({
    //   ...prevValues,
    //   MedicalHistory: JSON.parse(sessionStorage.getItem("MedicalHistory")),
    //   DrugHistory: JSON.parse(sessionStorage.getItem("DrugHistory")),
    //   PersonalHistory: JSON.parse(sessionStorage.getItem("PersonalHistory")),
    //   ExaminationHistory: JSON.parse(
    //     sessionStorage.getItem("ExaminationHistory")
    //   ),
    // }));
  };

  const fetchData = async () => {
    let data = JSON.stringify({
      userId: UserID,
      id: PATIENT_ID,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${Config.apiURL}${Config.routes.getPatientVisitsAPI}`,
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "x-caller": encrypted,
      },
      data: data,
    };

    try {
      const response = await axios.request(config);
      let res = response.data.data[0].VISIT_DATA;
      //console.log("res-------", res);
      // setHistory((prevValues) => ({
      //   ...prevValues,
      //   MedicalHistory: (res.medicalHostory !== undefined || res.medicalHostory !== null) ? JSON.parse(res.medicalHostory) : null,
      //   DrugHistory: res.drugHistory !== undefined ? JSON.parse(res.drugHistory) : null,
      //   PersonalHistory: res.personalHistory !== undefined ? JSON.parse(res.personalHistory) : null,
      //   ExaminationHistory: res.examinationHistory !== undefined ? JSON.parse(res.examinationHistory) : null,
      // }));
    } catch (error) {
      console.log("Error fetching data:", error);
      return null; // Return null if an error occurs
    }
  };

  // const handleBackClick = () => {
  //   navigate("/patients");
  // };

  const handleBackClick = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const sourcePage = urlParams.get("source");
    const tab = urlParams.get("tab");

    if (sourcePage === "followups") {
      if (tab === "reminders") {
        navigate("/followups?tab=reminders");
      } else {
        navigate("/followups");
      }
    } else if (sourcePage === "appointments") {
      navigate("/appointments");
    } else {
      navigate("/patients");
    }
  };

  const toggleSection = (sectionName) => {
    setActiveSection(sectionName);
    
    // setIsLoading(true);
    // toast.success("Data saved Successfully!");
  };
  const togglePopUp = (name) => {
    console.log(name, "sdjidjsijdiojoj");
    setpopupMessage(name);
  };

  const toggleSectionOk = () => {
    setActiveSection(popupMessage);
    setpopupMessage("");
  };
  const navigateToPatients = () => {
    toast.success("Drug Uploaded Successfully!");
    setTimeout(() => {
      navigate("/patients");
    }, 2000);
    // navigate("/patients");
  };

  const TabsContainer = () => {
    return (
      <div className="initalAssementMainContainer">
        <div className="initalAssessmentIteams">
          <div className="initalAssementHeading">
            <h3>INITIAL ASSESSMENT</h3>
          </div>

          <div className="initalAssessmentButtons">
            <button
              className={`toggle-buttonTab ${
                activeSection === "Medical" ? "activeTab" : ""
              }`}
              onClick={() => togglePopUp("Medical")}
            >
              Medical History
            </button>
            <button
              className={`toggle-buttonTab ${
                activeSection === "Drug" ? "activeTab" : ""
              }`}
              onClick={() => togglePopUp("Drug")}
            >
              Drug History
            </button>
            <button
              className={`toggle-buttonTab ${
                activeSection === "Personal" ? "activeTab" : ""
              }`}
              onClick={() => togglePopUp("Personal")}
            >
              Personal History
            </button>
            <button
              className={`toggle-buttonTab ${
                activeSection === "Examination" ? "activeTab" : ""
              }`}
              onClick={() => togglePopUp("Examination")}
            >
              Examination
            </button>
          </div>

          <hr style={{ color: "rgba(212, 212, 212, 1)" }} />
        </div>
        <MedicalTab
          activeSection={activeSection}
          // MedicalHistory={history.MedicalHistory}
          toggleSection1={() => toggleSection("Drug")}
        />
        <DrugHistory
          isVisible={true}
          activeSection={activeSection}
          // DrugHistory={history.DrugHistory}
          // MedicalHistory={history.MedicalHistory}
          toggleSection2={() => toggleSection("Personal")}
          toggleSection0={() => toggleSection("Medical")}
        />
        <PersonalHistoryTab
          activeSection={activeSection}
          // PersonalHistory={history.PersonalHistory}
          toggleSection3={() => toggleSection("Examination")}
          toggleSection1={() => toggleSection("Drug")}
        />
        <ExaminationTab
          activeSection={activeSection}
          ExaminationHistory={history.ExaminationHistory}
          toggleSection2={() => toggleSection("Personal")}
          toggleSection4={navigateToPatients}
        />

        {popupMessage.length > 0 && (
          <div className="popup">
            <div
              className="popup-inner"
              style={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <p
                style={{
                  fontSize: "24px",
                  fontWeight: "600",
                  fontFamily: "bold",
                }}
              >
                Attention!!!
              </p>
              <p>
                To Save data the was entered in this form, Please use 'BACK' or
                'NEXT' button
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "2rem",
                  padding: "4%",
                }}
              >
                <button
                  className="ContinueBtn"
                  style={{
                    Background: "#52447d !important",
                    width:'7rem'
                  }}
                  onClick={toggleSectionOk}
                >
                  Ok
                  {/* {isLoading && (
                      <CircularProgress size={24} color="inherit" />
                    )} */}
                </button>

                <button
                  className="ContinueBtn"
                  onClick={() => setpopupMessage("")}
                  style={{
                    Background: "#52447d !important",
                     width:'7rem'
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      {/* <Navbar /> */}
      {/*<div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "86vh",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: "70vh",
            overflow: "scroll",
            gap: "1rem",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "5px",
              height: "10px",
              alignItems: "center",
              cursor: "pointer",
              marginTop: "1rem",
            }}
            onClick={handleBackClick}
          >
            <img src={leftArrow} style={{ width: "1rem", height: "1rem" }} />
            <p>Back</p>
          </div>
          <TestVisits
            handleSelectedPatientHistory={handleSelectedPatientHistory}
          />
          <TabsContainer />
          <ToastContainer/>
        </div>
      </div> */}
      {/* <div>
      <PatientsInformation/>

<TabsContainer/>
      </div> */}

      {/* <div className="Patient-MainContainer">
        <Navbar />
        <div className="mainpageComponent">
          <TopNavBar />
          <div className="Patient-TopNavbar" style={{height:'90vh',overflow:'scroll'}}>
            
              <div className="appointmentsListContainer" style={{overflow:'none'}}>
                <PatientsInformation />
                <TabsContainer />
             
            </div>
          </div>
        </div>
      </div> */}
      <TabsContainer />
      <ToastContainer />
    </>
  );
};

export default TestVisit;
