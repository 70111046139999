import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import Config from "../../Config/config";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { publicEncrypt, constants } from "crypto-browserify";
import { Buffer } from "buffer";
// import { config, config, config, config } from "process";
import "./loginForm.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Margin } from "@mui/icons-material";
const LoginForm = () => {
  const [users, setUsers] = useState({
    MOBILE: "",
    MOBILE_PIN: "",
    PASSWORD: "",
  });
  const [isPin, setIsPin] = useState(true);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const navigate = useNavigate();

  const authentication = "Basic " + btoa("mpoweruser:Cl!nA11y123!@#");

  const handleLogin = async () => {
    console.log(users,"users..............")
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${
        users.PASSWORD
          ? Config.apiURL + Config.routes.loginAPI
          : Config.apiURL + Config.routes.pinLogin
      }`,
      headers: {
        authorization: authentication,
        "content-type": "application/json",
      },
      data: users,
    };
    try {
      const response = await axios.request(config);
      //console.log("response====", response);

      sessionStorage.setItem(
        "publicKey",
        JSON.stringify(response.data.publicKey)
      );
      sessionStorage.setItem(
        "HOSPITAL_SETUP",
        JSON.stringify(response.data.userdata[0].HOSPITAL_SETUP)
      );
      sessionStorage.setItem("token", JSON.stringify(response.data.token));
      sessionStorage.setItem(
        "UserID",
        JSON.stringify(response.data.userdata[0].USER_ID)
      );
      sessionStorage.setItem(
        "UserMOBILE",
        JSON.stringify(response.data.userdata[0].MOBILE)
      );
      sessionStorage.setItem(
        "UserData",
        JSON.stringify(response.data.userdata)
      );
      sessionStorage.setItem(
        "City",
        JSON.stringify(response.data.userdata[0].CITY)
      );
      sessionStorage.setItem(
        "State",
        JSON.stringify(response.data.userdata[0].STATE)
      );
      sessionStorage.setItem(
        "UserPhoto",
        JSON.stringify(response.data.userdata[0].USER_PHOTO)
      );
      sessionStorage.removeItem("PATIENT_ID");
      sessionStorage.removeItem("VISIT_ID");

      // const padding = constants.RSA_PKCS1_PADDING;
      // const publicKey = JSON.stringify(response.data.publicKey)
      // const UserID = response.data.userdata[0].USER_ID
      // console.log("publick key", publicKey, "UserID", UserID)
      // const data = Buffer.from(UserID.toString(), "utf-8");
      // const encrypted = publicEncrypt(
      //   { key: publicKey, padding: padding },
      //   data
      // ).toString("base64");

      // const getUserData = await axios.get(
      //   `${Config.base_url}/GETUSERDATA/${response.data.userdata[0].USER_ID}`,
      //   {
      //     headers: {
      //       authorization: `Bearer ${JSON.stringify(response.data.token)}`,
      //       "x-caller": await encrypted,
      //     },
      //   }
      // );
      // console.log("getUserData response", getUserData)
      navigate("/patients");
    } catch (error) {
      if (error.message === "Network Error") {
        toast.error("Network Error: Please check your network connection.");
      } else {
        toast.error("Please login with a valid Mobile number and Pin"); 
      }
      console.error(error);

    }
  };

  const handleToggle = () => {
    setPasswordVisible(!passwordVisible);
  };

  const passwordStyle = {
    display: 'flex',
    backgroundColor: 'rgb(243, 238, 255)',
    borderRadius: '7px',
    width: '100%',
    border: 'none',
    alignItems: 'center',
    marginTop: '3%'
  };

  const handleLoginOptions = () => {
    setIsPin(!isPin);
  };

  return (
    <div>
      <form>
        {/*MOBILE NUMBER*/}
        <input style={{marginTop:"15px"}}
          type="number"
          name="phone"
          className="input"
          maxLength={10}
          value={users.MOBILE}
          onChange={(e) => {
            const { value } = e.target;
            // Allow only numbers and restrict to 10 digits
            const numericValue = value.replace(/\D/g, "").slice(0, 10);
            setUsers((prev) => {
              return {
                ...prev,
                MOBILE: numericValue,
              };
            });
          }}
          placeholder="Enter Mobile Number"
          required
        />

        {/*MOBILE_PIN & PASSWORD*/}
        {isPin ? (
          <div style={passwordStyle}>
          <input 
            type={passwordVisible ? "text" : "password"}
            className="input"
            maxLength={6}
            value={users.MOBILE_PIN}
            onChange={(e) => {
              let pin = e.target.value;
              pin = pin.replace(/\D/g, '');
              setUsers((prev) => {
                return {
                  ...prev,
                  MOBILE_PIN: pin,
                };
              });
            }}
            placeholder="Pin"
          />

          <span style={{padding:"0% 3%"}}
          className="flex justify-around items-center cursor-pointer"
          onClick={handleToggle}
        >
          {passwordVisible ? (
            <FiEyeOff className="absolute mr-10" size={20} />
          ) : (
            <FiEye className="absolute mr-10" size={20} />
          )}
        </span>

          </div>
        ) : (
          <input
            type="text"
            className="input"
            value={users.PASSWORD}
            // onChange={(e) => {
            //   setUsers((prev) => {
            //     return {
            //       ...prev,
            //       PASSWORD: e.target.value,
            //     };
            //   });
            // }}
            onChange={(e) => {
              let pin = e.target.value;
              pin = pin.replace(/\D/g, '');
              setUsers((prev) => {
                return {
                  ...prev,
                  PASSWORD: pin,
                };
              });
            }}
            placeholder="Password"
          />
        )}

        {/*LOGIN OPTIONS & Recover PASSWORD AND PIN*/}
        <div className="loginOptions">
          {isPin ? (
            <>
              {/* <button
                className="resend-otp"
                name="recover"
                onClick={handleLoginOptions}
              >
                Login With Password
              </button> */}
              <div></div>
              <Link to="/reset-pin">
                <button className="resend-otp" name="recover">
                  Recover Pin ?
                </button>
              </Link>
            </>
          ) : (
            <>
              <button
                className="resend-otp"
                name="recover"
                onClick={handleLoginOptions}
              >
                Login With Pin
              </button>
              <Link to="/reset-password">
                <button className="resend-otp" name="recover">
                  Recover Password ?
                </button>
              </Link>
            </>
          )}
        </div>

        {/*LOGIN BUTTON*/}
        <button
          type="button"
          className="send-otp"
          onClick={handleLogin}
          name="send"
        >
          Login
        </button>
      </form>
      <ToastContainer position="top-right" />
    </div>
  );
};

export default LoginForm;
