import React, { useState } from "react";
import "./TreatmentPlan.css";
import { ToastContainer, toast } from "react-toastify";
const Others = ({ onFormSubmit }) => {
  const [formData, setFormData] = useState({
    drugName: "",
    strength: "",
    frequency: "",
    instructions: "",
    duration: "",
    reason: "",
    remarks: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if(formData.drugName!==""&&
      formData.strength!== ""&&
      formData.frequency!== ""&&
      formData.instructions!== ""&&
      formData.duration!== ""
      // &&
      // formData.reason!== ""&&
      // formData.remarks!== ""
    ){
   

    var existingList = sessionStorage.getItem("addingDrugList");
    var drugList = existingList ? JSON.parse(existingList) : [];
    var uniqueIdentifier = formData.drugName; // Replace with the appropriate property
    var isDuplicate = drugList.some(item => item.drugName === uniqueIdentifier); // Modify 'id' accordingly
    console.log(isDuplicate, "other .............",drugList);
    if (!isDuplicate) {
      drugList.push(formData);
      sessionStorage.setItem("addingDrugList", JSON.stringify(drugList));
      //console.log("Item added to the list:", formData);
    } else {
      //console.log("Duplicate item not added:", formData);
    }
    
    onFormSubmit(formData);
    setFormData(formData);
    toast.success("Drug add  Successfully!")}
    setFormData({
      drugName: "",
      strength: "",
      frequency: "",
      instructions: "",
      duration: "",
      reason: "",
      remarks: "",
    })
    
    
  };

  return (
    <div>
      <div className="Hypertension-section">
        <form className="Hypertension-form" onSubmit={handleSubmit}>
          <div className="TreatmentplanInputBox">
            <input
              type="text"
              name="drugName"
              value={formData.drugName}
              onChange={handleChange}
              placeholder="Drug Name*"
              required
            />
            <input
              type="number"
              name="strength"
              value={formData.strength}
              onChange={handleChange}
              placeholder="Strength*"
              required
                 className="TreatmentplanInputBoxnumbers"
            />
          </div>

          <div className="frequncyinstructions">
            <select
              className="Treatmentplandropdown"
              name="frequency"
              value={formData.frequency}
              onChange={handleChange}
            >
              <option value="">Select Frequency</option>
              <option value="once">Once</option>
              <option value="twice">Twice</option>
              <option value="thrice">Thrice</option>
              <option value="four">Four</option>
            </select>

            <select
              className="Treatmentplandropdown"
              name="instructions"
              value={formData.instructions}
              onChange={handleChange}
              required
            >
              <option value="">Select Instructions*</option>
              <option value="beforefood">Before Food</option>
              <option value="afterfood">After Food</option>
            </select>
          </div>
          <div className="TreatmentplanInputBox">
            <input
              type="number"
              name="duration"
              value={formData.duration}
              onChange={handleChange}
              placeholder="Duration (in days)"
                 className="TreatmentplanInputBoxnumbers"
            />

            <input
              type="text"
              name="reason"
              value={formData.reason}
              onChange={handleChange}
              placeholder="Reason"
            />
          </div>
          <div>
            <input
              type="text"
              name="remarks"
              value={formData.remarks}
              onChange={handleChange}
              placeholder="Remarks"
            />
          </div>
          <div className="hypertension-submit">
            <button className="add" type="submit">
              ADD
            </button>
          </div>
          <ToastContainer/>
        </form>
      </div>
    </div>
  );
};

export default Others;
