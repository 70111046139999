import React from "react";
import { useRef, useEffect } from "react";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "./ArticleData.css";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import config from "../../../Config/config";
const Hypertension = () => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const hypertensionUrls = [
    {
      title: "Hypertension Articles",
      urls: [
        {
          type: "pdf",
          url: `${config.apiURL}/uploads/articlespdfs/hypertension-infographic-005-web.pdf`,
        // url:'https://incentivedashboard.clinally.com/CareContext/2203.pdf',
        },
        {
          type: "image",
          urls: [
            `${config.apiURL}/uploads/articlespdfs/one-in-4-men.jpg`,
            `${config.apiURL}/uploads/articlespdfs/one-in-5-women.jpg`,
            `${config.apiURL}/uploads/articlespdfs/control-it.jpg`,
            `${config.apiURL}/uploads/articlespdfs/no-symptoms.jpg`,
            `${config.apiURL}/uploads/articlespdfs/know-your-numbers.jpg`,
            `${config.apiURL}/uploads/articlespdfs/premature-death.jpg`,
            `${config.apiURL}/uploads/articlespdfs/prevalence.jpg`,
            `${config.apiURL}/uploads/articlespdfs/prevent-it.jpg`,
            `${config.apiURL}/uploads/articlespdfs/hearts.jpg`,
            //not working "https://mpowerqa.clinally.com/uploads/articlespdfs/who-005446-orig.tmb-1024c.jpg",
          ],
        },
        {
          type: "link",
          url: "https://www.who.int/health-topics/hypertension#",
          title: "Hypertension",
          description: "Hypertension",
          image:
            "https://www.who.int/images/default-source/departments/ncds/cardiovascular-diseases/who-005446-orig.tmb-1366v.jpg?Culture=en&sfvrsn=27864450_8%201366w",
        },
      ],
    },
  ];

  return (
    <>
    <div className="Articles-digitalHealth">
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js" />

        {hypertensionUrls[0].urls.map((item, index) => {
          if (item.type === "pdf") {
            return (
              <div className="articles-hyper-pdf">
                <Viewer
                  key={index}
                  fileUrl={item.url}
                  plugins={[defaultLayoutPluginInstance]}
                  
                />
              </div>
            );
          } else if (item.type === "image") {
            return item.urls.map((imageUrl, imageIndex) => (
              <div className="articles-hyper-image">
                <img key={imageIndex} crossorigin="anonymous" src={imageUrl} />
              </div>
            ));
          } else if (item.type === "link") {
            return (
              <div className="articles-hyper-link">

                <a href={item.url} target="_blank" rel="noopener noreferrer">
                  <div>
                    <p>{item.url}</p>
                  </div>
                </a>
                <h3>{item.title}</h3>
                <p>{item.description}</p>
                <img src={item.image} alt="Link Image" />

              </div>
            );
          }
          return null;
        })}
      </div>
    </>
  );
};

export default Hypertension;
