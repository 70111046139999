import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../firebase/firebase.js";
import Axios from "axios";
import { signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Config from "../../Config/config.jsx";

const Test = () => {
  const [users, setUsers] = useState({
    MOBILE: "",
    MOBILE_PIN: "",
    PASSWORD: "",
  });
  const [passwordMatch, setPasswordMatch] = useState({
    value: "",
    match: false,
    lengthValid: false,
    lowercaseValid: false,
    uppercaseValid: false,
    numericValid: false,
    specialCharValid: false,
  });
  const [activeSection, setActiveSection] = useState("MobileNumberWithSendOTP");
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();

  const authentication = "Basic " + btoa("mpoweruser:Cl!nA11y123!@#");

  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(auth, "recaptcha", {
      size: "invisible",
      callback: (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        // ...
      },
    });
  };

  const handleSend = (event) => {
    event.preventDefault();
    generateRecaptcha();
    const phone = "+91" + users.MOBILE;
    //console.log(phone);
    let appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, phone, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        //console.log(confirmationResult);
      })
      .catch((error) => {
        // Error; SMS not sent
        console.log(error);
      });
    setActiveSection("EnterOTPWithVerifyOTP");
  };

  const handleResend = (event) => {
    event.preventDefault();
    // generateRecaptcha();
    const phone = "+91" + users.MOBILE;
    let appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, phone, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        //console.log(confirmationResult);
      })
      .catch((error) => {
        // Error; SMS not sent
        console.log(error);
      });
  };

  const verifyOtp = (e) => {
    e.preventDefault();
    if (otp.length === 6) {
      // verify otp
      let confirmationResult = window.confirmationResult;
      confirmationResult
        .confirm(otp)
        .then((result) => {
          // User signed in successfully.
          let user = result.user;
          //console.log(user);
          setActiveSection("SubmitPassword");
          // alert("User signed in successfully");
          // ...
        })
        .catch((error) => {
          console.log(error);
          toast.error("User couldn't sign in (bad verification code?)");
        });
    }
  };

  const handleRegister = () => {
    //console.log("hiiii coming");

    let payload = {
      MOBILE: "7993632299",
      PASSWORD: "Harshi@1",
      MOBILE_PIN: "1234",
      FCM_TOKEN: "",
    };

    // console.log(
    //   "payload",
    //   JSON.stringify(payload),
    //   Config.routes.apiAuthentication,
    //   Config.base_url + "signup"
    // );

    Axios.post(Config.base_url + "signup", payload, {
      headers: {
        authorization: authentication,
        "content-type": "application/json",
      },
    })
      .then((response) => {
        //console.log("response", response);

        if (response.status === 201) {
          //console.log("coming to if");
          alert("User Registered Successfully");
          //console.log(JSON.stringify(response));
          sessionStorage.setItem("RegpublicKey", response.data.publicKey);
          sessionStorage.setItem("Regtoken", response.data.token);
          sessionStorage.setItem("RegUserId", response.data.data.userId);
          navigate("/profile?action=register");
        } else {
          //console.log("coming to else");
        }
        // navigate("/patients");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    //console.log("newPassword", newPassword);
    setUsers({
      ...users,
      PASSWORD: newPassword,
    });
    setPasswordMatch({
      ...passwordMatch,
      lengthValid: newPassword.length >= 8,
      lowercaseValid: /[a-z]/.test(newPassword),
      uppercaseValid: /[A-Z]/.test(newPassword),
      numericValid: /\d/.test(newPassword),
      specialCharValid: /[!@#$%^&*]/.test(newPassword),
    });
  };

  const handleRePasswordChange = (e) => {
    const newRePassword = e.target.value;
    //console.log("newRePassword", newRePassword);
    setPasswordMatch({
      ...passwordMatch,
      value: newRePassword,
      match: newRePassword === users.PASSWORD,
    });
  };

  return (
    <div>
      <button className="send-otp" onClick={handleRegister} name="send">
        SET PIN
      </button>
    </div>
  );
};

export default Test;
