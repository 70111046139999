import React from "react";
import "./Billing.css";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Axios from "axios";
import { publicEncrypt, constants } from "crypto-browserify";
import Config from "../../../Config/config";
import axios from "axios";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import Pdf from "../../../assets/pdf1.png";
import {
  Page,
  Text,
  Image,
  Document,
  StyleSheet,
  View,
  pdf,
} from "@react-pdf/renderer";

import numWords from "num-words";
import styles from "./styles";

import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { config } from "process";
const Billing = () => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const [billingDetails, setBillingDetails] = useState({
    serviceName: "",
    quantity: "",
    amount: "",
    payName: "",
    modePayment: "",
  });

  const [items, setItems] = useState([]);
  const [overlltotal, setoverlltotal] = useState(0.0);
  const [GenerateInvoice, setGenerateInvoice] = useState(0);
  const [recodsList, setrecodsList] = useState([]);
  const [DisplayItem, setDisplayItem] = useState("Billing");
  const UserID = sessionStorage.getItem("RegUserId")
    ? JSON.parse(sessionStorage.getItem("RegUserId"))
    : sessionStorage.getItem("UserID");

  const PATIENT_ID = sessionStorage.getItem("PATIENT_ID")
    ? JSON.parse(sessionStorage.getItem("PATIENT_ID"))
    : sessionStorage.getItem("PATIENT_ID");

  const mobile = sessionStorage.getItem("RegMobileNum")
    ? sessionStorage.getItem("RegMobileNum")
    : sessionStorage.getItem("UserMOBILE");

  const publicKey = sessionStorage.getItem("RegpublicKey")
    ? sessionStorage.getItem("RegpublicKey")
    : JSON.parse(sessionStorage.getItem("publicKey"));
  const token = sessionStorage.getItem("Regtoken")
    ? sessionStorage.getItem("Regtoken")
    : JSON.parse(sessionStorage.getItem("token"));

  const padding = constants.RSA_PKCS1_PADDING;
  const data = Buffer.from(UserID.toString(), "utf-8");
  const encrypted = publicEncrypt(
    { key: publicKey, padding: padding },
    data
  ).toString("base64");

  const userDetails = JSON.parse(sessionStorage.getItem("UserData"))[0];
  //console.log("User------", userDetails);

  const patient_id = sessionStorage.getItem("PATIENT_ID");
  //console.log("patient_id", patient_id);

  const patientDetails = JSON.parse(
    sessionStorage.getItem("selectedPatientDetails")
  );
  const selectedPatientHistory = JSON.parse(
    sessionStorage.getItem("selectedPatientHistory")
  );

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const UserID = JSON.parse(sessionStorage.getItem("UserID"));
  //     const PATIENT_ID = JSON.parse(sessionStorage.getItem("PATIENT_ID"));
  //     let data = JSON.stringify({
  //       userId: UserID,
  //       id: PATIENT_ID,
  //     });

  //     let config = {
  //       method: "post",
  //       maxBodyLength: Infinity,
  //       url: `${Config.apiURL}${Config.routes.getPatientVisitsAPI}`,
  //       headers: {
  //         authorization: `Bearer ${token}`,
  //         "Content-Type": "application/json",
  //         "x-caller": encrypted,
  //       },
  //       data: data,
  //     };

  //     axios
  //       .request(config)
  //       .then((response) => {
  //         console.log("visit history-----", response.data.data)
  //         setVisitData(response.data.data);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   };

  //   fetchData();
  // }, []);

  useEffect(() => {
    getRecods();
  }, [GenerateInvoice]);

  const getRecods = async () => {
    console.log("UserID", UserID);
    Axios.get(
      `${Config.apiURL}${Config.routes.getInvoiceRecordsAPI}?id=${patientDetails?.USER_ID}&userId=${UserID}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
          "x-caller": await encrypted,
        },
      }
    )
      .then((response) => {
        // console.log("response :", response);
        if (response.status === 200) {
          console.log("respones:", response.data.data);
          setrecodsList(response.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function NewDay() {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date().toLocaleDateString(undefined, options);
  }

  function formatDate(dateString) {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      throw new Error("Invalid date format");
    }

    const day = date.getDate().toString().padStart(2, "0");
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;

    return formattedDate;
  }

  // console.log(
  //   patientDetails,
  //   "userDetails..........",
  //   selectedPatientHistory,
  //   "dkkd"
  // );
  function fileformatDate() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}${month}${day}_${hours}${minutes}${seconds}`;
  }
  const convertFileToBytes = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const arrayBuffer = e.target.result;
        const bytes = new Uint8Array(arrayBuffer);
        resolve(bytes);
      };
      reader.onerror = (e) => {
        reject(e);
      };
      reader.readAsArrayBuffer(file);
    });
  };

  function getUniqueValues(data, propertyName) {
    const values = data.map((item) => item[propertyName]);
    const uniqueValues = [...new Set(values)];

    const uniqueValuesString = uniqueValues.join(", ");
    // console.log(`${propertyName}: ${uniqueValuesString}`);

    return uniqueValuesString;
  }
  const PDFFile = () => {
    return (
      <Document>
        <Page style={styles.body}>
          <View style={styles.user}>
            <View style={styles.userInfo}>
              <Text style={styles.dctrname}>
                Mr {userDetails.FIRST_NAME} {userDetails.LAST_NAME}
              </Text>
              <Text style={styles.email}>
                Email: {userDetails.EMAIL} Phone: {userDetails.MOBILE}
              </Text>
            </View>
            <View>
              <Image
                style={styles.image}
                source={{ uri: "/static/media/logo.f691f45d4d71e34ff640.png" }}
              />
            </View>
          </View>
          <View style={styles.hr}>
            <hr />
          </View>
          <Text style={styles.presc}>Patient Details</Text>
          <View style={styles.container}>
            <View style={{ flex: 1, flexDirection: "row" }}>
              <Text style={[styles.patient, { flex: 1 }]}>
                Patient Name : {patientDetails.FIRST_NAME}
                {"\n"}
                Visit Id :{selectedPatientHistory?.VISIT_ID}
                {"\n"}
                Mobile Number : {patientDetails.MOBILE}
                {"\n"}
                Visit Date : {formatDate(selectedPatientHistory?.VISIT_DATE)}
                {"\n"}
                Consultant : Dr {userDetails.FIRST_NAME}
                {"\n"}
              </Text>
              <Text style={[styles.patient, { flex: 1 }]}>
                Gender/Age : {patientDetails.GENDER}/{patientDetails.age}
                {"\n"}
                Patient ID : {patientDetails.USER_ID}
                {"\n"}
                Payee Name : {getUniqueValues(items, "payName")}
                {"\n"}
                Bill NO :{recodsList.length + 1}
                {"\n"}
                Bill Date :{NewDay()}
              </Text>
            </View>
          </View>
          <Text>Payment Details</Text>

          <View style={styles.table}>
            <View style={styles.tableRow}>
              <Text style={styles.tableColHeader}>Service Name</Text>
              <Text style={styles.tableColHeader}>Quantity</Text>
              <Text style={styles.tableColHeader}>Price</Text>
              <Text style={styles.tableColHeader}>Amount</Text>
            </View>
            {items.length > 0 ? (
              <>
                {console.log(items, "dsjisjidsjijsiljs...........")}
                {items &&
                  items.map((drug, index) => (
                    <View style={styles.tableRow2}>
                      <Text style={styles.tableCol2}>{drug.serviceName}</Text>
                      <Text style={styles.tableCol2}>{drug.quantity}</Text>
                      <Text style={styles.tableCol2}>{drug.amount}</Text>
                      <Text style={styles.tableCol2}>{drug.total}</Text>
                    </View>
                  ))}
              </>
            ) : (
              // </View>
              <Text></Text>
            )}
          </View>

          <View style={styles.hr1}>
            <hr />
          </View>
          <Text style={styles.hd}>
            {/* Diabetes Diagnosis - {dbdiagnosis.diagnosis ? "yes" : "no"} */}
          </Text>
          <Text style={styles.totalAmount}>Total : {overlltotal}</Text>
          <View style={styles.hr1}>
            <hr />
          </View>
          <View style={styles.billingcontainer}>
            <View style={{ flex: 1, flexDirection: "row" }}>
              <Text style={[styles.billing, { flex: 1 }]}>
                Mode Of Payment : {getUniqueValues(items, "modePayment")}
                {"\n"}
                Billed By :{patientDetails.FIRST_NAME}
                {"\n"}
              </Text>
              <Text style={[styles.billing, { flex: 1 }]}>
                In Words : {numWords(overlltotal)}
                {"\n"}
                Printed On : {NewDay()}
              </Text>
            </View>
          </View>
        </Page>
      </Document>
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "amount" || name === "quantity") {
      if (!/^\d*$/.test(value)) {
        return;
      }
    }
    setBillingDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleAddItem = () => {
    const { serviceName, quantity, amount, payName, modePayment } =
      billingDetails;
    if (
      serviceName &&
      payName &&
      modePayment &&
      quantity &&
      !isNaN(quantity) &&
      parseInt(quantity) > 0 &&
      amount &&
      !isNaN(amount) &&
      parseInt(amount) > 0
    ) {
      const total = parseInt(quantity) * parseInt(amount);
      setItems((prevItems) => [
        ...prevItems,
        { serviceName, quantity, amount, total, payName, modePayment },
      ]);
      setBillingDetails({
        serviceName: "",
        quantity: "",
        amount: "",
        payName: "",
        modePayment: "",
      });
      setoverlltotal(overlltotal + total);
    } else {
      toast.error("Please fill in all fields with valid values");
    }
  };
  const BackButton = () => {
    setDisplayItem("Billing");
  };

  const handelGenerateInvoice = () => {
    if (items.length > 0) {
      UploadBilling();
      setDisplayItem("GenerateInvoice");
    } else {
      toast.warning("Please ADD Minimum One Billing Invoice Details");
    }
  };
  // const UploadBillingData = async () => {
  //   try {
  //     const data = {
  //       USER_ID: UserID,
  //       PATIENT_ID: PATIENT_ID,
  //     };
  //     console.log("data", data);
  //     var options = {
  //       method: "POST",
  //       url: `${Config.base_url}upload_invoice`,
  //       headers: {
  //         authorization: `Bearer ${token}`,
  //         "content-type": "multipart/form-data",
  //         "x-caller": encrypted,
  //       },
  //       data: data,
  //     };

  //     Axios(options)
  //       .then(function (response) {
  //         if (response.status === 201) {
  //           console.log("Response Body:", response.data);
  //         } else if (response.status === 404) {
  //           console.warn("Response Body:", response.data);
  //         } else {
  //           console.warn("Error: An unknown error occurred.", response.data);
  //         }
  //       })
  //       .catch(function (error) {
  //         console.error("Error:", error);
  //       });
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // };
  const UploadBilling = async () => {
    try {
      const blobBuffer = await pdf(<PDFFile />).toBlob();
      const fileBytes = await convertFileToBytes(blobBuffer);
      const data = new FormData();
      const blob = new Blob([fileBytes], { type: "application/pdf" });

      // Ensure the field name matches the server expectation
      data.append("files", blob, `Patient-Invoice-${fileformatDate()}.pdf`);
      data.append(
        "data",
        JSON.stringify({
          USER_ID: UserID,
          PATIENT_ID: PATIENT_ID,
          BILL_NO: recodsList.length + 1,
        })
      );

      const options = {
        method: "POST",
        url: `${Config.base_url}upload_invoice`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
          "x-caller": await encrypted,
        },
        data,
      };
      const response = await axios(options);
      //console.log(response.data, "data-----");
      if (response.status === 201) {
        toast.success("File Uploaded Successfully!");
      } else if (response.status === 404) {
        toast.warning("Sorry", "File is not uploaded ");
      } else {
        toast.error(
          "An unknown error occurred.Please try again after sometime"
        );
      }
    } catch (error) {
      console.error("Error uploading PDF:", error);
    }
  };
  return (
    <>
      <div className="BillingMaincontainer">
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js" />
        <div className="BillingHeading">
          <h3>BILLING</h3>
          <button
           className="backbutton"
           style={{margin:'0'}}
            onClick={() => setDisplayItem("GenerateInvoiceList")}
          >
            View Records
          </button>
        </div>
        {DisplayItem === "Billing" && (
          <>
            <div style={{ margin: "5%" }}>
              <div
                className="BillingInvoice"
                style={{ justifyContent: "left" }}
              >
                <div className="BillingInvoiceHeader">
                  <h4>Billing Invoice</h4>
                </div>
                <div className="BillingInputfields">
                  <div className="patientDetailsContainer">
                    <div className="addp-inputField">
                      <label htmlFor="serviceName" className="description">
                        Service Name
                      </label>
                      <input
                        type="text"
                        name="serviceName"
                        value={billingDetails.serviceName}
                        onChange={handleChange}
                        className="addp-patientsInputField"
                      />
                    </div>
                    <div className="addp-inputField">
                      <label htmlFor="amount" className="description">
                        Amount
                      </label>
                      <input
                        type="text"
                        name="amount"
                        value={billingDetails.amount}
                        onChange={handleChange}
                        className="addp-patientsInputField"
                      />
                    </div>
                  </div>
                  <div className="patientDetailsContainer">
                    <div className="addp-inputField">
                      <label htmlFor="quantity" className="description">
                        Quantity
                      </label>
                      <input
                        type="text"
                        name="quantity"
                        value={billingDetails.quantity}
                        onChange={handleChange}
                        className="addp-patientsInputField"
                      />
                    </div>
                    <div className="addp-inputField">
                      <label htmlFor="modePayment" className="description">
                        Mode of Payment
                      </label>
                      <select
                        name="modePayment"
                        value={billingDetails.modePayment}
                        onChange={handleChange}
                        className="addp-patientsInputField"
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        <option value="Cash">Cash</option>
                        <option value="Credit Card">Credit Card</option>
                        <option value="Debit Card">Debit Card</option>
                        <option value="Net Banking">Net Banking</option>
                        <option value="UPI">UPI</option>
                      </select>
                    </div>
                  </div>
                  <div className="patientDetailsContainer">
                    <div className="addp-inputField">
                      <label htmlFor="payName" className="description">
                        Payee Name
                      </label>
                      <input
                        type="text"
                        name="payName"
                        value={billingDetails.payName}
                        onChange={handleChange}
                        className="addp-patientsInputField"
                      />
                    </div>
                  </div>
                </div>
                <div className="addp-addPatientsButtonContainer">
                  <button
                    className="add-addPatientsButton"
                    onClick={handleAddItem}
                  >
                    Add Item
                  </button>
                  <button
                    className="add-addPatientsButton"
                    onClick={() => {
                      // setGenerateInvoice(!GenerateInvoice);
                      handelGenerateInvoice();
                    }}
                  >
                    Generate Invoice
                  </button>
                </div>
                <div className="BillingItems">
                  {items.length > 0 && (
                    <>
                      <div>
                        <table className="BillingIteams">
                          <thead>
                            <tr>
                              <th>Service Name</th>
                              <th>Quantity</th>
                              <th>Unit Price</th>
                              <th>Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            {items.map((item, index) => (
                              <tr key={index}>
                                <td>{item.serviceName}</td>
                                <td>{item.quantity}</td>
                                <td>{item.amount}</td>
                                <td>{item.total}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
        {DisplayItem === "GenerateInvoice" && (
          <>
            <button onClick={BackButton} className="backbutton">
              Back
            </button>
            <PDFViewer
              style={{ width: "100%", height: "100vh", marginTop: "3%" }}
            >
              <PDFFile />
            </PDFViewer>
          </>
        )}

        {DisplayItem === "GenerateInvoiceList" && (
          <>
            <div style={{ display: "flex", margin: "1% 0%" }}>
              <div style={{ width: "30%", marginRight: "2%" }}>
                <button onClick={BackButton} className="backbutton">
                  Back
                </button>
                <div style={{ marginTop: "10px" }}>
                {recodsList?.map((pdf, index) => (
                  <li key={index}>
                    <div
                      className="generatepdf"
                      onClick={() => setGenerateInvoice(index)}
                      style={{background:index===GenerateInvoice?'#ffd8d8':'',height: "auto !important"}}
                    >
                      <div>
                        <img src={Pdf} alt="PDF" />
                      </div>
                      <div style={{ marginLeft: "1%" }}>
                        <div style={{ color: "#52447D", fontSize: "90%" }}>
                          {pdf.RECORD_FILE}
                        </div>
                        <p style={{ margin: "1%", fontSize: "65%" }}>
                          Record#: {pdf.BILL_NO}
                        </p>
                      </div>
                    </div>
                  </li>
                ))}</div>
              </div>
              <div style={{ width: "70%" }}>
                {console.log(
                  recodsList?.[GenerateInvoice]?.RECORD_FILE,
                  "items?.[GenerateInvoice]........",
                  GenerateInvoice,
                  recodsList
                )}
                <div className="articles-hyper-pdf">
                  <Viewer
                    fileUrl={`${Config.apiURL}/uploads/${recodsList?.[GenerateInvoice]?.RECORD_FILE}`}
                    plugins={[defaultLayoutPluginInstance]}
                  />
                </div>
              </div>
            </div>
          </>
        )}

        <ToastContainer />
      </div>
    </>
  );
};

export default Billing;
