import React from 'react';
import PropTypes from 'prop-types';
import { MDBTable, MDBTableBody, MDBTableHead, MDBBtn } from 'mdb-react-ui-kit';
import './TablecomponentFollowups.css';

// Utility function to check if a string is a valid URL
const isValidUrl = (string) => {
  try {
    new URL(string);
    return true;
  } catch (error) {
    return false;
  }
};


const TablecomponentFollowups = ({
  data,
  columns,

}) => {
  return (
    <div className='FollowUpsTable'>
    <MDBTable align='middle' className='custom-table'>
      <MDBTableHead light>
        
        <tr>
          {columns.map((col, index) => (
            <th key={index}>{col.label}</th>
          ))}
        </tr>
        
      </MDBTableHead>
      <MDBTableBody>
        {data.map((row, index) => (
          <tr key={index}  className='custom-row'>
            {columns.map((col, colIndex) => (
              <td key={colIndex}>
                {col.field === 'profile' && isValidUrl(row[col.field]) ? (
                  <img src={row[col.field]} alt="Profile" className="profile-pic" />
                ) : (
                  row[col.field]
                )}
              </td>
            ))}
            
            {/*<td>
              <MDBBtn
                color='primary'
                className='link-abha-btn'
                onClick={(e) => {
                  e.stopPropagation();
                  onButtonClick(row);
                }}
              >
                {row.buttonLabel || 'Link ABHA'}
              </MDBBtn>
              <span className='three-dots'>⋮</span>
            </td>*/}
          </tr>
        ))}
      </MDBTableBody>
    </MDBTable>
    </div>
  );
};

// Define PropTypes for better validation and documentation
TablecomponentFollowups.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      field: PropTypes.string.isRequired,
    })
  ).isRequired,

};

// Provide default props for columns and data
TablecomponentFollowups.defaultProps = {
  data: [
    // {
    //   profile: 'Profile Text Here', // Text instead of a placeholder image
    //   name: 'Eswar Sampath',
    //   gender: 'Male',
    //   mobile: '+91 999 999 99 99',
    //   lastVisited: '08 May, 2024',
    // },
  ],
  columns: [
    // { label: 'Profile', field: 'profile' },
    // { label: 'Name', field: 'name' },
    // { label: 'Gender', field: 'gender' },
    // { label: 'Mobile Number', field: 'mobile' },
    // { label: 'Last Visited', field: 'lastVisited' },
  ],

};

export default TablecomponentFollowups;
