// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

import { getAuth, GoogleAuthProvider, FacebookAuthProvider, TwitterAuthProvider, GithubAuthProvider } from 'firebase/auth'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCZrZswKLUyi8xYaT_0kfXNmQE5AComl3E",
  authDomain: "clinally-32146.firebaseapp.com",
  databaseURL: "https://clinally-32146-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "clinally-32146",
  storageBucket: "clinally-32146.appspot.com",
  messagingSenderId: "159980966414",
  appId: "1:159980966414:web:d38d8e5c8f1ee22fa1a25f",
  measurementId: "G-DY12X7H8JL"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const google = new GoogleAuthProvider();
export const facebook = new FacebookAuthProvider();
export const twitter = new TwitterAuthProvider();
export const github = new GithubAuthProvider();