// import React, { useEffect, useState } from "react";
// import Navbar from "../Navbar";
// import "./ReviewPage.css";
// import Diagnosis from "./Diagnosis";
// import { useNavigate } from "react-router-dom";
// import BackArrow from "../../../images/Vector 34.svg";
// import { publicEncrypt, constants } from "crypto-browserify";
// import { Buffer } from "buffer";
// import Axios from "axios";
// import { Snackbar, Button, CircularProgress, Alert } from "@mui/material";
// import config from "../../../Config/config";
// import Config from "../../../Config/config";
// import axios from "axios";

// const Prescription = () => {
//   const userDetails = JSON.parse(sessionStorage.getItem("UserData"))[0];
//   const UserID = sessionStorage.getItem("RegUserId")
//     ? JSON.parse(sessionStorage.getItem("RegUserId"))
//     : sessionStorage.getItem("UserID");

//   const publicKey = sessionStorage.getItem("RegpublicKey")
//     ? sessionStorage.getItem("RegpublicKey")
//     : JSON.parse(sessionStorage.getItem("publicKey"));

//   const token = sessionStorage.getItem("Regtoken")
//     ? sessionStorage.getItem("Regtoken")
//     : JSON.parse(sessionStorage.getItem("token"));

//   const padding = constants.RSA_PKCS1_PADDING;
//   const data = Buffer.from(UserID.toString(), "utf-8");
//   const encrypted = publicEncrypt(
//     { key: publicKey, padding: padding },
//     data
//   ).toString("base64");

//   const PATIENT_ID = JSON.parse(sessionStorage.getItem("PATIENT_ID"));

//   const [visitData, setVisitData] = useState();
//   var cheifComplaint = [];

//   const navigate = useNavigate();

//   const patientDetails = JSON.parse(
//     sessionStorage.getItem("selectedPatientDetails")
//   );

//   const VISIT_ID = sessionStorage.getItem("VISIT_ID");
//   const fetchDataVisitHistory = async () => {
//     console.log("lsijdiojdsioj")
//     try {
//       let data = JSON.stringify({
//         userId: UserID,
//         id: PATIENT_ID,
//       });

//       //console.log("data in visits", data);

//       let config = {
//         method: "post",
//         maxBodyLength: Infinity,
//         url: `${Config.apiURL}${Config.routes.getPatientVisitsAPI}`,
//         headers: {
//           authorization: `Bearer ${token}`,
//           "Content-Type": "application/json",
//           "x-caller": encrypted,
//         },
//         data: data,
//       };

//       const response = await axios.request(config);
//       //console.log("Response:", response.data.data[0].VISIT_DATA);

//       const sessionVisitID = parseInt(VISIT_ID, 10);

//       const filteredRecords = response.data.data.filter((record) => {
//         return record.VISIT_ID === sessionVisitID;
//       });
//       //console.log("Filtered Records:", filteredRecords, typeof VISIT_ID);

//       let res = await filteredRecords[0].VISIT_DATA;
//       //console.log("---res----", res);

//       let parsedRes = res ? JSON.parse(res) : null;

//       // setVisitData(parsedRes);
//       // console.log("---res----,,,....ss",VISIT_ID,response.data.data,filteredRecords,filteredRecords[0].VISIT_DATA);
//     } catch (err) {
//       //console.log(err, "err");
//     }
//   };

//   useEffect(() => {

//     fetchDataVisitHistory();
//   }, [visitData]);

//   // const visitHistory = sessionStorage.getItem("visitData");
//   // const visitData =
//   //   visitHistory !== null
//   //     ? JSON.parse(sessionStorage.getItem("visitData"))
//   //     : null;

//   const MedicalHistoryData =
//     visitData && visitData.medicalHistory ? visitData.medicalHistory : null;
//   //console.log("MedicalHistoryData", MedicalHistoryData);

//   if (MedicalHistoryData !== null || MedicalHistoryData !== undefined) {
//     const parsedM = JSON.parse(MedicalHistoryData);
//     //console.log("parse", parsedM);
//     cheifComplaint =
//       parsedM && parsedM.cheifComplaint ? parsedM.cheifComplaint : null;
//     // setCheifComplaint(cheifComplaint)
//     // console.log("chiefComplaint", cheifComplaint);
//   }

//   const DrugHistoryData = !!visitData
//     ? JSON.parse(visitData.drugHistory)
//     : null;
//   //console.log("DrugHistoryData", DrugHistoryData);

//   const PersonalHistoryData = !!visitData
//     ? JSON.parse(visitData.personalHistory)
//     : null;

//   // console.log("PersonalHistoryData", PersonalHistoryData);

//   const ExaminationHistoryData = !!visitData
//     ? JSON.parse(visitData.examinationHistory)
//     : null;

//   //console.log("ExaminationHistoryData", ExaminationHistoryData);

//   const alternativeNames = {
//     isDiabetes: "Diabetes",
//     isHypertension: "Hypertension",
//     isAsthma: "Asthma",
//     isCKD: "Chronic Kidney Disease",
//     isCOPD: "Chronic Obstructive Pulmonary Disease(COPD)",
//     isFamilyCardioVascular: "Cardiovascular",
//     isFamilyDiabetes: "Diabetes Mellitus",
//     isFamilyHypertension: "Hypertension",
//     isFamilyOtherDisease: "Other Diseases",
//     isHeartAttack: "Heart Attack",
//     isHeartDisease: "Coronary Heart Disease",
//     isLiverFailure: "Liver Failure",
//     isPVD: "Peripheral Vascular Disease",
//     isStroke: "Stroke",
//   };

//   const medicalHistoryData = MedicalHistoryData
//     ? JSON.parse(MedicalHistoryData)
//     : null;
//   // Filter keys with a value of zero
//   const keysWithZeroValue = medicalHistoryData
//     ? Object.keys(medicalHistoryData).filter(
//         (key) => medicalHistoryData[key] === 0
//       )
//     : [];
//   // console.log("Keys with zero value:", keysWithZeroValue,);

//   const thirdSection = keysWithZeroValue.filter((key) =>
//     [
//       "isFamilyDiabetes",
//       "isFamilyHypertension",
//       "isFamilyOtherDisease",
//       "isHeartAttack",
//     ].includes(key)
//   );

//   const dateString = patientDetails?.DATE_OF_BIRTH;
//   const dateObject = new Date(dateString);
//   const formattedDate = dateObject.toISOString().split("T")[0];

//   const handleBackClick = () => {
//     navigate("/patients");
//   };

//   const alternativePersonalHistoryNames = {
//     isDrinking: "Drinking",
//     isOnDialysis: "On Dialysis",
//     isPhysicalActivity: "Physical Activity",
//     isPolydypsia: "Polydypsia",
//     isPolyuria: "Polyuria",
//     isSmoking: "Smoking",
//     isWeightloss: "Weight Loss",
//   };

//   const alternateEmergencyNames = {
//     isAlteredConciousness: "Altered Consciousness",
//     isBreathlessness: "Breathlessness",
//     isChestPain: "Agina(Chesr Pain)",
//     isDeficit: "Deficit",
//     isEdema: "Edema",
//     isFundus: "Fundus",
//     isGeneralizedSeizures: "Generalized Seizures",
//   };

//   const keysToConsider = [
//     "isAlteredConciousness",
//     "isBreathlessness",
//     "isChestPain",
//     "isDeficit",
//     "isEdema",
//     "isFundus",
//     "isGeneralizedSeizures",
//   ];

//   const totalDrugs =
//     (DrugHistoryData?.currentHTDrugs?.length || 0) +
//     ((DrugHistoryData?.currentDBDrugs &&
//       DrugHistoryData.currentDBDrugs.length) ||
//       0) +
//     ((DrugHistoryData?.currentInsulin &&
//       DrugHistoryData.currentInsulin.length) ||
//       0) +
//     ((DrugHistoryData?.currentLipid && DrugHistoryData.currentLipid.length) ||
//       0) +
//     ((DrugHistoryData?.currentAsprin && DrugHistoryData.currentAsprin.length) ||
//       0);

//   const TabsContainer = () => {
//     return (
//       <div
//         style={{
//           display: "flex",
//           backgroundColor: "#F5F5F5",
//           width: "80vw",
//           flexDirection: "column",
//           gap: "1rem",
//           overflow: "scroll",
//         }}
//       >
//         <div
//           style={{
//             backgroundColor: "rgba(227, 237, 255, 1)",
//             display: "flex",
//             position: "sticky",
//             // flexDirection:'row'
//             alignItems: "center",
//             justifyContent: "space-between",
//           }}
//         >
//           {/* <div
//             style={{
//               display: "flex",
//               gap: "5px",
//               height: "10px",
//               alignItems: "center",
//               cursor: "pointer",
//               marginTop: "1.05rem",
//               paddingLeft: "10px",
//             }}
//             onClick={handleBackClick}
//           >
//             <img src={BackArrow} style={{ width: "1rem", height: "0.8rem" }} />
//           </div>
//           */}
//           <button className="review">REVIEW</button>
//           <h4 style={{ color: "rgba(82, 68, 125, 1)", padding: "0 1rem" }}>
//             {/* Visit ID:{visitData ?visitData?.visitId:VISIT_ID} */}
//             Visit ID:{VISIT_ID}

//           </h4>
//         </div>

//         {/* <div
//           style={{
//             display: "flex",
//             flexDirection: "row",
//             // justifyContent: "space-between",
//             padding: "1rem",
//             gap: "10rem",
//           }}
//         >
//           <div className="patient_data">
//             <div className="patient_data_text">PATIENT DETAILS</div>
//             <div
//               style={{
//                 display: "flex",
//                 alignContent: "center",
//                 flexDirection: "column",
//                 gap: "0.03rem",
//                 padding: "1rem",
//               }}
//               className="text-data"
//             >
//               <p>
//                 {patientDetails.FIRST_NAME + " " + patientDetails.LAST_NAME}
//               </p>
//               <p>
//                 {patientDetails.GENDER}/{patientDetails.age}
//               </p>
//               <p className="PatientID-review">{patientDetails.PATIENT_ID}</p>
//               <p>{formattedDate}</p>
//             </div>
//           </div>

//           <div className="patient_data">
//             <div className="patient_data_text">Doctor Details</div>
//             <div
//               style={{
//                 display: "flex",
//                 alignContent: "center",
//                 flexDirection: "column",
//                 gap: "0.03rem",
//                 padding: "1rem",
//               }}
//               className="text-data"
//             >
//               <p>{userDetails.FIRST_NAME + " " + userDetails.LAST_NAME}</p>
//               <p>{userDetails.MOBILE}</p>
//             </div>
//           </div>
//         </div>*/}

//         {/* <div
//           style={{
//             display: "flex",
//             alignItems: "center",
//             // background: "#EBE3FF",
//             height: "38px",
//             fontSize: "21px",
//             fontWeight: "400",
//             lineHeight: "25px",
//             letterSpacing: "0em",
//             alignContent: "center",
//             padding: "1%",
//           }}
//         >
//           <p>
//             <b>Visit Note</b> - {VISIT_ID}
//           </p>
//         </div>
//         */}
//         {/* medical history tab */}
//         <div
//           style={{
//             display: "flex",
//             alignItems: "center",
//             background: "#EBE3FF",
//             height: "38px",
//             fontSize: "21px",
//             fontWeight: "400",
//             lineHeight: "25px",
//             letterSpacing: "0em",
//             alignContent: "center",
//             padding: "1%",
//           }}
//         >
//           <p>Medical History</p>
//         </div>

//         <div
//           style={{
//             display: "flex",
//             alignItems: "center",
//             background: "#D0E3FF",
//             height: "38px",
//             fontSize: "17px",
//             fontWeight: "400",
//             lineHeight: "25px",
//             letterSpacing: "0em",
//             alignContent: "center",
//             padding: "1%",
//           }}
//         >
//           <p>Cheif Complaint</p>
//         </div>

//         <div className="ReviewContainer" style={{ display: "flex", padding: "1rem", gap: "1rem" }}>
//           {/* {console.log("console", cheifComplaint)} */}
//           {cheifComplaint &&
//             cheifComplaint.map((complaint, index) => (
//               <p
//                 key={index}
//                 style={{
//                   display: "inline-block",
//                   padding: "0.5rem 1rem", // Adjust padding to your preference
//                   background: "#D1AEFF",
//                   borderRadius: "10px",
//                 }}
//               >
//                 {complaint}
//               </p>
//             ))}

//           {MedicalHistoryData && MedicalHistoryData.otherChiefComplaint && (
//             <p
//               style={{
//                 width: "fit-content",
//                 padding: "1%",
//                 height: "6vh",
//                 background: "#D1AEFF",
//                 borderRadius: "10px",
//               }}
//             >
//               {MedicalHistoryData.otherChiefComplaint}
//             </p>
//           )}
//         </div>

//         <div
//           style={{
//             display: "flex",
//             alignItems: "center",
//             background: "#D0E3FF",
//             height: "38px",
//             fontSize: "17px",
//             fontWeight: "400",
//             lineHeight: "25px",
//             letterSpacing: "0em",
//             alignContent: "center",
//             padding: "1%",
//           }}
//         >
//           <p>Previous Conditions</p>
//         </div>

//         <div
//           style={{
//             display: "flex",
//             padding: "1rem",
//           }}
//         >
//           {keysWithZeroValue.length > 0 ? (
//             <div style={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}>
//               {keysWithZeroValue.map((key) => (
//                 <p
//                   key={key}
//                   style={{
//                     display: "inline-block",
//                     padding: "0.5rem 1rem", // Adjust padding to your preference
//                     background: "#D1AEFF",
//                     borderRadius: "10px",
//                   }}
//                 >
//                   {alternativeNames[key]}
//                 </p>
//               ))}
//             </div>
//           ) : (
//             <p>No Data in this section</p>
//           )}
//         </div>

//         <div
//           style={{
//             display: "flex",
//             alignItems: "center",
//             background: "#D0E3FF",
//             height: "38px",
//             fontSize: "17px",
//             fontWeight: "400",
//             lineHeight: "25px",
//             letterSpacing: "0em",
//             alignContent: "center",
//             padding: "1%",
//           }}
//         >
//           <p>Family History</p>
//         </div>

//         <div style={{ display: "flex", padding: "1rem", gap: "1rem" }}>
//           {thirdSection.length > 0 ? (
//             <>
//               {thirdSection.map((key) => (
//                 <p
//                   key={key}
//                   style={{
//                     display: "inline-block",
//                     padding: "0.5rem 1rem", // Adjust padding to your preference
//                     background: "#D1AEFF",
//                     borderRadius: "10px",
//                   }}
//                 >
//                   {alternativeNames[key]}
//                 </p>
//               ))}
//             </>
//           ) : (
//             <p>No Data in this section</p>
//           )}
//         </div>

//         <div
//           style={{
//             display: "flex",
//             alignItems: "center",
//             background: "#EBE3FF",
//             height: "38px",
//             fontSize: "21px",
//             fontWeight: "400",
//             lineHeight: "25px",
//             letterSpacing: "0em",
//             alignContent: "center",
//             padding: "1%",
//           }}
//         >
//           <p>Drug History</p>
//         </div>
//         {/* drug history tab */}
//         <div style={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
//           {totalDrugs === 0 ? (
//             <p style={{ padding: "1%" }}>No Drug History for this patient.</p>
//           ) : (
//             <>
//               {DrugHistoryData.isHypertensiveDrugs === 0 && (
//                 <div
//                   style={{
//                     display: "flex",
//                     alignItems: "center",
//                     background:
//                       DrugHistoryData.isHypertensiveDrugs === 0
//                         ? "#D0E3FF"
//                         : "none",
//                     height: "38px",
//                     fontSize: "17px",
//                     fontWeight: "400",
//                     lineHeight: "25px",
//                     letterSpacing: "0em",
//                     alignContent: "center",
//                     padding: "1%",
//                   }}
//                 >
//                   <p>Anti Hypertensive Drugs</p>
//                 </div>
//               )}
//               {DrugHistoryData.currentHTDrugs !== null &&
//               DrugHistoryData.currentHTDrugs.length > 0 ? (
//                 <div
//                   style={{
//                     display: "flex",
//                     flexDirection: "column",
//                     //   gap: "1rem",
//                     width: "45%",
//                     padding: "4px 0px 0px 10px",
//                   }}
//                 >
//                   <div
//                     style={{
//                       display: "flex",
//                       flexDirection: "row",
//                       justifyContent: "space-between",
//                       // gap: "2rem",
//                       background: "#fff4b8",
//                       padding: "10px",
//                     }}
//                   >
//                     <p>Name</p>
//                     <p>Class</p>
//                     <p>Dose</p>
//                   </div>

//                   {DrugHistoryData.currentHTDrugs.map((item, index) => (
//                     <div
//                       key={index}
//                       style={{
//                         display: "flex",
//                         flexDirection: "row",
//                         justifyContent: "space-between",
//                         background: index % 2 === 0 ? "#ffedde" : "white", // Alternating background colors
//                         padding: "10px",
//                       }}
//                     >
//                       <p>{item.name}</p>
//                       <p>{item.className}</p>
//                       <p>
//                         {item.currentDose} {item.unit}
//                       </p>
//                     </div>
//                   ))}
//                 </div>
//               ) : null}
//               {DrugHistoryData.isDiabeticDrugs === 0 && (
//                 <div
//                   style={{
//                     display: "flex",
//                     alignItems: "center",
//                     background:
//                       DrugHistoryData.isDiabeticDrugs === 0
//                         ? "#D0E3FF"
//                         : "none",
//                     height: "38px",
//                     fontSize: "17px",
//                     fontWeight: "400",
//                     lineHeight: "25px",
//                     letterSpacing: "0em",
//                     alignContent: "center",
//                     padding: "1%",
//                   }}
//                 >
//                   <p>Anti Diabetic Drugs</p>
//                 </div>
//               )}
//               {DrugHistoryData.currentDBDrugs !== null &&
//               DrugHistoryData.currentDBDrugs.length > 0 ? (
//                 <>
//                   <div
//                     style={{
//                       display: "flex",
//                       flexDirection: "column",
//                       //   gap: "1rem",
//                       width: "45%",
//                       padding: "4px 0px 0px 10px",
//                     }}
//                   >
//                     <div
//                       style={{
//                         display: "flex",
//                         flexDirection: "row",
//                         justifyContent: "space-between",
//                         // gap: "2rem",
//                         background: "#fff4b8",
//                         padding: "10px",
//                       }}
//                     >
//                       <p>Name</p>
//                       <p>Class</p>
//                       <p>Dose</p>
//                     </div>

//                     {DrugHistoryData.currentDBDrugs.map((item, index) => (
//                       <div
//                         key={index}
//                         style={{
//                           display: "flex",
//                           flexDirection: "row",
//                           justifyContent: "space-between",
//                           background: index % 2 === 0 ? "#ffedde" : "white", // Alternating background colors
//                           padding: "10px",
//                         }}
//                       >
//                         <p>{item.name}</p>
//                         <p>{item.className}</p>
//                         <p>
//                           {item.currentDose} {item.unit}
//                         </p>
//                       </div>
//                     ))}
//                   </div>
//                 </>
//               ) : null}
//               {DrugHistoryData.isInsulin === 0 && (
//                 <div
//                   style={{
//                     display: "flex",
//                     alignItems: "center",
//                     background:
//                       DrugHistoryData.isInsulin === 0
//                         ? "#D0E3FF"
//                         : "transparent",
//                     height: "38px",
//                     fontSize: "17px",
//                     fontWeight: "400",
//                     lineHeight: "25px",
//                     letterSpacing: "0em",
//                     alignContent: "center",
//                     padding: "1%",
//                   }}
//                 >
//                   <p>Insulin Drugs</p>
//                 </div>
//               )}

//               {DrugHistoryData.currentInsulin !== null &&
//               DrugHistoryData.currentInsulin.length > 0 ? (
//                 <>
//                   <div
//                     style={{
//                       display: "flex",
//                       flexDirection: "column",
//                       //   gap: "1rem",
//                       width: "45%",
//                       padding: "4px 0px 0px 10px",
//                     }}
//                   >
//                     <div
//                       style={{
//                         display: "flex",
//                         flexDirection: "row",
//                         justifyContent: "space-between",
//                         // gap: "2rem",
//                         background: "#fff4b8",
//                         padding: "10px",
//                       }}
//                     >
//                       <p>Name</p>
//                       <p>Class</p>
//                       <p>Dose</p>
//                     </div>

//                     {DrugHistoryData.currentInsulin.map((item, index) => (
//                       <div
//                         key={index}
//                         style={{
//                           display: "flex",
//                           flexDirection: "row",
//                           justifyContent: "space-between",
//                           background: index % 2 === 0 ? "#ffedde" : "white", // Alternating background colors
//                           padding: "10px",
//                         }}
//                       >
//                         <p>{item.name}</p>
//                         <p>{item.className}</p>
//                         <p>
//                           {item.currentDose} {item.unit}
//                         </p>
//                       </div>
//                     ))}
//                   </div>
//                 </>
//               ) : null}
//               {DrugHistoryData.isLipidDrugs === 0 && (
//                 <div
//                   style={{
//                     display: "flex",
//                     alignItems: "center",
//                     // background: "#D0E3FF",
//                     background:
//                       DrugHistoryData.isLipidDrugs === 0
//                         ? "#D0E3FF"
//                         : "transparent",
//                     height: "38px",
//                     fontSize: "17px",
//                     fontWeight: "400",
//                     lineHeight: "25px",
//                     letterSpacing: "0em",
//                     alignContent: "center",
//                     padding: "1%",
//                   }}
//                 >
//                   <p>Lipid Lowering Drugs</p>
//                 </div>
//               )}

//               {DrugHistoryData.currentLipid !== null &&
//               DrugHistoryData.currentLipid.length > 0 ? (
//                 <>
//                   <div
//                     style={{
//                       display: "flex",
//                       flexDirection: "column",
//                       //   gap: "1rem",
//                       width: "45%",
//                       padding: "4px 0px 0px 10px",
//                     }}
//                   >
//                     <div
//                       style={{
//                         display: "flex",
//                         flexDirection: "row",
//                         justifyContent: "space-between",
//                         // gap: "2rem",
//                         background: "#fff4b8",
//                         padding: "10px",
//                       }}
//                     >
//                       <p>Name</p>
//                       <p>Class</p>
//                       <p>Dose</p>
//                     </div>

//                     {DrugHistoryData.currentLipid.map((item, index) => (
//                       <div
//                         key={index}
//                         style={{
//                           display: "flex",
//                           flexDirection: "row",
//                           justifyContent: "space-between",
//                           background: index % 2 === 0 ? "#ffedde" : "white", // Alternating background colors
//                           padding: "10px",
//                         }}
//                       >
//                         <p>{item.name}</p>
//                         <p>{item.className}</p>
//                         <p>
//                           {item.currentDose} {item.unit}
//                         </p>
//                       </div>
//                     ))}
//                   </div>
//                 </>
//               ) : null}
//               {DrugHistoryData.isAsprin === 0 && (
//                 <div
//                   style={{
//                     display: "flex",
//                     alignItems: "center",
//                     background:
//                       DrugHistoryData.isAsprin === 0
//                         ? "#D0E3FF"
//                         : "transparent",
//                     height: "38px",
//                     fontSize: "17px",
//                     fontWeight: "400",
//                     lineHeight: "25px",
//                     letterSpacing: "0em",
//                     alignContent: "center",
//                     padding: "1%",
//                   }}
//                 >
//                   <p>Aspirin Drugs</p>
//                 </div>
//               )}

//               {DrugHistoryData.currentAsprin !== null &&
//               DrugHistoryData.currentAsprin.length > 0 ? (
//                 <>
//                   <div
//                     style={{
//                       display: "flex",
//                       flexDirection: "column",
//                       width: "45%",
//                       padding: "4px 0px 0px 10px",
//                     }}
//                   >
//                     <div
//                       style={{
//                         display: "flex",
//                         flexDirection: "row",
//                         justifyContent: "space-between",
//                         // gap: "2rem",
//                         background: "#fff4b8",
//                         padding: "10px",
//                       }}
//                     >
//                       <p>Name</p>
//                       <p>Class</p>
//                       <p>Dose</p>
//                     </div>

//                     {DrugHistoryData.currentAsprin.map((item, index) => (
//                       <div
//                         key={index}
//                         style={{
//                           display: "flex",
//                           flexDirection: "row",
//                           justifyContent: "space-between",
//                           background: index % 2 === 0 ? "#ffedde" : "white", // Alternating background colors
//                           padding: "10px",
//                         }}
//                       >
//                         <p>{item.name}</p>
//                         <p>{item.className}</p>
//                         <p>
//                           {item.currentDose} {item.unit}
//                         </p>
//                       </div>
//                     ))}
//                   </div>
//                 </>
//               ) : null}
//             </>
//           )}
//         </div>

//         {/* personal History tab */}
//         <div
//           style={{
//             display: "flex",
//             alignItems: "center",
//             background: "#EBE3FF",
//             height: "38px",
//             fontSize: "21px",
//             fontWeight: "400",
//             lineHeight: "25px",
//             letterSpacing: "0em",
//             alignContent: "center",
//             padding: "1%",
//           }}
//         >
//           <p>Personal History</p>
//         </div>

//         <div
//           style={{
//             display: "flex",
//             alignItems: "center",
//             background: "#D0E3FF",
//             height: "38px",
//             fontSize: "17px",
//             fontWeight: "400",
//             lineHeight: "25px",
//             letterSpacing: "0em",
//             alignContent: "center",
//             padding: "1%",
//           }}
//         >
//           <p>Personal Habits</p>
//         </div>

//         <div style={{ display: "flex", padding: "1rem", gap: "1rem" }}>
//           {PersonalHistoryData &&
//             Object.entries(PersonalHistoryData).map(([key, value]) => {
//               if (
//                 (key === "isDrinking" ||
//                   key === "isPhysicalActivity" ||
//                   key === "isSmoking") &&
//                 value === 0
//               ) {
//                 return (
//                   <p
//                     key={key}
//                     style={{
//                       display: "inline-block",
//                       padding: "0.5rem 1rem", // Adjust padding to your preference
//                       background: "#D1AEFF",
//                       borderRadius: "10px",
//                     }}
//                   >
//                     {alternativePersonalHistoryNames[key]}
//                   </p>
//                 );
//               }
//               return null;
//             })}
//         </div>

//         <div
//           style={{
//             display: "flex",
//             alignItems: "center",
//             background: "#D0E3FF",
//             height: "38px",
//             fontSize: "17px",
//             fontWeight: "400",
//             lineHeight: "25px",
//             letterSpacing: "0em",
//             alignContent: "center",
//             padding: "1%",
//           }}
//         >
//           <p>Personal Symptoms</p>
//         </div>

//         <div style={{ display: "flex", padding: "1rem", gap: "1rem" }}>
//           {PersonalHistoryData &&
//             Object.entries(PersonalHistoryData).map(([key, value]) => {
//               if (
//                 (key === "isPolydypsia" ||
//                   key === "isPolyuria" ||
//                   key === "isSmoking" ||
//                   key === "isWeightloss") &&
//                 value === 0
//               ) {
//                 return (
//                   <p
//                     key={key}
//                     style={{
//                       display: "inline-block",
//                       padding: "0.5rem 1rem", // Adjust padding to your preference
//                       background: "#D1AEFF",
//                       borderRadius: "10px",
//                     }}
//                   >
//                     {alternativePersonalHistoryNames[key]}
//                   </p>
//                 );
//               }
//               return null;
//             })}
//         </div>

//         {/* examination history tab */}
//         <div
//           style={{
//             display: "flex",
//             alignItems: "center",
//             background: "#EBE3FF",
//             height: "38px",
//             fontSize: "21px",
//             fontWeight: "400",
//             lineHeight: "25px",
//             letterSpacing: "0em",
//             alignContent: "center",
//             padding: "1%",
//           }}
//         >
//           <p></p>Examination
//         </div>

//         <div
//           style={{
//             display: "flex",
//             alignItems: "center",
//             background: "#D0E3FF",
//             height: "38px",
//             fontSize: "17px",
//             fontWeight: "400",
//             lineHeight: "25px",
//             letterSpacing: "0em",
//             alignContent: "center",
//             padding: "1%",
//           }}
//         >
//           <p>Blood Pressure Readings</p>
//         </div>

//         <div className="ReviewContainer" style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
//           {ExaminationHistoryData && ExaminationHistoryData.averageSBP && (
//             <div style={{ padding: "0.5rem", textAlign: "center" }}>
//               <p
//                 style={{
//                   display: "inline-block",
//                   padding: "0.5rem 1rem", // Adjust padding to your preference
//                   background: "#D1AEFF",
//                   borderRadius: "10px",
//                 }}
//               >
//                 SBP: {ExaminationHistoryData.averageSBP}
//               </p>
//             </div>
//           )}
//           {ExaminationHistoryData &&
//             ExaminationHistoryData.averageDBP !== undefined &&
//             ExaminationHistoryData.averageDBP !== null && (
//               <div style={{ padding: "0.5rem", textAlign: "center" }}>
//                 <p
//                   style={{
//                     display: "inline-block",
//                     padding: "0.5rem 1rem", // Adjust padding to your preference
//                     background: "#D1AEFF",
//                     borderRadius: "10px",
//                   }}
//                 >
//                   DBP: {ExaminationHistoryData.averageDBP}
//                 </p>
//               </div>
//             )}

//           {ExaminationHistoryData && ExaminationHistoryData.heartRate && (
//             <div style={{ padding: "0.5rem", textAlign: "center" }}>
//               <p
//                 style={{
//                   display: "inline-block",
//                   padding: "0.5rem 1rem", // Adjust padding to your preference
//                   background: "#D1AEFF",
//                   borderRadius: "10px",
//                 }}
//               >
//                 Heart Rate: {ExaminationHistoryData.heartRate}
//               </p>
//             </div>
//           )}
//           {(visitData?.examinationHistory?.sbp ?? 0) >= 180 ||
//           (visitData?.examinationHistory?.dbp ?? 0) <= 110 ? (
//             <>
//               {ExaminationHistoryData &&
//                 ExaminationHistoryData.sbp2 != null && (
//                   <div style={{ padding: "0.5rem", textAlign: "center" }}>
//                     <p
//                       style={{
//                         display: "inline-block",
//                         padding: "0.5rem 1rem", // Adjust padding to your preference
//                         background: "#D1AEFF",
//                         borderRadius: "10px",
//                       }}
//                     >
//                       sbp 2 : {ExaminationHistoryData.sbp2}
//                     </p>
//                   </div>
//                 )}
//               {ExaminationHistoryData &&
//                 ExaminationHistoryData.dbp2 != null && (
//                   <div style={{ padding: "0.5rem", textAlign: "center" }}>
//                     <p
//                       style={{
//                         display: "inline-block",
//                         padding: "0.5rem 1rem", // Adjust padding to your preference
//                         background: "#D1AEFF",
//                         borderRadius: "10px",
//                       }}
//                     >
//                       dbp 2 : {ExaminationHistoryData.dbp2}
//                     </p>
//                   </div>
//                 )}
//               {ExaminationHistoryData &&
//                 ExaminationHistoryData.sbp3 != null && (
//                   <div style={{ padding: "0.5rem", textAlign: "center" }}>
//                     <p
//                       style={{
//                         display: "inline-block",
//                         padding: "0.5rem 1rem", // Adjust padding to your preference
//                         background: "#D1AEFF",
//                         borderRadius: "10px",
//                       }}
//                     >
//                       sbp3 : {ExaminationHistoryData.sbp3}
//                     </p>
//                   </div>
//                 )}
//               {ExaminationHistoryData &&
//                 ExaminationHistoryData.dbp3 != null && (
//                   <div style={{ padding: "0.5rem", textAlign: "center" }}>
//                     <p
//                       style={{
//                         display: "inline-block",
//                         padding: "0.5rem 1rem", // Adjust padding to your preference
//                         background: "#D1AEFF",
//                         borderRadius: "10px",
//                       }}
//                     >
//                       dbp3 : {ExaminationHistoryData.dbp3}
//                     </p>
//                   </div>
//                 )}
//             </>
//           ) : null}
//         </div>

//         <div>
//           <div
//             style={{
//               display: "flex",
//               alignItems: "center",
//               background: "#D0E3FF",
//               height: "38px",
//               fontSize: "17px",
//               fontWeight: "400",
//               lineHeight: "25px",
//               letterSpacing: "0em",
//               alignContent: "center",
//               padding: "1%",
//             }}
//           >
//             <p>Emergency Indications</p>
//           </div>
//           {ExaminationHistoryData &&
//             keysToConsider.map((key) => {
//               const value = ExaminationHistoryData[key];
//               if (value === 0) {
//                 const displayName = alternateEmergencyNames[key] || key;
//                 return (
//                   <div
//                     key={key}
//                     style={{
//                       display: "flex",
//                       padding: "0.5rem",
//                     }}
//                   >
//                     <p
//                       style={{
//                         display: "inline-block",
//                         padding: "0.5rem 1rem",
//                         background: "#D1AEFF",
//                         borderRadius: "10px",
//                       }}
//                     >
//                       {displayName}
//                     </p>
//                   </div>
//                 );
//               }
//               return null;
//             })}
//         </div>

//         <div
//           style={{
//             display: "flex",
//             alignItems: "center",
//             background: "#D0E3FF",
//             height: "38px",
//             fontSize: "17px",
//             fontWeight: "400",
//             lineHeight: "25px",
//             letterSpacing: "0em",
//             alignContent: "center",
//             padding: "1%",
//           }}
//         >
//           <p>Blood Sugar Readings</p>
//         </div>
//         {ExaminationHistoryData && (
//           <div style={{ display: "flex", padding: "0.5rem", gap: "1rem" }}>
//             {ExaminationHistoryData.fbs && (
//               <p
//                 style={{
//                   display: "inline-block",
//                   padding: "0.5rem 1rem", // Adjust padding to your preference
//                   background: "#D1AEFF",
//                   borderRadius: "10px",
//                 }}
//               >
//                 FBS : {ExaminationHistoryData.fbs}
//               </p>
//             )}
//             {ExaminationHistoryData.ppbs && (
//               <p
//                 style={{
//                   display: "inline-block",
//                   padding: "0.5rem 1rem", // Adjust padding to your preference
//                   background: "#D1AEFF",
//                   borderRadius: "10px",
//                 }}
//               >
//                 PPBS : {ExaminationHistoryData.ppbs}
//               </p>
//             )}
//             {ExaminationHistoryData.rbs && (
//               <p
//                 style={{
//                   display: "inline-block",
//                   padding: "0.5rem 1rem", // Adjust padding to your preference
//                   background: "#D1AEFF",
//                   borderRadius: "10px",
//                 }}
//               >
//                 RBS : {ExaminationHistoryData.rbs}
//               </p>
//             )}
//             {ExaminationHistoryData.hba1c && (
//               <p
//                 style={{
//                   display: "inline-block",
//                   padding: "0.5rem 1rem", // Adjust padding to your preference
//                   background: "#D1AEFF",
//                   borderRadius: "10px",
//                 }}
//               >
//                 Hb1Ac : {ExaminationHistoryData.hba1c}
//               </p>
//             )}
//             {ExaminationHistoryData.creatinine && (
//               <p
//                 style={{
//                   display: "inline-block",
//                   padding: "0.5rem 1rem", // Adjust padding to your preference
//                   background: "#D1AEFF",
//                   borderRadius: "10px",
//                 }}
//               >
//                 Serum Creatine : {ExaminationHistoryData.creatinine}
//               </p>
//             )}
//             {ExaminationHistoryData.gfr && (
//               <p
//                 style={{
//                   display: "inline-block",
//                   padding: "0.5rem 1rem", // Adjust padding to your preference
//                   background: "#D1AEFF",
//                   borderRadius: "10px",
//                 }}
//               >
//                 GFR : {ExaminationHistoryData.gfr}
//               </p>
//             )}
//           </div>
//         )}

//         <div
//           style={{
//             display: "flex",
//             alignItems: "center",
//             background: "#D0E3FF",
//             height: "38px",
//             fontSize: "17px",
//             fontWeight: "400",
//             lineHeight: "25px",
//             letterSpacing: "0em",
//             alignContent: "center",
//             padding: "1%",
//           }}
//         >
//           <p>Measurements</p>
//         </div>

//         {ExaminationHistoryData && (
//           <div style={{ display: "flex", padding: "0.5rem", gap: "1rem" }}>
//             {ExaminationHistoryData.weight && (
//               <p
//                 style={{
//                   display: "inline-block",
//                   padding: "0.5rem 1rem", // Adjust padding to your preference
//                   background: "#D1AEFF",
//                   borderRadius: "10px",
//                 }}
//               >
//                 Weight : {ExaminationHistoryData.weight}
//               </p>
//             )}
//             {ExaminationHistoryData.height && (
//               <p
//                 style={{
//                   display: "inline-block",
//                   padding: "0.5rem 1rem", // Adjust padding to your preference
//                   background: "#D1AEFF",
//                   borderRadius: "10px",
//                 }}
//               >
//                 Height : {ExaminationHistoryData.height}
//               </p>
//             )}
//             {ExaminationHistoryData.waist && (
//               <p
//                 style={{
//                   display: "inline-block",
//                   padding: "0.5rem 1rem", // Adjust padding to your preference
//                   background: "#D1AEFF",
//                   borderRadius: "10px",
//                 }}
//               >
//                 Waist : {ExaminationHistoryData.waist}
//               </p>
//             )}
//             {ExaminationHistoryData.bmi !== null && ( // Check if BMI is not null
//               <p
//                 style={{
//                   display: "inline-block",
//                   padding: "0.5rem 1rem",
//                   background: "#D1AEFF",
//                   borderRadius: "10px",
//                 }}
//               >
//                 BMI: {ExaminationHistoryData.bmi}
//               </p>
//             )}
//           </div>
//         )}

//         <div
//           style={{
//             display: "flex",
//             alignItems: "center",
//             background: "#D0E3FF",
//             height: "38px",
//             fontSize: "17px",
//             fontWeight: "400",
//             lineHeight: "25px",
//             letterSpacing: "0em",
//             alignContent: "center",
//             padding: "1%",
//           }}
//         >
//           <p>Lab Values</p>
//         </div>
//         {ExaminationHistoryData && (
//           <div style={{ display: "flex", padding: "0.5rem", gap: "1rem" }}>
//             {ExaminationHistoryData.ldl && (
//               <p
//                 style={{
//                   display: "inline-block",
//                   padding: "0.5rem 1rem", // Adjust padding to your preference
//                   background: "#D1AEFF",
//                   borderRadius: "10px",
//                 }}
//               >
//                 LDL : {ExaminationHistoryData.ldl}
//               </p>
//             )}
//             {ExaminationHistoryData.cholestrol && (
//               <p
//                 style={{
//                   display: "inline-block",
//                   padding: "0.5rem 1rem", // Adjust padding to your preference
//                   background: "#D1AEFF",
//                   borderRadius: "10px",
//                 }}
//               >
//                 Cholesterol : {ExaminationHistoryData.cholestrol}
//               </p>
//             )}
//           </div>
//         )}

//         <div style={{ display: "flex", justifyContent: "center" }}>
//           <button
//             className="addAppointmentsBtn"
//             style={{
//               Background: "#52447d !important",
//               boxShadow: "0px 3px 16px 1px #52447D75",
//             }}
//             onClick={()=>handleAnalysis}
//           >
//             Analysis
//             {isLoading && <CircularProgress size={24} color="inherit" />}
//           </button>
//           {/* <button
//             className="addAppointmentsBtn"
//             style={{
//               Background: "#52447d !important",
//               boxShadow: "0px 3px 16px 1px #52447D75",
//             }}
//             onClick={() => <GeneratePrescriptionAsPdf visitData={visitData} />}>
//             Generate PDF
//           </button> */}
//           {/* {showPopup && <AnalysisPopup onContinue={handleContinue} />} */}
//         </div>
//       </div>
//     );
//   };

//   //code change raviteja
//   const [isLoading, setIsLoading] = useState(false);
//   const [popupMessage, setPopupMessage] = useState("");

//   const showDialogBox = (message) => {
//     setPopupMessage(message);
//   };

//   const closePopup = () => {
//     setPopupMessage("");
//   };

//   function getMedicalHistoryMap(medicalHistoryData) {
//     // console.log("medicalHistory", medicalHistoryData)
//     // const medicalHistory = medicalHistoryData === undefined ? [] : JSON.parse(medicalHistoryData);
//     const medicalHistory =
//       medicalHistoryData === undefined ? [] : JSON.parse(medicalHistoryData);
//     //console.log("medicalHistory", medicalHistoryData);

//     const medicalHistoryMap = [];

//     if (medicalHistory?.isDiabetes === 0) {
//       medicalHistoryMap.push("DB");
//     }
//     if (medicalHistory?.isHypertension === 0) {
//       medicalHistoryMap.push("HTN");
//     }
//     if (medicalHistory?.isCOPD === 0 || medicalHistory?.isAsthma === 0) {
//       medicalHistoryMap.push("CA");
//     }
//     if (medicalHistory?.isStroke === 0) {
//       medicalHistoryMap.push("STK");
//     }
//     if (medicalHistory?.isHeartDisease === 0) {
//       medicalHistoryMap.push("HD");
//     }
//     if (medicalHistory?.isCKD === 0) {
//       medicalHistoryMap.push("CKD");
//     }
//     if (medicalHistory?.isPVD === 0) {
//       medicalHistoryMap.push("PVD");
//     }
//     if (medicalHistory?.isLiverFailure === 0) {
//       medicalHistoryMap.push("LF");
//     }
//     if (medicalHistory?.isHeartAttack === 0) {
//       //medicalHistory?.isHeartAttack === 9 ||
//       medicalHistoryMap.push("HA");
//     }
//     //console.log("medicalHistoryMap--------", medicalHistoryMap);
//     return medicalHistoryMap;
//   }

//   function getRiskFactors(visitData) {
//     const personalHistory = visitData?.personalHistory
//       ? JSON.parse(visitData.personalHistory)
//       : [];
//     const examinationHistory = visitData?.examinationHistory
//       ? JSON.parse(visitData.examinationHistory)
//       : [];

//     //console.log("personalHistory--------", personalHistory.isSmoking);
//     //console.log("examinationHistory--------", examinationHistory);
//     const riskFactors = [];

//     if (personalHistory?.isSmoking === 0) {
//       //personalHistory?.isSmoking === 9 ||
//       riskFactors.push("Smoking");
//     }
//     if (personalHistory?.isFamillyHistoryOfMI === 0) {
//       riskFactors.push("Family");
//     }
//     if (
//       examinationHistory?.cholestrol === 0 ||
//       examinationHistory?.cholestrol > 200
//     ) {
//       riskFactors.push("Dyslipidemia");
//     }

//     return riskFactors;
//   }

//   function getNeurologicalSigns(examinationHistoryData) {
//     // console.log("ecamination", examinationHistoryData)
//     const examinationHistory =
//       examinationHistoryData === undefined
//         ? []
//         : JSON.parse(examinationHistoryData);
//     let counter = 0;

//     if (examinationHistory?.isEdema === 0) counter++;
//     if (examinationHistory?.isBreathlessness === 0) counter++;
//     if (examinationHistory?.isChestPain === 0) counter++;
//     if (examinationHistory?.isAlteredConciousness === 0) counter++;
//     if (examinationHistory?.isDeficit === 0) counter++;
//     if (examinationHistory?.isFundus === 0) counter++;
//     if (examinationHistory?.isGeneralizedSeizures === 0) counter++;

//     if (counter >= 4) {
//       return true;
//     } else {
//       return false;
//     }
//   }

//   function getCurrentHTDrugs(drugHistoryData) {
//     // console.log("drugHistoryData", drugHistoryData)
//     const drugHistory =
//       drugHistoryData === undefined ? [] : JSON.parse(drugHistoryData);
//     // const filteredDrugs = drugHistory.currentHTDrugs.filter(drug => drug.className != null || drug.classId != null);
//     const filteredDrugs = (drugHistory.currentHTDrugs || []).filter(
//       (drug) => drug && (drug.className != null || drug.classId != null)
//     );

//     // console.log("filteredDrugs", filteredDrugs);
//     return filteredDrugs;
//   }

//   function getCurrentDBDrugs(drugHistoryData) {
//     // console.log("drugHistoryData", drugHistoryData)
//     const drugHistory =
//       drugHistoryData === undefined ? [] : JSON.parse(drugHistoryData);
//     // const filteredDrugs = drugHistory.currentHTDrugs.filter(drug => drug.className != null || drug.classId != null);
//     const filteredDrugs = (drugHistory.currentDBDrugs || []).filter(
//       (drug) => drug && (drug.className != null || drug.classId != null)
//     );

//     // console.log("filteredDrugs", filteredDrugs);
//     return filteredDrugs;
//   }

//   const getHTAnalysis = async () => {
//     try {
//       // const username = "tiatech-tele";
//       // const password = "cds$Tia2221";
//       // const basicAuth = "Basic " + btoa(`${username}:${password}`);

//       //console.log("basic ", basicAuth)

//       const payload = {
//         USER_ID: UserID,
//         visitData: visitData,
//         // Basic_Auth: basicAuth,
//         medicalHistory: getMedicalHistoryMap(visitData?.medicalHistory),
//         riskFactor: getRiskFactors(visitData),
//         neurologicalSigns: getNeurologicalSigns(visitData?.examinationHistory),
//         currentDrugs: getCurrentHTDrugs(visitData?.drugHistory),
//       };
//       //console.log("paylaod", payload);

//       const examinationHistory = visitData?.examinationHistory
//         ? JSON.parse(visitData.examinationHistory)
//         : null;
//       const sbp =
//         examinationHistory?.sbp === undefined
//           ? "null"
//           : examinationHistory?.sbp;
//       const dbp =
//         examinationHistory?.dbp === undefined
//           ? "null"
//           : examinationHistory?.dbp;
//       const medicalHistoryMap = getMedicalHistoryMap(visitData?.medicalHistory);
//       const getRiskFactorsData = getRiskFactors(visitData);
//       const getNeurologicalSignsData = getNeurologicalSigns(
//         visitData?.examinationHistory
//       );
//       const currentDrugsData = getCurrentHTDrugs(visitData?.drugHistory);

//       const json = {
//         sbp: sbp,
//         dbp: dbp,
//         medicalHistory: medicalHistoryMap,
//         riskFactor: getRiskFactorsData,
//         neurologicalSigns: getNeurologicalSignsData,
//         treatmentFacility: "2",
//         currentDrugs: currentDrugsData,
//       };

//       //console.log("json", json);

//       const response = await Axios.post(
//         `${config.abhaBaseUrl}getHTAnalysis`,
//         payload,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             authorization: `Bearer ${token}`,
//             "x-caller": encrypted,
//           },
//         }
//       );
//       // console.log("respons of hy cdss cl", response.data)
//       if (response.status === 200) {
//         const jsonData = response.data;

//         //console.log(jsonData, "jsonDatajsonData.......");
//         sessionStorage.setItem("htResult", JSON.stringify(jsonData));

//         // API CALL TO SAVE DAT AIN DB
//         const Response = await Axios.post(
//           `${config.apiURL}${config.routes.saveCDSSAPI}`,
//           {
//             cdssID: "0", //hypertension
//             inputData: json,
//             outputData: jsonData,
//             remarks: "",
//             accepted: "0",
//             createdBy: visitData.nurseUserId,
//             visitID: visitData.visitId,
//           },
//           {
//             headers: {
//               "Content-Type": "application/json",
//               authorization: `Bearer ${token}`,
//               "x-caller": encrypted,
//             },
//           }
//         );

//         if (Response.status === 201) {
//           //console.log("Status = ", Response.data);
//         }
//       }
//     } catch (error) {
//       console.error("Error fetching HT analysis:", error.message);
//       throw error;
//     }
//   };

//   const getDiabeticAnalysis = async () => {
//     try {
//       // const username = "tiatech-tele";
//       // const password = "cds$Tia2221";
//       // const basicAuth = "Basic " + btoa(`${username}:${password}`);
//       //console.log("visitData", visitData);
//       let insulinData = [];

//       if (
//         visitData &&
//         visitData.drugHistory &&
//         visitData.drugHistory.currentInsulin
//       ) {
//         insulinData = visitData.drugHistory.currentInsulin.map((element) => ({
//           id: element.id,
//           name: element.name,
//           currentDose: Math.round(element.currentDose),
//           recommendedDose: Math.round(element.recommendedDose),
//         }));
//       }

//       const payload = {
//         USER_ID: UserID,
//         visitData: visitData,
//         // Basic_Auth: basicAuth,
//         medicalHistory: getMedicalHistoryMap(visitData?.medicalHistory),
//         currentDBDrugs: getCurrentDBDrugs(visitData?.drugHistory),
//         currentInsulin: insulinData.length === 0 ? null : insulinData,
//       };
//       // console.log("paylaod", payload)

//       const medicalHistoryMap = getMedicalHistoryMap(visitData?.medicalHistory);
//       const currentInsulin = insulinData.length === 0 ? null : insulinData;
//       const currentDBDrugs = getCurrentDBDrugs(visitData?.drugHistory);
//       const personalHistory = visitData?.personalHistory
//         ? JSON.parse(visitData.personalHistory)
//         : null;
//       const examinationHistory = visitData?.examinationHistory
//         ? JSON.parse(visitData.examinationHistory)
//         : null;
//       const fbs =
//         examinationHistory?.fbs === undefined
//           ? "null"
//           : examinationHistory?.fbs;
//       const ppbs =
//         examinationHistory?.ppbs === undefined
//           ? "null"
//           : examinationHistory?.ppbs;
//       const rbs =
//         examinationHistory?.rbs === undefined
//           ? "null"
//           : examinationHistory?.rbs;
//       const hba1c =
//         examinationHistory?.hba1c === undefined
//           ? "null"
//           : examinationHistory?.hba1c;
//       const gfr =
//         examinationHistory?.gfr === undefined
//           ? "null"
//           : examinationHistory?.gfr;
//       const weight =
//         examinationHistory?.weight === undefined
//           ? "null"
//           : examinationHistory?.weight;

//       const creatinine = examinationHistory?.creatinine;

//       const data = {
//         fbs: fbs,
//         ppbs: ppbs,
//         rbs: rbs,
//         hba1c: hba1c,
//         bloodSugarTypePpbs: 1,
//         bloodSugarTypeRbs: 1,
//         medicalHistory: medicalHistoryMap,
//         treatmentFacility: "2",
//         gender: "Male", //change
//         // "serumCreatinine": examinationHistory?.creatinine?.toDouble(),
//         serumCreatinine: creatinine ? parseFloat(creatinine) : null,
//         onDialysis: personalHistory?.isOnDialysis === 0 ? true : false,
//         gfr: gfr,
//         weight: weight,
//         polyuria: personalHistory?.isPolyuria === 0 ? true : false,
//         polydipsia: personalHistory?.isPolydypsia === 0 ? true : false,
//         weightLoss: personalHistory?.isWeightloss === 0 ? true : false,
//         currentInsulin: currentInsulin,
//         currentDrugs: currentDBDrugs,
//       };

//       const response = await Axios.post(
//         `${config.abhaBaseUrl}getDiabeticAnalysis`,
//         payload,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             authorization: `Bearer ${token}`,
//             "x-caller": encrypted,
//           },
//         }
//       );
//       // console.log("respons of diabetes cdss cl", response.data)
//       if (response.status === 200) {
//         const jsonData = response.data;
//         sessionStorage.setItem("dbResult", JSON.stringify(jsonData));

//         // API CALL TO SAVE DATA IN DB

//         const Response = await Axios.post(
//           `${config.apiURL}${config.routes.saveCDSSAPI}`,
//           {
//             cdssID: "1", //diabetes
//             inputData: data,
//             outputData: jsonData,
//             remarks: "",
//             accepted: "0",
//             createdBy: visitData.nurseUserId,
//             visitID: visitData.visitId,
//           },
//           {
//             headers: {
//               "Content-Type": "application/json",
//               authorization: `Bearer ${token}`,
//               "x-caller": encrypted,
//             },
//           }
//         );

//         if (Response.status === 201) {
//           //console.log("Status = ", Response.data);
//         }
//       }
//     } catch (error) {
//       console.error("Error fetching HT analysis:", error.message);
//       throw error;
//     }
//   };

//   async function calculateCVDRiskScoreCal(
//     cholesterolValue,
//     gender,
//     age,
//     cholesterol,
//     sbp,
//     height,
//     weight,
//     hasDiabetes,
//     isSmoking
//   ) {
//     //console.log("ssdf", cholesterolValue);
//     //console.log(gender);
//     //console.log(age);
//     //console.log(cholesterol);
//     //console.log(sbp);
//     //console.log(height);
//     //console.log(weight);
//     //console.log(hasDiabetes);
//     //console.log(isSmoking);

//     let bmi =
//       height !== null && weight !== null
//         ? height !== 0
//           ? (10000 * weight) / (height * height)
//           : 0
//         : 0;

//     let lab = cholesterolValue === 0 ? true : false;

//     let miRiskScore, strokeRiskScore;

//     let labStrokeRiskCoefficient = 0;
//     let labMiRiskCoefficient = 0;
//     let nonLabMiRiskCoefficient = 0;
//     let nonLabStrokeRiskCoefficient = 0;
//     let labMiRisk = 0;
//     let labStrokeRisk = 0;
//     let labCvdRisk = 0;
//     let nonLabMiRisk = 0;
//     let nonLabStrokeRisk = 0;
//     let nonLabCvdRisk = 0;

//     let _ageDifferenceFromBase = age - 60;
//     let _cholesterolDifferenceFromBase = cholesterol * 0.02586 - 6;
//     let _sbpDifferenceFromBase = sbp - 120;
//     let _bmiDifferenceFromBase = bmi - 25;
//     let _diabetesHistory = hasDiabetes;
//     let _smokingHistory = isSmoking;

//     let cvdScore = 0;

//     let labMIData = [
//       {
//         age: 0.0719227,
//         cholesterol: 0.2284944,
//         sbp: 0.0132183,
//         dbHistory: 0.6410114,
//         smokingHistory: 0.5638109,
//         cholesterolInteraction: -0.0045806,
//         sbpInteraction: -0.0001576,
//         dbInteraction: -0.0124966,
//         smokingInteraction: -0.0182545,
//         baselineSurvival: 0.954,
//       },
//       {
//         age: 0.1020713,
//         cholesterol: 0.2050377,
//         sbp: 0.015823,
//         dbHistory: 1.070358,
//         smokingHistory: 1.053223,
//         cholesterolInteraction: -0.0051932,
//         sbpInteraction: -0.0001378,
//         dbInteraction: -0.0234174,
//         smokingInteraction: 0.0332666,
//         baselineSurvival: 0.989,
//       },
//     ];

//     let labStrokeData = [
//       {
//         age: 0.0986578,
//         cholesterol: 0.0986578,
//         sbp: 0.0222629,
//         dbHistory: 0.6268712,
//         smokingHistory: 0.4981217,
//         cholesterolInteraction: 0.00142,
//         sbpInteraction: -0.0004147,
//         dbInteraction: -0.026302,
//         smokingInteraction: -0.0150561,
//         baselineSurvival: 0.985,
//       },
//       {
//         age: 0.1056632,
//         cholesterol: 0.0257782,
//         sbp: 0.0206278,
//         dbHistory: 0.8581998,
//         smokingHistory: 0.7443627,
//         cholesterolInteraction: -0.0021387,
//         sbpInteraction: -0.0004897,
//         dbInteraction: -0.0209826,
//         smokingInteraction: -0.0200822,
//         baselineSurvival: 0.989,
//       },
//     ];

//     let nonLabMIData = [
//       {
//         age: 0.073593,
//         bmi: 0.0337219,
//         sbp: 0.0133937,
//         smokingHistory: 0.5954767,
//         bmiInteraction: -0.0010432,
//         sbpInteraction: -0.0001837,
//         smokingInteraction: -0.0200831,
//         baselineSurvival: 0.954,
//       },
//       {
//         age: 0.1049418,
//         bmi: 0.0257616,
//         sbp: 0.016726,
//         smokingHistory: 1.093132,
//         bmiInteraction: -0.0006537,
//         sbpInteraction: -0.0001966,
//         smokingInteraction: -0.0343739,
//         baselineSurvival: 0.989,
//       },
//     ];

//     let nonLabStrokeData = [
//       {
//         age: 0.097674,
//         bmi: 0.0159518,
//         sbp: 0.0227294,
//         smokingHistory: 0.4999862,
//         bmiInteraction: -0.0003516,
//         sbpInteraction: -0.0004374,
//         smokingInteraction: -0.0153895,
//         baselineSurvival: 0.985,
//       },
//       {
//         age: 0.1046105,
//         bmi: 0.0036406,
//         sbp: 0.0216741,
//         smokingHistory: 0.7399405,
//         bmiInteraction: -0.0000129,
//         sbpInteraction: -0.0005311,
//         smokingInteraction: -0.0203997,
//         baselineSurvival: 0.989,
//       },
//     ];

//     let genderIndex = gender === "Male" ? 0 : 1;

//     labMiRiskCoefficient =
//       labMIData[genderIndex].age * _ageDifferenceFromBase +
//       labMIData[genderIndex].cholesterol * _cholesterolDifferenceFromBase +
//       labMIData[genderIndex].sbp * _sbpDifferenceFromBase +
//       labMIData[genderIndex].dbHistory * _diabetesHistory +
//       labMIData[genderIndex].smokingHistory * _smokingHistory +
//       labMIData[genderIndex].cholesterolInteraction *
//         _cholesterolDifferenceFromBase *
//         _ageDifferenceFromBase +
//       labMIData[genderIndex].sbpInteraction *
//         _sbpDifferenceFromBase *
//         _ageDifferenceFromBase +
//       labMIData[genderIndex].dbInteraction *
//         _diabetesHistory *
//         _ageDifferenceFromBase +
//       labMIData[genderIndex].smokingInteraction *
//         _smokingHistory *
//         _ageDifferenceFromBase;

//     labStrokeRiskCoefficient =
//       labStrokeData[genderIndex].age * _ageDifferenceFromBase +
//       labStrokeData[genderIndex].cholesterol * _cholesterolDifferenceFromBase +
//       labStrokeData[genderIndex].sbp * _sbpDifferenceFromBase +
//       labStrokeData[genderIndex].dbHistory * _diabetesHistory +
//       labStrokeData[genderIndex].smokingHistory * _smokingHistory +
//       labStrokeData[genderIndex].cholesterolInteraction *
//         _cholesterolDifferenceFromBase *
//         _ageDifferenceFromBase +
//       labStrokeData[genderIndex].sbpInteraction *
//         _sbpDifferenceFromBase *
//         _ageDifferenceFromBase +
//       labStrokeData[genderIndex].dbInteraction *
//         _diabetesHistory *
//         _ageDifferenceFromBase +
//       labStrokeData[genderIndex].smokingInteraction *
//         _smokingHistory *
//         _ageDifferenceFromBase;

//     nonLabMiRiskCoefficient =
//       nonLabMIData[genderIndex].age * _ageDifferenceFromBase +
//       nonLabMIData[genderIndex].bmi * _bmiDifferenceFromBase +
//       nonLabMIData[genderIndex].sbp * _sbpDifferenceFromBase +
//       nonLabMIData[genderIndex].smokingHistory * _smokingHistory +
//       nonLabMIData[genderIndex].bmiInteraction *
//         _bmiDifferenceFromBase *
//         _ageDifferenceFromBase +
//       nonLabMIData[genderIndex].sbpInteraction *
//         _sbpDifferenceFromBase *
//         _ageDifferenceFromBase +
//       nonLabMIData[genderIndex].smokingInteraction *
//         _smokingHistory *
//         _ageDifferenceFromBase;

//     nonLabStrokeRiskCoefficient =
//       nonLabStrokeData[genderIndex].age * _ageDifferenceFromBase +
//       nonLabStrokeData[genderIndex].bmi * _bmiDifferenceFromBase +
//       nonLabStrokeData[genderIndex].sbp * _sbpDifferenceFromBase +
//       nonLabStrokeData[genderIndex].smokingHistory * _smokingHistory +
//       nonLabStrokeData[genderIndex].bmiInteraction *
//         _bmiDifferenceFromBase *
//         _ageDifferenceFromBase +
//       nonLabStrokeData[genderIndex].sbpInteraction *
//         _sbpDifferenceFromBase *
//         _ageDifferenceFromBase +
//       nonLabStrokeData[genderIndex].smokingInteraction *
//         _smokingHistory *
//         _ageDifferenceFromBase;

//     if (lab) {
//       labMiRisk =
//         1 -
//         Math.pow(
//           labMIData[genderIndex].baselineSurvival,
//           Math.exp(labMiRiskCoefficient)
//         );
//       labStrokeRisk =
//         1 -
//         Math.pow(
//           labStrokeData[genderIndex].baselineSurvival,
//           Math.exp(labStrokeRiskCoefficient)
//         );
//       labCvdRisk = 1 - (1 - labMiRisk) * (1 - labStrokeRisk);
//     } else {
//       nonLabMiRisk =
//         1 -
//         Math.pow(
//           nonLabMIData[genderIndex].baselineSurvival,
//           Math.exp(nonLabMiRiskCoefficient)
//         );
//       nonLabStrokeRisk =
//         1 -
//         Math.pow(
//           nonLabStrokeData[genderIndex].baselineSurvival,
//           Math.exp(nonLabStrokeRiskCoefficient)
//         );
//       nonLabCvdRisk = 1 - (1 - nonLabMiRisk) * (1 - nonLabStrokeRisk);
//     }

//     // console.log(
//     //   "Printing Input values: labValues: " +
//     //     cholesterolValue +
//     //     ", Gender: " +
//     //     gender +
//     //     ", age: " +
//     //     age +
//     //     ", Cholesterol: " +
//     //     cholesterol +
//     //     ", sbp: " +
//     //     sbp +
//     //     ", Height: " +
//     //     height +
//     //     ", weight: " +
//     //     weight +
//     //     ", Diabetes? " +
//     //     hasDiabetes +
//     //     ", Smoking?: " +
//     //     isSmoking
//     // );

//     //console.log("lab MI Risk Coefficient= " + labMiRiskCoefficient);
//     //console.log("lab Stroke Risk Coefficient = " + labStrokeRiskCoefficient);
//     //console.log("lab MI Risk: " + labMiRisk);
//     //console.log("lab Stroke Risk: " + labStrokeRisk);
//     //console.log("lab CVD RISK SCORE: " + labCvdRisk);
//     //console.log("non lab MI Risk Coefficient= " + nonLabMiRiskCoefficient);
//     // //console.log(
//     //   "non lab Stroke Risk Coefficient = " + nonLabStrokeRiskCoefficient
//     // );
//     //console.log("non lab MI Risk: " + nonLabMiRisk);
//     //console.log("non lab Stroke Risk: " + nonLabStrokeRisk);
//     //console.log("non lab CVD RISK SCORE: " + nonLabCvdRisk);

//     miRiskScore = lab ? labMiRisk * 100 : nonLabMiRisk * 100;
//     strokeRiskScore = lab ? labStrokeRisk * 100 : nonLabStrokeRisk * 100;

//     cvdScore = lab ? labCvdRisk * 100 : nonLabCvdRisk * 100;

//     //console.log("Over all MI Risk Score: " + miRiskScore);
//     //console.log("Over all STROKE Risk Score: " + strokeRiskScore);
//     //console.log("Over all CVD Risk Score: " + cvdScore);

//     return [cvdScore, strokeRiskScore, miRiskScore];
//   }

//   // const handleAnalysis = async () => {
//   //   setIsLoading(true);

//   //   const cvdRiskScoreList = calculateCVDRiskScoreCal(
//   //     ExaminationHistoryData?.cholestrol != null ? 0 : 1,
//   //     patientDetails?.GENDER ?? "Male",
//   //     patientDetails?.age ?? 0,
//   //     ExaminationHistoryData?.cholestrol ?? 0,

//   //     ExaminationHistoryData?.sbp ?? 0,
//   //     ExaminationHistoryData?.height,
//   //     parseFloat(ExaminationHistoryData?.weight ?? '0').toFixed(1),

//   //     MedicalHistoryData?.isDiabetes === 0 ? 1 : 0,

//   //     PersonalHistoryData?.isSmoking === 0 ? 1 : 0,
//   //   );
//   //   console.log("cvdScore,strokeRiskScore,miRiskScore", cvdRiskScoreList)
//   //   let cvdRiskScoreData = []
//   //   cvdRiskScoreData = [{
//   //     cvdScore: cvdRiskScoreList[0],
//   //     strokeRiskScore: cvdRiskScoreList[1],
//   //     miRiskScore: cvdRiskScoreList[2]
//   //   }]
//   //   console.log("cvdRiskScoreData", cvdRiskScoreData)
//   //   sessionStorage.setItem("cvdRiskScoreList", JSON.stringify(cvdRiskScoreData))
//   //   console.log("cvdRiskScoreList", cvdRiskScoreList);

//   //   // if (!visitData?.examinationHistory?.dbp || !visitData?.examinationHistory?.sbp ||
//   //   //   (!visitData?.examinationHistory?.fbs && !visitData?.examinationHistory?.rbs && !visitData?.examinationHistory?.ppbs)) {
//   //   if ((visitData?.examinationHistory?.fbs === null || visitData?.examinationHistory?.ppbs === null || visitData?.examinationHistory?.rbs === null) &&
//   //     (visitData?.examinationHistory?.dbp === null || visitData?.examinationHistory?.sbp === null)) {

//   //     let alertMessage = '';

//   //     // if (!visitData?.examinationHistory?.fbs &&
//   //     //   !visitData?.examinationHistory?.ppbs &&
//   //     //   !visitData?.examinationHistory?.rbs) {
//   //     if (visitData?.examinationHistory?.fbs === null &&
//   //       visitData?.examinationHistory?.ppbs === null &&
//   //       visitData?.examinationHistory?.rbs === null) {
//   //       alertMessage = '\nEither FBS or PPBS or RBS is required for Diabetes Analysis.Are you sure you want to continue with existing data? Press Cancel to change.';
//   //       // showDialogBox(alertMessage);
//   //     }
//   //     // else if (!visitData?.examinationHistory?.dbp || !visitData?.examinationHistory?.sbp) {
//   //     else if (visitData?.examinationHistory?.dbp === null || visitData?.examinationHistory?.sbp === null) {
//   //       alertMessage = '\nSBP and DBP are required for Hypertension Analysis.\n';

//   //     }
//   //     await showDialogBox(alertMessage);
//   //   }
//   //   else {
//   //     try {
//   //       await getHTAnalysis();
//   //     }
//   //     catch (error) {
//   //       // showDialogBox('Hypertension Analysis Error', 'Hypertension Analysis is not available. Please continue with the rest of the diagnosis.');
//   //     }

//   //     try {
//   //       await getDiabeticAnalysis();
//   //     } catch (error) {
//   //       // showDialogBox('Diabetes Analysis Error', 'Diabetes Analysis is not available. Please continue with the rest of the diagnosis.');
//   //     }
//   //     navigate("/patients/diagnosis");
//   //   }
//   //   setIsLoading(false);

//   // };

//   const handleAnalysis = async () => {
//     console.log("click this ")
//     const cvdRiskScoreList = await calculateCVDRiskScoreCal(
//       ExaminationHistoryData?.cholestrol != null ? 0 : 1,
//       patientDetails?.GENDER ?? "Male",
//       patientDetails?.age ?? 0,
//       ExaminationHistoryData?.cholestrol ?? 0,
//       ExaminationHistoryData?.sbp ?? 0,
//       ExaminationHistoryData?.height,
//       parseFloat(ExaminationHistoryData?.weight ?? "0").toFixed(1),
//       MedicalHistoryData?.isDiabetes === 0 ? 1 : 0,
//       PersonalHistoryData?.isSmoking === 0 ? 1 : 0
//     );

//     //console.log("cvdScore,strokeRiskScore,miRiskScore", cvdRiskScoreList);

//     const cvdRiskScoreData = [
//       {
//         cvdScore: cvdRiskScoreList[0],
//         strokeRiskScore: cvdRiskScoreList[1],
//         miRiskScore: cvdRiskScoreList[2],
//       },
//     ];

//     //console.log("cvdRiskScoreData", cvdRiskScoreData);

//     sessionStorage.setItem(
//       "cvdRiskScoreList",
//       JSON.stringify(cvdRiskScoreData)
//     );
//     //console.log("cvdRiskScoreList", cvdRiskScoreList);

//     const requiresDiabetesAnalysis =
//       !ExaminationHistoryData?.fbs ||
//       !ExaminationHistoryData?.ppbs ||
//       !ExaminationHistoryData?.rbs;

//     //console.log("fbs:", ExaminationHistoryData?.fbs);
//     //console.log("ppbs:", ExaminationHistoryData?.ppbs);
//     //console.log("rbs:", ExaminationHistoryData?.rbs);
//     //console.log("requiresDiabetesAnalysis:", requiresDiabetesAnalysis);

//     const requiresHypertensionAnalysis =
//       !ExaminationHistoryData?.dbp || !ExaminationHistoryData?.sbp;

//     const nodata =
//       !ExaminationHistoryData?.dbp &&
//       !ExaminationHistoryData?.sbp &&
//       !ExaminationHistoryData?.fbs &&
//       !ExaminationHistoryData?.ppbs &&
//       !ExaminationHistoryData?.rbs;
//     //console.log("dbp:", ExaminationHistoryData?.dbp);
//     //console.log("sbp:", ExaminationHistoryData?.sbp);

//     setIsLoading(true);

//     if (requiresDiabetesAnalysis || requiresHypertensionAnalysis) {
//       let alertMessage = "";

//       if (nodata) {
//         alertMessage +=
//           "\nEither FBS or PPBS or RBS is required for Diabetes Analysis. ";
//       } else if (requiresDiabetesAnalysis) {
//         alertMessage +=
//           "\nEither FBS or PPBS or RBS is required for Diabetes Analysis.\n ";
//       } else if (requiresHypertensionAnalysis) {
//         alertMessage +=
//           "\nSBP and DBP are required for Hypertension Analysis.\n";
//       }

//       alertMessage +=
//         "\nAre you sure you want to continue with existing data? Press OK to continue or Cancel to change.";

//       // const confirmed = window.confirm(alertMessage);
//       showDialogBox(alertMessage);
//     } else {
//       try {
//         await getHTAnalysis();
//       } catch (error) {
//         console.error("Hypertension Analysis error:", error);
//         // showDialogBox('Hypertension Analysis Error', 'Hypertension Analysis is not available. Please continue with the rest of the diagnosis.');
//       }

//       try {
//         await getDiabeticAnalysis();
//       } catch (error) {
//         console.error("Diabetes Analysis error:", error);
//         // showDialogBox('Diabetes Analysis Error', 'Diabetes Analysis is not available. Please continue with the rest of the diagnosis.');
//       }

//       navigate("/patients/diagnosis");
//     }

//     setIsLoading(false);
//   };

//   const handleContinue = async () => {
//     setIsLoading(true);

//     try {
//       await getHTAnalysis();
//     } catch (error) {
//       console.error("Hypertension Analysis error:", error);
//     }

//     try {
//       await getDiabeticAnalysis();
//     } catch (error) {
//       console.error("Diabetes Analysis error:", error);
//     }
//     setIsLoading(false);
//     navigate("/patients/diagnosis");
//   };

//   //console.log("visitdata from api call", visitData);

//   return (
//     <>
//       {/* <Navbar /> */}
//       <div
//         style={{
//           display: "flex",
//           flexDirection: "column",
//           justifyContent: "center",
//           alignItems: "center",
//           // height: "86vh",
//         }}
//       >
//         <div
//           style={{
//             display: "flex",
//             justifyContent: "center",
//             // height: "70vh",
//             overflow: "scroll",
//             gap: "1rem",
//             width: "100%",
//           }}
//         >
//           {isLoading?(

//               <div className="spinningLoader">
//                     <CircularProgress size={48} color="inherit" />
//               </div>
//             ):
//           (<TabsContainer />)
//           }
//           {popupMessage && (
//             <div className="popup">
//               <div className="popup-inner">
//                 <p
//                   style={{
//                     fontSize: "24px",
//                     fontWeight: "600",
//                     fontFamily: "bold",
//                   }}
//                 >
//                   Missing Data for Analysis
//                 </p>
//                 <p>{popupMessage}</p>
//                 <div
//                   style={{
//                     display: "flex",
//                     flexDirection: "row",
//                     gap: "2rem",
//                     padding: "4%",
//                   }}
//                 >
//                   <button
//                     className="ContinueBtn"
//                     style={{
//                       Background: "#52447d !important",
//                     }}
//                     onClick={handleContinue}
//                   >
//                     Continue
//                     {isLoading && (
//                       <CircularProgress size={24} color="inherit" />
//                     )}
//                   </button>

//                   <button
//                     className="ContinueBtn"
//                     onClick={closePopup}
//                     style={{
//                       Background: "#52447d !important",
//                     }}
//                   >
//                     Cancel
//                   </button>
//                 </div>
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//     </>
//   );
// };

// export default Prescription;

import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import "./ReviewPage.css";
import Diagnosis from "./Diagnosis";
import { useNavigate } from "react-router-dom";
import BackArrow from "../../../images/Vector 34.svg";
import { publicEncrypt, constants } from "crypto-browserify";
import { Buffer } from "buffer";
import Axios from "axios";
import { Snackbar, Button, CircularProgress, Alert } from "@mui/material";
import config from "../../../Config/config";
import Config from "../../../Config/config";
import axios from "axios";

const Prescription = () => {
  const userDetails = JSON.parse(sessionStorage.getItem("UserData"))[0];
  const UserID = sessionStorage.getItem("RegUserId")
    ? JSON.parse(sessionStorage.getItem("RegUserId"))
    : sessionStorage.getItem("UserID");

  const publicKey = sessionStorage.getItem("RegpublicKey")
    ? sessionStorage.getItem("RegpublicKey")
    : JSON.parse(sessionStorage.getItem("publicKey"));

  const token = sessionStorage.getItem("Regtoken")
    ? sessionStorage.getItem("Regtoken")
    : JSON.parse(sessionStorage.getItem("token"));

  const padding = constants.RSA_PKCS1_PADDING;
  const data = Buffer.from(UserID.toString(), "utf-8");
  const encrypted = publicEncrypt(
    { key: publicKey, padding: padding },
    data
  ).toString("base64");

  const PATIENT_ID = JSON.parse(sessionStorage.getItem("PATIENT_ID"));

  const [visitData, setVisitData] = useState();
  var cheifComplaint = [];

  const navigate = useNavigate();

  const patientDetails = JSON.parse(
    sessionStorage.getItem("selectedPatientDetails")
  );

  const VISIT_ID = sessionStorage.getItem("VISIT_ID");

  useEffect(() => {
    const fetchDataVisitHistory = async () => {
      try {
        let data = JSON.stringify({
          userId: UserID,
          id: PATIENT_ID,
        });

        //console.log("data in visits", data);

        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${Config.apiURL}${Config.routes.getPatientVisitsAPI}`,
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "x-caller": encrypted,
          },
          data: data,
        };

        const response = await axios.request(config);
        //console.log("Response:", response.data.data[0].VISIT_DATA);

        const sessionVisitID = parseInt(VISIT_ID, 10);

        const filteredRecords = response.data.data.filter((record) => {
          return record.VISIT_ID === sessionVisitID;
        });
        //console.log("Filtered Records:", filteredRecords, typeof VISIT_ID);

        let res = await filteredRecords[0].VISIT_DATA;
        //console.log("---res----", res);

        let parsedRes = res ? JSON.parse(res) : null;
        setVisitData(parsedRes);
        //console.log("---res----", res);
      } catch (err) {
        //console.log(err, "err");
      }
    };

    fetchDataVisitHistory();
  }, []);

  // const visitHistory = sessionStorage.getItem("visitData");
  // const visitData =
  //   visitHistory !== null
  //     ? JSON.parse(sessionStorage.getItem("visitData"))
  //     : null;

  const MedicalHistoryData =
    visitData && visitData.medicalHistory ? visitData.medicalHistory : null;
  //console.log("MedicalHistoryData", MedicalHistoryData);

  if (MedicalHistoryData !== null || MedicalHistoryData !== undefined) {
    const parsedM = JSON.parse(MedicalHistoryData);
    //console.log("parse", parsedM);
    cheifComplaint =
      parsedM && parsedM.cheifComplaint ? parsedM.cheifComplaint : null;
    // setCheifComplaint(cheifComplaint)
    // console.log("chiefComplaint", cheifComplaint);
  }

  const DrugHistoryData = !!visitData
    ? JSON.parse(visitData.drugHistory)
    : null;
  //console.log("DrugHistoryData", DrugHistoryData);

  const PersonalHistoryData = !!visitData
    ? JSON.parse(visitData.personalHistory)
    : null;

  // console.log("PersonalHistoryData", PersonalHistoryData);

  const ExaminationHistoryData = !!visitData
    ? JSON.parse(visitData.examinationHistory)
    : null;
    
  //console.log("ExaminationHistoryData", ExaminationHistoryData);

  const alternativeNames = {
    isDiabetes: "Diabetes",
    isHypertension: "Hypertension",
    isAsthma: "Asthma",
    isCKD: "Chronic Kidney Disease",
    isCOPD: "Chronic Obstructive Pulmonary Disease(COPD)",
    isFamilyCardioVascular: "Cardiovascular",
    isFamilyDiabetes: "Diabetes Mellitus",
    isFamilyHypertension: "Hypertension",
    isFamilyOtherDisease: "Other Diseases",
    isHeartAttack: "Heart Attack",
    isHeartDisease: "Coronary Heart Disease",
    isLiverFailure: "Liver Failure",
    isPVD: "Peripheral Vascular Disease",
    isStroke: "Stroke",
  };

  const medicalHistoryData = MedicalHistoryData
    ? JSON.parse(MedicalHistoryData)
    : null;
  // Filter keys with a value of zero
  const keysWithZeroValue = medicalHistoryData
    ? Object.keys(medicalHistoryData).filter(
        (key) => medicalHistoryData[key] === 0
      )
    : [];
  // console.log("Keys with zero value:", keysWithZeroValue,);

  const thirdSection = keysWithZeroValue.filter((key) =>
    [
      "isFamilyDiabetes",
      "isFamilyHypertension",
      "isFamilyOtherDisease",
      "isHeartAttack",
    ].includes(key)
  );

  const dateString = patientDetails?.DATE_OF_BIRTH;
  const dateObject = new Date(dateString);
  const formattedDate = dateObject.toISOString().split("T")[0];

  const handleBackClick = () => {
    navigate("/patients");
  };

  const alternativePersonalHistoryNames = {
    isDrinking: "Drinking",
    isOnDialysis: "On Dialysis",
    isPhysicalActivity: "Physical Activity",
    isPolydypsia: "Polydypsia",
    isPolyuria: "Polyuria",
    isSmoking: "Smoking",
    isWeightloss: "Weight Loss",
  };

  const alternateEmergencyNames = {
    isAlteredConciousness: "Altered Consciousness",
    isBreathlessness: "Breathlessness",
    isChestPain: "Agina(Chesr Pain)",
    isDeficit: "Deficit",
    isEdema: "Edema",
    isFundus: "Fundus",
    isGeneralizedSeizures: "Generalized Seizures",
  };

  const keysToConsider = [
    "isAlteredConciousness",
    "isBreathlessness",
    "isChestPain",
    "isDeficit",
    "isEdema",
    "isFundus",
    "isGeneralizedSeizures",
  ];

  const totalDrugs =
    (DrugHistoryData?.currentHTDrugs?.length || 0) +
    ((DrugHistoryData?.currentDBDrugs &&
      DrugHistoryData.currentDBDrugs.length) ||
      0) +
    ((DrugHistoryData?.currentInsulin &&
      DrugHistoryData.currentInsulin.length) ||
      0) +
    ((DrugHistoryData?.currentLipid && DrugHistoryData.currentLipid.length) ||
      0) +
    ((DrugHistoryData?.currentAsprin && DrugHistoryData.currentAsprin.length) ||
      0);

  const TabsContainer = () => {
    return (
      <div
        style={{
          display: "flex",
          backgroundColor: "#F5F5F5",
          width: "80vw",
          flexDirection: "column",
          gap: "1rem",
          overflow: "scroll",
        }}
      >
        <div
          style={{
            backgroundColor: "rgba(227, 237, 255, 1)",
            display: "flex",
            position: "sticky",
            // flexDirection:'row'
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {/* <div
            style={{
              display: "flex",
              gap: "5px",
              height: "10px",
              alignItems: "center",
              cursor: "pointer",
              marginTop: "1.05rem",
              paddingLeft: "10px",
            }}
            onClick={handleBackClick}
          >
            <img src={BackArrow} style={{ width: "1rem", height: "0.8rem" }} />
          </div>
          */}
          <button className="review">REVIEW</button>
          <h4 style={{ color: "rgba(82, 68, 125, 1)", padding: "0 1rem" }}>
            Visit ID:{visitData && visitData?.visitId}
          </h4>
        </div>

        {/* <div
          style={{
            display: "flex",
            flexDirection: "row",
            // justifyContent: "space-between",
            padding: "1rem",
            gap: "10rem",
          }}
        >
          <div className="patient_data">
            <div className="patient_data_text">PATIENT DETAILS</div>
            <div
              style={{
                display: "flex",
                alignContent: "center",
                flexDirection: "column",
                gap: "0.03rem",
                padding: "1rem",
              }}
              className="text-data"
            >
              <p>
                {patientDetails.FIRST_NAME + " " + patientDetails.LAST_NAME}
              </p>
              <p>
                {patientDetails.GENDER}/{patientDetails.age}
              </p>
              <p className="PatientID-review">{patientDetails.PATIENT_ID}</p>
              <p>{formattedDate}</p>
            </div>
          </div>

          <div className="patient_data">
            <div className="patient_data_text">Doctor Details</div>
            <div
              style={{
                display: "flex",
                alignContent: "center",
                flexDirection: "column",
                gap: "0.03rem",
                padding: "1rem",
              }}
              className="text-data"
            >
              <p>{userDetails.FIRST_NAME + " " + userDetails.LAST_NAME}</p>
              <p>{userDetails.MOBILE}</p>
            </div>
          </div>
        </div>*/}

        {/* <div
          style={{
            display: "flex",
            alignItems: "center",
            // background: "#EBE3FF",
            height: "38px",
            fontSize: "21px",
            fontWeight: "400",
            lineHeight: "25px",
            letterSpacing: "0em",
            alignContent: "center",
            padding: "1%",
          }}
        >
          <p>
            <b>Visit Note</b> - {VISIT_ID}
          </p>
        </div>
        */}
        {/* medical history tab */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            background: "#EBE3FF",
            height: "38px",
            fontSize: "21px",
            fontWeight: "400",
            lineHeight: "25px",
            letterSpacing: "0em",
            alignContent: "center",
            padding: "1%",
          }}
        >
          <p>Medical History</p>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            background: "#D0E3FF",
            height: "38px",
            fontSize: "17px",
            fontWeight: "400",
            lineHeight: "25px",
            letterSpacing: "0em",
            alignContent: "center",
            padding: "1%",
          }}
        >
          <p>Previous Conditions</p>
        </div>

        <div
          style={{
            display: "flex",
            padding: "1rem",
          }}
        >
          {keysWithZeroValue.length > 0 ? (
            <div style={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}>
              {keysWithZeroValue.map((key) => (
                <p
                  key={key}
                  style={{
                    display: "inline-block",
                    padding: "0.5rem 1rem", // Adjust padding to your preference
                    background: "#D1AEFF",
                    borderRadius: "10px",
                  }}
                >
                  {alternativeNames[key]}
                </p>
              ))}
            </div>
          ) : (
            <p>No Data in this section</p>
          )}
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            background: "#D0E3FF",
            height: "38px",
            fontSize: "17px",
            fontWeight: "400",
            lineHeight: "25px",
            letterSpacing: "0em",
            alignContent: "center",
            padding: "1%",
          }}
        >
          <p>Family History</p>
        </div>

        <div style={{ display: "flex", padding: "1rem", gap: "1rem" }}>
          {thirdSection.length > 0 ? (
            <>
              {thirdSection.map((key) => (
                <p
                  key={key}
                  style={{
                    display: "inline-block",
                    padding: "0.5rem 1rem", // Adjust padding to your preference
                    background: "#D1AEFF",
                    borderRadius: "10px",
                  }}
                >
                  {alternativeNames[key]}
                </p>
              ))}
            </>
          ) : (
            <p>No Data in this section</p>
          )}
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            background: "#D0E3FF",
            height: "38px",
            fontSize: "17px",
            fontWeight: "400",
            lineHeight: "25px",
            letterSpacing: "0em",
            alignContent: "center",
            padding: "1%",
          }}
        >
          <p>Chief Complaint</p>
        </div>

        <div
          className="ReviewContainer"
          style={{ display: "flex", padding: "1rem", gap: "1rem" }}
        >
          {/* {console.log("console", cheifComplaint)} */}
          {cheifComplaint &&
            cheifComplaint.map((complaint, index) => (
              <p
                key={index}
                style={{
                  display: "inline-block",
                  padding: "0.5rem 1rem", // Adjust padding to your preference
                  background: "#D1AEFF",
                  borderRadius: "10px",
                }}
              >
                {complaint}
              </p>
            ))}

          {MedicalHistoryData && MedicalHistoryData.otherChiefComplaint && (
            <p
              style={{
                width: "fit-content",
                padding: "1%",
                height: "6vh",
                background: "#D1AEFF",
                borderRadius: "10px",
              }}
            >
              {MedicalHistoryData.otherChiefComplaint}
            </p>
          )}
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            background: "#EBE3FF",
            height: "38px",
            fontSize: "21px",
            fontWeight: "400",
            lineHeight: "25px",
            letterSpacing: "0em",
            alignContent: "center",
            padding: "1%",
          }}
        >
          <p>Drug History</p>
        </div>
        {/* drug history tab */}
        <div style={{ display: "flex", flexDirection: "column", gap: "2rem" }}>
          {totalDrugs === 0 ? (
            <p style={{ padding: "1%" }}>No Drug History for this patient.</p>
          ) : (
            <>
              {DrugHistoryData.isHypertensiveDrugs === 0 && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    background:
                      DrugHistoryData.isHypertensiveDrugs === 0
                        ? "#D0E3FF"
                        : "none",
                    height: "38px",
                    fontSize: "17px",
                    fontWeight: "400",
                    lineHeight: "25px",
                    letterSpacing: "0em",
                    alignContent: "center",
                    padding: "1%",
                  }}
                >
                  <p>Anti Hypertensive Drugs</p>
                </div>
              )}
              {DrugHistoryData.currentHTDrugs !== null &&
              DrugHistoryData.currentHTDrugs.length > 0 ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    //   gap: "1rem",
                    width: "35%",
                    padding: "4px 0px 0px 10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      // gap: "2rem",
                      background: "#fff4b8",
                      padding: "10px",
                    }}
                  >
                    <p>Name</p>
                    <p>Class</p>
                    <p>Dose</p>
                  </div>

                  {DrugHistoryData.currentHTDrugs.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        background: index % 2 === 0 ? "#ffedde" : "white", // Alternating background colors
                        padding: "10px",
                      }}
                    >
                      <p>{item.name}</p>
                      <p>{item.className}</p>
                      <p>
                        {item.currentDose} {item.unit}
                      </p>
                    </div>
                  ))}
                </div>
              ) : null}
              {DrugHistoryData.isDiabeticDrugs === 0 && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    background:
                      DrugHistoryData.isDiabeticDrugs === 0
                        ? "#D0E3FF"
                        : "none",
                    height: "38px",
                    fontSize: "17px",
                    fontWeight: "400",
                    lineHeight: "25px",
                    letterSpacing: "0em",
                    alignContent: "center",
                    padding: "1%",
                  }}
                >
                  <p>Anti Diabetic Drugs</p>
                </div>
              )}
              {DrugHistoryData.currentDBDrugs !== null &&
              DrugHistoryData.currentDBDrugs.length > 0 ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      //   gap: "1rem",
                      width: "35%",
                      padding: "4px 0px 0px 10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        // gap: "2rem",
                        background: "#fff4b8",
                        padding: "10px",
                      }}
                    >
                      <p>Name</p>
                      <p>Class</p>
                      <p>Dose</p>
                    </div>

                    {DrugHistoryData.currentDBDrugs.map((item, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          background: index % 2 === 0 ? "#ffedde" : "white", // Alternating background colors
                          padding: "10px",
                        }}
                      >
                        <p>{item.name}</p>
                        <p>{item.className}</p>
                        <p>
                          {item.currentDose} {item.unit}
                        </p>
                      </div>
                    ))}
                  </div>
                </>
              ) : null}
              {DrugHistoryData.isInsulin === 0 && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    background:
                      DrugHistoryData.isInsulin === 0
                        ? "#D0E3FF"
                        : "transparent",
                    height: "38px",
                    fontSize: "17px",
                    fontWeight: "400",
                    lineHeight: "25px",
                    letterSpacing: "0em",
                    alignContent: "center",
                    padding: "1%",
                  }}
                >
                  <p>Insulin Drugs</p>
                </div>
              )}

              {DrugHistoryData.currentInsulin !== null &&
              DrugHistoryData.currentInsulin.length > 0 ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      //   gap: "1rem",
                      width: "35%",
                      padding: "4px 0px 0px 10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        // gap: "2rem",
                        background: "#fff4b8",
                        padding: "10px",
                      }}
                    >
                      <p>Name</p>
                      <p>Class</p>
                      <p>Dose</p>
                    </div>

                    {DrugHistoryData.currentInsulin.map((item, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          background: index % 2 === 0 ? "#ffedde" : "white", // Alternating background colors
                          padding: "10px",
                        }}
                      >
                        <p>{item.name}</p>
                        <p>{item.className}</p>
                        <p>
                          {item.currentDose} {item.unit}
                        </p>
                      </div>
                    ))}
                  </div>
                </>
              ) : null}
              {DrugHistoryData.isLipidDrugs === 0 && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    // background: "#D0E3FF",
                    background:
                      DrugHistoryData.isLipidDrugs === 0
                        ? "#D0E3FF"
                        : "transparent",
                    height: "38px",
                    fontSize: "17px",
                    fontWeight: "400",
                    lineHeight: "25px",
                    letterSpacing: "0em",
                    alignContent: "center",
                    padding: "1%",
                  }}
                >
                  <p>Lipid Lowering Drugs</p>
                </div>
              )}

              {DrugHistoryData.currentLipid !== null &&
              DrugHistoryData.currentLipid.length > 0 ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      //   gap: "1rem",
                      width: "35%",
                      padding: "4px 0px 0px 10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        // gap: "2rem",
                        background: "#fff4b8",
                        padding: "10px",
                      }}
                    >
                      <p>Name</p>
                      <p>Class</p>
                      <p>Dose</p>
                    </div>

                    {DrugHistoryData.currentLipid.map((item, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          background: index % 2 === 0 ? "#ffedde" : "white", // Alternating background colors
                          padding: "10px",
                        }}
                      >
                        <p>{item.name}</p>
                        <p>{item.className}</p>
                        <p>
                          {item.currentDose} {item.unit}
                        </p>
                      </div>
                    ))}
                  </div>
                </>
              ) : null}
              {DrugHistoryData.isAsprin === 0 && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    background:
                      DrugHistoryData.isAsprin === 0
                        ? "#D0E3FF"
                        : "transparent",
                    height: "38px",
                    fontSize: "17px",
                    fontWeight: "400",
                    lineHeight: "25px",
                    letterSpacing: "0em",
                    alignContent: "center",
                    padding: "1%",
                  }}
                >
                  <p>Aspirin Drugs</p>
                </div>
              )}

              {DrugHistoryData.currentAsprin !== null &&
              DrugHistoryData.currentAsprin.length > 0 ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "35%",
                      padding: "4px 0px 0px 10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        // gap: "2rem",
                        background: "#fff4b8",
                        padding: "10px",
                      }}
                    >
                      <p>Name</p>
                      <p>Class</p>
                      <p>Dose</p>
                    </div>

                    {DrugHistoryData.currentAsprin.map((item, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          background: index % 2 === 0 ? "#ffedde" : "white", // Alternating background colors
                          padding: "10px",
                        }}
                      >
                        <p>{item.name}</p>
                        <p>{item.className}</p>
                        <p>
                          {item.currentDose} {item.unit}
                        </p>
                      </div>
                    ))}
                  </div>
                </>
              ) : null}
            </>
          )}
        </div>

        {/* personal History tab */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            background: "#EBE3FF",
            height: "38px",
            fontSize: "21px",
            fontWeight: "400",
            lineHeight: "25px",
            letterSpacing: "0em",
            alignContent: "center",
            padding: "1%",
          }}
        >
          <p>Personal History</p>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            background: "#D0E3FF",
            height: "38px",
            fontSize: "17px",
            fontWeight: "400",
            lineHeight: "25px",
            letterSpacing: "0em",
            alignContent: "center",
            padding: "1%",
          }}
        >
          <p>Personal Habits</p>
        </div>

        <div style={{ display: "flex", padding: "1rem", gap: "1rem" }}>
          {PersonalHistoryData &&
            Object.entries(PersonalHistoryData).map(([key, value]) => {
              if (
                (key === "isDrinking" ||
                  key === "isPhysicalActivity" ||
                  key === "isSmoking") &&
                value === 0
              ) {
                return (
                  <p
                    key={key}
                    style={{
                      display: "inline-block",
                      padding: "0.5rem 1rem", // Adjust padding to your preference
                      background: "#D1AEFF",
                      borderRadius: "10px",
                    }}
                  >
                    {alternativePersonalHistoryNames[key]}
                  </p>
                );
              }
              return null;
            })}
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            background: "#D0E3FF",
            height: "38px",
            fontSize: "17px",
            fontWeight: "400",
            lineHeight: "25px",
            letterSpacing: "0em",
            alignContent: "center",
            padding: "1%",
          }}
        >
          <p>Personal Symptoms</p>
        </div>

        <div style={{ display: "flex", padding: "1rem", gap: "1rem" }}>
          {PersonalHistoryData &&
            Object.entries(PersonalHistoryData).map(([key, value]) => {
              if (
                (key === "isPolydypsia" ||
                  key === "isPolyuria" ||
                  key === "isSmoking" ||
                  key === "isWeightloss") &&
                value === 0
              ) {
                return (
                  <p
                    key={key}
                    style={{
                      display: "inline-block",
                      padding: "0.5rem 1rem", // Adjust padding to your preference
                      background: "#D1AEFF",
                      borderRadius: "10px",
                    }}
                  >
                    {alternativePersonalHistoryNames[key]}
                  </p>
                );
              }
              return null;
            })}
        </div>

        {/* examination history tab */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            background: "#EBE3FF",
            height: "38px",
            fontSize: "21px",
            fontWeight: "400",
            lineHeight: "25px",
            letterSpacing: "0em",
            alignContent: "center",
            padding: "1%",
          }}
        >
          <p></p>Examination
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            background: "#D0E3FF",
            height: "38px",
            fontSize: "17px",
            fontWeight: "400",
            lineHeight: "25px",
            letterSpacing: "0em",
            alignContent: "center",
            padding: "1%",
          }}
        >
          <p>Blood Pressure Readings</p>
        </div>

        <div
          className="ReviewContainer"
          style={{ display: "flex", gap: "1rem", alignItems: "center" }}
        >
          {ExaminationHistoryData && ExaminationHistoryData.averageSBP && (
            <div style={{ padding: "0.5rem", textAlign: "center" }}>
              <p
                style={{
                  display: "inline-block",
                  padding: "0.5rem 1rem", // Adjust padding to your preference
                  background: "#D1AEFF",
                  borderRadius: "10px",
                }}
              >
                SBP: {isNaN(ExaminationHistoryData.averageSBP) ? "": ExaminationHistoryData.averageSBP}
              </p>
            </div>
          )}
          {ExaminationHistoryData &&
            ExaminationHistoryData.averageDBP !== undefined &&
            ExaminationHistoryData.averageDBP !== null && (
              <div style={{ padding: "0.5rem", textAlign: "center" }}>
                <p
                  style={{
                    display: "inline-block",
                    padding: "0.5rem 1rem", // Adjust padding to your preference
                    background: "#D1AEFF",
                    borderRadius: "10px",
                  }}
                >
                  DBP: {isNaN(ExaminationHistoryData.averageDBP) ? "" : ExaminationHistoryData.averageDBP}
                </p>
              </div>
            )}

          {ExaminationHistoryData && ExaminationHistoryData.heartRate && (
            <div style={{ padding: "0.5rem", textAlign: "center" }}>
              <p
                style={{
                  display: "inline-block",
                  padding: "0.5rem 1rem", // Adjust padding to your preference
                  background: "#D1AEFF",
                  borderRadius: "10px",
                }}
              >
                Heart Rate: {ExaminationHistoryData.heartRate}
              </p>
            </div>
          )}
          {(visitData?.examinationHistory?.sbp ?? 0) >= 180 ||
          (visitData?.examinationHistory?.dbp ?? 0) <= 110 ? (
            <>
              {ExaminationHistoryData &&
                ExaminationHistoryData.sbp2 != null && (
                  <div style={{ padding: "0.5rem", textAlign: "center" }}>
                    <p
                      style={{
                        display: "inline-block",
                        padding: "0.5rem 1rem", // Adjust padding to your preference
                        background: "#D1AEFF",
                        borderRadius: "10px",
                      }}
                    >
                      sbp 2 : {ExaminationHistoryData.sbp2}
                    </p>
                  </div>
                )}
              {ExaminationHistoryData &&
                ExaminationHistoryData.dbp2 != null && (
                  <div style={{ padding: "0.5rem", textAlign: "center" }}>
                    <p
                      style={{
                        display: "inline-block",
                        padding: "0.5rem 1rem", // Adjust padding to your preference
                        background: "#D1AEFF",
                        borderRadius: "10px",
                      }}
                    >
                      dbp 2 : {ExaminationHistoryData.dbp2}
                    </p>
                  </div>
                )}
              {ExaminationHistoryData &&
                ExaminationHistoryData.sbp3 != null && (
                  <div style={{ padding: "0.5rem", textAlign: "center" }}>
                    <p
                      style={{
                        display: "inline-block",
                        padding: "0.5rem 1rem", // Adjust padding to your preference
                        background: "#D1AEFF",
                        borderRadius: "10px",
                      }}
                    >
                      sbp3 : {ExaminationHistoryData.sbp3}
                    </p>
                  </div>
                )}
              {ExaminationHistoryData &&
                ExaminationHistoryData.dbp3 != null && (
                  <div style={{ padding: "0.5rem", textAlign: "center" }}>
                    <p
                      style={{
                        display: "inline-block",
                        padding: "0.5rem 1rem", // Adjust padding to your preference
                        background: "#D1AEFF",
                        borderRadius: "10px",
                      }}
                    >
                      dbp3 : {ExaminationHistoryData.dbp3}
                    </p>
                  </div>
                )}
            </>
          ) : null}
        </div>

        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              background: "#D0E3FF",
              height: "38px",
              fontSize: "17px",
              fontWeight: "400",
              lineHeight: "25px",
              letterSpacing: "0em",
              alignContent: "center",
              padding: "1%",
            }}
          >
            <p>Emergency Indications</p>
          </div>
          {ExaminationHistoryData &&
            keysToConsider.map((key) => {
              const value = ExaminationHistoryData[key];
              if (value === 0) {
                const displayName = alternateEmergencyNames[key] || key;
                return (
                  <div
                    key={key}
                    style={{
                      display: "flex",
                      padding: "0.5rem",
                    }}
                  >
                    <p
                      style={{
                        display: "inline-block",
                        padding: "0.5rem 1rem",
                        background: "#D1AEFF",
                        borderRadius: "10px",
                      }}
                    >
                      {displayName}
                    </p>
                  </div>
                );
              }
              return null;
            })}
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            background: "#D0E3FF",
            height: "38px",
            fontSize: "17px",
            fontWeight: "400",
            lineHeight: "25px",
            letterSpacing: "0em",
            alignContent: "center",
            padding: "1%",
          }}
        >
          <p>Blood Sugar Readings</p>
        </div>
        {ExaminationHistoryData && (
          <div style={{ display: "flex", padding: "0.5rem", gap: "1rem" }}>
            {ExaminationHistoryData.fbs && (
              <p
                style={{
                  display: "inline-block",
                  padding: "0.5rem 1rem", // Adjust padding to your preference
                  background: "#D1AEFF",
                  borderRadius: "10px",
                }}
              >
                FBS : {ExaminationHistoryData.fbs}
              </p>
            )}
            {ExaminationHistoryData.ppbs && (
              <p
                style={{
                  display: "inline-block",
                  padding: "0.5rem 1rem", // Adjust padding to your preference
                  background: "#D1AEFF",
                  borderRadius: "10px",
                }}
              >
                PPBS : {ExaminationHistoryData.ppbs}
              </p>
            )}
            {ExaminationHistoryData.rbs && (
              <p
                style={{
                  display: "inline-block",
                  padding: "0.5rem 1rem", // Adjust padding to your preference
                  background: "#D1AEFF",
                  borderRadius: "10px",
                }}
              >
                RBS : {ExaminationHistoryData.rbs}
              </p>
            )}
            {ExaminationHistoryData.hba1c && (
              <p
                style={{
                  display: "inline-block",
                  padding: "0.5rem 1rem", // Adjust padding to your preference
                  background: "#D1AEFF",
                  borderRadius: "10px",
                }}
              >
                Hb1Ac : {isNaN(ExaminationHistoryData.hba1c) ? "": ExaminationHistoryData.hba1c}
              </p>
            )}
            {ExaminationHistoryData.creatinine && (
              <p
                style={{
                  display: "inline-block",
                  padding: "0.5rem 1rem", // Adjust padding to your preference
                  background: "#D1AEFF",
                  borderRadius: "10px",
                }}
              >
                Serum Creatine : {ExaminationHistoryData.creatinine}
              </p>
            )}
            {ExaminationHistoryData.gfr && (
              <p
                style={{
                  display: "inline-block",
                  padding: "0.5rem 1rem", // Adjust padding to your preference
                  background: "#D1AEFF",
                  borderRadius: "10px",
                }}
              >
                GFR : {ExaminationHistoryData.gfr}
              </p>
            )}
          </div>
        )}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            background: "#D0E3FF",
            height: "38px",
            fontSize: "17px",
            fontWeight: "400",
            lineHeight: "25px",
            letterSpacing: "0em",
            alignContent: "center",
            padding: "1%",
          }}
        >
          <p>Measurements</p>
        </div>

        {ExaminationHistoryData && (
          <div style={{ display: "flex", padding: "0.5rem", gap: "1rem" }}>
            {ExaminationHistoryData.weight && (
              <p
                style={{
                  display: "inline-block",
                  padding: "0.5rem 1rem", // Adjust padding to your preference
                  background: "#D1AEFF",
                  borderRadius: "10px",
                }}
              >
                Weight : {ExaminationHistoryData.weight}
              </p>
            )}
            {ExaminationHistoryData.height && (
              <p
                style={{
                  display: "inline-block",
                  padding: "0.5rem 1rem", // Adjust padding to your preference
                  background: "#D1AEFF",
                  borderRadius: "10px",
                }}
              >
                Height : {ExaminationHistoryData.height}
              </p>
            )}
            {ExaminationHistoryData.waist && (
              <p
                style={{
                  display: "inline-block",
                  padding: "0.5rem 1rem", // Adjust padding to your preference
                  background: "#D1AEFF",
                  borderRadius: "10px",
                }}
              >
                Waist : {ExaminationHistoryData.waist}
              </p>
            )}
            {ExaminationHistoryData.bmi !== null && ( // Check if BMI is not null
              <p
                style={{
                  display: "inline-block",
                  padding: "0.5rem 1rem",
                  background: "#D1AEFF",
                  borderRadius: "10px",
                }}
              >
                BMI: {ExaminationHistoryData.bmi}
              </p>
            )}
          </div>
        )}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            background: "#D0E3FF",
            height: "38px",
            fontSize: "17px",
            fontWeight: "400",
            lineHeight: "25px",
            letterSpacing: "0em",
            alignContent: "center",
            padding: "1%",
          }}
        >
          <p>Lab Values</p>
        </div>
        {ExaminationHistoryData && (
          <div style={{ display: "flex", padding: "0.5rem", gap: "1rem" }}>
            {ExaminationHistoryData.ldl && (
              <p
                style={{
                  display: "inline-block",
                  padding: "0.5rem 1rem", // Adjust padding to your preference
                  background: "#D1AEFF",
                  borderRadius: "10px",
                }}
              >
                LDL : {ExaminationHistoryData.ldl}
              </p>
            )}
            {ExaminationHistoryData.cholestrol && (
              <p
                style={{
                  display: "inline-block",
                  padding: "0.5rem 1rem", // Adjust padding to your preference
                  background: "#D1AEFF",
                  borderRadius: "10px",
                }}
              >
                Cholesterol : {ExaminationHistoryData.cholestrol}
              </p>
            )}
          </div>
        )}

        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            className="addAppointmentsBtn"
            style={{
              Background: "#52447d !important",
              boxShadow: "0px 3px 16px 1px #52447D75",
            }}
            onClick={handleAnalysis}
          >
            Analysis
            {isLoading && <CircularProgress size={24} color="inherit" />}
          </button>
          {/* <button
            className="addAppointmentsBtn"
            style={{
              Background: "#52447d !important",
              boxShadow: "0px 3px 16px 1px #52447D75",
            }}
            onClick={() => <GeneratePrescriptionAsPdf visitData={visitData} />}>
            Generate PDF
          </button> */}
          {/* {showPopup && <AnalysisPopup onContinue={handleContinue} />} */}
        </div>
      </div>
    );
  };

  //code change raviteja
  const [isLoading, setIsLoading] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  const showDialogBox = (message) => {
    setPopupMessage(message);
  };

  const closePopup = () => {
    setPopupMessage("");
  };

  function getMedicalHistoryMap(medicalHistoryData) {
    // console.log("medicalHistory", medicalHistoryData)
    // const medicalHistory = medicalHistoryData === undefined ? [] : JSON.parse(medicalHistoryData);
    const medicalHistory =
      medicalHistoryData === undefined ? [] : JSON.parse(medicalHistoryData);
    //console.log("medicalHistory", medicalHistoryData);

    const medicalHistoryMap = [];

    if (medicalHistory?.isDiabetes === 0) {
      medicalHistoryMap.push("DB");
    }
    if (medicalHistory?.isHypertension === 0) {
      medicalHistoryMap.push("HTN");
    }
    if (medicalHistory?.isCOPD === 0 || medicalHistory?.isAsthma === 0) {
      medicalHistoryMap.push("CA");
    }
    if (medicalHistory?.isStroke === 0) {
      medicalHistoryMap.push("STK");
    }
    if (medicalHistory?.isHeartDisease === 0) {
      medicalHistoryMap.push("HD");
    }
    if (medicalHistory?.isCKD === 0) {
      medicalHistoryMap.push("CKD");
    }
    if (medicalHistory?.isPVD === 0) {
      medicalHistoryMap.push("PVD");
    }
    if (medicalHistory?.isLiverFailure === 0) {
      medicalHistoryMap.push("LF");
    }
    if (medicalHistory?.isHeartAttack === 0) {
      //medicalHistory?.isHeartAttack === 9 ||
      medicalHistoryMap.push("HA");
    }
    //console.log("medicalHistoryMap--------", medicalHistoryMap);
    return medicalHistoryMap;
  }

  function getRiskFactors(visitData) {
    const personalHistory = visitData?.personalHistory
      ? JSON.parse(visitData.personalHistory)
      : [];
    const examinationHistory = visitData?.examinationHistory
      ? JSON.parse(visitData.examinationHistory)
      : [];

    //console.log("personalHistory--------", personalHistory.isSmoking);
    //console.log("examinationHistory--------", examinationHistory);
    const riskFactors = [];

    if (personalHistory?.isSmoking === 0) {
      //personalHistory?.isSmoking === 9 ||
      riskFactors.push("Smoking");
    }
    if (personalHistory?.isFamillyHistoryOfMI === 0) {
      riskFactors.push("Family");
    }
    if (
      examinationHistory?.cholestrol === 0 ||
      examinationHistory?.cholestrol > 200
    ) {
      riskFactors.push("Dyslipidemia");
    }

    return riskFactors;
  }

  function getNeurologicalSigns(examinationHistoryData) {
    // console.log("ecamination", examinationHistoryData)
    const examinationHistory =
      examinationHistoryData === undefined
        ? []
        : JSON.parse(examinationHistoryData);
    let counter = 0;

    if (examinationHistory?.isEdema === 0) counter++;
    if (examinationHistory?.isBreathlessness === 0) counter++;
    if (examinationHistory?.isChestPain === 0) counter++;
    if (examinationHistory?.isAlteredConciousness === 0) counter++;
    if (examinationHistory?.isDeficit === 0) counter++;
    if (examinationHistory?.isFundus === 0) counter++;
    if (examinationHistory?.isGeneralizedSeizures === 0) counter++;

    if (counter >= 4) {
      return true;
    } else {
      return false;
    }
  }

  function getCurrentHTDrugs(drugHistoryData) {
    // console.log("drugHistoryData", drugHistoryData)
    const drugHistory =
      drugHistoryData === undefined ? [] : JSON.parse(drugHistoryData);
    // const filteredDrugs = drugHistory.currentHTDrugs.filter(drug => drug.className != null || drug.classId != null);
    const filteredDrugs = (drugHistory.currentHTDrugs || []).filter(
      (drug) => drug && (drug.className != null || drug.classId != null)
    );

    // console.log("filteredDrugs", filteredDrugs);
    return filteredDrugs;
  }

  function getCurrentDBDrugs(drugHistoryData) {
    // console.log("drugHistoryData", drugHistoryData)
    const drugHistory =
      drugHistoryData === undefined ? [] : JSON.parse(drugHistoryData);
    // const filteredDrugs = drugHistory.currentHTDrugs.filter(drug => drug.className != null || drug.classId != null);
    const filteredDrugs = (drugHistory.currentDBDrugs || []).filter(
      (drug) => drug && (drug.className != null || drug.classId != null)
    );

    // console.log("filteredDrugs", filteredDrugs);
    return filteredDrugs;
  }

  const getHTAnalysis = async () => {
    try {
      // const username = "tiatech-tele";
      // const password = "cds$Tia2221";
      // const basicAuth = "Basic " + btoa(`${username}:${password}`);

      //console.log("basic ", basicAuth)

      const payload = {
        USER_ID: UserID,
        visitData: visitData,
        // Basic_Auth: basicAuth,
        medicalHistory: getMedicalHistoryMap(visitData?.medicalHistory),
        riskFactor: getRiskFactors(visitData),
        neurologicalSigns: getNeurologicalSigns(visitData?.examinationHistory),
        currentDrugs: getCurrentHTDrugs(visitData?.drugHistory),
      };
      //console.log("paylaod", payload);

      const examinationHistory = visitData?.examinationHistory
        ? JSON.parse(visitData.examinationHistory)
        : null;
      const sbp =
        examinationHistory?.sbp === undefined
          ? "null"
          : examinationHistory?.sbp;
      const dbp =
        examinationHistory?.dbp === undefined
          ? "null"
          : examinationHistory?.dbp;
      const medicalHistoryMap = getMedicalHistoryMap(visitData?.medicalHistory);
      const getRiskFactorsData = getRiskFactors(visitData);
      const getNeurologicalSignsData = getNeurologicalSigns(
        visitData?.examinationHistory
      );
      const currentDrugsData = getCurrentHTDrugs(visitData?.drugHistory);

      const json = {
        sbp: sbp,
        dbp: dbp,
        medicalHistory: medicalHistoryMap,
        riskFactor: getRiskFactorsData,
        neurologicalSigns: getNeurologicalSignsData,
        treatmentFacility: "2",
        currentDrugs: currentDrugsData,
      };

      //console.log("json", json);

      const response = await Axios.post(
        `${config.abhaBaseUrl}getHTAnalysis`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
            "x-caller": encrypted,
          },
        }
      );
      // console.log("respons of hy cdss cl", response.data)
      if (response.status === 200) {
        const jsonData = response.data;

        console.log(jsonData, "jsonDatajsonData.......");
        sessionStorage.setItem("htResult", JSON.stringify(jsonData));

        // API CALL TO SAVE DAT AIN DB
        const Response = await Axios.post(
          `${config.apiURL}${config.routes.saveCDSSAPI}`,
          {
            cdssID: "0", //hypertension
            inputData: json,
            outputData: jsonData,
            remarks: "",
            accepted: "0",
            createdBy: visitData.nurseUserId,
            visitID: visitData.visitId,
          },
          {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${token}`,
              "x-caller": encrypted,
            },
          }
        );

        if (Response.status === 201) {
          //console.log("Status = ", Response.data);
        }
      }
    } catch (error) {
      console.error("Error fetching HT analysis:", error.message);
      throw error;
    }
  };

  const getDiabeticAnalysis = async () => {
    try {
      // const username = "tiatech-tele";
      // const password = "cds$Tia2221";
      // const basicAuth = "Basic " + btoa(`${username}:${password}`);
      //console.log("visitData", visitData);
      let insulinData = [];

      // if (
      //   visitData &&
      //   visitData.drugHistory &&
      //   visitData.drugHistory?.currentInsulin
      // ) {
      //   insulinData = visitData.drugHistory.currentInsulin.map((element) => ({
      //     id: element.id,
      //     name: element.name,
      //     currentDose: Math.round(element.currentDose),
      //     recommendedDose: Math.round(element.recommendedDose),
      //   }));
      //   console.log(insulinData,"insulinDatadhwhdhfhsoai..")
      // }

      if(visitData){
      const visitDataDrugHistory=JSON.parse(visitData?.drugHistory)
      if(visitDataDrugHistory){
       insulinData = visitDataDrugHistory?.currentInsulin.map((element) => ({
          id: element.id,
          name: element.name,
          currentDose: Math.round(element.currentDose),
          recommendedDose: Math.round(element.recommendedDose),
        }));
        console.log(insulinData,"insulinDatadhwhdhfhsoai..")
      }
      }
      const payload = {
        USER_ID: UserID,
        visitData: visitData,
        // Basic_Auth: basicAuth,
        medicalHistory: getMedicalHistoryMap(visitData?.medicalHistory),
        currentDBDrugs: getCurrentDBDrugs(visitData?.drugHistory),
        currentInsulin: insulinData.length === 0 ? null : insulinData,
        
      };

      console.log(JSON.parse(visitData.drugHistory),"visitData.............");

      const medicalHistoryMap = getMedicalHistoryMap(visitData?.medicalHistory);
      const currentInsulin = insulinData.length === 0 ? null : insulinData;
      const currentDBDrugs = getCurrentDBDrugs(visitData?.drugHistory);
      const personalHistory = visitData?.personalHistory
        ? JSON.parse(visitData.personalHistory)
        : null;
      const examinationHistory = visitData?.examinationHistory
        ? JSON.parse(visitData.examinationHistory)
        : null;
      const fbs =
        examinationHistory?.fbs === undefined
          ? "null"
          : examinationHistory?.fbs;
      const ppbs =
        examinationHistory?.ppbs === undefined
          ? "null"
          : examinationHistory?.ppbs;
      const rbs =
        examinationHistory?.rbs === undefined
          ? "null"
          : examinationHistory?.rbs;
      const hba1c =
        examinationHistory?.hba1c === undefined
          ? "null"
          : examinationHistory?.hba1c;
      const gfr =
        examinationHistory?.gfr === undefined
          ? "null"
          : examinationHistory?.gfr;
      const weight =
        examinationHistory?.weight === undefined
          ? "null"
          : examinationHistory?.weight;

      const creatinine = examinationHistory?.creatinine;

      const data = {
        fbs: fbs,
        ppbs: ppbs,
        rbs: rbs,
        hba1c: hba1c,
        bloodSugarTypePpbs: 1,
        bloodSugarTypeRbs: 1,
        medicalHistory: medicalHistoryMap,
        treatmentFacility: "2",
        gender: "Male", //change
        // "serumCreatinine": examinationHistory?.creatinine?.toDouble(),
        serumCreatinine: creatinine ? parseFloat(creatinine) : null,
        onDialysis: personalHistory?.isOnDialysis === 0 ? true : false,
        gfr: gfr,
        weight: weight,
        polyuria: personalHistory?.isPolyuria === 0 ? true : false,
        polydipsia: personalHistory?.isPolydypsia === 0 ? true : false,
        weightLoss: personalHistory?.isWeightloss === 0 ? true : false,
        currentInsulin: currentInsulin,
        currentDrugs: currentDBDrugs,
      };

      const response = await Axios.post(
        `${config.abhaBaseUrl}getDiabeticAnalysis`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
            "x-caller": encrypted,
          },
        }
      );
      console.log(
        JSON.stringify(response.data),
        "respons of diabetes cdss cl",
        response.data
      );
      if (response.status === 200) {
        const jsonData = response.data;
        console.log("dbResult&&&&&&&&&&&&&&&", jsonData);
        console.log( "paylaod.....................", payload);
        console.log(jsonData,"jsonData..........");
        sessionStorage.setItem("dbResult", JSON.stringify(jsonData));

        // API CALL TO SAVE DATA IN DB

        const Response = await Axios.post(
          `${config.apiURL}${config.routes.saveCDSSAPI}`,
          {
            cdssID: "1", //diabetes
            inputData: data,
            outputData: jsonData,
            remarks: "",
            accepted: "0",
            createdBy: visitData.nurseUserId,
            visitID: visitData.visitId,
          },
          {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${token}`,
              "x-caller": encrypted,
            },
          }
        );

        if (Response.status === 201) {
          console.log("Status = ", Response.data);
        }
      }
    } catch (error) {
      console.error("Error fetching HT analysis:", error.message);
      throw error;
    }
  };

  async function calculateCVDRiskScoreCal(
    cholesterolValue,
    gender,
    age,
    cholesterol,
    sbp,
    height,
    weight,
    hasDiabetes,
    isSmoking
  ) {
    //console.log("ssdf", cholesterolValue);
    //console.log(gender);
    //console.log(age);
    //console.log(cholesterol);
    //console.log(sbp);
    //console.log(height);
    //console.log(weight);
    //console.log(hasDiabetes);
    //console.log(isSmoking);

    let bmi =
      height !== null && weight !== null
        ? height !== 0
          ? (10000 * weight) / (height * height)
          : 0
        : 0;

    let lab = cholesterolValue === 0 ? true : false;

    let miRiskScore, strokeRiskScore;

    let labStrokeRiskCoefficient = 0;
    let labMiRiskCoefficient = 0;
    let nonLabMiRiskCoefficient = 0;
    let nonLabStrokeRiskCoefficient = 0;
    let labMiRisk = 0;
    let labStrokeRisk = 0;
    let labCvdRisk = 0;
    let nonLabMiRisk = 0;
    let nonLabStrokeRisk = 0;
    let nonLabCvdRisk = 0;

    let _ageDifferenceFromBase = age - 60;
    let _cholesterolDifferenceFromBase = cholesterol * 0.02586 - 6;
    let _sbpDifferenceFromBase = sbp - 120;
    let _bmiDifferenceFromBase = bmi - 25;
    let _diabetesHistory = hasDiabetes;
    let _smokingHistory = isSmoking;

    let cvdScore = 0;

    let labMIData = [
      {
        age: 0.0719227,
        cholesterol: 0.2284944,
        sbp: 0.0132183,
        dbHistory: 0.6410114,
        smokingHistory: 0.5638109,
        cholesterolInteraction: -0.0045806,
        sbpInteraction: -0.0001576,
        dbInteraction: -0.0124966,
        smokingInteraction: -0.0182545,
        baselineSurvival: 0.954,
      },
      {
        age: 0.1020713,
        cholesterol: 0.2050377,
        sbp: 0.015823,
        dbHistory: 1.070358,
        smokingHistory: 1.053223,
        cholesterolInteraction: -0.0051932,
        sbpInteraction: -0.0001378,
        dbInteraction: -0.0234174,
        smokingInteraction: 0.0332666,
        baselineSurvival: 0.989,
      },
    ];

    let labStrokeData = [
      {
        age: 0.0986578,
        cholesterol: 0.0986578,
        sbp: 0.0222629,
        dbHistory: 0.6268712,
        smokingHistory: 0.4981217,
        cholesterolInteraction: 0.00142,
        sbpInteraction: -0.0004147,
        dbInteraction: -0.026302,
        smokingInteraction: -0.0150561,
        baselineSurvival: 0.985,
      },
      {
        age: 0.1056632,
        cholesterol: 0.0257782,
        sbp: 0.0206278,
        dbHistory: 0.8581998,
        smokingHistory: 0.7443627,
        cholesterolInteraction: -0.0021387,
        sbpInteraction: -0.0004897,
        dbInteraction: -0.0209826,
        smokingInteraction: -0.0200822,
        baselineSurvival: 0.989,
      },
    ];

    let nonLabMIData = [
      {
        age: 0.073593,
        bmi: 0.0337219,
        sbp: 0.0133937,
        smokingHistory: 0.5954767,
        bmiInteraction: -0.0010432,
        sbpInteraction: -0.0001837,
        smokingInteraction: -0.0200831,
        baselineSurvival: 0.954,
      },
      {
        age: 0.1049418,
        bmi: 0.0257616,
        sbp: 0.016726,
        smokingHistory: 1.093132,
        bmiInteraction: -0.0006537,
        sbpInteraction: -0.0001966,
        smokingInteraction: -0.0343739,
        baselineSurvival: 0.989,
      },
    ];

    let nonLabStrokeData = [
      {
        age: 0.097674,
        bmi: 0.0159518,
        sbp: 0.0227294,
        smokingHistory: 0.4999862,
        bmiInteraction: -0.0003516,
        sbpInteraction: -0.0004374,
        smokingInteraction: -0.0153895,
        baselineSurvival: 0.985,
      },
      {
        age: 0.1046105,
        bmi: 0.0036406,
        sbp: 0.0216741,
        smokingHistory: 0.7399405,
        bmiInteraction: -0.0000129,
        sbpInteraction: -0.0005311,
        smokingInteraction: -0.0203997,
        baselineSurvival: 0.989,
      },
    ];

    let genderIndex = gender === "Male" ? 0 : 1;

    labMiRiskCoefficient =
      labMIData[genderIndex].age * _ageDifferenceFromBase +
      labMIData[genderIndex].cholesterol * _cholesterolDifferenceFromBase +
      labMIData[genderIndex].sbp * _sbpDifferenceFromBase +
      labMIData[genderIndex].dbHistory * _diabetesHistory +
      labMIData[genderIndex].smokingHistory * _smokingHistory +
      labMIData[genderIndex].cholesterolInteraction *
        _cholesterolDifferenceFromBase *
        _ageDifferenceFromBase +
      labMIData[genderIndex].sbpInteraction *
        _sbpDifferenceFromBase *
        _ageDifferenceFromBase +
      labMIData[genderIndex].dbInteraction *
        _diabetesHistory *
        _ageDifferenceFromBase +
      labMIData[genderIndex].smokingInteraction *
        _smokingHistory *
        _ageDifferenceFromBase;

    labStrokeRiskCoefficient =
      labStrokeData[genderIndex].age * _ageDifferenceFromBase +
      labStrokeData[genderIndex].cholesterol * _cholesterolDifferenceFromBase +
      labStrokeData[genderIndex].sbp * _sbpDifferenceFromBase +
      labStrokeData[genderIndex].dbHistory * _diabetesHistory +
      labStrokeData[genderIndex].smokingHistory * _smokingHistory +
      labStrokeData[genderIndex].cholesterolInteraction *
        _cholesterolDifferenceFromBase *
        _ageDifferenceFromBase +
      labStrokeData[genderIndex].sbpInteraction *
        _sbpDifferenceFromBase *
        _ageDifferenceFromBase +
      labStrokeData[genderIndex].dbInteraction *
        _diabetesHistory *
        _ageDifferenceFromBase +
      labStrokeData[genderIndex].smokingInteraction *
        _smokingHistory *
        _ageDifferenceFromBase;

    nonLabMiRiskCoefficient =
      nonLabMIData[genderIndex].age * _ageDifferenceFromBase +
      nonLabMIData[genderIndex].bmi * _bmiDifferenceFromBase +
      nonLabMIData[genderIndex].sbp * _sbpDifferenceFromBase +
      nonLabMIData[genderIndex].smokingHistory * _smokingHistory +
      nonLabMIData[genderIndex].bmiInteraction *
        _bmiDifferenceFromBase *
        _ageDifferenceFromBase +
      nonLabMIData[genderIndex].sbpInteraction *
        _sbpDifferenceFromBase *
        _ageDifferenceFromBase +
      nonLabMIData[genderIndex].smokingInteraction *
        _smokingHistory *
        _ageDifferenceFromBase;

    nonLabStrokeRiskCoefficient =
      nonLabStrokeData[genderIndex].age * _ageDifferenceFromBase +
      nonLabStrokeData[genderIndex].bmi * _bmiDifferenceFromBase +
      nonLabStrokeData[genderIndex].sbp * _sbpDifferenceFromBase +
      nonLabStrokeData[genderIndex].smokingHistory * _smokingHistory +
      nonLabStrokeData[genderIndex].bmiInteraction *
        _bmiDifferenceFromBase *
        _ageDifferenceFromBase +
      nonLabStrokeData[genderIndex].sbpInteraction *
        _sbpDifferenceFromBase *
        _ageDifferenceFromBase +
      nonLabStrokeData[genderIndex].smokingInteraction *
        _smokingHistory *
        _ageDifferenceFromBase;

    if (lab) {
      labMiRisk =
        1 -
        Math.pow(
          labMIData[genderIndex].baselineSurvival,
          Math.exp(labMiRiskCoefficient)
        );
      labStrokeRisk =
        1 -
        Math.pow(
          labStrokeData[genderIndex].baselineSurvival,
          Math.exp(labStrokeRiskCoefficient)
        );
      labCvdRisk = 1 - (1 - labMiRisk) * (1 - labStrokeRisk);
    } else {
      nonLabMiRisk =
        1 -
        Math.pow(
          nonLabMIData[genderIndex].baselineSurvival,
          Math.exp(nonLabMiRiskCoefficient)
        );
      nonLabStrokeRisk =
        1 -
        Math.pow(
          nonLabStrokeData[genderIndex].baselineSurvival,
          Math.exp(nonLabStrokeRiskCoefficient)
        );
      nonLabCvdRisk = 1 - (1 - nonLabMiRisk) * (1 - nonLabStrokeRisk);
    }

    // console.log(
    //   "Printing Input values: labValues: " +
    //     cholesterolValue +
    //     ", Gender: " +
    //     gender +
    //     ", age: " +
    //     age +
    //     ", Cholesterol: " +
    //     cholesterol +
    //     ", sbp: " +
    //     sbp +
    //     ", Height: " +
    //     height +
    //     ", weight: " +
    //     weight +
    //     ", Diabetes? " +
    //     hasDiabetes +
    //     ", Smoking?: " +
    //     isSmoking
    // );

    //console.log("lab MI Risk Coefficient= " + labMiRiskCoefficient);
    //console.log("lab Stroke Risk Coefficient = " + labStrokeRiskCoefficient);
    //console.log("lab MI Risk: " + labMiRisk);
    //console.log("lab Stroke Risk: " + labStrokeRisk);
    //console.log("lab CVD RISK SCORE: " + labCvdRisk);
    //console.log("non lab MI Risk Coefficient= " + nonLabMiRiskCoefficient);
    // //console.log(
    //   "non lab Stroke Risk Coefficient = " + nonLabStrokeRiskCoefficient
    // );
    //console.log("non lab MI Risk: " + nonLabMiRisk);
    //console.log("non lab Stroke Risk: " + nonLabStrokeRisk);
    //console.log("non lab CVD RISK SCORE: " + nonLabCvdRisk);

    miRiskScore = lab ? labMiRisk * 100 : nonLabMiRisk * 100;
    strokeRiskScore = lab ? labStrokeRisk * 100 : nonLabStrokeRisk * 100;

    cvdScore = lab ? labCvdRisk * 100 : nonLabCvdRisk * 100;

    //console.log("Over all MI Risk Score: " + miRiskScore);
    //console.log("Over all STROKE Risk Score: " + strokeRiskScore);
    //console.log("Over all CVD Risk Score: " + cvdScore);

    return [cvdScore, strokeRiskScore, miRiskScore];
  }

  // const handleAnalysis = async () => {
  //   setIsLoading(true);

  //   const cvdRiskScoreList = calculateCVDRiskScoreCal(
  //     ExaminationHistoryData?.cholestrol != null ? 0 : 1,
  //     patientDetails?.GENDER ?? "Male",
  //     patientDetails?.age ?? 0,
  //     ExaminationHistoryData?.cholestrol ?? 0,

  //     ExaminationHistoryData?.sbp ?? 0,
  //     ExaminationHistoryData?.height,
  //     parseFloat(ExaminationHistoryData?.weight ?? '0').toFixed(1),

  //     MedicalHistoryData?.isDiabetes === 0 ? 1 : 0,

  //     PersonalHistoryData?.isSmoking === 0 ? 1 : 0,
  //   );
  //   console.log("cvdScore,strokeRiskScore,miRiskScore", cvdRiskScoreList)
  //   let cvdRiskScoreData = []
  //   cvdRiskScoreData = [{
  //     cvdScore: cvdRiskScoreList[0],
  //     strokeRiskScore: cvdRiskScoreList[1],
  //     miRiskScore: cvdRiskScoreList[2]
  //   }]
  //   console.log("cvdRiskScoreData", cvdRiskScoreData)
  //   sessionStorage.setItem("cvdRiskScoreList", JSON.stringify(cvdRiskScoreData))
  //   console.log("cvdRiskScoreList", cvdRiskScoreList);

  //   // if (!visitData?.examinationHistory?.dbp || !visitData?.examinationHistory?.sbp ||
  //   //   (!visitData?.examinationHistory?.fbs && !visitData?.examinationHistory?.rbs && !visitData?.examinationHistory?.ppbs)) {
  //   if ((visitData?.examinationHistory?.fbs === null || visitData?.examinationHistory?.ppbs === null || visitData?.examinationHistory?.rbs === null) &&
  //     (visitData?.examinationHistory?.dbp === null || visitData?.examinationHistory?.sbp === null)) {

  //     let alertMessage = '';

  //     // if (!visitData?.examinationHistory?.fbs &&
  //     //   !visitData?.examinationHistory?.ppbs &&
  //     //   !visitData?.examinationHistory?.rbs) {
  //     if (visitData?.examinationHistory?.fbs === null &&
  //       visitData?.examinationHistory?.ppbs === null &&
  //       visitData?.examinationHistory?.rbs === null) {
  //       alertMessage = '\nEither FBS or PPBS or RBS is required for Diabetes Analysis.Are you sure you want to continue with existing data? Press Cancel to change.';
  //       // showDialogBox(alertMessage);
  //     }
  //     // else if (!visitData?.examinationHistory?.dbp || !visitData?.examinationHistory?.sbp) {
  //     else if (visitData?.examinationHistory?.dbp === null || visitData?.examinationHistory?.sbp === null) {
  //       alertMessage = '\nSBP and DBP are required for Hypertension Analysis.\n';

  //     }
  //     await showDialogBox(alertMessage);
  //   }
  //   else {
  //     try {
  //       await getHTAnalysis();
  //     }
  //     catch (error) {
  //       // showDialogBox('Hypertension Analysis Error', 'Hypertension Analysis is not available. Please continue with the rest of the diagnosis.');
  //     }

  //     try {
  //       await getDiabeticAnalysis();
  //     } catch (error) {
  //       // showDialogBox('Diabetes Analysis Error', 'Diabetes Analysis is not available. Please continue with the rest of the diagnosis.');
  //     }
  //     navigate("/patients/diagnosis");
  //   }
  //   setIsLoading(false);

  // };

  const handleAnalysis = async () => {
    // sessionStorage.getItem("htResult");
    // sessionStorage.getItem("dbResult");
    const cvdRiskScoreList = await calculateCVDRiskScoreCal(
      ExaminationHistoryData?.cholestrol != null ? 0 : 1,
      patientDetails?.GENDER ?? "Male",
      patientDetails?.age ?? 0,
      ExaminationHistoryData?.cholestrol ?? 0,
      ExaminationHistoryData?.sbp ?? 0,
      ExaminationHistoryData?.height,
      parseFloat(ExaminationHistoryData?.weight ?? "0").toFixed(1),
      MedicalHistoryData?.isDiabetes === 0 ? 1 : 0,
      PersonalHistoryData?.isSmoking === 0 ? 1 : 0
    );

    //console.log("cvdScore,strokeRiskScore,miRiskScore", cvdRiskScoreList);

    const cvdRiskScoreData = [
      {
        cvdScore: cvdRiskScoreList[0],
        strokeRiskScore: cvdRiskScoreList[1],
        miRiskScore: cvdRiskScoreList[2],
      },
    ];

    //console.log("cvdRiskScoreData", cvdRiskScoreData);

    sessionStorage.setItem(
      "cvdRiskScoreList",
      JSON.stringify(cvdRiskScoreData)
    );
    //console.log("cvdRiskScoreList", cvdRiskScoreList);

    const requiresDiabetesAnalysis = (!ExaminationHistoryData?.ppbs || !ExaminationHistoryData?.fbs) && !ExaminationHistoryData?.rbs;

      

    //console.log("fbs:", ExaminationHistoryData?.fbs);
    //console.log("ppbs:", ExaminationHistoryData?.ppbs);
    //console.log("rbs:", ExaminationHistoryData?.rbs);
    //console.log("requiresDiabetesAnalysis:", requiresDiabetesAnalysis);

    const requiresHypertensionAnalysis =
      !ExaminationHistoryData?.dbp || !ExaminationHistoryData?.sbp;

    const nodata =
      !ExaminationHistoryData?.dbp ||
      !ExaminationHistoryData?.sbp ||
      !ExaminationHistoryData?.fbs &&
      !ExaminationHistoryData?.ppbs ||
      !ExaminationHistoryData?.rbs;
    //console.log("dbp:", ExaminationHistoryData?.dbp);
    //console.log("sbp:", ExaminationHistoryData?.sbp);

    setIsLoading(true);
    console.log(requiresDiabetesAnalysis,"requiresDiabetesAnalysis.....",ExaminationHistoryData?.rbs,ExaminationHistoryData?.fbs,
      ExaminationHistoryData?.ppbs )
    if (requiresDiabetesAnalysis || requiresHypertensionAnalysis) {
      let alertMessage = "";

      // if (nodata) {
      //   alertMessage +=
      //     "\nEither FBS or PPBS or RBS is required for Diabetes Analysis. ";
      // } else

    
       if (requiresDiabetesAnalysis) {
        alertMessage +=
          "\nEither FBS or PPBS or RBS is required for Diabetes Analysis.\n ";
      } else if (requiresHypertensionAnalysis) {
        alertMessage +=
          "\nSBP and DBP are required for Hypertension Analysis.\n";
      }

      alertMessage +=
        "\nAre you sure you want to continue with existing data? Press OK to continue or Cancel to change.";

      // const confirmed = window.confirm(alertMessage);
      showDialogBox(alertMessage);
    } else {
      try {
        await getHTAnalysis();
      } catch (error) {
        console.error("Hypertension Analysis error:", error);
        // showDialogBox('Hypertension Analysis Error', 'Hypertension Analysis is not available. Please continue with the rest of the diagnosis.');
      }

      try {
        await getDiabeticAnalysis();
      } catch (error) {
        console.error("Diabetes Analysis error:", error);
        // showDialogBox('Diabetes Analysis Error', 'Diabetes Analysis is not available. Please continue with the rest of the diagnosis.');
      }

      navigate("/patients/diagnosis");
    }

    setIsLoading(false);
  };

  const handleContinue = async () => {
    setIsLoading(true);

    try {
      await getHTAnalysis();
    } catch (error) {
      console.error("Hypertension Analysis error:", error);
    }

    try {
      await getDiabeticAnalysis();
    } catch (error) {
      console.error("Diabetes Analysis error:", error);
    }
    setIsLoading(false);
    navigate("/patients/diagnosis");
  };

  //console.log("visitdata from api call", visitData);

  return (
    <>
      {/* <Navbar /> */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          // height: "86vh",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            // height: "70vh",
            overflow: "scroll",
            gap: "1rem",
            width: "100%",
          }}
        >
          {isLoading ? (
            <div className="spinningLoader">
              <CircularProgress size={48} color="inherit" />
            </div>
          ) : (
            <TabsContainer />
          )}
          {popupMessage && (
            <div className="popup">
              <div className="popup-inner">
                <p
                  style={{
                    fontSize: "24px",
                    fontWeight: "600",
                    fontFamily: "bold",
                  }}
                >
                  Missing Data for Analysis
                </p>
                <p>{popupMessage}</p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "2rem",
                    padding: "4%",
                  }}
                >
                  <button
                    className="ContinueBtn"
                    style={{
                      Background: "#52447d !important",
                    }}
                    onClick={handleContinue}
                  >
                    Continue
                    {isLoading && (
                      <CircularProgress size={24} color="inherit" />
                    )}
                  </button>

                  <button
                    className="ContinueBtn"
                    onClick={closePopup}
                    style={{
                      Background: "#52447d !important",
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Prescription;
