import React from "react";

// Data structure for the articles
const articles = [
  {
    header: ["Review Article", "Open Access", "Published 06 February 2020"],
    url: "https://www.nature.com/articles/s41746-020-0221-y",
    text: "An overview of clinical decision support systems: benefits, risks, and strategies for success",
    authors: [
      {
        url: "https://pubmed.ncbi.nlm.nih.gov/?cmd=search&term=Reed+T.+Sutton",
        text: "Reed T. Sutton, David Pincock, Daniel C.Baugmart, Daniel C. Sadwski, Richard N. Fedorak & Karen I. Kroeker"
      }
    ]
  },
  {
    header: ["Review Article", "Open Access", "Published 21 December 2016"],
    url: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5210443/",
    text: "Development of a Smartphone-Enabled Hypertension and Diabetes Mellitus Management Package to Facilitate Evidence-Based Care Delivery in Primary Helathcare Facilities in India:The mPower Heart Project",
    authors: [
      { url: "https://pubmed.ncbi.nlm.nih.gov/?term=Ajay%20VS%5BAuthor%5D&cauthor=true&cauthor_uid=28003248", text: "Vamadevan S.Ajay,MPH,DLSHTM,phD" },
      { url: "https://pubmed.ncbi.nlm.nih.gov/?term=Jindal%20D%5BAuthor%5D&cauthor=true&cauthor_uid=28003248", text: "Devraj Jindal,BS,MPH" },
      { url: "https://pubmed.ncbi.nlm.nih.gov/?term=Roy%20A%5BAuthor%5D&cauthor=true&cauthor_uid=28003248", text: "Ambuj Roy MD,DM" },
      { url: "https://pubmed.ncbi.nlm.nih.gov/?term=Venugopal%20V%5BAuthor%5D&cauthor=true&cauthor_uid=28003248", text: "Vidya Venugopal,MS,phD" },
      { url: "https://pubmed.ncbi.nlm.nih.gov/?term=Sharma%20R%5BAuthor%5D&cauthor=true&cauthor_uid=28003248", text: "Rakshit Sharma,MBA" },
      { url: "https://pubmed.ncbi.nlm.nih.gov/?term=Pawar%20A%5BAuthor%5D&cauthor=true&cauthor_uid=28003248", text: "Abha Pawar,BDS,MPH" },
      { url: "https://pubmed.ncbi.nlm.nih.gov/?term=Kinra%20S%5BAuthor%5D&cauthor=true&cauthor_uid=28003248", text: "Sanjay Kinra,MD,MRCP,MSc,PhD" },
      { url: "https://pubmed.ncbi.nlm.nih.gov/?term=Tandon%20N%5BAuthor%5D&cauthor=true&cauthor_uid=28003248", text: "Nikhil Tandon,MD,PhD" },
      { url: "https://pubmed.ncbi.nlm.nih.gov/?term=Prabhakaran%20D%5BAuthor%5D&cauthor=true&cauthor_uid=28003248", text: "Dorairaj Prabhakaran,MD,DM,MSc" }
    ]
  },
  {
    header: ["Review Article", "Open Access", "Published 21 December 2016"],
    url: "https://pubmed.ncbi.nlm.nih.gov/31324371/",
    text: "Strategies for Stakeholder Engagement and Uptake of New Intervention: Experience From State-Wide Implementation of mHealth Technology for NCD Care in Tripura, India",
    authors: [
      { url: "https://pubmed.ncbi.nlm.nih.gov/?term=Jindal+D&cauthor_id=31324371", text: "Devraj Jindal\u00B9" },
      { url: "https://pubmed.ncbi.nlm.nih.gov/?term=Roy+A&cauthor_id=31324371", text: "Ambuj Roy\u00B2" },
      { url: "https://pubmed.ncbi.nlm.nih.gov/?term=Ajay+VS&cauthor_id=31324371", text: "Vamadevan S Ajay\u00B9" },
      { url: "https://pubmed.ncbi.nlm.nih.gov/?term=Yadav+SK&cauthor_id=31324371", text: "Shailesh Kumar Yadav\u00B3" },
      { url: "https://pubmed.ncbi.nlm.nih.gov/?term=Prabhakaran+D&cauthor_id=31324371", text: "Dorairaj Prabhakaran\u2074" },
      { url: "https://pubmed.ncbi.nlm.nih.gov/?term=Tandon+N&cauthor_id=31324371", text: "Nikhil Tandon\u2075" }
    ]
  }
];

const DigitalHealthArticle = ({ article }) => (
  <div className="digitalhealthcontainer">
    <div className="digitalhealthheader">
      {article.header.map((item, index) => (
        <div key={index}>{item}</div>
      ))}
    </div>
    <div className="digitalhealthfirstlink"
      style={{
        // marginTop: "20px",
        // paddingLeft: "2rem",
        // marginLeft: "1.8rem",
        // fontSize:'15px'
      }}
    >
      <a href={article.url}>{article.text}</a>
    </div>
    <div className="digitalhealthlinks">
      {article.authors.map((author, index) => (
        <a
          key={index}
          style={{ color: "#599BFB" }}
          href={author.url}
        >
          {author.text}
        </a>
      ))}
    </div>
  </div>
);

const DigitalHealth = () => (
  <div className="Articles-digitalHealth">
    {articles.map((article, index) => (
      <DigitalHealthArticle key={index} article={article} />
    ))}
  </div>
);

export default DigitalHealth;
