import React, { useState, useEffect } from "react";
import "./Navbar.css";
import logo from "../../images/logo.png";
import patient from "../../assets/icons/patient.svg";
import calender from "../../assets/icons/calendar-mark.svg";
import bellIcon from "../../assets/icons/bell-icon.svg";
import helpCircle from "../../assets/icons/help-circle.svg";
import aboutFilled from "../../assets/icons/about-filled.svg";
import time from "../../assets/icons/time1.svg";
import calenderIcon from "../../assets/icons/calendar-icon.svg";
import calculatorMinimalistic from "../../assets/icons/calculator-minimalistic2.svg";
import articleSearchIcon from "../../assets/icons/article-search-line.svg";
import logoutIcon from "../../assets/icons/log-out-icon.svg";
import clinallyLogo from "../../assets/Clinally-another.png";
import profileIcon from "../../assets/icons/profile3.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Tooltip from "react-tooltip-lite";
import RightArrow from "../../images/right-arrow-image.svg";
// import LeftArrow from "../../images/left-arrow-next-svgrepo-com.svg";
import leftArrow from "../../images/leftArrow.svg";

const Navbar = ({ navbarMapulationvalue, selectedRow }) => {
  const [navbarMapulation, setNavbarMapulation] = useState(false);
  const [activeSection, setActiveSection] = useState("");
  const [actionselectedRow, setactionselectedRow] = useState(selectedRow);
  const navigate = useNavigate();
  const location = useLocation();
  const toggleSection = (section, path) => {
    //console.log(".............Clicked section:", section);
    setActiveSection(section);
    navigate(path);
  };
  const toggleSectionlogout = () => {
    sessionStorage.clear();
    navigate("/");
  };
  const path = window.location.pathname; // "/patients/testvisits/3837"
  const firstSegment = path.split('/')[1];
  useEffect(() => {
    // Extract the section name from the pathname
    const action = sessionStorage.getItem("action");
    const sectionName =
      location.pathname.slice(1).charAt(0).toUpperCase() +
      location.pathname.slice(2);
    setActiveSection(sectionName || "Patients"); // Default to "Patients" if no section is found
  }, [location.pathname]);

  const toggleSidebarMode = () => {
    // sessionStorage.setItem("navbarMapulation",!navbarMapulation)
    navbarMapulationvalue(navbarMapulation);
    setNavbarMapulation((prevState) => !prevState);
  };
  // const patient = sessionStorage.getItem("selectedPatientHistory");
  // console.log(patient,"...........//.//////")
  const userPhoto = sessionStorage.getItem("UserPhoto");
  // console.log("userPhot", userPhoto);
  return (
    <>
      <div
        className="side-navHeader"
        style={{ width: navbarMapulation ? "5%" : "15%" }}
      >
        <div className="side-navItems">
          <div className="logoContainer">
            <button onClick={toggleSidebarMode} className="logoToggle">
            <img
                src={navbarMapulation ? leftArrow:RightArrow}
                className={navbarMapulation ? "leftArrow" : "RightArrow"}
                alt="logo"
              />
              <img
                src={navbarMapulation ? clinallyLogo : logo}
                className={navbarMapulation ? "logo-small" : "logo-large"}
                alt="logo"
              />
            </button>
            <div className="sideNavTopMenu">
            {/*Raviteja changes*/}
              <div
                className={`buttonContainer ${
                  activeSection === "Patients" ||firstSegment==='patients' ? "active" : ""
                } ${
                  navbarMapulation ? "small-navbar-icon" : ""
                }`}
                onClick={() => toggleSection("Patients", "/patients")}
              >
                <div className="navItemIconContainer">
                  <img className="navItemIcon" src={patient} alt="Patients" />
                  {!navbarMapulation && (
                    <span
                      className={`toggle-button ${
                        activeSection === "Patients" ||firstSegment==='patients'? "active" : ""
                      }`}
                    >
                      Patients
                    </span>
                  )}
                </div>
                <div
                  className={`${
                    activeSection === "Patients"||firstSegment==='patients' ? "underline" : ""
                  }`}
                ></div>
              </div>

              <div
                className={`buttonContainer ${
                  activeSection === "Appointments" ? "active" : ""
                } ${
                  navbarMapulation ? "small-navbar-icon" : ""
                }`}
                onClick={() => toggleSection("Appointments", "/appointments")}
              >
                <div className="navItemIconContainer">
                  <img
                    className="navItemIcon"
                    src={calender}
                    alt="Appointments"
                  />
                  {!navbarMapulation && (
                    <span
                      className={`toggle-button ${
                        activeSection === "Appointments" ? "active" : ""
                      }`}
                    >
                      Appointments
                    </span>
                  )}
                </div>
                <div
                  className={`${
                    activeSection === "Appointments" ? "underline" : ""
                  }`}
                ></div>
              </div>
       
                <div
                  className={`buttonContainer ${
                    activeSection === "Followups" ? "active" : ""
                  } ${
                    navbarMapulation ? "small-navbar-icon" : ""
                  }`}
                  onClick={() => toggleSection("Followups", "/followups")}
                >
                  <div className="navItemIconContainer">
                    <img className="navItemIcon" src={time} alt="Followups" />
                    {!navbarMapulation && (
                      <span
                        className={`toggle-button ${
                          activeSection === "Followups" ? "active" : ""
                        }`}
                      >
                        Follow Ups
                      </span>
                    )}
                  </div>
                  <div
                    className={`${
                      activeSection === "Followups" ? "underline" : ""
                    }`}
                  ></div>
                </div>
          

              <div
                className={`buttonContainer ${
                  activeSection === "Calculator" ? "active" : ""
                } ${
                  navbarMapulation ? "small-navbar-icon" : ""
                }`}
                onClick={() => toggleSection("Calculator", "/calculator")}
              >
                <div className="navItemIconContainer">
                  <img
                    className="navItemIcon"
                    src={calculatorMinimalistic}
                    alt="Calculator"
                  />
                  {!navbarMapulation && (
                    <span
                      className={`toggle-button ${
                        activeSection === "Calculator" ? "active" : ""
                      }`}
                    >
                      Calculator
                    </span>
                  )}
                </div>
                <div
                  className={`${
                    activeSection === "Calculator" ? "underline" : ""
                  }`}
                ></div>
              </div>

              <div
                className={`buttonContainer ${
                  activeSection === "Article" ? "active" : ""
                } ${
                  navbarMapulation ? "small-navbar-icon" : ""
                }`}
                onClick={() => toggleSection("Article", "/Article")}
              >
                <div className="navItemIconContainer">
                  <img
                    className="navItemIcon"
                    src={articleSearchIcon}
                    alt="Articles"
                  />
                  {!navbarMapulation && (
                    <span
                      className={`toggle-button ${
                        activeSection === "Article" ? "active" : ""
                      }`}
                    >
                      Articles
                    </span>
                  )}
                </div>
                <div
                  className={`${
                    activeSection === "Article" ? "underline" : ""
                  }`}
                ></div>
              </div>

              {/* <div
                className={`buttonContainer ${
                  activeSection === "Notifications" ? "active" : ""
                }`}
                onClick={() => toggleSection("Notifications", "/notifications")}
              >
                <div className="navItemIconContainer">
                  <img
                    className="navItemIcon"
                    src={bellIcon}
                    alt="Notifications"
                  />
                  {!navbarMapulation && (
                    <span
                      className={`toggle-button ${
                        activeSection === "Notifications" ? "active" : ""
                      }`}
                    >
                      Notifications
                    </span>
                  )}
                </div>
                <div
                  className={`${
                    activeSection === "Notifications" ? "underline" : ""
                  }`}
                ></div>
              </div> */}

              <div
                className={`buttonContainer ${
                  activeSection === "Help" ? "active" : ""
                } ${
                  navbarMapulation ? "small-navbar-icon" : ""
                }`}
                onClick={() => toggleSection("Help", "/help")}
              >
                <div className="navItemIconContainer">
                  <img className="navItemIcon" src={helpCircle} alt="Help" />
                  {!navbarMapulation && (
                    <span
                      className={`toggle-button ${
                        activeSection === "Help" ? "active" : ""
                      }`}
                    >
                      Help
                    </span>
                  )}
                </div>
                <div
                  className={`${activeSection === "Help" ? "underline" : ""}`}
                ></div>
              </div>

              <div
                className={`buttonContainer ${
                  activeSection === "About Us"||firstSegment==='aboutus' ? "active" : ""
                } ${
                  navbarMapulation ? "small-navbar-icon" : ""
                }`}
                onClick={() => toggleSection("About Us", "/aboutus")}
              >
                <div className="navItemIconContainer">
                  <img
                    className="navItemIcon"
                    src={aboutFilled}
                    alt="About Us"
                  />
                  {!navbarMapulation && (
                    <span
                      className={`toggle-button ${
                        activeSection === "About Us" ||firstSegment==='aboutus'? "active" : ""
                      }`}
                    >
                      About Us
                    </span>
                  )}
                </div>
                <div
                  className={`${
                    activeSection === "About Us" ||firstSegment==='aboutus'? "underline" : ""
                  }`}
                ></div>
              </div>
              
            </div>
          </div>

          <div className="sideNavMenu">
            <div className="sideNavBottomMenu">
              <div
                className={`buttonContainer ${
                  activeSection === "Profile" ? "active" : ""
                } ${
                  navbarMapulation ? "small-navbar-icon" : ""
                }`}
                onClick={() => toggleSection("Profile", "/profile")}
              >
                <div className="navItemIconContainer">
                  <img
                    src={profileIcon}
                    className="navItemIcon"
                    alt="Profile"
                  />
                  {!navbarMapulation && (
                    <span
                      className={`toggle-button ${
                        activeSection === "Profile" ? "active" : ""
                      }`}
                    >
                      Profile
                    </span>
                  )}
                </div>
                <div
                  className={`${
                    activeSection === "Profile" ? "underline" : ""
                  }`}
                ></div>
              </div>

              <div
                className={`buttonContainer logout-menu ${
                  activeSection === "Logout" ? "active" : ""
                } ${
                  navbarMapulation ? "small-navbar-icon" : ""
                }`}
                onClick={() => toggleSectionlogout("Logout", "/logout")}
              >
                <div className="navItemIconContainer">
                  <img className="navItemIcon" src={logoutIcon} alt="Logout" />
                  {!navbarMapulation && (
                    <span
                      style={{ color: "rgba(220, 68, 74, 1)" }}
                      className={`toggle-button ${
                        activeSection === "Logout" ? "active" : ""
                      }`}
                    >
                      Logout
                    </span>
                  )}
                </div>
                <div
                  className={`${activeSection === "Logout" ? "underline" : ""}`}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// const Navbar = ({ disableFields }) => {
//   const location = useLocation();
//   const [activeSection, setActiveSection] = useState("Patients");
//   const [navbarMapulation, setNavbarMapulation] = useState();

//   useEffect(() => {
//     // Extract the section name from the pathname
//     const sectionName =
//       location.pathname.slice(1).charAt(0).toUpperCase() +
//       location.pathname.slice(2);
//     setActiveSection(sectionName || "Patients"); // Default to "Patients" if no section is found
//   }, [location.pathname]);

//   const userDetails = sessionStorage.getItem("UserData")
//     ? JSON.parse(sessionStorage.getItem("UserData"))[0]
//     : "";

//   const toggleSection = (sectionName) => {
//     setActiveSection(sectionName);
//   };

//   // const userPhoto = sessionStorage.getItem("UserPhoto")
//   // console.log("userPhot", userPhoto)

//   return (
//     <>
//       <div className="side-navHeader">
//         <div className="side-navItems">
//           <div className="logoContainer">
//             <img src={logo} className="logo" alt="" />
//           </div>
//           <div className="sideNavMenu">
//             <div className="sideNavTopMenu">
//               <div
//                 className={`buttonContainer ${
//                   activeSection === "Patients" ? "active" : ""
//                 }`}
//               >
//                 <Link to="/patients" onClick={() => toggleSection("Patients")}>
//                   <img className="navItemIcon" src={patient} alt="patient" />
//                   {disableFields ? (
//                     <Tooltip
//                       content="Please complete your profile"
//                       direction="down"
//                       arrow={false}
//                       background="white"
//                       color="gray"
//                     >
//                       <button
//                         className={`toggle-button ${
//                           activeSection === "Patients" ? "active" : ""
//                         }`}
//                         disabled={disableFields}
//                       >
//                         Patients
//                       </button>
//                     </Tooltip>
//                   ) : (
//                     <button
//                       className={`toggle-button ${
//                         activeSection === "Patients" ? "active" : ""
//                       }`}
//                       // disabled={disableFields}
//                     >
//                       Patients
//                     </button>
//                   )}
//                 </Link>
//                 <div
//                   className={`${
//                     activeSection !== "Patients" ? "active" : "underline"
//                   }`}
//                 ></div>
//               </div>

//               <div
//                 className={`buttonContainer ${
//                   activeSection === "Appointments" ? "active" : "inactive"
//                 }`}
//               >
//                 <Link
//                   className={`navItemLink`}
//                   to="/appointments"
//                   onClick={() => toggleSection("Appointments")}
//                 >
//                   <img className="navItemIcon" src={calender} alt="" />
//                   {disableFields ? (
//                     <Tooltip
//                       content="Please complete your profile"
//                       direction="down"
//                       arrow={false}
//                       background="white"
//                       color="gray"
//                     >
//                       <button
//                         className={`toggle-button ${
//                           activeSection === "Appointments" ? "active" : ""
//                         }`}
//                         disabled={disableFields}
//                       >
//                         Appointments
//                       </button>
//                     </Tooltip>
//                   ) : (
//                     <button
//                       className={`toggle-button ${
//                         activeSection === "Appointments" ? "active" : ""
//                       }`}
//                     >
//                       Appointments
//                     </button>
//                   )}
//                 </Link>
//                 <div
//                   className={`${
//                     activeSection !== "Appointments" ? "active" : "underline"
//                   }`}
//                 ></div>
//               </div>
//               {/* <div className="buttonContainer">

//             {disableFields ? (
//               <>
//                 <Tooltip
//                   content="Please complete your profile"
//                   direction="down"
//                   arrow={false}
//                   background="white"
//                   color="gray"
//                 >
//                   <button
//                     className={`toggle-button ${
//                       activeSection === "Follow Ups" ? "active" : ""
//                     }`}
//                     onClick={() => toggleSection("Follow Ups")}
//                     disabled={disableFields}
//                   >
//                     Follow Ups
//                   </button>
//                 </Tooltip>
//               </>
//             ) : (
//               <button
//                 className={`toggle-button ${
//                   activeSection === "Follow Ups" ? "active" : ""
//                 }`}
//                 onClick={() => toggleSection("Follow Ups")}
//               >
//                 Follow Ups
//               </button>
//             )}

//             <div
//               className={`${
//                 activeSection === "Follow Ups" ? "active" : "underline"
//               }`}
//             ></div>
//           </div> */}
//               <div
//                 className={`buttonContainer ${
//                   activeSection === "Followups" ? "active" : ""
//                 }`}
//               >
//                 <Link
//                   to="/followups"
//                   onClick={() => toggleSection("Followups")}
//                 >
//                   <img className="navItemIcon" src={time} alt="" />
//                   {disableFields ? (
//                     <Tooltip
//                       content="Please complete your profile"
//                       direction="down"
//                       arrow={false}
//                       background="white"
//                       color="gray"
//                     >
//                       <button
//                         className={`toggle-button ${
//                           activeSection === "Followups" ? "active" : ""
//                         }`}
//                         // onClick={() => toggleSection("Follow Ups")}
//                         disabled={disableFields}
//                       >
//                         Follow Ups
//                       </button>
//                     </Tooltip>
//                   ) : (
//                     <button
//                       className={`toggle-button ${
//                         activeSection === "Followups" ? "active" : ""
//                       }`}
//                       // onClick={() => toggleSection("Follow Ups")}
//                     >
//                       Follow Ups
//                     </button>
//                   )}
//                 </Link>
//                 <div
//                   className={`${
//                     activeSection !== "Followups" ? "active" : "underline"
//                   }`}
//                 ></div>
//               </div>

//               <div
//                 className={`buttonContainer ${
//                   activeSection === "Calculator" ? "active" : ""
//                 }`}
//               >
//                 {disableFields ? (
//                   <Tooltip
//                     content="Please complete your profile"
//                     direction="down"
//                     arrow={false}
//                     background="white"
//                     color="gray"
//                   >
//                     <button
//                       className={`toggle-button ${
//                         activeSection === "Calculator" ? "active" : ""
//                       }`}
//                       onClick={() => toggleSection("Calculator")}
//                       disabled={disableFields}
//                     >
//                       Calculator
//                     </button>
//                   </Tooltip>
//                 ) : (
//                   <>
//                     <Link
//                       to="/calculator"
//                       onClick={() => toggleSection("Calculator")}
//                     >
//                       <img className="navItemIcon" src={calenderIcon} alt="" />
//                       <button
//                         className={`toggle-button ${
//                           activeSection === "Calculator" ? "active" : ""
//                         }`}
//                         onClick={() => toggleSection("Calculator")}
//                       >
//                         Calculator
//                       </button>
//                     </Link>
//                   </>
//                 )}

//                 <div
//                   className={`${
//                     activeSection !== "Calculator" ? "active" : "underline"
//                   }`}
//                 ></div>
//               </div>
//               <div
//                 className={`buttonContainer ${
//                   activeSection === "Articles" ? "active" : ""
//                 }`}
//               >
//                 {disableFields ? (
//                   <Tooltip
//                     content="Please complete your profile"
//                     direction="down"
//                     arrow={false}
//                     background="white"
//                     color="gray"
//                   >
//                     <button
//                       className={`toggle-button ${
//                         activeSection === "Article" ? "active" : ""
//                       }`}
//                       onClick={() => toggleSection("Article")}
//                       disabled={disableFields}
//                     >
//                       Articles
//                     </button>
//                   </Tooltip>
//                 ) : (
//                   <>
//                     <Link
//                       to="/Article"
//                       onClick={() => toggleSection("Article")}
//                     >
//                       <img
//                         className="navItemIcon"
//                         src={articleSearchIcon}
//                         alt=""
//                       />
//                       <button
//                         className={`toggle-button ${
//                           activeSection === "Article" ? "active" : ""
//                         }`}
//                         onClick={() => toggleSection("Article")}
//                       >
//                         Articles
//                       </button>
//                     </Link>
//                     <div
//                       className={`${
//                         activeSection !== "Article" ? "active" : "underline"
//                       }`}
//                     ></div>
//                   </>
//                 )}
//               </div>
//               <div
//                 className={`buttonContainer ${
//                   activeSection === "Followups" ? "active" : ""
//                 }`}
//               >
//                 <Link
//                   to="/followups"
//                   onClick={() => toggleSection("Followups")}
//                 >
//                   <img className="navItemIcon" src={bellIcon} alt="" />
//                   {disableFields ? (
//                     <Tooltip
//                       content="Please complete your profile"
//                       direction="down"
//                       arrow={false}
//                       background="white"
//                       color="gray"
//                     >
//                       <button
//                         className={`toggle-button ${
//                           activeSection === "Notifications" ? "active" : ""
//                         }`}
//                         // onClick={() => toggleSection("Follow Ups")}
//                         disabled={disableFields}
//                       >
//                         Notifications
//                       </button>
//                     </Tooltip>
//                   ) : (
//                     <button
//                       className={`toggle-button ${
//                         activeSection === "Notifications" ? "active" : ""
//                       }`}
//                       // onClick={() => toggleSection("Follow Ups")}
//                     >
//                       Notifications
//                     </button>
//                   )}
//                 </Link>
//                 <div
//                   className={`${
//                     activeSection !== "Followups" ? "active" : "underline"
//                   }`}
//                 ></div>
//               </div>
//               <div
//                 className={`buttonContainer ${
//                   activeSection === "Followups" ? "active" : ""
//                 }`}
//               >
//                 <Link
//                   to="/followups"
//                   onClick={() => toggleSection("Followups")}
//                 >
//                   <img className="navItemIcon" src={helpCircle} alt="" />
//                   {disableFields ? (
//                     <Tooltip
//                       content="Please complete your profile"
//                       direction="down"
//                       arrow={false}
//                       background="white"
//                       color="gray"
//                     >
//                       <button
//                         className={`toggle-button ${
//                           activeSection === "Followups" ? "active" : ""
//                         }`}
//                         // onClick={() => toggleSection("Follow Ups")}
//                         disabled={disableFields}
//                       >
//                         Help
//                       </button>
//                     </Tooltip>
//                   ) : (
//                     <button
//                       className={`toggle-button ${
//                         activeSection === "Followups" ? "active" : ""
//                       }`}
//                       // onClick={() => toggleSection("Follow Ups")}
//                     >
//                       Help
//                     </button>
//                   )}
//                 </Link>
//                 <div
//                   className={`${
//                     activeSection !== "Followups" ? "active" : "underline"
//                   }`}
//                 ></div>
//               </div>
//               <div
//                 className={`buttonContainer ${
//                   activeSection === "Followups" ? "active" : ""
//                 }`}
//               >
//                 <Link
//                   to="/followups"
//                   onClick={() => toggleSection("Followups")}
//                 >
//                   <img className="navItemIcon" src={aboutFilled} alt="" />
//                   {disableFields ? (
//                     <Tooltip
//                       content="Please complete your profile"
//                       direction="down"
//                       arrow={false}
//                       background="white"
//                       color="gray"
//                     >
//                       <button
//                         className={`toggle-button ${
//                           activeSection === "Followups" ? "active" : ""
//                         }`}
//                         // onClick={() => toggleSection("Follow Ups")}
//                         disabled={disableFields}
//                       >
//                         About Us
//                       </button>
//                     </Tooltip>
//                   ) : (
//                     <button
//                       className={`toggle-button ${
//                         activeSection === "Followups" ? "active" : ""
//                       }`}
//                       // onClick={() => toggleSection("Follow Ups")}
//                     >
//                       About Us
//                     </button>
//                   )}
//                 </Link>
//                 <div
//                   className={`${
//                     activeSection !== "Followups" ? "active" : "underline"
//                   }`}
//                 ></div>
//               </div>
//             </div>
//             <div className="sideNavBottomMenu">
//               <div className="buttonContainer">
//                 <div
//                   style={{
//                     display: "flex",
//                     flexDirection: "column",
//                     alignItems: "center",
//                   }}
//                   className={`toggle-button ${
//                     activeSection === "Profile" ? "active" : ""
//                   }`}
//                 >
//                   <Link to="/profile" onClick={() => toggleSection("Profile")}>
//                     <button
//                       className="toggle-button toggle-button2"
//                       // className={`toggle-button ${
//                       //   activeSection === "Profile" ? "active" : ""
//                       // }`}
//                     >
//                       <img src={profileIcon} className="profileicon" alt="" />
//                       Profile
//                     </button>
//                   </Link>
//                   <div
//                     className={`${
//                       activeSection !== "Profile" ? "active" : "underline"
//                     }`}
//                   ></div>
//                 </div>
//               </div>
//               <div
//                 className={`buttonContainer logout-menu ${
//                   activeSection === "Logout" ? "active" : ""
//                 }`}
//               >
//                 <Link to="/logout" onClick={() => toggleSection("Logout")}>
//                   <img className="navItemIcon" src={logoutIcon} alt="" />

//                   <button
//                     className={`toggle-button ${
//                       activeSection === "Logout" ? "active" : ""
//                     }`}
//                     // disabled={disableFields}
//                   >
//                     Logout
//                   </button>
//                 </Link>
//                 <div
//                   className={`${
//                     activeSection !== "Logout" ? "active" : "underline"
//                   }`}
//                 ></div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

export default Navbar;
