import React, { useEffect, useState } from "react";
// import TestProfile from "../images/testVisitsProfile.svg";
import AddDocument from "../images/addDocument.png";
import Graphs from "../images/graph.svg";
import ConsolidatedReport from "../images/consolidatedReport.svg";
// import Emergency from "../images/emergency.svg";
import InitialAssesments from "../images/initialAssesment.svg";
import ReviewAssessments from "../images/reviewsnassessments.svg";
import Arrow from "../images/arrow.svg";
import "./Patients.css";
// import { MDBIcon } from "mdbreact";
// import icon from "../images/icon.png";
// import threeDots from "../images/threeDots.png";
// import TestVisits from "../components/modules/TestVisits";
import TestVisit from "../../src/pages/TestVisit";
// import backgroundCircle from "../images/backgroundCircle.png";
// import search from "../images/search.svg";
import searchAlt from "../images/search-alt.png";
// import Navbar from "../components/modules/Navbar";
// import leftArrow from "../images/leftArrow.svg";
import axios from "axios";
// import { Link } from "react-router-dom";
import { publicEncrypt, constants } from "crypto-browserify";
import { Buffer } from "buffer";
// import { DateTime } from "luxon";
import { useNavigate } from "react-router-dom";
import Config from "../Config/config";
import Axios from "axios";
import Lottie from "lottie-react";
// import profileLottie from "../images/mph_registration.json";
// import InitialAssessmentLottie from "../images/mph_ia_icon.json";
import ReviewLottie from "../images/mph_summary.json";
import PrescriptionLottie from "../images/mph_files_1.json";
import { useLocation } from "react-router-dom";
import { FaAngleUp, FaAngleDown } from "react-icons/fa";
// import TestVisit from "./TestVisit";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  CircularProgress,
} from "@mui/material";
// import {
//   MDBTable,
//   MDBTableBody,
//   MDBTableHead,
//   MDBTableRow,
//   MDBTableCell,
// } from "mdb-react-ui-kit";

import TableComponent from "../components/Tablecomponent";
// import TopNavBar from "../components/modules/TopNavBar";
// import { color } from "echarts";

const Patients = ({ setSelectedRowsendData }) => {
  const [patients, setPatients] = useState([]);
  const [visitHistory, setVisitHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  var [selectedPatientHistory, setSelectedPatientHistory] = useState();
  const navigate = useNavigate();

  const PATIENT_ID = (() => {
    try {
      const item = sessionStorage.getItem("PATIENT_ID");
      return item ? item : [];
    } catch {
      return [];
    }
  })();
  // console.log("patient_id-----", PATIENT_ID);

  const [showAlert, setshowAlert] = useState(false);
  const [variables, setVariables] = useState({
    aimHealthyUsers: [],
    aimSearch: "",
    searchQuery: "",
    selectedPatient: null,
    showPopup: false,
  });

  const [siglePatient, setSiglePatient] = useState(true);
  const [profileArrow, setprofileArrow] = useState(true);
  const [profileAbhaArrow, setprofileAbhaArrow] = useState(true);

  const [openDialog, setOpenDialog] = useState({
    checkIn: false,
    schedule: false,
    closedRecord: false,
  });

  const [linkedPatientName, setLinkedPatientName] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);

  const UserID = sessionStorage.getItem("RegUserId")
    ? JSON.parse(sessionStorage.getItem("RegUserId"))
    : sessionStorage.getItem("UserID");

  //console.log("id", UserID);

  const publicKey = sessionStorage.getItem("RegpublicKey")
    ? sessionStorage.getItem("RegpublicKey")
    : JSON.parse(sessionStorage.getItem("publicKey"));
  const token = sessionStorage.getItem("Regtoken")
    ? sessionStorage.getItem("Regtoken")
    : JSON.parse(sessionStorage.getItem("token"));

  const padding = constants.RSA_PKCS1_PADDING;
  const data = Buffer.from(UserID.toString(), "utf-8");
  const encrypted = publicEncrypt(
    { key: publicKey, padding: padding },
    data
  ).toString("base64");

  const futureDateAppointment = localStorage.getItem("visit_date"); //selected date

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const action = queryParams.get("action");

  //patient details
  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      // url: `https://mpowerqa.clinally.com/patient/allpatients?userid=${UserID}`,
      url: `${Config.apiURL}${Config.routes.getPatientsAPI}?userid=${UserID}`,
      headers: {
        authorization: "Basic bXBvd2VydXNlcjpDbCFuQTExeTEyMyFAIw==",
        "content-type": "application/json",
      },
    };

    Axios.request(config)
      .then((response) => {
        const sortedData = response.data.data.sort((a, b) => {
          return new Date(b.CREATED_DATE) - new Date(a.CREATED_DATE);
        });
        setPatients(sortedData);

        if (sortedData.length > 0) {
          setVariables({
            ...variables,
            selectedPatient: response.data.data[0],
          });

          setIsLoading(false);
          sessionStorage.setItem(
            "MOBILE",
            JSON.stringify(response.data.data[0].MOBILE)
          );
          sessionStorage.setItem(
            "SelectedPatient",
            JSON.stringify(response.data.data[0])
          );
          sessionStorage.setItem(
            "PATIENT_ID",
            JSON.stringify(response.data.data[0].USER_ID)
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const currentPath = window.location.pathname;
  //console.log(currentPath, "currentPath....................");
  useEffect(() => {
    const fetchData = async () => {
      const UserID = JSON.parse(sessionStorage.getItem("UserID"));
      const PATIENT_ID = (() => {
        try {
          const item = sessionStorage.getItem("PATIENT_ID");
          return item ? item : [];
        } catch {
          return [];
        }
      })();
      let data = JSON.stringify({
        userId: UserID,
        id: PATIENT_ID,
      });

      //console.log("data in visits", data);

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        // url: "https://mpowerqa.clinally.com/patient/patientvisits",
        url: `${Config.apiURL}${Config.routes.getPatientVisitsAPI}`,
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "x-caller": encrypted,
        },
        data: data,
      };
      //console.log(config, "config data ...........");
      axios
        .request(config)
        .then((response) => {
          // if (response.data.data[0].status === 2) {
          setVisitHistory(response.data.data);
          // setIsLoading(false);
          //console.log("response----------------", response.data.data);
          // }

          sessionStorage.setItem(
            "selectedPatientHistory",
            JSON.stringify(response.data.data[0])
          );
        })
        .catch((error) => {
          console.log(error);
        });
    };

    fetchData();
  }, [selectedRow]);

  // useEffect(() => {
  //   if(selectedPatientHistory !== null || selectedPatientHistory !== undefined){
  //     setSelectedPatientHistory(sessionStorage.getItem("selectedPatientHistory"));

  //   const patient = sessionStorage.getItem("selectedPatientHistory") ? JSON.parse(
  //     sessionStorage.getItem("selectedPatientHistory")
  //   ) : null;
  //   const MedicalHistory =
  //     patient?.VISIT_DATA && patient.VISIT_DATA
  //       ? JSON.parse(patient.VISIT_DATA).medicalHistory
  //       : [];
  //   sessionStorage.setItem("MedicalHistory", JSON.stringify(MedicalHistory));
  //   }

  // })

  //aim Healthy api
  const handleAimHealthySearchResults = async () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      // url: `https://mpowerqa.clinally.com/user/PATIENTDETAILS/${variables.aimSearch}`,
      url: `${Config.base_url}PATIENTDETAILS/${variables.aimSearch}`,
      headers: {
        authorization: `Bearer ${token}`,
        "content-type": "application/json",
        "x-caller": encrypted,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setVariables({
          ...variables,
          aimHealthyUsers: response.data.recordsets,
          showPopup: response.data.recordsets.length > 0,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const handleSearch = debounce((value) => {
    setVariables({
      ...variables,
      searchQuery: value,
    });
    // setSearchQuery(value);
  }, 300);

  // const handleAimHealthySearch = (value) => {
  //   setVariables((prevState) => ({
  //     ...prevState,
  //     aimSearch: value,
  //   }));
  // };

  // const handleClosePopup = () => {
  //   // setVariables({
  //   //   ...variables,
  //   //   showPopup: false,
  //   // });
  //   if (variables.aimSearch === "") {
  //     setVariables((prevState) => ({
  //       ...prevState,
  //       showPopup: false,
  //     }));
  //   }
  // };

  const handlePatientClick = (index, patientData) => {
    //console.log("patientData", patientData, "parients");
    setVariables({
      ...variables,
      // selectedPatient: patients[index],
      selectedPatient: patientData,
    });
    sessionStorage.setItem(
      "PATIENT_ID",
      // JSON.stringify(patients.flat()[index].USER_ID)
      JSON.stringify(patientData.USER_ID)
    );
    sessionStorage.setItem(
      "selectedPatientDetails",
      JSON.stringify(patientData)
    );
    sessionStorage.removeItem("VISIT_ID");
    sessionStorage.removeItem("htResult");
    sessionStorage.removeItem("dbResult");
    // sessionStorage.removeItem('visitData');
  };

  const handleVisitHistory = (index, visit) => {
    setSelectedPatientHistory(visitHistory.flat()[index]);
    //console.log("==index", index, "_++-====v", visit);

    const visitData = visitHistory[index]?.VISIT_DATA;
    sessionStorage.setItem("VISIT_ID", visit?.VISIT_ID);
    sessionStorage.setItem("visitData", JSON.stringify(visitData));
    // console.log(
    //   visitHistory.flat()[index],
    //   index,
    //   "visitHistory.flat()[index]"
    // );
    sessionStorage.setItem(
      "selectedPatientHistory",
      JSON.stringify(visitHistory.flat()[index])
    );
    // const patient = JSON.parse(
    //   sessionStorage.getItem("selectedPatientHistory")
    // );
    // console.log("patient =====", patient);

    navigate(
      `/patients/testvisits/${
        PATIENT_ID || variables.selectedPatient.USER_ID
      }?source=patients`
    );
  };

  const getFormattedDate = (date) => {
    if (date && date !== null && date !== "") {
      const dt = new Date(date);
      const options = { year: "numeric", month: "short", day: "numeric" };
      return dt.toLocaleDateString("en-US", options);
    } else {
      return "";
    }
  };

  const handleOpenDialog = (type) => {
    setOpenDialog({ ...openDialog, [type]: true });
  };

  const handleAddVisit = async () => {
    // console.log("futureDateAppointment", futureDateAppointment);
    if (futureDateAppointment !== null) {
      // console.log("coming");
      handleOpenDialog("schedule");
    } else {
      handleOpenDialog("checkIn");
    }
  };

  const handleNewVisit = async () => {
    const MOBILE = JSON.parse(sessionStorage.getItem("MOBILE"));
    // const selectedDateParam = localStorage.getItem("visit_date"); //selected date
    // console.log("visit_date", selectedDateParam);

    // const inputDate = new Date(futureDateAppointment);
    // const selectedDate = inputDate.toISOString().slice(0, 19).replace("T", " ");

    const inputDate = futureDateAppointment
      ? new Date(futureDateAppointment)
      : new Date();
    const selectedDate = inputDate.toISOString().slice(0, 19).replace("T", " ");
    const visitDate = futureDateAppointment
      ? selectedDate
      : new Date().toISOString().slice(0, 19).replace("T", " ");

    const status = selectedDate == null ? "1" : "0";
    let data = JSON.stringify({
      doctorUserId: UserID,
      id: PATIENT_ID,
      mobile: MOBILE,
      nurseUserId: UserID,
      status: status,
      visitDate: visitDate,
    });

    //console.log("data", data);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${Config.apiURL}${Config.routes.addPatientVisitRecordAPI}`,
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "x-caller": encrypted,
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        if (response.status === 200 || response.status === 201) {
          setOpenDialog({ checkIn: false, schedule: false });
          navigate("/appointments");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCloseAlert = () => {
    setOpenDialog({ checkIn: false, schedule: false });
  };

  function formatDate(dateString) {
    const currentDate = new Date();
    const inputDate = new Date(dateString);

    // Check if the date is in the future
    if (inputDate > currentDate) {
      const options = { day: "2-digit", month: "short", year: "numeric" };
      return new Intl.DateTimeFormat("en-US", options).format(inputDate);
    }

    // Calculate the number of months ago
    // const monthsAgo = Math.floor(
    //   (currentDate - inputDate) / (1000 * 60 * 60 * 24 * 30)
    // );

    const options = { day: "2-digit", month: "short", year: "numeric" };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      inputDate
    );

    return `${formattedDate} `;
  }

  // const linkPatientToHospital = async (user, index) => {
  //   try {
  //     const paylaod = {
  //       userId: user.USER_ID || "",
  //       patientId: user.PATIENT_ID || "",
  //       mobile: user.MOBILE || "",
  //       nurseId: UserID,
  //     };
  //     // console.log("payload", paylaod);
  //     const response = await axios.post(
  //       `${Config.base_url}linkpatienttohospital`,
  //       paylaod,
  //       {
  //         headers: {
  //           "content-type": "application/json",
  //           authorization: `Bearer ${token}`,
  //           "x-caller": await encrypted,
  //         },
  //       }
  //     );

  //     if (response.status === 201) {
  //       setLinkedPatientName(`${user.FIRST_NAME} ${user.LAST_NAME}`);
  //       setPatients((prevPatients) => [...prevPatients, user]);
  //       setVariables({ ...variables, showPopup: false });
  //       setshowAlert(true);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };
  function calculateAge(birthDateStr) {
    const birthDate = new Date(birthDateStr);
    const today = new Date();

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    // Check if the birthday has occurred this year yet
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  }
  const handleOk = () => {
    setshowAlert(false);
  };

  const handleClose = () => {
    setshowAlert(false);
  };

  // const handleNavigate = (id) => {
  //   const PATIENT_ID = JSON.parse(sessionStorage.getItem("PATIENT_ID"));
  //   navigate(`/patients/add-patients?patientId=${PATIENT_ID}`);
  // };

  const handleNaviagteToPrescription = (visit) => {
    // console.log("visit", JSON.stringify(visit.VISIT_DATA));
    sessionStorage.setItem("visitData", JSON.stringify(visit.VISIT_DATA));
    // setIsLoading(false);
    sessionStorage.setItem("VISIT_ID", visit.VISIT_ID);
    const patientId = sessionStorage.getItem("PATIENT_ID");

    //console.log("---------", patientId);
    if (patientId !== null) {
      navigate("/patients/reviewprescription");
    }
  };

  const handleNaviagteToGraphs = (visit) => {
    const patientId = sessionStorage.getItem("PATIENT_ID");
    //console.log("---------", patientId);
    if (patientId !== null) {
      navigate("/patients/graph");
    }
  };

  const statusString = [
    "Add Details",
    "Checked In",
    "Screening",
    "Examination",
    "Analysis",
    "Prescription",
    "Closed",
    "Followup",
  ];

  const formatVisitDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    });
  };

  const handleNaviagteToDocument = (visit) => {
    const patientId = sessionStorage.getItem("PATIENT_ID");
    if (patientId !== null) {
      navigate("/patients/document");
    }
  };

  const handleNavigateAbha = (index, user) => {
    //console.log("user", user);
    sessionStorage.setItem("selectedPatientDetails", JSON.stringify(user));
    sessionStorage.setItem("PATIENT_ID", user.USER_ID);
    navigate("/ABHA?action=addPatient");
  };

  const handleNavigateManageAbha = (index, user) => {
    sessionStorage.setItem("selectedPatientDetails", JSON.stringify(user));
    sessionStorage.setItem("PATIENT_ID", user.USER_ID);
    navigate("/manageabha");
  };

  const handleNaviagteToConsolidatedReport = (visit) => {
    sessionStorage.setItem("visitData", visit.VISIT_DATA);
    navigate("/patients/ConsolidatedReport");
  };

  const handleNavigatetoVisits = () => {
    navigate(
      `/patients/testvisits/${
        PATIENT_ID || variables.selectedPatient.USER_ID
      }?source=patients`
    );
  };
  const getConsentsList = (AbhaAdress) => {
    //console.log("entering inot getConsentsList");
    navigate("/patients/manageabhaconsents");
  };
  const getABHASubscription = async (AbhaAdress) => {
    navigate("/patients/manageabhasubscription");
  };
  //NEW PATIENT FUNCTIONS
  const patientsProps = patients.map((patient) => {
    // console.log(patient, "Patients data");
    const date = new Date(patient.LAST_VISIT_DATE);

    const options = { day: "2-digit", month: "short", year: "numeric" };
    const formattedDate = date
      .toLocaleDateString("en-GB", options)
      .replace(/ /g, " ");

    const abhaNumber = patient.ABHA_NUMBER
      ? patient.ABHA_NUMBER.replace(/-/g, "")
      : "";

    const capitalizeFirstLetter = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    };

    return {
      profile: (
        <div className="FollowupProfile">
          <p>{`${patient.FIRST_NAME.charAt(
            0
          ).toUpperCase()} ${patient.LAST_NAME.charAt(0).toUpperCase()}`}</p>
        </div>
      ),
      name: `${capitalizeFirstLetter(
        patient.FIRST_NAME
      )} ${capitalizeFirstLetter(patient.LAST_NAME)}`,
      gender: `${patient.GENDER}`,
      age: `${patient.age}`,
      mobile: `${patient.MOBILE}`,
      lastVisited: formattedDate,
      ABHAID: abhaNumber,
      userdetails: patient,
    };
  });

  const filteredData = patientsProps.filter((user) => {
    const fullName = `${user.name}`.toLowerCase();
    const mobileNumber = `${user.mobile}`.toLowerCase();
    const searchQueryLower = variables.searchQuery.toLowerCase();
    return (
      fullName.includes(searchQueryLower) ||
      mobileNumber.includes(searchQueryLower)
    );
  });

  const columns = [
    { label: "Profile", field: "profile" },
    { label: "Name", field: "name" },
    {
      label: "Gender",
      field: "gender",
    },
    { label: "Age", field: "age" },
    { label: "Mobile Number", field: "mobile" },
    {
      label: "Last Visited",
      field: "lastVisited",
    },
    { label: "ABHA ID", field: "ABHAID" },
  ];

  const onRowClick = async (row) => {
    // setSelectedRowsendData(row)
    console.log("Row clicked:", row.userdetails);

    await handlePatientClick("index", row.userdetails);

    // setSiglePatient(true);
    await setSelectedRow(row);

    sessionStorage.setItem(
      "PATIENT_ID",
      JSON.stringify(row.userdetails.USER_ID)
    );
    //console.log(row, "rowrowrowrow");
    sessionStorage.setItem(
      "selectedPatientDetails",
      JSON.stringify(row.userdetails)
    );
    await handleVisitHistory(0, visitHistory[0]);
    // navigate("")
  };

  const LinkAbhaFunc = (index, row) => {
    //console.log("user", row);
    sessionStorage.setItem("selectedPatientDetails", JSON.stringify(row));
    //console.log(row.USER_ID, "row.USER_ID");
    sessionStorage.setItem("PATIENT_ID", row.USER_ID);
    navigate("/ABHA?action=linkabha");
  };

  const MangeAbhaFunc = (index, row) => {
    //console.log("row.........", row);
    sessionStorage.setItem("selectedPatientDetails", JSON.stringify(row));
    sessionStorage.setItem("PATIENT_ID", row.USER_ID);
    navigate("/manageabha");
  };

  //Example data of visitdata
  // const Visitdata = [
  //   {
  //     visitedDate: "02, May 2024",
  //     visitedID: "12345",
  //     review: false,
  //   },
  // ];

  // const backTOpatientpage = () => {
  //   setSiglePatient(false);
  // };
  const MyContent = () => {
    return <TestVisit />;
  };

  return isLoading ? (
    <div className="spinningLoader" style={{ height: "90vh" }}>
      <CircularProgress size={48} color="inherit" />
    </div>
  ) : (
    <>
      {/* <div className="Patient-MainContainer">
        <Navbar selectedRow={selectedRow}/>
        <div className="mainpageComponent">
          <TopNavBar />
          <div className="Patient-TopNavbar">
            <div className="Patient-container"> */}
      {/*Patientlist*/}
      {/*console.log("siglePatient", siglePatient)*/}
      {siglePatient && !isLoading ? (
        <>
          <div className="Main-patient">
            <div className="patient-search-addpatient">
              <h1 className="Patient-heading">Patients List</h1>
              <div className="Patient-search-main">
                <div className="patient-search">
                  <img
                    src={searchAlt}
                    style={{ width: "20px", marginLeft: "4%" }}
                    alt="Search icon"
                  />
                  <input
                    className="searchBox-patient"
                    type="text"
                    placeholder="Search patients"
                    onKeyUp={(e) => handleSearch(e.target.value)}
                  />
                </div>
                <button
                  className="addPatientBtn"
                  onClick={() =>
                    (window.location.href = "/ABHA?action=addPatient")
                  }
                >
                  + Add Patient
                </button>
              </div>
            </div>
            {patients.length > 0 ? (
              <div className="Patient-TableComponent">
                <div>
                  <TableComponent
                    data={filteredData}
                    columns={columns}
                    onRowClick={onRowClick}
                    LinkAbhaBtn={LinkAbhaFunc}
                    ManageAbhaBtn={MangeAbhaFunc}
                  />
                </div>
              </div>
            ) : (
              <div className="nopatients">
                <div className="nopatients1">
                  <p>
                    Please check if the patient has already registered in ai.m
                    Healthy app
                  </p>
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        // <div className="single-patient-main">
        //   {selectedRow && (
        //     <div className="Patient-profile-details">
        //       <div className="patient-button">
        //         <button
        //           className="Patient-LinkAbha"
        //           onClick={backTOpatientpage}
        //         >
        //           Back
        //         </button>

        //         <button className="Patient-LinkAbha">LINK ABHA</button>
        //       </div>
        //       <div className="nameContainer">
        //         <p className="shortName">
        //           {selectedRow.userdetails.FIRST_NAME
        //             ? selectedRow.userdetails.FIRST_NAME.charAt(
        //                 0
        //               ).toUpperCase()
        //             : ""}
        //           {selectedRow.userdetails.LAST_NAME
        //             ? selectedRow.userdetails.LAST_NAME.charAt(
        //                 0
        //               ).toUpperCase()
        //             : ""}
        //         </p>
        //         <p>
        //           {selectedRow.userdetails.FIRST_NAME}
        //           {selectedRow.userdetails.LAST_NAME}
        //         </p>
        //         <div className="patient-patientID">
        //           <p className="patient-heading">Patient ID :</p>
        //           <p className="patient-value">
        //             {selectedRow.userdetails.PATIENT_ID}
        //           </p>
        //         </div>
        //       </div>
        //       <hr></hr>
        //       <div style={{ display: "flex", flexDirection: "column" }}>
        //         <div className="patient-AG-container">
        //           <div className="patient-personal-details">
        //             personal Details
        //           </div>
        //           <div className="Patient-AG">
        //             <div>
        //               <p className="patient-heading">Age</p>
        //               <p className="patient-value">
        //                 {selectedRow.userdetails.age}yrs
        //               </p>
        //             </div>
        //             <div>
        //               <p className="patient-heading">Gender</p>
        //               <p className="patient-value">
        //                 {selectedRow.userdetails.GENDER}
        //               </p>
        //             </div>
        //           </div>
        //           <div>
        //             <p className="patient-heading">Mobile Number</p>
        //             <p className="patient-value">
        //               {selectedRow.userdetails.MOBILE}
        //             </p>
        //           </div>
        //           <div>
        //             <p className="patient-heading">Last Visted</p>
        //             <p className="patient-value">
        //               {formatDate(
        //                 selectedRow.userdetails.LAST_VISIT_DATE
        //               )}
        //             </p>
        //           </div>
        //         </div>
        //         <hr></hr>
        //         <div style={{ paddingLeft: "3%" }}>
        //           <div className="patient-personal-details">
        //             Visit History
        //           </div>
        //         </div>
        //         <div className="patient-visit-table">
        //           <table className="Visittable">
        //             <thead>
        //               <tr>
        //                 <th>Visited Date</th>
        //                 <th>Visited ID</th>
        //                 <th>Review</th>
        //                 <th>Prescription</th>
        //               </tr>
        //             </thead>
        //             <tbody>
        //               {Visitdata.map((row, index) => (
        //                 <tr key={index}>
        //                   <td>{row.visitedDate}</td>
        //                   <td>{row.visitedID}</td>
        //                   <td>
        //                     <input
        //                       type="checkbox"
        //                       checked={row.review}
        //                       readOnly
        //                     />
        //                   </td>
        //                   <td>
        //                     <button className="prescription-button">
        //                       Prescription
        //                     </button>
        //                   </td>
        //                 </tr>
        //               ))}
        //             </tbody>
        //           </table>
        //         </div>
        //       </div>
        //     </div>
        //   )}
        //   {/* <div><TestVisit/></div> */}
        //   <div>AddDocument</div>
        // </div>
        <div
          className="patientsBodyContainerb"
          // style={{ height: "92vh" }}
        >
          <div
            className="appointmentsListContainer"
            // style={{ height: "80vh" }}
          >
            {/* <div
                      className="appointmentsDetails"
                      style={{ display: "block" }}
                    >
                      <h1 className="patientHeading">PATIENTS LIST</h1>
                      <div className="searchContainer">
                        <img src={search} alt="" />
                        <input
                          type="text"
                          placeholder="Search Patients List"
                          onKeyUp={(e) => handleSearch(e.target.value)}
                        />
                        <div>
                          <Link
                            // to="/patients/add-patients"
                            to="/ABHA?action=addPatient"
                            className="link"
                          >
                            <button
                              className="addAppointmentsBtn"
                              style={{
                                // Background: "#52447d !important",
                                // boxShadow: "0px 3px 16px 1px #52447D75",
                                fontSize: "16px",
                              }}
                            >
                              Add Patient
                            </button>
                          </Link>
                        </div>
                      </div>
                      <div className="searchContainerBorder">
                        <p></p>
                      </div>
                      {variables.searchQuery && filteredData.length === 0 ? (
                        // <p className="notificationContainer2">
                        //   Please check if the patient "{variables.searchQuery}" has
                        //   registered in ai.m Healthy app.
                        // </p>
                        <div className="notificationContainer1">
                          <p
                            style={{
                              color: "gray",
                              fontSize: "18px",
                              fontWeight: "500",
                              letterSpacing: "0.035rem",
                            }}
                          >
                            This person is not registered with Clinally.Please
                            click on Add patient to continue
                          </p>

                          {/* <Link
                        // to="/patients/add-patients"
                        to="/ABHA?action=addPatient"
                        className="link"
                        style={{ display: "flex", padding: "0 30%" }}
                      >
                        <button
                          className="addAppointmentsBtn"
                          style={{
                            Background: "#52447d !important",
                            // boxShadow: "0px 3px 16px 1px #52447D75",
                            fontSize: "16px",
                          }}
                        >
                          Add Patient
                        </button>
                      </Link> 
                        </div>
                      ) : (
                        filteredData.map((user, index) => (
                          <div
                            key={index}
                            className="containerPatientsList"
                            // style={{
                            //   backgroundColor: `${
                            //     (index + 1) % 5 === 0
                            //       ? "#FFDE67"
                            //       : (index + 1) % 4 === 0 && (index + 1) % 2 === 0
                            //       ? "#ACACFF"
                            //       : (index + 1) % 3 === 0
                            //       ? "#FFB7D9"
                            //       : (index + 1) % 2 === 0 && (index + 1) % 4 !== 0
                            //       ? "#E0C8FF"
                            //       : "#cbfff2"
                            //   }`,
                            // }}
                            style={{
                              backgroundColor: `${
                                variables.selectedPatient &&
                                variables.selectedPatient.USER_ID ===
                                  user.USER_ID
                                  ? "#D1AEFF" // Highlighted color
                                  : "#EEE1FF" // Default color
                              }`,
                              // border: `${
                              //   variables.selectedPatient &&
                              //   variables.selectedPatient.USER_ID === user.USER_ID
                              //     ? "1px solid #52447D" // Highlighted color
                              //     : "none" // Default color
                              // }`,
                            }}
                            onClick={() => handlePatientClick(index, user)}
                          >
                            <div key={index} className="patientListItem">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "10px",
                                }}
                              >
                                <div
                                  key={user.USER_ID}
                                  className="nameContainer"
                                  // style={{
                                  //   backgroundColor: `${
                                  //     (index + 1) % 5 === 0
                                  //       ? "#FFDE67"
                                  //       : (index + 1) % 4 === 0 &&
                                  //         (index + 1) % 2 === 0
                                  //       ? "#ACACFF"
                                  //       : (index + 1) % 3 === 0
                                  //       ? "#FFB7D9"
                                  //       : (index + 1) % 2 === 0 &&
                                  //         (index + 1) % 4 !== 0
                                  //       ? "#E0C8FF"
                                  //       : "#cbfff2"
                                  //   }`,
                                  // }}
                                  style={{
                                    backgroundColor: `${
                                      variables.selectedPatient &&
                                      variables.selectedPatient.USER_ID ===
                                        user.USER_ID
                                        ? "#CBCBFF" // Highlighted color
                                        : "#EEE1FF" // Default color
                                    }`,
                                  }}
                                >
                                  <p
                                    className="shortName"
                                    style={{
                                      backgroundColor: `${
                                        variables.selectedPatient &&
                                        variables.selectedPatient.USER_ID ===
                                          user.USER_ID
                                          ? "#CBCBFF" // Highlighted color
                                          : "#EEE1FF" // Default color
                                      }`,
                                    }}
                                  >
                                    {user.FIRST_NAME
                                      ? user.FIRST_NAME.charAt(0)
                                      : ""}
                                    {user.LAST_NAME
                                      ? user.LAST_NAME.charAt(0)
                                      : ""}
                                  </p>
                                </div>
                                <div>
                                  <p className="patientName">{`${user.FIRST_NAME} ${user.LAST_NAME}`}</p>
                                </div>
                              </div>

                              {user.ABHA_NUMBER === "" ||
                              user.ABHA_NUMBER === null ? (
                                <div className="linkAbha">
                                  <p
                                    // onClick={handleNavigateAbha}
                                    onClick={() =>
                                      handleNavigateAbha(index, user)
                                    }
                                  >
                                    Link ABHA
                                  </p>
                                </div>
                              ) : (
                                <div className="manageAbha">
                                  <p
                                    onClick={() =>
                                      handleNavigateManageAbha(index, user)
                                    }
                                  >
                                    Manage ABHA
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        ))
                      )}
                    </div> */}
            {/* second container */}

            <div className="patientsDetilaContainer">
              <div className="imagepatientDetail">
                <div className="Circlearoundnameicon">
                  <div className="nameContainer">
                    <p
                      className="shortName"
                      style={{ height: "4rem", width: "4rem" }}
                    >
                      {variables?.selectedPatient &&
                      variables?.selectedPatient?.FIRST_NAME
                        ? variables?.selectedPatient?.FIRST_NAME?.charAt(0)
                        : ""}

                      {variables?.selectedPatient &&
                      variables?.selectedPatient?.LAST_NAME
                        ? variables?.selectedPatient?.LAST_NAME?.charAt(0)
                        : ""}
                    </p>
                  </div>
                </div>
                <div className="profileImageTabDetailsContainer">
                  {" "}
                  <div className="profileImageTabDetails">
                    <span className="patientName">
                      {variables.selectedPatient?.FIRST_NAME
                        ? variables.selectedPatient?.FIRST_NAME
                        : "No  FIRST NAME"}
                    </span>
                  </div>
                  <div className="profileImageTabDetailsId">
                    Patient ID :{" "}
                    <span className="patientId">
                      {variables.selectedPatient?.PATIENT_ID
                        ? variables.selectedPatient?.PATIENT_ID
                        : "No  PATIENT ID"}
                    </span>
                  </div>
                </div>
              </div>
              {/* <hr /> */}

              <div
                className="personalDetailDataMainContainer"
                onClick={() => setprofileArrow(!profileArrow)}
              >
                <p>Personal Details</p>
                {!profileArrow ? <FaAngleUp /> : <FaAngleDown />}
              </div>
              {profileArrow && (
                <div className="personalDetailDataMainContainerInner">
                  <div className="inner1">
                    <div className="lastChecked">
                      Age{" "}
                      <span className="lastChecked">
                        {variables.selectedPatient.DATE_OF_BIRTH
                          ? calculateAge(
                              variables.selectedPatient.DATE_OF_BIRTH
                            )
                          : "No Data"}
                        Yrs
                      </span>
                    </div>
                    <div className="lastChecked">
                      Gender{" "}
                      <span className="lastChecked">
                        {variables.selectedPatient.GENDER
                          ? variables.selectedPatient.GENDER
                          : "No Data"}
                      </span>
                    </div>
                  </div>
                  <div className="lastChecked">
                    Mobile Number
                    <span className="lastChecked">
                      +91{" "}
                      {variables.selectedPatient.MOBILE
                        ? variables.selectedPatient.MOBILE
                        : "No Data"}
                    </span>
                  </div>
                  <div className="lastChecked">
                    Last Visited{" "}
                    <span className="patientName">
                      {" "}
                      {variables.selectedPatient.LAST_VISIT_DATE
                        ? formatVisitDate(
                            variables.selectedPatient.LAST_VISIT_DATE
                          )
                        : "No visits"}
                    </span>
                  </div>
                </div>
              )}
              {variables.selectedPatient.ABHA_NUMBER && (
                <>
                  <div
                    className="personalDetailDataMainContainer"
                    onClick={() => setprofileAbhaArrow(!profileAbhaArrow)}
                  >
                    <p>ABHA Details</p>
                    {!profileAbhaArrow ? <FaAngleUp /> : <FaAngleDown />}
                  </div>
                  {/* &&variables.selectedPatient.ABHA_NUMBER */}
                  {profileAbhaArrow && (
                    <div className="personalDetailDataMainContainerInner">
                      <div className="lastChecked">
                        ABHA Number{" "}
                        <span className="patientName">
                          {" "}
                          {variables.selectedPatient.ABHA_NUMBER
                            ? variables.selectedPatient.ABHA_NUMBER
                            : "No Number"}
                        </span>
                      </div>
                      <div className="lastChecked">
                        ABHA Address{" "}
                        <span className="patientName">
                          {" "}
                          {variables.selectedPatient.ABHA_ADDRESS
                            ? variables.selectedPatient.ABHA_ADDRESS
                            : "No Address"}
                        </span>
                      </div>
                    </div>
                  )}
                </>
              )}
              <hr />
              {PATIENT_ID !== null ? (
                <>
                  <div className="patientsListContainer">
                    {variables?.selectedPatient &&
                      !(
                        variables?.selectedPatient?.ABHA_NUMBER === null ||
                        variables?.selectedPatient?.ABHA_NUMBER === ""
                      ) && (
                        <div className="M3functionality">
                          <div
                            className="row"
                            // style={{
                            //   justifyContent: "space-between",
                            //   margin: "0.5rem 0",
                            //   padding: "0.75rem 1rem",
                            //   background: "#EBE3FF",
                            // }}
                          >
                            <p>ABHA Consent</p>
                            <button
                              className="AddvisitBtn"
                              onClick={() =>
                                getConsentsList(
                                  variables.selectedPatient.ABHA_ADDRESS
                                )
                              }
                            >
                              Consent
                            </button>
                          </div>
                          <div
                            className="row"
                            // style={{
                            //   justifyContent: "space-between",
                            //   margin: "0.5rem 0",
                            //   padding: "0.75rem 1rem",
                            //   background: "#EBE3FF",
                            // }}
                          >
                            <p>ABHA Subscription</p>
                            <button
                              className="AddvisitBtn"
                              onClick={() =>
                                getABHASubscription(
                                  variables.selectedPatient.ABHA_ADDRESS
                                )
                              }
                            >
                              Subscription
                            </button>
                          </div>
                        </div>
                      )}
                    {/* <div
                              className="row"
                              style={{
                                justifyContent: "space-between",
                                margin: "0.5rem 0",
                                padding: "0.75rem 1rem",
                                background: "#EBE3FF",
                              }}
                            >
                              <p>Visit History</p>
                              <button
                                className="AddvisitBtn"
                                onClick={handleAddVisit}
                              >
                                Add New Visit
                              </button>
                            </div> */}
                    {/* <div
                    className="row"
                    style={{
                      backgroundColor: "#FFF5E2",
                      padding: "2rem 0.75rem 0.75rem 0.75rem",
                      borderRadius: "10px",
                      marginTop: "1rem",
                      gap: "1rem",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      className="testProfileContainer"
                      style={{
                        backgroundColor: "white",
                        width: "93px",
                        height: "72px",
                      }}
                    >
                      <img
                        src={InitialAssesments}
                        alt=""
                        style={{ height: "39px" }}
                      />
                      <p style={{ fontSize: "0.5rem" }}>Initial Assesments</p>
                    </div>
                    <div
                      className="testProfileContainer"
                      style={{
                        backgroundColor: "white",
                        width: "93px",
                        height: "72px",
                      }}
                    >
                      <img
                        src={ReviewAssessments}
                        alt=""
                        style={{ height: "39px" }}
                      />
                      <p style={{ fontSize: "0.5rem" }}>
                        Review & Prescription
                      </p>
                    </div>
                  </div> */}
                    {/* {visitHistory &&
                    visitHistory.map((visit, index) => (
                      <div
                        key={index}
                        className="row"
                        style={{
                          boxShadow: "0px 4px 5px 0px #00348270",
                          borderRadius: "10px",
                          marginBottom: "4%",marginTop: "0.8rem",
                          padding: "1rem",
                        }}
                      >
                        <div
                          className="imgContainer"
                          style={{ backgroundColor: "#EBE3FF" }}
                        >
                          <img src="" alt="" />
                        </div>
                        <div>
                          <p style={{ fontSize: "10px", fontWeight: "600" }}>
                            {formatDate(visit.VISIT_DATE)}{" "}
                          </p>
                          <p style={{ fontSize: "10px" }}>
                            Visit Reference ID: {visit.VISIT_ID}
                          </p>
                        </div>
                        <div
                          key={visit.VISIT_ID}
                          className="columnData"
                          style={{
                            background: "#EBE3FF",
                            borderRadius: "6px",
                            padding: "0.5rem 0.75rem",
                            cursor: "pointer",
                          }}
                          onClick={() => handleVisitHistory(index)}
                        >
                          <p style={{ fontSize: "10px" }}>
                            {statusString[visit.STATUS]}
                          </p>
                          <img src={Arrow} alt="Arrow icon" />
                        </div>
                      </div>
                    ))} */}
                    <hr />
                    <div className="col">
                      <div
                        className="columnData"
                        style={{ cursor: "pointer" }}
                        onClick={handleNaviagteToDocument}
                      >
                        <div className="imgContainer">
                          <img src={AddDocument} alt="" />
                        </div>
                        <p>Add Document</p>
                      </div>
                      <div
                        className="columnData"
                        style={{ cursor: "pointer" }}
                        onClick={handleNaviagteToGraphs}
                      >
                        <div className="imgContainer">
                          <img src={Graphs} alt="" />
                        </div>
                        <p>Graphs</p>
                      </div>
                      <div
                        className="columnData"
                        onClick={handleNaviagteToConsolidatedReport}
                      >
                        <div className="imgContainer">
                          <img src={ConsolidatedReport} alt="" />
                        </div>
                        <p>Consolidated Report</p>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}

              <div
                className="row"
                style={{
                  justifyContent: "space-between",
                  margin: "0.5rem 0",
                  padding: "0.75rem 1rem",
                  background: "#EBE3FF",
                }}
              >
                <p>Visit History</p>
                <button className="AddvisitBtn" onClick={handleAddVisit}>
                  Add New Visit
                </button>
              </div>
              {visitHistory &&
                visitHistory.map((visit, index) => {
                  return (
                    <>
                      {statusString[visit.STATUS] === "Closed" &&
                      visit.STATUS === 7 &&
                      visit.STATUS != null ? (
                        <div
                          key={index}
                          className="row"
                          style={{
                            // boxShadow: "0px 4px 5px 0px #00348270",
                            borderRadius: "10px",
                            marginBottom: "4%",
                            marginTop: "0.8rem",
                            padding: "1rem",
                            justifyContent: "center",
                            background: "#F6F6F6",
                          }}
                        >
                          {/* DOCUMENT */}
                          {statusString[visit.STATUS] &&
                            visit.PRESCRIPTION_FILE !== null && (
                              <div
                                className="testProfileContainer"
                                style={{
                                  backgroundColor: "white",
                                  width: "50px",
                                  height: "30px",
                                  cursor: "pointer",
                                }}
                                // onClick={ }
                                onClick={() => {
                                  // Logic to handle the click event
                                  const url = `${Config.apiURL}/${
                                    visit.PRESCRIPTION_FILE || ""
                                  }`;
                                  window.open(url, "_blank");
                                }}
                              >
                                <Lottie
                                  animationData={PrescriptionLottie}
                                  loop={true} // Set to false if you don't want the animation to loop
                                  autoplay={true} // Set to false if you want to control the animation manually
                                  style={{
                                    height: "50px",
                                    width: "100%",
                                  }}
                                />
                              </div>
                            )}

                          <div>
                            <p
                              style={{
                                fontSize: "10px",
                                fontWeight: "600",
                              }}
                            >
                              {formatDate(visit.VISIT_DATE)}{" "}
                            </p>
                            <p style={{ fontSize: "10px" }}>
                              Visit Reference ID: {visit.VISIT_ID}
                            </p>
                          </div>

                          {/* prescription */}
                          {statusString[visit.STATUS] &&
                            visit.STATUS !== 6 &&
                            visit.STATUS !== 7 && (
                              <div
                                className="testProfileContainer"
                                style={{
                                  backgroundColor: "white",
                                  width: "50px",
                                  height: "30px",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  handleNaviagteToPrescription(visit)
                                }
                              >
                                {/* <img
                          src={ReviewAssessments}
                          alt=""
                          style={{ height: "39px" }}
                        /> */}
                                <Lottie
                                  animationData={ReviewLottie}
                                  loop={true} // Set to false if you don't want the animation to loop
                                  autoplay={true} // Set to false if you want to control the animation manually
                                  style={{
                                    height: "35px",
                                    width: "80%",
                                  }}
                                />
                              </div>
                            )}

                          <div
                            key={visit.VISIT_ID}
                            className="columnData"
                            style={{
                              background: "#EBE3FF",
                              borderRadius: "6px",
                              padding: "0.5rem 0.75rem",
                              cursor: "pointer",
                            }}
                            onClick={() => handleVisitHistory(index, visit)}
                          >
                            <p style={{ fontSize: "10px" }}>
                              {" "}
                              {statusString[visit.STATUS]}
                            </p>
                            <img src={Arrow} alt="Arrow icon" />
                          </div>
                        </div>
                      ) : (
                        <>
                          {index === 0 &&
                          statusString[visit.STATUS] !== "Closed" &&
                          visit.STATUS !== 7 &&
                          visit.STATUS != null ? (
                            <>
                              <div className="initialassessmentboxbg">
                                <div
                                  className="row"
                                  style={{
                                    // backgroundColor: "#FFF5E2",
                                    padding: "2rem 0.75rem 0.75rem 0.75rem",
                                    borderRadius: "10px",
                                    marginTop: "1rem",
                                    gap: "1rem",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div
                                    className="testProfileContainer"
                                    style={{
                                      backgroundColor: "white",
                                      width: "93px",
                                      height: "83px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      handleVisitHistory(index, visit)
                                    }
                                  >
                                    <img
                                      src={InitialAssesments}
                                      alt=""
                                      style={{ height: "39px" }}
                                    />
                                    {/* <Lottie
                                          style={{ height: "60px", width: "100%" }}
                                          animationData={InitialAssessmentLottie}
                                          loop={true} // Set to false if you don't want the animation to loop
                                          autoplay={true} // Set to false if you want to control the animation manually
                                        /> */}

                                    <p
                                      style={{
                                        fontSize: "0.5rem",
                                      }}
                                    >
                                      Initial Assessments
                                    </p>
                                  </div>

                                  <div
                                    className="testProfileContainer"
                                    style={{
                                      backgroundColor: "white",
                                      width: "93px",
                                      height: "83px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      handleNaviagteToPrescription(visit)
                                    }
                                  >
                                    <img
                                      src={ReviewAssessments}
                                      alt=""
                                      style={{ height: "39px" }}
                                    />
                                    {/* <Lottie
                                          animationData={ReviewLottie}
                                          loop={true} // Set to false if you don't want the animation to loop
                                          autoplay={true} // Set to false if you want to control the animation manually
                                          style={{ height: "60px", width: "100%" }}
                                        /> */}
                                    <p
                                      style={{
                                        fontSize: "0.5rem",
                                      }}
                                    >
                                      Review & Prescription
                                    </p>
                                  </div>
                                </div>
                                <div
                                  key={index}
                                  className="row"
                                  style={{
                                    // boxShadow: "0px 4px 5px 0px #00348270",
                                    borderRadius: "10px",
                                    marginBottom: "4%",
                                    marginTop: "0.8rem",
                                    padding: "1rem",
                                    justifyContent: "center",
                                  }}
                                >
                                  {/* <div
                                  className="imgContainer"
                                  // style={{ backgroundColor: "#EBE3FF" }}
                                >
                                  <img src="" alt="" />
                                </div> */}
                                  {/* DOCUMENT */}

                                  {statusString[visit.STATUS] &&
                                    visit.PRESCRIPTION_FILE !== null && (
                                      <div
                                        className="testProfileContainer"
                                        style={{
                                          backgroundColor: "white",
                                          width: "50px",
                                          height: "30px",
                                          cursor: "pointer",
                                        }}
                                        // onClick={ }
                                        onClick={() => {
                                          // Logic to handle the click event
                                          const url = `${Config.apiURL}/${
                                            visit.PRESCRIPTION_FILE || ""
                                          }`;
                                          window.open(url, "_blank");
                                        }}
                                      >
                                        <Lottie
                                          animationData={PrescriptionLottie}
                                          loop={true} // Set to false if you don't want the animation to loop
                                          autoplay={true} // Set to false if you want to control the animation manually
                                          style={{
                                            height: "50px",
                                            width: "100%",
                                          }}
                                        />
                                      </div>
                                    )}

                                  <div>
                                    <p
                                      style={{
                                        fontSize: "10px",
                                        fontWeight: "600",
                                      }}
                                    >
                                      {formatDate(visit.VISIT_DATE)}{" "}
                                    </p>
                                    <p style={{ fontSize: "10px" }}>
                                      Visit Reference ID: {visit.VISIT_ID}
                                    </p>
                                  </div>
                                  <div
                                    key={visit.VISIT_ID}
                                    className="columnData"
                                    style={{
                                      background: "#EBE3FF",
                                      borderRadius: "6px",
                                      padding: "0.5rem 0.75rem",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      handleVisitHistory(index, visit)
                                    }
                                  >
                                    <p style={{ fontSize: "10px" }}>
                                      {" "}
                                      {/* {statusString[visit.STATUS]} */}
                                      {new Date(visit.VISIT_DATE) >= new Date()
                                        ? "Schedule"
                                        : statusString[visit.STATUS]}
                                    </p>
                                    {new Date(visit.VISIT_DATE) >=
                                    new Date() ? (
                                      ""
                                    ) : (
                                      <img src={Arrow} alt="Arrow icon" />
                                    )}
                                    {/* <img src={Arrow} alt="Arrow icon" /> */}
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              {/* {console.log(visitHistory[0].STATUS,visitHistory,"visitHistory[0].status")} */}
                              {index === 1 &&
                              !(new Date(visit.VISIT_DATE) > new Date()) &&
                              (visitHistory[0].STATUS === 6 ||
                                visitHistory[0].STATUS === 7) ? (
                                <>
                                  <div className="initialassessmentboxbg">
                                    <div
                                      className="row"
                                      style={{
                                        // backgroundColor: "#FFF5E2",
                                        padding: "2rem 0.75rem 0.75rem 0.75rem",
                                        borderRadius: "10px",
                                        marginTop: "1rem",
                                        gap: "1rem",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <div
                                        className="testProfileContainer"
                                        style={{
                                          backgroundColor: "white",
                                          width: "93px",
                                          height: "83px",
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          handleVisitHistory(index, visit)
                                        }
                                      >
                                        <img
                                          src={InitialAssesments}
                                          alt=""
                                          style={{
                                            height: "39px",
                                          }}
                                        />
                                        {/* <Lottie
                                          style={{ height: "60px", width: "100%" }}
                                          animationData={InitialAssessmentLottie}
                                          loop={true} // Set to false if you don't want the animation to loop
                                          autoplay={true} // Set to false if you want to control the animation manually
                                        /> */}

                                        <p
                                          style={{
                                            fontSize: "0.5rem",
                                          }}
                                        >
                                          Initial Assessments
                                        </p>
                                      </div>
                                      <div
                                        className="testProfileContainer"
                                        style={{
                                          backgroundColor: "white",
                                          width: "93px",
                                          height: "83px",
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          handleNaviagteToPrescription(visit)
                                        }
                                      >
                                        <img
                                          src={ReviewAssessments}
                                          alt=""
                                          style={{
                                            height: "39px",
                                          }}
                                        />
                                        {/* <Lottie
                                          animationData={ReviewLottie}
                                          loop={true} // Set to false if you don't want the animation to loop
                                          autoplay={true} // Set to false if you want to control the animation manually
                                          style={{ height: "60px", width: "100%" }}
                                        /> */}
                                        <p
                                          style={{
                                            fontSize: "0.5rem",
                                          }}
                                        >
                                          Review & Prescription
                                        </p>
                                      </div>
                                    </div>
                                    <div
                                      key={index}
                                      className="row"
                                      style={{
                                        // boxShadow: "0px 4px 5px 0px #00348270",
                                        borderRadius: "10px",
                                        marginBottom: "4%",
                                        marginTop: "0.8rem",
                                        padding: "1rem",
                                        justifyContent: "center",
                                      }}
                                    >
                                      {/* <div
                                  className="imgContainer"
                                  // style={{ backgroundColor: "#EBE3FF" }}
                                >
                                  <img src="" alt="" />
                                </div> */}
                                      {/* DOCUMENT */}

                                      {statusString[visit.STATUS] &&
                                        visit.PRESCRIPTION_FILE !== null && (
                                          <div
                                            className="testProfileContainer"
                                            style={{
                                              backgroundColor: "white",
                                              width: "50px",
                                              height: "30px",
                                              cursor: "pointer",
                                            }}
                                            // onClick={ }
                                            onClick={() => {
                                              // Logic to handle the click event
                                              const url = `${Config.apiURL}/${
                                                visit.PRESCRIPTION_FILE || ""
                                              }`;
                                              window.open(url, "_blank");
                                            }}
                                          >
                                            <Lottie
                                              animationData={PrescriptionLottie}
                                              loop={true} // Set to false if you don't want the animation to loop
                                              autoplay={true} // Set to false if you want to control the animation manually
                                              style={{
                                                height: "50px",
                                                width: "100%",
                                              }}
                                            />
                                          </div>
                                        )}

                                      <div>
                                        <p
                                          style={{
                                            fontSize: "10px",
                                            fontWeight: "600",
                                          }}
                                        >
                                          {formatDate(visit.VISIT_DATE)}{" "}
                                        </p>
                                        <p
                                          style={{
                                            fontSize: "10px",
                                          }}
                                        >
                                          Visit Reference ID: {visit.VISIT_ID}
                                        </p>
                                      </div>
                                      <div
                                        key={visit.VISIT_ID}
                                        className="columnData"
                                        style={{
                                          background: "#EBE3FF",
                                          borderRadius: "6px",
                                          padding: "0.5rem 0.75rem",
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          handleVisitHistory(index, visit)
                                        }
                                      >
                                        <p
                                          style={{
                                            fontSize: "10px",
                                          }}
                                        >
                                          {" "}
                                          {statusString[visit.STATUS]}
                                        </p>
                                        <img src={Arrow} alt="Arrow icon" />
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div
                                    key={index}
                                    className="row"
                                    style={{
                                      // boxShadow: "0px 4px 5px 0px #00348270",
                                      borderRadius: "10px",
                                      marginBottom: "4%",
                                      marginTop: "0.8rem",
                                      padding: "1rem",
                                      justifyContent: "center",
                                      background:
                                        new Date(visit.VISIT_DATE) > new Date()
                                          ? "#d0d0d0"
                                          : "#F6F6F6",
                                    }}
                                  >
                                    {/* DOCUMENT */}
                                    {statusString[visit.STATUS] &&
                                      visit.PRESCRIPTION_FILE !== null && (
                                        <div
                                          className="testProfileContainer"
                                          style={{
                                            backgroundColor: "white",
                                            width: "50px",
                                            height: "30px",
                                            cursor: "pointer",
                                          }}
                                          // onClick={ }
                                          onClick={() => {
                                            // Logic to handle the click event
                                            const url = `${Config.apiURL}/${
                                              visit.PRESCRIPTION_FILE || ""
                                            }`;
                                            window.open(url, "_blank");
                                          }}
                                        >
                                          <Lottie
                                            animationData={PrescriptionLottie}
                                            loop={true} // Set to false if you don't want the animation to loop
                                            autoplay={true} // Set to false if you want to control the animation manually
                                            style={{
                                              height: "50px",
                                              width: "100%",
                                            }}
                                          />
                                        </div>
                                      )}

                                    <div>
                                      <p
                                        style={{
                                          fontSize: "10px",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {formatDate(visit.VISIT_DATE)}{" "}
                                      </p>
                                      <p
                                        style={{
                                          fontSize: "10px",
                                        }}
                                      >
                                        Visit Reference ID: {visit.VISIT_ID}
                                      </p>
                                    </div>

                                    {/* prescription */}
                                    {new Date(visit.VISIT_DATE) >
                                    new Date() ? null : (
                                      <>
                                        {" "}
                                        {statusString[visit.STATUS] &&
                                          visit.STATUS !== 6 &&
                                          visit.STATUS !== 7 && (
                                            <div
                                              className="testProfileContainer"
                                              style={{
                                                backgroundColor: "white",
                                                width: "50px",
                                                height: "30px",
                                                cursor: "pointer",
                                              }}
                                              onClick={() =>
                                                handleNaviagteToPrescription(
                                                  visit
                                                )
                                              }
                                            >
                                              <Lottie
                                                animationData={ReviewLottie}
                                                loop={true} // Set to false if you don't want the animation to loop
                                                autoplay={true} // Set to false if you want to control the animation manually
                                                style={{
                                                  height: "35px",
                                                  width: "80%",
                                                }}
                                              />
                                            </div>
                                          )}{" "}
                                      </>
                                    )}

                                    <div
                                      key={visit.VISIT_ID}
                                      className="columnData"
                                      style={{
                                        background: "#EBE3FF",
                                        borderRadius: "6px",
                                        padding: "0.5rem 0.75rem",
                                        cursor:
                                          new Date(visit.VISIT_DATE) >
                                          new Date()
                                            ? "none"
                                            : "pointer",
                                      }}
                                      onClick={
                                        new Date(visit.VISIT_DATE) > new Date()
                                          ? null
                                          : () =>
                                              handleVisitHistory(index, visit)
                                      }
                                    >
                                      <p
                                        style={{
                                          fontSize: "10px",
                                        }}
                                      >
                                        {" "}
                                        {/* {statusString[visit.STATUS]} */}
                                        {new Date(visit.VISIT_DATE) > new Date()
                                          ? "Schedule"
                                          : statusString[visit.STATUS]}
                                      </p>
                                      {new Date(visit.VISIT_DATE) >
                                      new Date() ? (
                                        ""
                                      ) : (
                                        <img src={Arrow} alt="Arrow icon" />
                                      )}
                                      {/* <img
                                                src={Arrow}
                                                alt="Arrow icon"
                                              /> */}
                                    </div>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  );
                })}
            </div>

            <div className="allDataContainerBox">
              <MyContent />
            </div>
            {/* <div className="VisitesListContainer">
                     
                    </div> */}
            {/* )} */}
          </div>

          <Dialog open={showAlert} onClose={handleClose}>
            <DialogTitle>Successful</DialogTitle>

            <DialogContent>
              <p>
                The patent {linkedPatientName} has been successfully linked to
                your hospital.
              </p>
            </DialogContent>

            <DialogActions>
              <Button onClick={handleOk} color="primary">
                Ok
              </Button>
            </DialogActions>
          </Dialog>

          {/* schedule appoointment*/}
          <Dialog open={openDialog.schedule} onClose={handleClose}>
            <DialogTitle>Schedule appointment!</DialogTitle>
            <DialogContent>
              <Typography>
                Would you like to schedule an appointment for the Patient on
                {getFormattedDate(futureDateAppointment)}?
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseAlert} color="primary">
                cancel
              </Button>
              <Button onClick={handleNewVisit} color="primary">
                continue
              </Button>
            </DialogActions>
          </Dialog>

          {/* checkin appointment */}
          <Dialog open={openDialog.checkIn} onClose={handleClose}>
            <DialogTitle>Patient Check-in!</DialogTitle>
            <DialogContent>
              <Typography>Would you like to Check-in the Patient??</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseAlert} color="primary">
                cancel
              </Button>
              <Button onClick={handleNewVisit} color="primary">
                continue
              </Button>
            </DialogActions>
          </Dialog>

          {/* for closed record view popup */}
          {openDialog.closedRecord && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "3%",
              }}
            >
              <div>
                <Dialog open={openDialog.closedRecord}>
                  <DialogTitle> Alert!</DialogTitle>
                  <DialogContent>
                    <p>
                      This is a view only record. You will be not be able to
                      edit it. Click 'OK' to view the record.
                    </p>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={handleNavigatetoVisits}
                      style={{ background: "#9b00d3", color: "white" }}
                    >
                      OK
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            </div>
          )}

          {action === "addappointment" ? (
            <div className="botbox">
              <div className="noteaddappbox">
                Note: Please select patient and click on Add New Visit
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      )}
      {/* </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Patients;
