import React, { useEffect, useState } from "react";
import { publicEncrypt, constants } from "crypto-browserify";
import { Buffer } from "buffer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import backArrow from "../../images/leftArrow.svg";
import UploadImg from "../../images/Group 431.svg";
import axios from "axios";
import Config from "../../Config/config";

const RegIdentityProof = () => {
  const RegMobileNum = sessionStorage.getItem("RegMobileNum");
  const UserID = JSON.parse(sessionStorage.getItem("RegUserId"));
  const publicKey = sessionStorage.getItem("RegpublicKey");
  const token = sessionStorage.getItem("Regtoken");
  var padding = constants.RSA_PKCS1_PADDING;
  var data = Buffer.from(UserID.toString(), "utf-8");
  var encrypted = publicEncrypt(
    { key: publicKey, padding: padding },
    data
  ).toString("base64");

  const [selectedfile, setSelectedFile] = useState({
    Identity: null,
    Hospital: null,
    Medical: null,
  });

  const [file, setFile] = useState({
    Identity: null,
    Hospital: null,
    Medical: null,
  }); //to render the selected image

  const doctorName = sessionStorage.getItem("doctorName");
  const HospitalName = sessionStorage.getItem("hospitalName");
  const [activeSection, setActiveSection] = useState("IdenitityProof");
  const [variables, setVariables] = useState({
    selectedOption: "",
  });

  const handleIdentityFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = () => {
        setFile({ ...file, Identity: reader.result }); // Set the base64 data URL to the state variable
      };
      reader.readAsDataURL(selectedFile);
    }

    setSelectedFile({
      ...selectedFile,
      Identity: selectedFile,
    });
  };

  const handleHospitalFileChange = (event) => {
    //console.log("event.......", selectedfile);
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = () => {
        setFile({ ...file, Hospital: reader.result }); // Set the base64 data URL to the state variable
      };
      reader.readAsDataURL(selectedFile);
    }

    setSelectedFile({
      ...selectedFile,
      Hospital: selectedFile,
    });
  };

  const handleMedicalFileChange = (event) => {
    //console.log("event.......", selectedfile);

    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = () => {
        setFile({ ...file, Medical: reader.result }); // Set the base64 data URL to the state variable
      };
      reader.readAsDataURL(selectedFile);
    }

    setSelectedFile({
      ...selectedFile,
      Medical: selectedFile,
    });
  };

  const handleNext = async (action) => {
    console.log(`handleNext called with action: ${action}`);
    if (action === "Done") {
      console.log("Action is 'Done'");
      sessionStorage.setItem("action", "");
      console.log("Reloading page...");
      window.location.reload();
    }

    if (activeSection === "IdenitityProof") {
      const formData1 = new FormData();
      formData1.append("files", selectedfile.Identity);
      formData1.append("data", JSON.stringify({ USER_ID: UserID }));
      try {
        const response = await axios.post(
          `${Config.base_url}v2/IDENTITY_PROOF`,
          formData1,
          {
            headers: {
              "content-type": "multipart/form-data",
              authorization: `Bearer ${token}`,
              "x-caller": encrypted,
            },
          }
        );
        console.log("API Response:", response.data);
        setActiveSection("HospitalProof");
      } catch (error) {
        console.error("API Error:", error);
      }
      return;
    } else if (activeSection === "HospitalProof") {
      const formData1 = new FormData();
      formData1.append("files", selectedfile.Hospital);
      formData1.append("data", JSON.stringify({ USER_ID: UserID }));
      try {
        const response = await axios.post(
          `${Config.base_url}v2/HOSPITAL_PROOF`,
          formData1,
          {
            headers: {
              "content-type": "multipart/form-data",
              authorization: `Bearer ${token}`,
              "x-caller": encrypted,
            },
          }
        );
        console.log("API Response:", response.data);
        setActiveSection("MedicalProof");
      } catch (error) {
        console.error("API Error:", error);
      }
      return;
    } else if (activeSection === "MedicalProof") {
      const formData1 = new FormData();
      formData1.append("files", selectedfile.Medical);
      formData1.append("data", JSON.stringify({ USER_ID: UserID }));
      try {
        const response = await axios.post(
          `${Config.base_url}v2/MEDICAL_PROOF`,
          formData1,
          {
            headers: {
              "content-type": "multipart/form-data",
              authorization: `Bearer ${token}`,
              "x-caller": encrypted,
            },
          }
        );
        console.log("API Response:", response.data);
      } catch (error) {
        console.error("API Error:", error);
      }
      return;
    }
  };

  const handleBack = () => {
    if (activeSection === "HospitalProof") {
      setActiveSection("IdenitityProof");
    } else if (activeSection === "MedicalProof") {
      setActiveSection("HospitalProof");
    }
  };

  const onValueChange = (event) => {
    //console.log("cmg");
    setVariables((prevVariables) => ({
      ...prevVariables,
      selectedOption: event.target.value,
    }));
  };

  //PROFILE_VERIFICATIOM.DART page (apis for hospital proof. medical proof and idemtity proof)
  return (
    <>
      <div className="profileRightContainer">
        <div className="leftContainer" style={{ width: "70%" }}>
          <div className="bottomContainer">
            {activeSection === "IdenitityProof" && (
              <>
                <div className="headingContainer">
                  <p className="personalInfoHeading">Identity Proof</p>
                </div>

                <div
                  style={{
                    background: "#AEAEAE",
                    width: "100%",
                    height: "1px",
                  }}
                ></div>
                <div
                  className="personalInfoContainer"
                  style={{
                    display: "flex",
                    gap: "2rem",
                    flexWrap: "wrap",
                  }}
                >
                  <p>For Dr.{doctorName}</p>
                  <p
                    style={{
                      fontFamily: "Varela Round",
                      fontSize: "18px",
                      fontWeight: "400",
                      lineHeight: "20px",
                      letterSpacing: "0em",
                      textAlign: "left",
                      color: "rgba(82, 68, 125, 1)",
                    }}
                  >
                    Please upload your identity proof to ensure that the
                    ownership of your profile remains with only you.{" "}
                  </p>
                  <div className="topContainer">
                    <div
                      style={{
                        flexDirection: "column",
                        display: "flex",
                        gap: "2rem",
                      }}
                    >
                      {file.Identity ? (
                        <div>
                          <img
                            src={file.Identity}
                            alt="Preview"
                            style={{ maxWidth: "200px", maxHeight: "200px" }}
                          />
                        </div>
                      ) : (
                        <img src={UploadImg} alt="Upload" />
                      )}

                      <input
                        type="file"
                        accept=".jpg, .jpeg, .png"
                        multiple={false}
                        id="uploadImage"
                        style={{ display: "none" }}
                        onChange={handleIdentityFileChange}
                      />
                      <label
                        className="upload_image1"
                        htmlFor="uploadImage"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "0.75rem",
                        }}
                      >
                        Upload
                      </label>
                    </div>

                    <div className="AcceptableDocHeader">
                      <p style={{ textDecoration: "underline", gap: "10rem" }}>
                        Acceptable Documents
                      </p>
                      <ul
                        style={{
                          listStyleType: "disc",
                          paddingLeft: "1.5rem",
                          paddingTop: "10px",
                          justifyContent: "space-between",
                        }}
                      >
                        <li>i. Aadhaar Card</li>
                        <li>ii. Driving License</li>
                        <li>iii.Voter Card</li>
                        <li>iv. Any other Govt. ID</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "3rem",
                    alignItems: "center",
                  }}
                >
                  <div onClick={handleNext}>
                    <p
                      style={{
                        fontFamily: "Varela Round",
                        fontSize: "15px",
                        fontWeight: 400,
                        lineHeight: "18px",
                        letterSpacing: "0em",
                        textAlign: "center",
                        color: "#52447D",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      Skip for now
                    </p>
                  </div>
                  <div
                    className={"editButton2"}
                    style={{ width: "30%", height: "45px" }}
                    onClick={handleNext}
                  >
                    <p style={{ color: "white" }}>Next</p>
                  </div>
                </div>
              </>
            )}
            {activeSection === "HospitalProof" && (
              <>
                <div style={{ display: "flex", gap: "1rem" }}>
                  <img
                    src={backArrow}
                    style={{ cursor: "pointer" }}
                    onClick={handleBack}
                  />
                  <p className="personalInfoHeading">Hospital Proof</p>
                </div>

                <div
                  style={{
                    background: "#AEAEAE",
                    width: "100%",
                    height: "1px",
                  }}
                ></div>
                <div>
                  <p
                    style={{
                      fontFamily: "Varela Round",
                      fontSize: "21px",
                      fontWeight: "400",
                      lineHeight: "25px",
                      letterSpacing: "0em",
                      textAlign: "left",
                      color: "rgba(82, 68, 125, 1)",
                    }}
                  >
                    For {HospitalName} Hospital
                  </p>
                </div>
                <div className="topContainer">
                  <div
                    style={{
                      flexDirection: "column",
                      display: "flex",
                      gap: "2rem",
                    }}
                  >
                    {file.Hospital ? (
                      <div>
                        <img
                          src={file.Hospital}
                          alt="Preview"
                          style={{ maxWidth: "200px", maxHeight: "200px" }}
                        />
                      </div>
                    ) : (
                      <img src={UploadImg} alt="Upload" />
                    )}

                    {/* <input
                      type="file"
                      accept=".jpg, .jpeg, .png"
                      multiple={false}
                      id="uploadImage"
                      style={{ display: "none" }}
                      onChange={handleHospitalFileChange}
                    /> */}
                    <input
                      type="file"
                      accept=".jpg, .jpeg, .png"
                      multiple={false}
                      id="uploadImage"
                      style={{ display: "none" }}
                      onChange={handleHospitalFileChange}
                    />
                    <label
                      className="upload_image1"
                      htmlFor="uploadImage"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "0.75rem",
                      }}
                    >
                      Upload
                    </label>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0em",
                    }}
                  >
                    <div className="radio">
                      <input
                        type="radio"
                        value="owner"
                        checked={variables.selectedOption === "owner"}
                        onChange={onValueChange}
                      />
                      <label className="radioLabel">
                        The owner of Hospital
                      </label>
                    </div>
                    <div className="radio">
                      <input
                        type="radio"
                        value="rented"
                        checked={variables.selectedOption === "rented"}
                        onChange={onValueChange}
                      />
                      <label className="radioLabel">
                        {" "}
                        Have rented at other Hospital
                      </label>
                    </div>
                    <div className="radio">
                      <input
                        type="radio"
                        value="consultant"
                        checked={variables.selectedOption === "consultant"}
                        onChange={onValueChange}
                      />
                      <label className="radioLabel"> A Consultant Doctor</label>
                    </div>
                    <div className="radio">
                      <input
                        type="radio"
                        value="practicing"
                        checked={variables.selectedOption === "practicing"}
                        onChange={onValueChange}
                      />
                      <label className="radioLabel"> Practicing at Home</label>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "4rem",
                    alignItems: "center",
                    // padding: "2rem",
                  }}
                >
                  <div className="AcceptableDocHeader">
                    <p style={{ textDecoration: "underline", gap: "10rem" }}>
                      Acceptable Documents
                    </p>
                    <ul
                      style={{
                        listStyleType: "disc",
                        paddingLeft: "1.5rem",
                        paddingTop: "10px",
                        justifyContent: "space-between",
                      }}
                    >
                      <li>i. Clinic Registration Proof</li>
                      <li>ii. Water Disposal Proof</li>
                      <li>iii.Tax Receipt</li>
                    </ul>
                  </div>

                </div>
                
                <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: "3rem",
                      alignItems: "center",
                    }}
                  >
                    <div onClick={handleNext}>
                      <p
                        style={{
                          fontFamily: "Varela Round",
                          fontSize: "15px",
                          fontWeight: 400,
                          lineHeight: "18px",
                          letterSpacing: "0em",
                          textAlign: "center",
                          color: "#52447D",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                      >
                        Skip for now
                      </p>
                    </div>
                    <div
                      className={"editButton2"}
                      style={{ width: "30%", height: "45px" }}
                      onClick={handleNext}
                    >
                      <p style={{ color: "white" }}>Next</p>
                    </div>
                    
                  </div>
              </>
            )}

            {activeSection === "MedicalProof" && (
              <>
                <div style={{ display: "flex", gap: "1rem" }}>
                  <img
                    src={backArrow}
                    style={{ cursor: "pointer" }}
                    onClick={handleBack}
                  />
                  <p className="personalInfoHeading">
                    Medical Registration Proof
                  </p>
                </div>

                <div
                  style={{
                    background: "#AEAEAE",
                    width: "100%",
                    height: "1px",
                  }}
                ></div>
                <div
                  className="personalInfoContainer"
                  style={{
                    display: "flex",
                    gap: "2rem",
                    flexWrap: "wrap",
                  }}
                >
                  {/* <p
                    style={{
                      fontFamily: "Varela Round",
                      fontSize: "18px",
                      fontWeight: "400",
                      lineHeight: "20px",
                      letterSpacing: "0em",
                      textAlign: "left",
                      color: "rgba(82, 68, 125, 1)",
                    }}
                  >
                    Please upload your identity proof to ensure that the
                    ownership of your profile remains with only you.{" "}
                  </p> */}
                  <div className="topContainer">
                    <div
                      style={{
                        flexDirection: "column",
                        display: "flex",
                        gap: "2rem",
                      }}
                    >
                      {file.Medical ? (
                        <div>
                          <img
                            src={file.Medical}
                            alt="Preview"
                            style={{ maxWidth: "200px", maxHeight: "200px" }}
                          />
                        </div>
                      ) : (
                        <img src={UploadImg} alt="Upload" />
                      )}
                      <input
                        type="file"
                        accept=".jpg, .jpeg, .png"
                        multiple={false}
                        id="uploadImage"
                        style={{ display: "none" }}
                        onChange={handleMedicalFileChange}
                      />
                      <label
                        className="upload_image1"
                        htmlFor="uploadImage"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "0.75rem",
                        }}
                      >
                        Upload
                      </label>
                    </div>

                    <div className="AcceptableDocHeader">
                      <p
                        style={{
                          textDecoration: "underline",
                          marginLeft: "35px",
                        }}
                      >
                        Acceptable Documents
                      </p>
                      <ul
                        style={{
                          listStyleType: "disc",
                          // paddingLeft: "1.5rem",
                          paddingTop: "10px",
                          justifyContent: "space-between",
                        }}
                      >
                        <li>Medical Registration Certification</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "3rem",
                    alignItems: "center",
                  }}
                >
                  <div
                    //onClick={handleNext}
                    onClick={() => handleNext("Done")}
                  >
                    <p
                      style={{
                        fontFamily: "Varela Round",
                        fontSize: "15px",
                        fontWeight: 400,
                        lineHeight: "18px",
                        letterSpacing: "0em",
                        textAlign: "center",
                        color: "#52447D",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      Skip for now
                    </p>
                  </div>

                  <div
                    className={"editButton2"}
                    style={{ width: "30%", height: "45px" }}
                    onClick={() => handleNext("Done")}
                  >
                    <p style={{ color: "white" }}>Done</p>
                  </div>
                </div>
              </>
            )}

            <ToastContainer />
          </div>
        </div>
      </div>
    </>
  );
};
export default RegIdentityProof;
