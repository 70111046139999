import React, { useEffect, useState, useRef } from "react";
import "../Registration/Register.css";
// import ProfilePic from "../../images/profilePic.svg";
import profileCamera from "../../images/profileCamera.svg";
import vectorProfile from "../../images/VectorProfile.svg";
import Edit1 from "../../images/edit1.svg";
import Edit from "../../images/editIcon.svg";
import Axios from "axios";
import EditIcon from "../../images/edit-fill-1480 1.svg";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Calendar from "../Calendar";
import { DateTime } from "luxon";
import { publicEncrypt, constants } from "crypto-browserify";
import { Buffer } from "buffer";
import { ToastContainer, toast } from "react-toastify";
import Config from "../../Config/config";
import profileAlt from "../../images/ProfileAlt.png";
import { useNavigate } from "react-router-dom";

const PersonalInfo = ({ ProfileDetailsCount }) => {
  const [file, setFile] = useState(null);
  const [value, setValue] = useState(new Date());
  const [details, setDetails] = useState([]);
  const [isEditMode, setIsEditMode] = useState(true);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedfile, setSelectedFile] = useState(null);
  const [HospiralId, setHospiralId] = useState("");
  const [hospitalName, setHospitalName] = useState("");
  const [error, setError] = useState("");
  const [editStatus, setEditStatus] = useState({
    firstName: false,
    lastName: false,
    mobileNumber: false,
    email: false,
    address: false,
  });

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupTitle, setPopupTitle] = useState("");
  const [popupMessage, setPopupMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [deleteReason, setDeleteReason] = useState("");

  const handleDeleteClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleDeleteAccount = () => {
    // Implement the account deletion logic here
    console.log("Account deleted for reason:", deleteReason);
    setShowModal(false);
  };

  const deleteButtonStyle = {
    backgroundColor: "#f44336",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    padding: "10px 20px",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "bold",
    transition: "background-color 0.3s",
  };

  const modalOverlayStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  };

  const modalStyle = {
    backgroundColor: "#fff",
    borderRadius: "8px",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
    width: "400px",
    textAlign: "center",
  };

  const inputStyle = {
    width: "90%",
    padding: "8px",
    margin: "10px 0",
    borderRadius: "4px",
    border: "1px solid #ccc",
  };

  const buttonContainerStyle = {
    display: "flex",
    justifyContent: "space-between",
  };

  const confirmDeleteButtonStyle = {
    backgroundColor: "#f44336",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    padding: "10px 20px",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "bold",
    transition: "background-color 0.3s",
    margin: "10px",
  };

  const cancelButtonStyle = {
    backgroundColor: "#ccc",
    color: "#333",
    border: "none",
    borderRadius: "4px",
    padding: "10px 20px",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "bold",
    transition: "background-color 0.3s",
    margin: "10px",
  };

  const toggleEditMode = (event) => {
    const fieldName = event.target.getAttribute("data-field");
    setEditStatus((prevState) => ({
      ...prevState,
      [fieldName]: !prevState[fieldName],
    }));
  };

  const UserID = sessionStorage.getItem("RegUserId")
    ? JSON.parse(sessionStorage.getItem("RegUserId"))
    : sessionStorage.getItem("UserID");

  const mobile = sessionStorage.getItem("RegMobileNum")
    ? sessionStorage.getItem("RegMobileNum")
    : sessionStorage.getItem("UserMOBILE");
  const [formData, setFormData] = useState({
    USER_PHOTO: "",
    FIRST_NAME: "",
    LAST_NAME: "",
    MOBILE: mobile,
    DATE_OF_BIRTH: "",
    AGE: "",
    EMAIL: "",
    ADDRESS1: "",
    GENDER: "",
    TITLE: "Dr",
    CREATED_BY: UserID,
  });

  const navigate = useNavigate();

  const publicKey = sessionStorage.getItem("RegpublicKey")
    ? sessionStorage.getItem("RegpublicKey")
    : JSON.parse(sessionStorage.getItem("publicKey"));
  const token = sessionStorage.getItem("Regtoken")
    ? sessionStorage.getItem("Regtoken")
    : JSON.parse(sessionStorage.getItem("token"));

  const padding = constants.RSA_PKCS1_PADDING;
  const data = Buffer.from(UserID.toString(), "utf-8");
  const encrypted = publicEncrypt(
    { key: publicKey, padding: padding },
    data
  ).toString("base64");

  const fileInputRef = useRef(null);

  useEffect(() => {
    const getUserData = async () => {
      try {
        const response = await Axios.get(
          `${Config.base_url}/GETUSERDATA/${UserID}`,
          {
            headers: {
              authorization: `Bearer ${token}`,
              "x-caller": await encrypted,
            },
          }
        );

        const configuration = await Axios.get(
          `${Config.apiURL}/config/getconfiguration/${UserID}`,
          {
            headers: {
              "content-type": "application/json",
              authorization: `Bearer ${token}`,
              "x-caller": await encrypted,
            },
          }
        );

        if (response.status === 200) {
          const extractData = response.data.recordset;

          // console.log(response.data.recordsets[0][0]);
          setDetails(response.data.recordsets[0][0]);
          setFormData({
            FIRST_NAME: response.data.recordsets[0][0].FIRST_NAME || "",
            LAST_NAME: response.data.recordsets[0][0].LAST_NAME || "",
            MOBILE: response.data.recordsets[0][0].MOBILE || "",
            DATE_OF_BIRTH: response.data.recordsets[0][0].DATE_OF_BIRTH || "",
            AGE: response.data.recordsets[0][0].age || "",
            EMAIL: response.data.recordsets[0][0].EMAIL || "",
            ADDRESS1: response.data.recordsets[0][0].ADDRESS1 || "",
            GENDER: response.data.recordsets[0][0].GENDER || "",
            TITLE: response.data.recordsets[0][0].TITLE || "Dr",
            CREATED_BY: response.data.recordsets[0][0].CREATED_BY || UserID,
            USER_PHOTO: response.data.recordsets[0][0].USER_PHOTO || "",
          });

          setSelectedDate(
            // formatDate
            response.data.recordsets[0][0].DATE_OF_BIRTH
          );
          localStorage.setItem("role", extractData[0].ROLE.toString());
          localStorage.setItem("firstName", extractData[0].FIRST_NAME);
          localStorage.setItem("lastName", extractData[0].LAST_NAME);
          localStorage.setItem("email", extractData[0].EMAIL);
          localStorage.setItem("title", extractData[0].TITLE);
          localStorage.setItem(
            "hospitalSetup",
            extractData[0].HOSPITAL_SETUP || 0
          );
          localStorage.setItem(
            "hprAddress",
            extractData[0].ABHA_HPR_ADDRESS || ""
          );
        }

        if (configuration.status === 200) {
          // console.log("coming inside ");
          // console.log("configuration", configuration);
          const configure = configuration.data.data;

          localStorage.setItem("clientSecret", configure[0].CLIENT_SECRET);
          localStorage.setItem("apiUrl", configure[0].API_URL);
          localStorage.setItem("baseUrl", configure[0].BASE_URL || "");
          localStorage.setItem("gatewayUrl", configure[0].GATEWAY_URL || "");
          localStorage.setItem("sessionUrl", configure[0].SESSIONS_URL || "");
        }

        localStorage.removeItem("doctorName");
        localStorage.removeItem("hospitalNameValue");
        localStorage.removeItem("profileDetails");
        localStorage.removeItem("profileVerification");
      } catch (error) {
        console.error("Error:", error);
      }
    };
    getUserData();
  }, [isEditMode]);

  useEffect(() => {
    const defaultHospital = async () => {
      const UserID = sessionStorage.getItem("RegUserId")
        ? JSON.parse(sessionStorage.getItem("RegUserId"))
        : sessionStorage.getItem("UserID");

      const authentication = "Basic " + btoa("mpoweruser:Cl!nA11y123!@#");
      var options = {
        method: "GET",
        url: `${Config.base_url}getDefaultHospital/${UserID}`,
        headers: {
          authorization: authentication,
          "Content-Type": "application/json",
          "x-caller": encrypted,
        },
      };

      Axios.request(options)
        .then(function (response) {
          console.log("setHospitalList", response.data);
          const hospitalName =
            response.data.recordset?.[0]?.HOSPITAL_NAME || "";
          setHospitalName(hospitalName);
        })
        .catch(function (error) {
          console.error(error);
        });
    };

    defaultHospital();
  }, []);

  const CheckStatusCount = () => {
    const numberOfFields = Object.keys(formData).length;
    const percentageIncrement = 100 / numberOfFields;
    let newTotalCount = 0;

    Object.keys(formData).forEach((key) => {
      if (formData[key] === "") {
        // console.log(
        //   `The field ${key} is empty, percentage: ${percentageIncrement}`
        // );
      } else {
        newTotalCount += percentageIncrement;
        // console.log(
        //   `The field ${key} is not empty, value: ${formData[key]} ${newTotalCount}`
        // );
      }
    });

    ProfileDetailsCount(newTotalCount, "ProfileDetailsCount");

    // setTotalCount(newTotalCount);
    //console.log(newTotalCount, "totalCount");
  };

  useEffect(() => {
    if (Object.keys(formData).length > 0) {
      CheckStatusCount();
    }
  }, [formData]);
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = () => {
        setFile(reader.result); // Set the base64 data URL to the state variable
      };
      reader.readAsDataURL(selectedFile);
    }

    setSelectedFile(selectedFile);
  };

  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };

  const handleDateChange = (birthYear) => {
    if (!isNaN(birthYear) && birthYear !== "") {
      const today = dayjs();
      const birthdate = today.subtract(birthYear, "year");
      const formattedDate = birthdate.format("MM-DD-YYYY");

      setFormData({
        ...formData,
        DATE_OF_BIRTH: formattedDate,
        AGE: birthYear,
      });
    } else {
      setFormData({
        ...formData,
        DATE_OF_BIRTH: "",
        AGE: "",
      });
    }
  };

  const handleEditClick = () => {
    setIsEditMode(!isEditMode);
  };

  const handleMobileChange = (e) => {
    const { value } = e.target;
    if (value && /^\d{0,10}$/.test(value)) {
      setFormData({
        ...formData,
        MOBILE: value,
      });
    }
  };

  const handleFirstNameChange = (e) => {
    const { value } = e.target;
    const validInputPattern = /^[a-zA-Z\s]*$/;

    if (validInputPattern.test(value)) {
      setError("");
      setFormData((prevFormData) => ({
        ...prevFormData,
        FIRST_NAME: value,
      }));
    } else {
      setError("First name can only contain letters ");
    }
  };

  const handleLastNameChange = (e) => {
    const { value } = e.target;
    const validInputPattern = /^[a-zA-Z\s]*$/;

    if (validInputPattern.test(value)) {
      setError("");
      setFormData((prevFormData) => ({
        ...prevFormData,
        LAST_NAME: value,
      }));
    } else {
      setError("Last name can only contain letters ");
    }
  };

  const handleEmailChange = (e) => {
    const { value } = e.target;
    const emailvalidation = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if (emailvalidation.test(value)) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        EMAIL: value,
      }));
    }
  };

  const handleAddressChange = (e) => {
    const { value } = e.target;
    if (value) {
      console.log("Valid input for ADDRESS1, updating state");
      setFormData({
        ...formData,
        ADDRESS1: value,
      });
    }
  };

  const setUserProfile = async () => {
    try {
      let formData1 = new FormData();
      formData1.append("picture", selectedfile);
      formData1.append("data", JSON.stringify(formData));

      console.log("formdata----------", formData);

      var options = {
        method: "POST",
        url: `${Config.base_url}USER_PROFILE`,
        headers: {
          authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
          "x-caller": encrypted,
        },
        data: formData1,
      };

      Axios(options)
        .then(function (response) {
          if (response.status === 201) {
            //console.log(response.data);
            toast.success("Your Profile Edited Successfully");
            setIsEditMode(true);
            // setFile(null)
          } else if (response.status === 500) {
            toast.error("Please upload only png, jpg,jpeg files");
          } else if (response.status === 404) {
            //console.log(response.data);
            toast.warning("Sorry, Data is not submitted. Please submit again.");
          } else {
            //console.log(response.data);
            toast.warning("Error: An unknown error occurred.");
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
    setEditStatus({
      firstName: false,
      lastName: false,
      mobileNumber: false,
      email: false,
      address: false,
    });
  };

  const handleChangeProfileClick = () => {
    fileInputRef.current.click();
  };

  const handleDeleteProfileClick = async () => {
    try {
      console.log(UserID.toString());
      console.log(deleteReason.toString());
      const options = {
        method: "POST",
        url: `${Config.base_url}deleteAccount`,
        headers: {
          authorization: `Bearer ${token}`,
          "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          "x-caller": await encrypted,
        },
        data: {
          user_id: UserID.toString(),
          comment: deleteReason.toString(),
        },
      };
      const response = await Axios(options);
      console.log(response.data, "data-----");
      if (response.status === 201) {
        toast.success("Successfully Account Deleted");
        setTimeout(() => navigate("/login"), 2000);
      }
    } catch (error) {
      console.error(
        "An Error Occurred",
        "Please try again after sometime...",
        error
      );
    }
  };

  return (
    <>
      <div className="profileRightContainer">
        <div className="leftContainer" style={{ paddingLeft: "7%" }}>
          <div className="topContainer">
            <div className="pi-Image">
              {details.USER_PHOTO ? (
                <img
                  src={file ? file : `${Config.apiURL}${details.USER_PHOTO}`}
                  alt="user"
                  className="pi-image-src"
                  style={{
                    width: "200px",
                    height: "200px",
                    borderRadius: "100px",
                  }}
                  crossOrigin="true"
                />
              ) : (
                <img
                  className="Profile-Image"
                  src={profileAlt}
                  alt="profile photo"
                />
              )}
              <input
                type="file"
                ref={fileInputRef}
                accept=".jpg, .jpeg, .png"
                multiple={false}
                id="uploadImage"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              <label className="pi-image-label" htmlFor="uploadImage">
                <img className="" src={profileCamera} alt="" />
              </label>
            </div>
            <div>
              <button
                onClick={handleChangeProfileClick}
                className="change-profile-prev-button"
              >
                Change Profile
              </button>
              <button
                onClick={handleDeleteClick}
                className="change-profile-next-button"
              >
                Delete Profile
              </button>
              {showModal && (
                <div style={modalOverlayStyle}>
                  <div style={modalStyle}>
                    <h2
                      style={{
                        backgroundColor: "#E3EDFF",
                        padding: "10px",
                        width: "100%",
                      }}
                    >
                      Delete Account
                    </h2>
                    <p
                      style={{
                        color: "grey",
                        fontSize: "16px",
                        padding: "10px",
                      }}
                    >
                      Kindly note that the deletion of your account will result
                      in the permanent removal of all associated data, thereby
                      irreversibly discounting your access to our services.
                    </p>
                    <p style={{ fontSize: "16px", padding: "10px" }}>
                      You cannot UNDO this action
                    </p>
                    <p style={{ fontSize: "16px", padding: "10px" }}>
                      Reason for deleting account in mPOWER HEALTH
                    </p>
                    <input
                      type="text"
                      placeholder="Comment"
                      value={deleteReason}
                      onChange={(e) => setDeleteReason(e.target.value)}
                      style={inputStyle}
                    />
                    <div style={buttonContainerStyle}>
                      <button
                        style={confirmDeleteButtonStyle}
                        onClick={handleDeleteProfileClick}
                      >
                        Delete
                      </button>
                      <button
                        style={cancelButtonStyle}
                        onClick={handleCloseModal}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="personal-info-section info-space">
            <img
              className="vector-profile-img"
              src={vectorProfile}
              alt="vector-profile-img"
            />
            <p className="personal-info">Personal Information</p>
          </div>
          <div style={{display:"flex",marginBottom:"20px"}} className="input-fields-container">
            <div className="personnal-info-form-section">
              <label htmlFor="form-first-name" className="name-description">
                First Name
              </label>
              <div className="xyz">
                <input
                  type="text"
                  value={formData.FIRST_NAME}
                  onChange={handleFirstNameChange}
                  readOnly={!editStatus["firstName"]}
                  name="firstName"
                  className={`profile-input-text ${
                    editStatus["firstName"] ? "active" : ""
                  }`}
                />
                <img
                  className="edit-icon"
                  src={Edit1}
                  alt="edit1"
                  onClick={toggleEditMode}
                  data-field="firstName"
                />
              </div>
              {error.firstName && (
                <p style={{ color: "red" }}>{error.firstName}</p>
              )}
            </div>
            <div className="personnal-info-form-section">
              <label htmlFor="lastName" className="name-description">
                Last Name
              </label>
              <div className="xyz">
                <input
                  type="text"
                  value={formData.LAST_NAME}
                  onChange={handleLastNameChange}
                  readOnly={!editStatus["lastName"]}
                  name="lastName"
                  className={`profile-input-text ${
                    editStatus["lastName"] ? "active" : ""
                  }`}
                />
                <img
                  className="edit-icon"
                  src={Edit1}
                  alt="edit1"
                  onClick={toggleEditMode}
                  data-field="lastName"
                />
              </div>
              {error.lastName && (
                <p style={{ color: "red" }}>{error.lastName}</p>
              )}
            </div>
          </div>
          <div className="input-feilds-container">
            <div className="personnal-info-form-section">
              <label htmlFor="mobileNumber" className="name-description">
                Mobile Number
              </label>
              <div className="xyz">
                <input
                  type="text"
                  value={formData.MOBILE}
                  onChange={handleMobileChange}
                  name="mobileNumber"
                  readOnly={!editStatus["mobileNumber"]}
                  className={`profile-input-text ${
                    editStatus["mobileNumber"] ? "active" : ""
                  }`}
                />
                <img
                  className="edit-icon"
                  src={Edit1}
                  alt="edit1"
                  onClick={toggleEditMode}
                  data-field="mobileNumber"
                />
              </div>
            </div>
            <div className="personnal-info-form-section">
              <label htmlFor="email" className="name-description">
                Email
              </label>
              <div className="xyz">
                <input
                  type="email"
                  value={formData.EMAIL}
                  onChange={handleEmailChange}
                  readOnly={!editStatus["email"]}
                  name="email"
                  className={`profile-input-text ${
                    editStatus["email"] ? "active" : ""
                  }`}
                />
                <img
                  className="edit-icon"
                  src={Edit1}
                  alt="edit1"
                  onClick={toggleEditMode}
                  data-field="email"
                />
              </div>
            </div>
          </div>
          <div className="input-feilds-containeraddress">
            <div className="personnal-info-form-section profile-wide">
              <label htmlFor="hospitalName" className="name-description">
                Default Hospital
              </label>
              <div className="xyz">
                <div
                  style={{ display: "flex", alignItems: "center" }}
                  id="hospitalName"
                  className="profile-input-text profile-wide read-only-field"
                >
                  {hospitalName}
                </div>
              </div>
            </div>
            <div className="personnal-info-form-section profile-wide">
              <label htmlFor="address" className="name-description">
                Address
              </label>
              <div className="xyz">
                <input
                  type="text"
                  defaultValue={formData.ADDRESS1}
                  readOnly={!editStatus["address"]}
                  onChange={handleAddressChange}
                  name="address"
                  className={`profile-input-text profile-wide ${
                    editStatus["address"] ? "active" : ""
                  }`}
                />
                <img
                  className="edit-icon"
                  src={Edit1}
                  alt="edit1"
                  onClick={toggleEditMode}
                  data-field="address"
                />
              </div>
            </div>
          </div>
          <div style={{ width: "fitContent" }}>
            <p
              onClick={setUserProfile}
              className={"editButton2"}
              style={{ color: "white", marginTop: "8px" }}
            >
              Submit
            </p>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
};
export default PersonalInfo;
