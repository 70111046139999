import React, { useState } from "react";
import Logo from "../images/logo.png";
import "./Login.css";
// import "./Sample.css";
import aiimsLogo from "../images/aiims_logo.png";
import ccdcLogo from "../images/CCDC-new-logo-1-1.png";
import Computer from "../images/computer.gif";
import Doctor from "../images/loginDoctorLogo.png";
import { useNavigate } from "react-router-dom";
import { auth } from "../components/firebase/firebase.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  signInWithPopup,
  signOut,
  signInWithPhoneNumber,
  RecaptchaVerifier,
} from "firebase/auth";

const ResetPassword = () => {
  {
    /*To Determine the State of Login and Register */
  }
  const [users, setUsers] = useState({
    phoneNumber: "",
    password: "",
    rePassword: "",
  });
  const [isPass, setIsPass] = useState(false);
  // const [phone, setPhone] = useState("");
  const [hasFilled, setHasFilled] = useState(false);
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();

  const INTEGER_SIX_DIGITS_REGEX = /^\d{0,6}$/;

  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(auth, "recaptcha", {
      size: "invisible",
      callback: (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        // ...
      },
    });
  };

  //console.log(users.phoneNumber);

  const handleSend = (event) => {
    if (users.phoneNumber.length === 10) {

      event.preventDefault();
      setHasFilled(true);
      generateRecaptcha();
      const phone = "+91" + users.phoneNumber;
      let appVerifier = window.recaptchaVerifier;
      signInWithPhoneNumber(auth, phone, appVerifier)
        .then((confirmationResult) => {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          window.confirmationResult = confirmationResult;
          //console.log(confirmationResult);
        })
        .catch((error) => {
          // Error; SMS not sent
          console.log(error);
        });
      
    } else {
      toast.error("Enter valid phone number");
    }

  };

  const handleResend = (event) => {
    event.preventDefault();
    // generateRecaptcha();
    const phone = "+91" + users.phoneNumber;
    let appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, phone, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        //console.log(confirmationResult);
      })
      .catch((error) => {
        // Error; SMS not sent
        console.log(error);
      });
  };

  const verifyOtp = (e) => {
    e.preventDefault();
    if (otp.length === 6) {
      let confirmationResult = window.confirmationResult;
      confirmationResult
        .confirm(otp)
        .then((result) => {
          // let user = result.user;
          setIsPass(true);
          // alert("Password Updated Successfully");
        })
        .catch((error) => {
          console.log(error);
          toast.error('Please enter a valid OTP.');
        });
    } else {
      toast.error('Please enter a valid 6-digit OTP.');
    }
  };

  const handleSubmitPassword = () => {
    if (users.password === users.rePassword) {
      console.log(users.password,users.rePassword,"passwordCorrection");
      navigate("/login");
    } else {
      toast.error('Passwords do not match. Please enter the same password.');
    }
  };

  const handleClick = () => {
    navigate("/login");
  };

  //console.log("Hello world", isPass);
  //console.log("isPass>>", isPass);
  return (
    <div className="login">
      {/* <img src={Doctor} className="doctor-image" alt="" /> */}
      <div className="heade">
        <div className="logo-container-password">
          <img
            src={Logo}
            className="clinally-logo"
            alt=""
            style={{ width: "6rem", cursor: "pointer" }}
            onClick={handleClick}
          />

          <p className="clinallytext">CLINALLY mPOWER HEALTH</p>
        </div>
        <div className="heading"></div>
      </div>
      <div className="body">
        <div className="image-container-password">
          <div className="clinallyPragposition">
            Clinally mPower Health is designed to assist and support doctors to
            provide best possible care to their patients along with managing the
            patient's visit records. It has a proprietary AI based clinical
            decision support system to provide recommendations based clinical
            parameters of the patinets.
          </div>
          <img src={Computer} className="computer-image" alt="" />
        </div>
        <div>
          <h1 className="Resetpasstext">Reset Password</h1>
          <form action="">
            {!hasFilled && (
              <input
                type="text"
                name="phone"
                className="input"
                value={users.phoneNumber}
                onChange={(e) =>
                  setUsers((prev) => ({
                    ...prev,
                    phoneNumber: e.target.value,
                  }))
                }
                placeholder="Enter Mobile Number"
                required
              />
            )}

            {hasFilled && !isPass && (
              <>
                <input
                  type="text"
                  className="input"
                  value={otp}
                  onChange={(e) => {
                    if( !INTEGER_SIX_DIGITS_REGEX.test(e.target.value) ) {
                      return;
                    }
                    setOtp(e.target.value);
                  }}
                  placeholder="Enter OTP"
                  required
                />
                <button className="send-otp" type="button" onClick={verifyOtp}>
                  Verify OTP
                </button>
              </>
            )}

            {isPass && (
              <>
                <input
                  type="password"
                  placeholder="Password"
                  className="input"
                  value={users.password}
                  onChange={(e) =>
                    setUsers((prev) => ({
                      ...prev,
                      password: e.target.value,
                    }))
                  }
                  required
                />
                <input
                  type="password"
                  placeholder="Re-Enter Password"
                  className="input"
                  value={users.rePassword}
                  onChange={(e) =>
                    setUsers((prev) => ({
                      ...prev,
                      rePassword: e.target.value,
                    }))
                  }
                  required
                />
                <button
                  className="send-otp"
                  type="button"
                  onClick={handleSubmitPassword}
                >
                  Update Password
                </button>
              </>
            )}

            {!hasFilled && (
              <button
                className="send-otp"
                type="button"
                onClick={handleSend}
              >
                Send OTP
              </button>
            )}

            {hasFilled && !isPass && (
              <button
                className="resend-otp"
                type="button"
                onClick={handleResend}
              >
                Resend OTP
              </button>
            )}
          </form>
          <div className="resetpasscontainer">
            {/* <p> powered by</p> */}
            {/* <img src={Logo} className="logo" alt="" width="50%" /> */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                // justifyContent: "space-between",
                gap: "1rem",
              }}
            >
              <h2 style={{ textAlign: "center" }}>
                CDSS Developed in Collaboration
              </h2>
              <div className="AIIMSCDCCImgposition">
                <div className="AIIMSCDCCImgGap">
                  <img style={{ height: "8vh" }} src={aiimsLogo} />
                  <label className="About" style={{ fontSize: "18px" }}>
                    AIIMS
                  </label>
                </div>
                <div className="cdccimgtext">
                  <img
                    style={{ height: "8vh", width: "100%" }}
                    src={ccdcLogo}
                  />
                  <label className="About" style={{ fontSize: "18px" }}>
                    CCDC
                  </label>
                </div>
              </div>

              {/* <p style={{ fontSize: "16px", textAlign: "center" }}>
                By continuing you agree to our <a href=""> Terms of service </a>{" "}
                and <a href="">Privacy policy</a>
              </p> */}
            </div>
          </div>
        </div>
      </div>
      <div id="recaptcha"></div>
      <ToastContainer position="top-right" />
    </div>
  );
};

export default ResetPassword;
