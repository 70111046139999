// import React, { useEffect, useState, } from "react";
// import Navbar from "../Navbar";
// import { useNavigate } from "react-router-dom";
// import BackArrow from "../../../images/Vector 34.svg";
// // import "echarts/lib/component/tooltip";
// import { publicEncrypt, constants } from "crypto-browserify";
// import { Buffer } from "buffer";
// import Config from "../../../Config/config";
// import axios from "axios";
// import './ConsolidatedReport.css';
// import html2PDF from 'jspdf-html2canvas';

// const ConsolidatedReport = () => {
//     const [visitData, setVisitData] = useState(null);
//     // const [parsedData, setParsedData] = useState([]);
//     const [pages, setPages] = useState(null);
//     const navigate = useNavigate();

//     const UserID = sessionStorage.getItem("RegUserId")
//         ? JSON.parse(sessionStorage.getItem("RegUserId"))
//         : sessionStorage.getItem("UserID");

//     const publicKey = sessionStorage.getItem("RegpublicKey")
//         ? sessionStorage.getItem("RegpublicKey")
//         : JSON.parse(sessionStorage.getItem("publicKey"));
//     const token = sessionStorage.getItem("Regtoken")
//         ? sessionStorage.getItem("Regtoken")
//         : JSON.parse(sessionStorage.getItem("token"));

//     const padding = constants.RSA_PKCS1_PADDING;
//     const data = Buffer.from(UserID.toString(), "utf-8");
//     const encrypted = publicEncrypt(
//         { key: publicKey, padding: padding },
//         data
//     ).toString("base64");

//     const userDetails = JSON.parse(sessionStorage.getItem("UserData"))[0];
//     console.log("User------", userDetails);

//     const patient_id = sessionStorage.getItem("PATIENT_ID");
//     console.log("patient_id", patient_id);

//     const patientDetails = JSON.parse(
//         sessionStorage.getItem("selectedPatientDetails")
//     );
//     console.log("patientDetails", patientDetails.FIRST_NAME);
//     console.log("Patients------", patientDetails);

//     // useEffect(() => {
//     //     let config = {
//     //         method: "get",
//     //         maxBodyLength: Infinity,
//     //         url: `${Config.apiURL}${Config.routes.getPatientsAPI}?userid=${UserID}`,
//     //         headers: {
//     //             authorization: "Basic bXBvd2VydXNlcjpDbCFuQTExeTEyMyFAIw==",
//     //             "content-type": "application/json",
//     //         },
//     //     };

//     //     Axios.request(config)
//     //         .then((response) => {
//     //             const sortedData = response.data.data.sort((a, b) => {
//     //                 return new Date(b.CREATED_DATE) - new Date(a.CREATED_DATE);
//     //             });
//     //             setParsedData(sortedData);
//     //         })
//     //         .catch((error) => {
//     //             console.log(error);
//     //         });
//     // }, []);

//     useEffect(() => {
//         const fetchData = async () => {
//             const UserID = JSON.parse(sessionStorage.getItem("UserID"));
//             const PATIENT_ID = JSON.parse(sessionStorage.getItem("PATIENT_ID"));
//             let data = JSON.stringify({
//                 userId: UserID,
//                 id: PATIENT_ID,
//             });

//             let config = {
//                 method: "post",
//                 maxBodyLength: Infinity,
//                 url: `${Config.apiURL}${Config.routes.getPatientVisitsAPI}`,
//                 headers: {
//                     authorization: `Bearer ${token}`,
//                     "Content-Type": "application/json",
//                     "x-caller": encrypted,
//                 },
//                 data: data,
//             };

//             axios
//                 .request(config)
//                 .then((response) => {
//                     // console.log("visit history-----", response.data.data)
//                     setVisitData(response.data.data); // Assuming you want to set visit data from the first object
//                     console.log("visitData====", visitData)
//                 })
//                 .catch((error) => {
//                     console.log(error);
//                 });
//         };

//         fetchData();
//     }, []);

//     function formatDate(dateString) {
//         const date = new Date(dateString);
//         const day = date.getDate();
//         const month = date.toLocaleString('default', { month: 'short' });
//         const year = date.getFullYear();
//         const formattedDate = `${day}-${month}-${year}`;

//         return formattedDate;
//     }


//     const handleClick = () => {
//         if (!pages) return;
//         html2PDF(pages, {
//             jsPDF: {
//                 format: 'a4',
//             },
//             imageType: 'image/jpeg',
//             output: './pdf/ConsolidatedReport.pdf',
//             html2canvas: {
//                 scrollX: 0,
//                 scrollY: -window.scrollY,
//             },
//             success: function (pdf) {
//                 pdf.save('ConsolidatedReport.pdf');
//             },
//             options: {
//                 jsPDF: {
//                     unit: 'px',
//                     format: 'a4',
//                 },
//                 html2canvas: {
//                     imageTimeout: 15000,
//                     logging: true,
//                     useCORS: false,
//                 },
//                 imageType: 'image/jpeg',
//             },
//         });
//     };

//     // console.log("parsedData=====", parsedData);

//     return (
//         <>
//             <Navbar />
//             <div>
//                 <div className="entireA4sheet">
//                     <div className="A4sheet">
//                         <div>
//                             <div className="patientNameInReport">
//                                 <div className="backButton" onClick={() => navigate("/patients")} >
//                                     <img id="image" src={BackArrow} />
//                                 </div>
//                                 <button className="review">
//                                     <h3>
//                                         {patientDetails && patientDetails.FIRST_NAME
//                                             ? patientDetails.FIRST_NAME.toUpperCase()
//                                             : ""}
//                                         'S READINGS
//                                     </h3>
//                                 </button>
//                             </div>
//                             <div ref={setPages} className="ConsolidatedReport">
//                                 <div className='patientReport'>
//                                     <p id="DoctorName"> Mr {userDetails.FIRST_NAME}{userDetails.LAST_NAME}</p>
//                                     <p id="DoctorPh">Emial: {userDetails.EMAIL} Phone: {userDetails.MOBILE}</p>
//                                     <hr></hr>
//                                     <p id="patientVisitHistory">Visit History</p>
//                                     <div className='patientData1'>
//                                         <div className='patientData2'>
//                                             <p id='patient'>Name: {patientDetails.FIRST_NAME}</p>
//                                             <p id='patient'>MobileNumber: {patientDetails.MOBILE}</p>
//                                             <p id='patient'>Date of Birth:{patientDetails.DATE_OF_BIRTH}</p>
//                                             <p id='patient'>Gender:{patientDetails.GENDER}</p>
//                                         </div>
//                                         <div className='patientData3'>
//                                             <p id='patient'>Visit Date: {formatDate(patientDetails.LAST_VISIT_DATE)}</p>
//                                             <p id='patient'>Patient ID:{patientDetails.PATIENT_ID}</p>
//                                             <p id='patient'>Age:{patientDetails.age}</p>
//                                         </div>
//                                     </div>
//                                     <div>
//                                         <p id="patientVisitHistory">Vitals</p>
//                                         {visitData && visitData.length > 0 ? (
//                                             <div>
//                                                 <table>
//                                                     <thead>
//                                                         <tr>
//                                                             <th>Visit Date</th>
//                                                             <th>BP(mmHg)</th>
//                                                             <th>FBS(mg/dl)</th>
//                                                             <th>RBS(mg/dl)</th>
//                                                             <th>PPBS(mg/dl)</th>
//                                                             <th>HbA1c%</th>
//                                                             <th>BMI(Kg/m²)</th>
//                                                         </tr>
//                                                     </thead>
//                                                     <tbody>
//                                                         {/* {visitData.map((visit, index) => (
//                                                             <tr key={index}>
//                                                                 <td>{visit.VISIT_DATA ? (JSON.parse(visit.VISIT_DATA)?.visitDate ? formatDate(JSON.parse(visit.VISIT_DATA).visitDate) : "") : ""}</td>
//                                                                 <td>{visit.VISIT_DATA ? (JSON.parse(visit.VISIT_DATA)?.examinationHistory ? JSON.parse(JSON.parse(visit.VISIT_DATA).examinationHistory)?.sbp : "0") : ""}/{visit.VISIT_DATA ? (JSON.parse(visit.VISIT_DATA)?.examinationHistory ? JSON.parse(JSON.parse(visit.VISIT_DATA).examinationHistory)?.dbp : "0") : ""}</td>
//                                                                 <td>{visit.VISIT_DATA ? (JSON.parse(visit.VISIT_DATA)?.examinationHistory ? JSON.parse(JSON.parse(visit.VISIT_DATA).examinationHistory)?.fbs : "0") : ""}</td>
//                                                                 <td>{visit.VISIT_DATA ? (JSON.parse(visit.VISIT_DATA)?.examinationHistory ? JSON.parse(JSON.parse(visit.VISIT_DATA).examinationHistory)?.rbs : "0") : ""}</td>
//                                                                 <td>{visit.VISIT_DATA ? (JSON.parse(visit.VISIT_DATA)?.examinationHistory ? JSON.parse(JSON.parse(visit.VISIT_DATA).examinationHistory)?.ppbs : "0") : ""}</td>
//                                                                 <td>{visit.VISIT_DATA ? (JSON.parse(visit.VISIT_DATA)?.examinationHistory ? JSON.parse(JSON.parse(visit.VISIT_DATA).examinationHistory)?.hba1c : "0") : ""}</td>
//                                                                 <td>{visit.VISIT_DATA ? (JSON.parse(visit.VISIT_DATA)?.examinationHistory ? JSON.parse(JSON.parse(visit.VISIT_DATA).examinationHistory)?.bmi : "0") : ""}</td>
//                                                             </tr>
//                                                         ))} */}

//                                                         {visitData.map((visit, index) => (
//                                                             // Check if VISIT_DATA exists before rendering the row
//                                                             visit.VISIT_DATA ? (
//                                                                 <tr key={index}>
//                                                                     <td>{visit.VISIT_DATA && JSON.parse(visit.VISIT_DATA)?.visitDate ? formatDate(JSON.parse(visit.VISIT_DATA).visitDate) : ""}</td>
//                                                                     <td>{visit.VISIT_DATA && JSON.parse(visit.VISIT_DATA)?.examinationHistory ? JSON.parse(JSON.parse(visit.VISIT_DATA).examinationHistory)?.sbp || "0" : "0"}/{visit.VISIT_DATA && JSON.parse(visit.VISIT_DATA)?.examinationHistory ? JSON.parse(JSON.parse(visit.VISIT_DATA).examinationHistory)?.dbp || "0" : "0"}</td>
//                                                                     <td>{visit.VISIT_DATA && JSON.parse(visit.VISIT_DATA)?.examinationHistory ? JSON.parse(JSON.parse(visit.VISIT_DATA).examinationHistory)?.fbs || "0" : "0"}</td>
//                                                                     <td>{visit.VISIT_DATA && JSON.parse(visit.VISIT_DATA)?.examinationHistory ? JSON.parse(JSON.parse(visit.VISIT_DATA).examinationHistory)?.rbs || "0" : "0"}</td>
//                                                                     <td>{visit.VISIT_DATA && JSON.parse(visit.VISIT_DATA)?.examinationHistory ? JSON.parse(JSON.parse(visit.VISIT_DATA).examinationHistory)?.ppbs || "0" : "0"}</td>
//                                                                     <td>{visit.VISIT_DATA && JSON.parse(visit.VISIT_DATA)?.examinationHistory ? JSON.parse(JSON.parse(visit.VISIT_DATA).examinationHistory)?.hba1c || "0" : "0"}</td>
//                                                                     {/* <td>{visit.VISIT_DATA && JSON.parse(visit.VISIT_DATA)?.examinationHistory ? JSON.parse(JSON.parse(visit.VISIT_DATA).examinationHistory)?.bmi || "0" : "0"}</td> */}
//                                                                     {/* <td>{visit.VISIT_DATA && JSON.parse(visit.VISIT_DATA)?.examinationHistory ? (parseFloat(JSON.parse(JSON.parse(visit.VISIT_DATA).examinationHistory)?.bmi).toFixed(2) || "0") : "0"}</td> */}
//                                                                     <td>{visit.VISIT_DATA && JSON.parse(visit.VISIT_DATA)?.examinationHistory ? (!isNaN(parseFloat(JSON.parse(JSON.parse(visit.VISIT_DATA).examinationHistory)?.bmi)) ? parseFloat(JSON.parse(JSON.parse(visit.VISIT_DATA).examinationHistory)?.bmi).toFixed(0) : "0") : "0"}</td>
//                                                                 </tr>
//                                                             ) : null
//                                                         ))}

//                                                     </tbody>
//                                                 </table>

//                                             </div>
//                                         ) : (
//                                             <p>No visit data available</p>
//                                         )}
//                                     </div>
//                                 </div>
//                             </div>
//                             <div><button className="downloadButton" onClick={handleClick}>Download</button></div>
//                         </div>

//                     </div>
//                 </div>
//             </div>
//         </>
//     );
// };

// export default ConsolidatedReport;






import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import { useNavigate } from "react-router-dom";
import BackArrow from "../../../images/leftArrow.svg";
// import "echarts/lib/component/tooltip";
import { publicEncrypt, constants } from "crypto-browserify";
import { Buffer } from "buffer";
import Config from "../../../Config/config";
import logo from "../../../images/logo.png";
import axios from "axios";
import "./ConsolidatedReport.css";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { color } from "echarts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const ConsolidatedReport = () => {
  const [visitData, setVisitData] = useState(null);
  // const [parsedData, setParsedData] = useState([]);
  const [pages, setPages] = useState(null);
  const navigate = useNavigate();

  const UserID = sessionStorage.getItem("RegUserId")
    ? JSON.parse(sessionStorage.getItem("RegUserId"))
    : sessionStorage.getItem("UserID");

  const publicKey = sessionStorage.getItem("RegpublicKey")
    ? sessionStorage.getItem("RegpublicKey")
    : JSON.parse(sessionStorage.getItem("publicKey"));
  const token = sessionStorage.getItem("Regtoken")
    ? sessionStorage.getItem("Regtoken")
    : JSON.parse(sessionStorage.getItem("token"));

  const padding = constants.RSA_PKCS1_PADDING;
  const data = Buffer.from(UserID.toString(), "utf-8");
  const encrypted = publicEncrypt(
    { key: publicKey, padding: padding },
    data
  ).toString("base64");

  const userDetails = JSON.parse(sessionStorage.getItem("UserData"))[0];
  //console.log("User------", userDetails);

  const patient_id = sessionStorage.getItem("PATIENT_ID");
  //console.log("patient_id", patient_id);

  const patientDetails = JSON.parse(
    sessionStorage.getItem("selectedPatientDetails")
  );
  //console.log("patientDetails", patientDetails.FIRST_NAME);
  //console.log("Patients------", patientDetails);

  useEffect(() => {
    const fetchData = async () => {
      const UserID = JSON.parse(sessionStorage.getItem("UserID"));
      const PATIENT_ID = JSON.parse(sessionStorage.getItem("PATIENT_ID"));
      let data = JSON.stringify({
        userId: UserID,
        id: PATIENT_ID,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${Config.apiURL}${Config.routes.getPatientVisitsAPI}`,
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "x-caller": encrypted,
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          // console.log("visit history-----", response.data.data)
          setVisitData(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    fetchData();
  }, []);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;

    return formattedDate;
  }

  const handleClick = () => {
    if (!pages) return;

    const docDefinition = {
      content: [
        {
          text: `Mr ${userDetails.FIRST_NAME}${userDetails.LAST_NAME}`,
          color: "darkviolet",
          bold: true,
          fontSize: 20,
        },
        {
          text: `Email: ${userDetails.EMAIL} Phone: ${userDetails.MOBILE}`,
          color: "darkviolet",
          fontSize: 10,
        },
        {
          canvas: [
            { type: "line", x1: 0, y1: 10, x2: 500, y2: 10, lineWidth: 1 },
          ],
        },
        {
          text: "Visit History",
          fontSize: 16,
          bold: true,
          margin: [0, 0, 0, 10],
          color: "darkviolet",
        },

        {
          table: {
            widths: ["auto", "auto", "auto", "auto"],
            body: [
              [
                {
                  text: "Name:",
                  fillColor: "black",
                  color: "white",
                  bold: true,
                  border: [true, true, true, true],
                  margin: [5, 5, 5, 5],
                },
                {
                  text: `${patientDetails.FIRST_NAME}`,
                  fillColor: "black",
                  color: "white",
                  border: [true, true, true, true],
                  margin: [5, 5, 5, 5],
                },
                {
                  text: "Visit Date:",
                  fillColor: "black",
                  color: "white",
                  bold: true,
                  border: [true, true, true, true],
                  margin: [5, 5, 5, 5],
                },
                {
                  text: `${formatDate(patientDetails.LAST_VISIT_DATE)}`,
                  fillColor: "black",
                  color: "white",
                  border: [true, true, true, true],
                  margin: [5, 5, 5, 5],
                },
              ],
              [
                {
                  text: "Mobile Number:",
                  fillColor: "black",
                  color: "white",
                  bold: true,
                  border: [true, true, true, true],
                  margin: [5, 5, 5, 5],
                },
                {
                  text: `${patientDetails.MOBILE}`,
                  fillColor: "black",
                  color: "white",
                  border: [true, true, true, true],
                  margin: [5, 5, 5, 5],
                },
                {
                  text: "Patient ID:",
                  fillColor: "black",
                  color: "white",
                  bold: true,
                  border: [true, true, true, true],
                  margin: [5, 5, 5, 5],
                },
                {
                  text: `${patientDetails.PATIENT_ID}`,
                  fillColor: "black",
                  color: "white",
                  border: [true, true, true, true],
                  margin: [5, 5, 5, 5],
                },
              ],
              [
                {
                  text: "Date of Birth:",
                  fillColor: "black",
                  color: "white",
                  bold: true,
                  border: [true, true, true, true],
                  margin: [5, 5, 5, 5],
                },
                {
                  text: `${patientDetails.DATE_OF_BIRTH}`,
                  fillColor: "black",
                  color: "white",
                  border: [true, true, true, true],
                  margin: [5, 5, 5, 5],
                },
                {
                  text: "Age:",
                  fillColor: "black",
                  color: "white",
                  bold: true,
                  border: [true, true, true, true],
                  margin: [5, 5, 5, 5],
                },
                {
                  text: `${patientDetails.age}`,
                  fillColor: "black",
                  color: "white",
                  border: [true, true, true, true],
                  margin: [5, 5, 5, 5],
                },
              ],
              [
                {
                  text: "Gender:",
                  fillColor: "black",
                  color: "white",
                  bold: true,
                  border: [true, true, true, true],
                  margin: [5, 5, 5, 5],
                },
                {
                  text: `${patientDetails.GENDER}`,
                  fillColor: "black",
                  color: "white",
                  colSpan: 3,
                  border: [true, true, true, true],
                  margin: [5, 5, 5, 5],
                },
              ],
            ],
          },
        },

        {
          text: "Vitals",
          fontSize: 16,
          bold: true,
          margin: [0, 0, 0, 10],
          color: "darkviolet",
        },
        visitData &&
          visitData.length > 0 && {
            table: {
              headerRows: 1,
              widths: ["auto", "auto", "auto", "auto", "auto", "auto", "auto"],
              body: [
                [
                  "Visit Date",
                  "BP(mmHg)",
                  "FBS(mg/dl)",
                  "RBS(mg/dl)",
                  "PPBS(mg/dl)",
                  "HbA1c%",
                  "BMI(Kg/m²)",
                ],
                ...visitData
                  .filter((visit) => {
                    const visitDate =
                      visit.VISIT_DATA &&
                      JSON.parse(visit.VISIT_DATA)?.visitDate;
                    return visitDate;
                  })
                  .map((visit) => {
                    const visitData = JSON.parse(visit.VISIT_DATA);
                    const examinationHistory = visitData?.examinationHistory
                      ? JSON.parse(visitData.examinationHistory)
                      : {};

                    return [
                      visitData?.visitDate
                        ? formatDate(visitData.visitDate)
                        : "",
                      examinationHistory?.sbp && examinationHistory?.dbp
                        ? `${examinationHistory.sbp || "0"}/${
                            examinationHistory.dbp || "0"
                          }`
                        : "0/0",
                      examinationHistory?.fbs || "0",
                      examinationHistory?.rbs || "0",
                      examinationHistory?.ppbs || "0",
                      examinationHistory?.hba1c || "0",
                      !isNaN(parseFloat(examinationHistory?.bmi))
                        ? parseFloat(examinationHistory.bmi).toFixed(0)
                        : "0",
                    ];
                  }),
              ],
            },
            layout: {
              hLineColor: "fuchsia",
              vLineColor: "fuchsia",
              hLineWidth: function (i, node) {
                return i === 0 || i === node.table.body.length ? 1 : 1;
              },
              vLineWidth: function (i, node) {
                return i === 0 || i === node.table.widths.length ? 1 : 1;
              },
            },
          },
      ],
    };

    pdfMake.createPdf(docDefinition).download("ConsolidatedReport.pdf");
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  return (
    <>
      {/* <Navbar /> */}
      <div>
        <div className="entireA4sheet">
          <div className="A4sheet">
            <div style={{backgroundColor:"white"}}>
              <div className="patientNameInReport">
                <div
                  className="backButton"
                  onClick={() => navigate("/patients")}
                >
                  <img id="image" src={BackArrow} alt="Back" />
                </div>
                <button className="review">
                  <h3>
                    {patientDetails && patientDetails.FIRST_NAME
                      ? patientDetails.FIRST_NAME.toUpperCase()
                      : ""}
                    'S READINGS
                  </h3>
                </button>
              </div>
              <div ref={setPages} className="ConsolidatedReport">
                <div className="patientReport">
                  <p id="DoctorName">
                    {" "}
                    Mr {userDetails.FIRST_NAME}
                    {userDetails.LAST_NAME}
                  </p>
                  <p id="DoctorPh">
                    Email: {userDetails.EMAIL} Phone: {userDetails.MOBILE}
                  </p>
                  <hr></hr>
                  <p id="patientVisitHistory">Visit History</p>
                  <div className="patientData1">
                    <div className="patientData2">
                      <p id="patient">Name: {patientDetails.FIRST_NAME}</p>
                      <p id="patient">MobileNumber: {patientDetails.MOBILE}</p>
                      <p id="patient">
                        Date of Birth:{patientDetails.DATE_OF_BIRTH}
                      </p>
                      <p id="patient">Gender:{patientDetails.GENDER}</p>
                    </div>
                    <div className="patientData3">
                      <p id="patient">
                        Visit Date: {formatDate(patientDetails.LAST_VISIT_DATE)}
                      </p>
                      <p id="patient">Patient ID:{patientDetails.PATIENT_ID}</p>
                      <p id="patient">Age:{patientDetails.age}</p>
                    </div>
                  </div>
                  <div>
                    <p id="patientVisitHistory">Vitals</p>
                    {visitData && visitData.length > 0 ? (
                      <div >
                        <table className="ConsolidateReport">
                          <thead>
                            <tr>
                              <th>Visit Date</th>
                              <th>BP(mmHg)</th>
                              <th>FBS(mg/dl)</th>
                              <th>RBS(mg/dl)</th>
                              <th>PPBS(mg/dl)</th>
                              <th>HbA1c%</th>
                              <th>BMI(Kg/m²)</th>
                            </tr>
                          </thead>
                          <tbody>
                            {visitData.map((visit, index) =>
                              visit.VISIT_DATA ? (
                                <tr key={index}>
                                  <td>
                                    {visit.VISIT_DATA &&
                                    JSON.parse(visit.VISIT_DATA)?.visitDate
                                      ? formatDate(
                                          JSON.parse(visit.VISIT_DATA).visitDate
                                        )
                                      : ""}
                                  </td>
                                  <td>
                                    {visit.VISIT_DATA &&
                                    JSON.parse(visit.VISIT_DATA)
                                      ?.examinationHistory
                                      ? JSON.parse(
                                          JSON.parse(visit.VISIT_DATA)
                                            .examinationHistory
                                        )?.sbp || "0"
                                      : "0"}
                                    /
                                    {visit.VISIT_DATA &&
                                    JSON.parse(visit.VISIT_DATA)
                                      ?.examinationHistory
                                      ? JSON.parse(
                                          JSON.parse(visit.VISIT_DATA)
                                            .examinationHistory
                                        )?.dbp || "0"
                                      : "0"}
                                  </td>
                                  <td>
                                    {visit.VISIT_DATA &&
                                    JSON.parse(visit.VISIT_DATA)
                                      ?.examinationHistory
                                      ? JSON.parse(
                                          JSON.parse(visit.VISIT_DATA)
                                            .examinationHistory
                                        )?.fbs || "0"
                                      : "0"}
                                  </td>
                                  <td>
                                    {visit.VISIT_DATA &&
                                    JSON.parse(visit.VISIT_DATA)
                                      ?.examinationHistory
                                      ? JSON.parse(
                                          JSON.parse(visit.VISIT_DATA)
                                            .examinationHistory
                                        )?.rbs || "0"
                                      : "0"}
                                  </td>
                                  <td>
                                    {visit.VISIT_DATA &&
                                    JSON.parse(visit.VISIT_DATA)
                                      ?.examinationHistory
                                      ? JSON.parse(
                                          JSON.parse(visit.VISIT_DATA)
                                            .examinationHistory
                                        )?.ppbs || "0"
                                      : "0"}
                                  </td>
                                  <td>
                                    {visit.VISIT_DATA &&
                                    JSON.parse(visit.VISIT_DATA)
                                      ?.examinationHistory
                                      ? JSON.parse(
                                          JSON.parse(visit.VISIT_DATA)
                                            .examinationHistory
                                        )?.hba1c || "0"
                                      : "0"}
                                  </td>
                                  {/* <td>{visit.VISIT_DATA && JSON.parse(visit.VISIT_DATA)?.examinationHistory ? JSON.parse(JSON.parse(visit.VISIT_DATA).examinationHistory)?.bmi || "0" : "0"}</td> */}
                                  <td>
                                    {visit.VISIT_DATA &&
                                    JSON.parse(visit.VISIT_DATA)
                                      ?.examinationHistory
                                      ? !isNaN(
                                          parseFloat(
                                            JSON.parse(
                                              JSON.parse(visit.VISIT_DATA)
                                                .examinationHistory
                                            )?.bmi
                                          )
                                        )
                                        ? parseFloat(
                                            JSON.parse(
                                              JSON.parse(visit.VISIT_DATA)
                                                .examinationHistory
                                            )?.bmi
                                          ).toFixed(0)
                                        : "0"
                                      : "0"}
                                  </td>
                                </tr>
                              ) : null
                            )}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <p>No visit data available</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="downloadButtonmain">
                <button className="downloadButton" onClick={handleClick}>
                  Download
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConsolidatedReport;
