import React, { useState, useEffect } from "react";
import { publicEncrypt, constants } from "crypto-browserify";
import { Buffer } from "buffer";
import "../../pages/followUps.css";
import person from "../../assets/profile1.png";
import phone from "../../assets/call-ringing1.png";
import calender from "../../assets/calendar-lines-alt1.png";
import id from "../../assets/id-card-clip1.png";
import "../../pages/Patients.css";
import { useNavigate } from "react-router-dom";
import Config from "../../Config/config.jsx";
import Arrow from "../../images/arrow.svg";
import Lottie from "lottie-react";
import profileLottie from "../../images/mph_registration.json";
import AddDocument from "../../images/addDocument.png";
import Graphs from "../../images/graph.svg";
import InitialAssessmentLottie from "../../images/mph_ia_icon.json";
import ConsolidatedReport from "../../images/consolidatedReport.svg";
import Axios from "axios";
import TablecomponentFollowups from "../../components/TablecomponentFollowups.jsx";
import InitialAssesments from "../../images/initialAssesment.svg";
import ReviewAssessments from "../../images/reviewsnassessments.svg";
import ReviewLottie from "../../images/mph_summary.json";
import PrescriptionLottie from "../../images/mph_files_1.json";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";

export default function Reminders({ datavalue }) {
  console.log(`datavalue`, datavalue);
  // console.log(`datavalue`, datavalue);
  let patientReminders = JSON.stringify(datavalue);

  //console.log(`patientReminders`, JSON.parse(patientReminders)[0]);
  // console.log("type", typeof (datavalue))

  const UserID = sessionStorage.getItem("RegUserId")
    ? JSON.parse(sessionStorage.getItem("RegUserId"))
    : sessionStorage.getItem("UserID");

  const publicKey = sessionStorage.getItem("RegpublicKey")
    ? sessionStorage.getItem("RegpublicKey")
    : JSON.parse(sessionStorage.getItem("publicKey"));
  const token = sessionStorage.getItem("Regtoken")
    ? sessionStorage.getItem("Regtoken")
    : JSON.parse(sessionStorage.getItem("token"));

  const padding = constants.RSA_PKCS1_PADDING;
  const data = Buffer.from(UserID.toString(), "utf-8");
  const encrypted = publicEncrypt(
    { key: publicKey, padding: padding },
    data
  ).toString("base64");

  const [patients, setPatients] = useState([]);
  const navigate = useNavigate();
  const PATIENT_ID = JSON.parse(sessionStorage.getItem("PATIENT_ID"));
  const [visitHistory, setVisitHistory] = useState([]);
  var [selectedPatientHistory, setSelectedPatientHistory] = useState();
  const futureDateAppointment = localStorage.getItem("visit_date"); //selected date
  const [openDialog, setOpenDialog] = useState({
    checkIn: false,
    schedule: false,
    closedRecord: false,
  });
  const [variables, setVariables] = useState({
    selectedPatient: patientReminders ? JSON.parse(patientReminders)[0] : "",
  });
  
  useEffect(() => {
    setSelectedPatientHistory(sessionStorage.getItem("selectedPatientHistory"));
    const patient = sessionStorage.getItem("selectedPatientHistory")!=='undefined'?JSON.parse(
      sessionStorage.getItem("selectedPatientHistory")):[]
    const MedicalHistory =
      patient?.VISIT_DATA && patient.VISIT_DATA
        ? JSON.parse(patient.VISIT_DATA).medicalHistory
        : [];
    sessionStorage.setItem("MedicalHistory", JSON.stringify(MedicalHistory));

    const fetchData = async () => {
      const UserID = JSON.parse(sessionStorage.getItem("UserID"));
      const PATIENT_ID = JSON.parse(sessionStorage.getItem("PATIENT_ID"));
      let data = JSON.stringify({
        userId: UserID,
        id: PATIENT_ID,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        // url: "https://mpowerqa.clinally.com/patient/patientvisits",
        url: `${Config.apiURL}${Config.routes.getPatientVisitsAPI}`,
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "x-caller": encrypted,
        },
        data: data,
      };

      Axios.request(config)
        .then((response) => {
          console.log("respo se in vists", response);
          setVisitHistory(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    fetchData();
  }, [variables.selectedPatient]);

  useEffect(() => {
    setPatients(datavalue);
  });

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    const formattedDate = `${month}-${day}-${year}`;

    return formattedDate;
  }

  const handleNavigate = (id) => {
    const PATIENT_ID = JSON.parse(sessionStorage.getItem("PATIENT_ID"));
    // navigate(`/patients/add-patients?patientId=${PATIENT_ID}`);
  };

  const handleNaviagteToGraphs = (visit) => {
    const patientId = sessionStorage.getItem("PATIENT_ID");
    //console.log("---------", patientId);
    if (patientId !== null) {
      navigate("/patients/graph?source=followups&tab=reminders");
    }
  };

  const handleAddVisit = async () => {
    if (futureDateAppointment !== null) {
      // console.log("coming");
      handleOpenDialog("schedule");
    } else {
      handleOpenDialog("checkIn");
    }
  };

  const handleVisitHistory = (index, visit) => {
    setSelectedPatientHistory(visitHistory.flat()[index]);

    const visitData = visitHistory[index].VISIT_DATA;

    //console.log("visit data", visitData);
    sessionStorage.setItem("VISIT_ID", visit.VISIT_ID);
    sessionStorage.setItem("visitData", JSON.stringify(visitData));

    sessionStorage.setItem(
      "selectedPatientHistory",
      JSON.stringify(visitHistory.flat()[index])
    );
    const patient = JSON.parse(
      sessionStorage.getItem("selectedPatientHistory")
    );

    if (visit.STATUS === 6) {
      setOpenDialog({ ...openDialog, closedRecord: true });
    } else {
      navigate(
        `/followups/testvisits/${
          PATIENT_ID || variables.selectedPatient.USER_ID
        }?source=followups&tab=reminders`
      );
    }
  };

  function formatDate1(dateString) {
    const currentDate = new Date();
    const inputDate = new Date(dateString);

    // Check if the date is in the future
    if (inputDate > currentDate) {
      const options = { day: "2-digit", month: "short", year: "numeric" };
      return new Intl.DateTimeFormat("en-US", options).format(inputDate);
    }

    // Calculate the number of months ago
    const monthsAgo = Math.floor(
      (currentDate - inputDate) / (1000 * 60 * 60 * 24 * 30)
    );

    const options = { day: "2-digit", month: "short", year: "numeric" };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      inputDate
    );

    return `${formattedDate} (${monthsAgo}mon ago)`;
  }

  const handleNaviagteToPrescription = (visit) => {
    // console.log("visit", JSON.stringify(visit.VISIT_DATA));
    sessionStorage.setItem("visitData", JSON.stringify(visit.VISIT_DATA));
    const patientId = sessionStorage.getItem("PATIENT_ID");
    sessionStorage.setItem("VISIT_ID", visit.VISIT_ID);
    //console.log("---------", patientId);
    if (patientId !== null) {
      navigate("/patients/reviewprescription");
    }
  };

  const handleOpenDialog = (type) => {
    // console.log("coming handleOpenDialog");
    setOpenDialog({ ...openDialog, [type]: true });
  };

  const handlePatientClick = (index, patientData) => {
    //console.log("patientData", patientData);
    setVariables({
      ...variables,
      selectedPatient: patients[index],
    });
    sessionStorage.setItem(
      "PATIENT_ID",
      JSON.stringify(patients.flat()[index].USER_ID)
    );
    sessionStorage.setItem(
      "selectedPatientDetails",
      JSON.stringify(patientData)
    );
    sessionStorage.removeItem("htResult");
    sessionStorage.removeItem("dbResult");
  };

  const statusString = [
    "Add Details",
    "Checked In",
    "Screening",
    "Examination",
    "Analysis",
    "Prescription",
    "Closed",
    "Followup",
  ];

  const handleNavigatetoVisits = () => {
    navigate(
      `/followups/testvisits/${
        PATIENT_ID || variables.selectedPatient.USER_ID
      }?source=followups&tab=reminders`
    );
  };
 
  // new code
  const patientsProps = patients.map((patient) => {
    // console.log(patient, "Patients data");
    const date = new Date(patient.NEXT_VISIT_DATE);

    const options = { day: "2-digit", month: "short", year: "numeric" };
    const formattedDate = date
      .toLocaleDateString("en-GB", options)
      .replace(/ /g, " ");

    const abhaNumber = patient.ABHA_NUMBER
      ? patient.ABHA_NUMBER.replace(/-/g, "")
      : "";

   const lastVisitDate = patient.LAST_VISIT_DATE ? formatDate(patient.LAST_VISIT_DATE):"";

    return {
      profile: <div className="FollowupProfile"><p>{`${patient.FIRST_NAME.charAt(0)} ${patient.LAST_NAME.charAt(0)}`}</p></div>,
      name: `${patient.FIRST_NAME} ${patient.LAST_NAME}`,
      gender: `${patient.GENDER}`,
      age: `${patient.age}`,
      mobile: `${patient.MOBILE}`,
      visitdate: lastVisitDate,
      patientId:`${patient.PATIENT_ID}`,
    };
  });

  const columns = [
    {label:"Profile", field:"profile"},
    { label: "Name", field: "name" },
    {
      label: "Gender",
      field: "gender",
    },
    { label: "Age", field: "age" },
    { label: "Mobile Number", field: "mobile" },
    {
      label: "Visit Date",
      field: "visitdate",
    },
    {label:"Patient ID", field:"patientId"}
    
  ];

  return (
    <div >
      {/*   <div
        style={{
          width: "64%",
          marginRight: "3%",
          height: "72vh",
          overflow: "scroll",
        }}
      >
        {datavalue &&
          datavalue.map((patient, index) => (
            <div
              key={index}
              className="patientinfo"
              style={{
                backgroundColor: `${
                  variables.selectedPatient &&
                  variables.selectedPatient.USER_ID === patient.USER_ID
                    ? "#F2F7FF" // Highlighted color
                    : "#F9F9F9" // Default color
                }`,
              }}
              onClick={() => handlePatientClick(index, patient)}
            >
              <div className="patienticon">
                {patient.FIRST_NAME.charAt(0)}
                {patient.LAST_NAME.charAt(0)}
              </div>
              <div className="patientname">
                <div style={{ fontSize: "20px", padding: "2%", width: "100%" }}>
                  {patient.FIRST_NAME} {patient.LAST_NAME}
                </div>
                <div>
                  <h6>Weekly Visits</h6>
                </div>
              </div>

              <div className="patientinfo2">
                <div
                  style={{
                    display: "flex",
                    paddingBottom: "1%",
                    paddingTop: "1%",
                    fontSize: "15px",
                  }}
                >
                  <img src={person} alt="person" id="image" />
                  {patient.GENDER}/{patient.age}
                </div>
                <div style={{ display: "flex", fontSize: "15px" }}>
                  <img src={phone} alt="phone" id="image" />
                  {patient.MOBILE}
                </div>
              </div>
              <div className="patientinfo2">
                <div
                  style={{
                    display: "flex",
                    paddingTop: "1%",
                    paddingBottom: "1%",
                    fontSize: "15px",
                  }}
                >
                  <img src={calender} alt="calendar" id="image" />
                  {formatDate(patient.DATE_OF_BIRTH)}
                </div>

                <div style={{ display: "flex", fontSize: "15px" }}>
                  <img src={id} alt="id" id="image" />
                  {patient.PATIENT_ID}
                </div>
              </div>
            </div>
          ))}
      </div>*/}

      <TablecomponentFollowups data={patientsProps} columns={columns}  />
    </div>
  );
}
