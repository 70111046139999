// import React, { useState, useEffect } from "react";
// import "./Diagnosis.css";
// import { useNavigate } from "react-router-dom";
// import BackArrow from "../../../images/Vector 34.svg";
// import RightArrow from "../../../images/Group 36.png";
// import Expand from "../../../images/Group 38.png";
// import DeDown from "../../../images/Group 39.png";
// import { publicEncrypt, constants } from "crypto-browserify";
// import { Buffer } from "buffer";
// import Config from "../../../Config/config";
// import axios from "axios";
// import { Snackbar, Button, CircularProgress, Alert } from "@mui/material";
// import LeftArrow from "../../../images/leftArrow.svg";



// const Accordion = ({
//   isOpen,
//   toggleAccordion,
//   diagnosisTitle,
//   diagnosisResult,
//   controlRecommendation,
//   recommendedAction,
//   notes,
//   onAgree,
//   onDisagree,
//   onChangeReason,
//   htDiagnosis,
//   setButtonState,
// }) => {
//   const [buttonState, setButtonStateLocal] = useState(null);
//   const [reason, setReason] = useState("");

//   const handleButtonClick = (action) => {
//     setButtonStateLocal(action);
//     setButtonState(action); // Notify parent about button state change
//     if (action === "agree") {
//       onAgree();
//     } else if (action === "disagree") {
//       onDisagree();
//     }
//   };

//   const handleReasonChange = (event) => {
//     setReason(event.target.value);
//     onChangeReason(event.target.value);
//   };

//   const visitData = sessionStorage.getItem("visitData");
//   const trialvalue = visitData ? visitData.htDiagnosis?.doctorAcceptance : null;

//   return (
//     <div>
//       <div className="accordion">
//         <div className="accordion-header" onClick={toggleAccordion}>
//           {diagnosisTitle} Diagnosis: {diagnosisResult}
//           {buttonState ? `(${buttonState})` : trialvalue}
//           <div className="expand-icon">
//             <img
//               className="arrowSizeUD"
//               src={isOpen ? Expand : DeDown}
//               alt={isOpen ? "Expand" : "Dedown"}
//             />
//           </div>
//         </div>
//         {isOpen && (
//           <div className="accordion-content">
//             <div className="control statement">
//               {diagnosisTitle} Control/Recommendation:{" "}
//             </div>
//             <div>
//               {controlRecommendation
//                 ? `${controlRecommendation} - ${recommendedAction}`
//                 : "GOOD- NO CHANGES"}
//             </div>
//             <div>Notes : </div>
//             <div id="note">{notes || "NA"}</div>

//             <div>
//               <button
//                 className={
//                   buttonState === "agree" ? "button agree" : "buttondia"
//                 }
//                 onClick={() => handleButtonClick("agree")}
//               >
//                 Agree
//               </button>
//               <button
//                 className={
//                   buttonState === "disagree" ? "button disagree" : "buttondia"
//                 }
//                 onClick={() => handleButtonClick("disagree")}
//               >
//                 Disagree
//               </button>
//             </div>

//             <div>
//               <input
//                 className="inputfield"
//                 type="text"
//                 placeholder="Reason to override suggestions"
//                 value={reason}
//                 onChange={handleReasonChange}
//               />
//             </div>

//             {/* Conditional rendering for Hypertension and Diagnosis tabs */}
//             {buttonState === "agree" && (
//               <div>
//                 <h3>Hypertension Tab</h3>
//                 {/* Your hypertension tab content */}
//               </div>
//             )}

//             {buttonState === "disagree" && (
//               <div>
//                 <h3>Diagnosis Tab</h3>
//                 {/* Your diagnosis tab content */}
//               </div>
//             )}
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };




// const CvdRiskScore = () => {
//   const storedData = sessionStorage.getItem("cvdRiskScoreList");
//   const cvdRiskScoreData = storedData ? JSON.parse(storedData) : [];

//   const riskScore =
//     cvdRiskScoreData.length > 0 &&
//     cvdRiskScoreData[0] !== null &&
//     cvdRiskScoreData[0].cvdScore !== null
//       ? cvdRiskScoreData[0].cvdScore.toFixed(1)
//       : 0;

//   const getColor = (cvdRiskScore) => {
//     if (cvdRiskScore < 5.0) {
//       return "#81ee70";
//     } else if (cvdRiskScore >= 5.0 && cvdRiskScore < 10.0) {
//       return "#F7AB46";
//     } else if (cvdRiskScore >= 10.0 && cvdRiskScore < 20.0) {
//       return "orange";
//     } else if (cvdRiskScore >= 20.0 && cvdRiskScore < 30.0) {
//       return "deepOrange";
//     } else {
//       return "red";
//     }
//   };

//   const backColor = (cvdRiskScore) => {
//     if (cvdRiskScore < 5.0) {
//       return "#81ee70";
//     } else if (cvdRiskScore >= 5.0 && cvdRiskScore < 10.0) {
//       return "#F7AB46";
//     } else if (cvdRiskScore >= 10.0 && cvdRiskScore < 20.0) {
//       return "orange";
//     } else if (cvdRiskScore >= 20.0 && cvdRiskScore < 30.0) {
//       return "deepOrange";
//     } else {
//       return "red";
//     }
//   };

//   const getRiskLabel = (cvdRiskScore) => {
//     if (cvdRiskScore < 5.0) {
//       return "Very Low Risk";
//     } else if (cvdRiskScore >= 5.0 && cvdRiskScore < 10.0) {
//       return "Low Risk";
//     } else if (cvdRiskScore >= 10.0 && cvdRiskScore < 20.0) {
//       return "Medium Risk";
//     } else if (cvdRiskScore >= 20.0 && cvdRiskScore < 30.0) {
//       return "High Risk";
//     } else {
//       return "Very High Risk";
//     }
//   };

//   const counselingTextFunction = (riskScore) => {
//     if (riskScore >= 0 && riskScore < 5.0) {
//       return "Counsel on diet, physical activity, smoking cessation, and avoiding harmful use of alcohol. Consider following up in 12 months.";
//     } else if (riskScore >= 5.0 && riskScore < 10.0) {
//       return `Counsel on diet, physical activity, smoking cessation, and avoiding harmful use of alcohol. Consider following up every 3 months until targets are met, then 6–9 months thereafter.`;
//     } else if (riskScore >= 10.0 && riskScore < 20.0) {
//       return `Counsel on diet, physical activity, smoking cessation, and avoiding harmful use of alcohol. If BP persists as ≥140/90 mmHg consider drugs. Consider following up every 3–6 months.`;
//     } else {
//       return `Counsel on diet, physical activity, smoking cessation, and avoiding harmful use of alcohol. If BP persists as ≥130/80, consider drugs. Give a statin. Consider following up every 3 months. If there is no reduction in cardiovascular risk after six months of follow-up, consider referring to the next level.`;
//     }
//   };

//   return (
//     <div>
//       <div
//         className="score"
//         style={{
//           color: getColor(parseFloat(riskScore)),
//           backgroundColor: backColor(parseFloat(riskScore)),
//         }}
//       >
//         <div className="risk">
//           <div className="cvd">CVDRISKSCORE: {riskScore}%</div>
//           <div>{getRiskLabel(parseFloat(riskScore))}</div>
//         </div>
//       </div>
//       <div
//         id="conditions"
//         className="score"
//         style={{ backgroundColor: backColor(parseFloat(riskScore)) }}
//       >
//         {counselingTextFunction(parseFloat(riskScore))}
//       </div>
//     </div>
//   );
// };

// const Diagnosis = () => {
//   const UserID = sessionStorage.getItem("RegUserId")
//     ? JSON.parse(sessionStorage.getItem("RegUserId"))
//     : sessionStorage.getItem("UserID");

//   const doctorId = sessionStorage.getItem("RegUserId")
//     ? JSON.parse(sessionStorage.getItem("RegUserId"))
//     : JSON.parse(sessionStorage.getItem("UserID"));

//   const publicKey = sessionStorage.getItem("RegpublicKey")
//     ? sessionStorage.getItem("RegpublicKey")
//     : JSON.parse(sessionStorage.getItem("publicKey"));

//   const token = sessionStorage.getItem("Regtoken")
//     ? sessionStorage.getItem("Regtoken")
//     : JSON.parse(sessionStorage.getItem("token"));

//   const padding = constants.RSA_PKCS1_PADDING;
//   const data = Buffer.from(UserID.toString(), "utf-8");
//   const encrypted = publicEncrypt(
//     { key: publicKey, padding: padding },
//     data
//   ).toString("base64");

//   const PATIENT_ID = JSON.parse(sessionStorage.getItem("PATIENT_ID"));
//   // console.log("patieeeeeeeeeee", PATIENT_ID)

//   const selectedPatientHistory = sessionStorage.getItem(
//     "selectedPatientHistory"
//   )
//     ? JSON.parse(sessionStorage.getItem("selectedPatientHistory"))
//     : [];
//   // console.log("selectedPatientHistory----------", selectedPatientHistory.VISIT_DATE)

//   const VISIT_ID = sessionStorage.getItem("VISIT_ID");

//   function formatVisitDate(visitDate) {
//     // Parse the initial visit date string into a Date object
//     const initialDate = new Date(visitDate);

//     // Extract the date and time components
//     const year = initialDate.getFullYear();
//     const month = String(initialDate.getMonth() + 1).padStart(2, "0");
//     const day = String(initialDate.getDate()).padStart(2, "0");
//     const hours = String(initialDate.getHours()).padStart(2, "0");
//     const minutes = String(initialDate.getMinutes()).padStart(2, "0");
//     const seconds = String(initialDate.getSeconds()).padStart(2, "0");
//     const milliseconds = String(initialDate.getMilliseconds()).padStart(5, "0");

//     // Construct the formatted visit date string
//     const formattedVisitDateString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
//     //console.log("======----formated", formattedVisitDateString);

//     return formattedVisitDateString;
//   }

//   const [visitData, setVisitData] = useState();
//   const [htButtonState, setHtButtonState] = useState(null);
//   const [dbButtonState, setDbButtonState] = useState(null);

//   const VISITDATA = {
//     patientId: selectedPatientHistory.PATIENT_ID,
//     mobile: selectedPatientHistory.MOBILE,
//     nurseId: selectedPatientHistory.NURSE_USER_ID,
//     doctorId: selectedPatientHistory.DOCTOR_USER_ID,
//     visitId: selectedPatientHistory.VISIT_ID,
//     visitDate: formatVisitDate(selectedPatientHistory.VISIT_DATE),
//     medicalHistory: {
//       isDiabetes: null,
//       isHypertension: null,
//       isCKD: null,
//       isLiverFailure: null,
//       isCOPD: null,
//       isAsthma: null,
//       isHeartDisease: null,
//       isStroke: null,
//       isPVD: null,
//       isHeartAttack: null,
//       isFamilyHypertension: null,
//       isFamilyDiabetes: null,
//       isFamilyCardioVascular: null,
//       isFamilyOtherDisease: null,
//       cheifComplaint: [],
//       otherChiefComplaint: "",
//     },
//     drugHistory: {
//       isHypertensiveDrugs: null,
//       isDiabeticDrugs: null,
//       isLipidDrugs: null,
//       isAsprin: null,
//       isInsulin: null,
//       currentHTDrugs: [],
//       currentDBDrugs: [],
//       currentInsulin: [],
//       currentAsprin: [],
//       currentLipid: [],
//     },
//     personalHistory: {
//       isPhysicalActivity: null,
//       isSmoking: null,
//       isDrinking: null,
//       drinkingFrequency: null,
//       isFamilyHistoryOfMI: null,
//       isPolyuria: null,
//       isPolydypsia: null,
//       isWeightloss: null,
//       isOnDialysis: null,
//     },
//     examinationHistory: {
//       sbp: null,
//       dbp: null,
//       heartRate: null,
//       sbp2: null,
//       dbp2: null,
//       sbp3: null,
//       dbp3: null,
//       averageSBP: null,
//       averageDBP: null,
//       fbs: null,
//       ppbs: null,
//       rbs: null,
//       hba1c: null,
//       waist: null,
//       height: null,
//       weight: null,
//       bmi: null,
//       ldl: null,
//       creatinine: null,
//       cholestrol: null,
//       isEdema: null,
//       isBreathlessness: null,
//       isChestPain: null,
//       isDeficit: null,
//       isAlteredConciousness: null,
//       isFundus: null,
//       isGeneralizedSeizures: null,
//       gfr: null,
//     },
//     htDiagnosis: {
//       diagnosis: null,
//       control: null,
//       recommendation: null,
//       notes: null,
//       followup: null,
//       doctorAcceptance: null,
//       doctorNotes: null,
//       doctorId: null,
//       systemDecision: null,
//     },
//     dbDiagnosis: {
//       diagnosis: null,
//       control: null,
//       recommendation: null,
//       notes: null,
//       followup: null,
//       doctorAcceptance: null,
//       doctorNotes: null,
//       doctorId: null,
//       systemDecision: null,
//     },
//     copdDiagnosis: [],
//     tbDiagnosis: [],
//     copdHistory: {
//       isCopd: null,
//       isProgressiveDyspnoea: null,
//       isChronicCough: null,
//       isChronicSputum: null,
//       isChestPain: null,
//       isFever: null,
//       isWeightLoss: null,
//       isOrthopnoea: null,
//       isParoxysmalNocturnal: null,
//       isHaemoptysis: null,
//       isSpirometeryTest: null,
//       spirometeryValue: null,
//       isSmoking: null,
//       isBiomassFuel: null,
//       isPollution: null,
//       mmrcGrade: null,
//       isDifficultInBreathing: null,
//       isExacerbation: null,
//       isAdmitHospitalWithExacerbation: null,
//       severeBreathingDifficulty: null,
//       exacerbationSymptoms: [],
//       severeExacerbationSymptoms: [],
//       drugOptions: [],
//     },

//     tbHistory: {
//       confirmedTBType: [],
//       presumptiveTBType: [],
//       suspectedTBSymptoms: [],
//       suspectedComorbidities: [],
//       suspectedOtherTBDrugs: [],
//       tbPreviousDrugList: [],
//       tbCurrentDrugList: [],
//       tbAdditionalDrugList: [],
//     },
//     osteoArthritisHistory: {
//       oaAComorbidList: [],
//       oaPharmaList: [],
//       oaLifestyleList: [],
//       oaNonPharmaList: [],
//     },
//     orderTest: {
//       clinicalPathology: [],
//       radiology: [],
//       bioChemistry: [],
//       stoolAnalysis: [],
//       serology: [],
//       urineAnalysis: [],
//       cardiology: [],
//     },
//   };

//   const [htDiagnosis, setHtDiagnosis] = useState(VISITDATA.htDiagnosis);
//   //   {
//   //   diagnosis: "",
//   //   control: "",
//   //   recommendation: "",
//   //   notes: "",
//   //   followup: "",
//   //   doctorAcceptance: "",
//   //   doctorNotes: "",
//   //   doctorId: "",
//   //   systemDecision: "",
//   // })

//   const [dbDiagnosis, setDbDiagnosis] = useState(VISITDATA.dbDiagnosis);
//   // diagnosis: "",
//   // control: "",
//   // recommendation: "",
//   // notes: "",
//   // followup: "",
//   // doctorAcceptance: "",
//   // doctorNotes: "",
//   // doctorId: "",
//   // systemDecision: "",

//   // });

//   const [isOpenHT, setIsOpenHT] = useState(false);
//   const [isOpenDB, setIsOpenDB] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);
//   const navigation = useNavigate();
//   const htResult = sessionStorage.getItem("htResult");
//   const dbResult = sessionStorage.getItem("dbResult");
//   const [snackbarOpen, setSnackbarOpen] = useState(false);

//   const HtResult = htResult
//     ? JSON.parse(htResult).isHypertension
//       ? "Yes"
//       : "No"
//     : "No";
//   const DbResult = dbResult
//     ? JSON.parse(dbResult).isDiabetes
//       ? "Yes"
//       : "No"
//     : "No";
//   const controlRecommendationHT = htResult
//     ? JSON.parse(htResult).bloodPressureControl
//     : "GOOD";
//   const recommendedActionHT = htResult
//     ? JSON.parse(htResult).recommendedAction
//     : "No Changes";
//   const notesHT = htResult ? JSON.parse(htResult).notes : "NA";

//   const controlRecommendationDB = dbResult
//     ? JSON.parse(dbResult).glycaemicControl
//     : "GOOD";
//   const recommendedActionDB = dbResult
//     ? JSON.parse(dbResult).recommendedAction
//     : "No Changes";
//   const notesDB = dbResult ? JSON.parse(dbResult).notes : "NA";

//   useEffect(() => {
//     const fetchDataAndSetHTDBDiagnosisData = async () => {
//       try {
//         let data = JSON.stringify({
//           userId: UserID,
//           id: PATIENT_ID,
//         });

//         let config = {
//           method: "post",
//           maxBodyLength: Infinity,
//           url: `${Config.apiURL}${Config.routes.getPatientVisitsAPI}`,
//           headers: {
//             authorization: `Bearer ${token}`,
//             "Content-Type": "application/json",
//             "x-caller": encrypted,
//           },
//           data: data,
//         };

//         const response = await axios.request(config);
//         //console.log("Response from ..:", response, response.data.data);

//         const sessionVisitID = parseInt(VISIT_ID, 10);

//         const filteredRecords = response.data.data.filter((record) => {
//           return record.VISIT_ID === sessionVisitID;
//         });
//         //console.log("Filtered Records:", filteredRecords, typeof VISIT_ID);

//         // if (response.data.data === null || response.data.data.length === 0 || response.data.data[0].VISIT_DATA === null) {
//         //   setVisitData(VISITDATA);
//         //   //console.log("VISITDATA",VISITDATA)
//         // }
//         if (filteredRecords[0].VISIT_DATA === null) {
//           setVisitData(VISITDATA);
//           //console.log("VISITDATA", VISITDATA);
//         } else {
//           let res = await filteredRecords[0].VISIT_DATA;
//           //console.log("---res----", res);

//           let parsedRes = !!res ? JSON.parse(res) : null;
//           setVisitData(parsedRes);

//           let htDiagnosisData = res ? JSON.parse(res).htDiagnosis : null;
//           let dbDiagnosisData = res ? JSON.parse(res).dbDiagnosis : null;

//           if (htDiagnosisData !== null) {
//             const parsedHtDiagnosis = JSON.parse(htDiagnosisData);

//             setHtDiagnosis({
//               ...htDiagnosis,
//               diagnosis: parsedHtDiagnosis.diagnosis,
//               control: parsedHtDiagnosis.control,
//               recommendation: parsedHtDiagnosis.recommendation,
//               notes: parsedHtDiagnosis.notes,
//               followup: parsedHtDiagnosis.followup,
//               doctorAcceptance: parsedHtDiagnosis.doctorAcceptance,
//               doctorNotes: parsedHtDiagnosis.doctorNotes,
//               doctorId: parsedHtDiagnosis.doctorId,
//               systemDecision: parsedHtDiagnosis.systemDecision,
//             });
//           }
//           if (dbDiagnosisData !== null) {
//             const parsedDbDiagnosis = JSON.parse(dbDiagnosisData);

//             setDbDiagnosis({
//               ...dbDiagnosis,
//               diagnosis: parsedDbDiagnosis.diagnosis,
//               control: parsedDbDiagnosis.control,
//               recommendation: parsedDbDiagnosis.recommendation,
//               notes: parsedDbDiagnosis.notes,
//               followup: parsedDbDiagnosis.followup,
//               doctorAcceptance: parsedDbDiagnosis.doctorAcceptance,
//               doctorNotes: parsedDbDiagnosis.doctorNotes,
//               doctorId: parsedDbDiagnosis.doctorId,
//               systemDecision: parsedDbDiagnosis.systemDecision,
//             });
//           }
//         }
//       } catch (err) {
//         console.log(err, "err");
//       }
//     };
//     fetchDataAndSetHTDBDiagnosisData();
//   }, [VISIT_ID]);

//   const handleTreatClick = async () => {
//     if (
//       htButtonState !== null &&
//       dbButtonState !== null &&
//       htButtonState !== "" &&
//       dbButtonState !== ""
//     ) {
//       setIsLoading(true);
//       const recordSaved = await saveRecord();
//       if (recordSaved) {
//         setIsLoading(false);
//         navigation("/patients/treatmentplan");
//       } else {
//         // Handle error
//       }
//     } else {
//       setSnackbarOpen(true);
//     }
//   };

//   const handleSnackbarClose = (event, reason) => {
//     if (reason === "clickaway") {
//       return;
//     }

//     setSnackbarOpen(false);
//   };
//   const handleHtReasonChange = (reason) => {
//     setHtDiagnosis((prevState) => ({
//       ...prevState,
//       doctorNotes: reason,
//     }));
//   };

//   const handleDbReasonChange = (reason) => {
//     setDbDiagnosis((prevState) => ({
//       ...prevState,
//       doctorNotes: reason,
//     }));
//   };

//   function replacer(key, value) {
//     try {
//       if (key.trim() == "") return value;

//       // if (key.trim() === "drugHistory") {
//       //   console.log("======================================================")
//       //   let modifiedJsonString = value.replace(/\\"(\d+\.\d+)\\"/g, "$1");
//       //   console.log("===3=====", modifiedJsonString)
//       //   return modifiedJsonString;
//       // }

//       if (value && value !== null && typeof value === "object") {
//         //console.log("value inside if", value);
//         return value.replace(/"/g, '\\"'); // Replace " with \"  }   return value; }
//       }
//       return value;
//     } catch (err) {
//       console.log(
//         "======key===",
//         key,
//         "===========value==",
//         typeof value,
//         "=======",
//         value
//       );
//       // console.log(err);
//       if (Array.isArray(value) && value.length === 0) return value;
//       else if (
//         key.trim() === "tbHistory" ||
//         key.trim() === "osteoArthritisHistory"
//       ) {
//         let jsonString = JSON.stringify(value).replace(/"/g, '\\"');
//         //console.log(jsonString, "1#####");
//         jsonString = `\"${jsonString}\"`;
//         //console.log(jsonString, "2#####");
//         return jsonString;
//       } else {
//         return JSON.stringify(value);
//       }
//     }
//   }

//   const saveRecord = async () => {
//     try {
//       //console.log("****hi", visitData);
//       let existingHtDiagnosis = visitData?.htDiagnosis;

//       if (typeof existingHtDiagnosis === "string") {
//         existingHtDiagnosis = JSON.parse(existingHtDiagnosis);
//       }
//       //console.log("******", typeof existingHtDiagnosis);
//       existingHtDiagnosis.doctorId = doctorId;
//       existingHtDiagnosis.doctorAcceptance = htDiagnosis.doctorAcceptance;
//       // existingHtDiagnosis.doctorNotes = htDiagnosis?.doctorNotes;
//       existingHtDiagnosis.doctorNotes =
//         htDiagnosis && htDiagnosis.doctorNotes ? htDiagnosis.doctorNotes : null;
//       existingHtDiagnosis.diagnosis = htResult
//         ? JSON.parse(htResult).isHypertension
//           ? 1
//           : 0
//         : 0;
//       existingHtDiagnosis.control = htResult
//         ? JSON.parse(htResult)?.bloodPressureControl ?? null
//         : null;
//       // existingHtDiagnosis.recommendedAction = htResult ? JSON.parse(htResult).recommendation ?? null : null;
//       existingHtDiagnosis.notes = htResult
//         ? JSON.parse(htResult).notes ?? null
//         : null;

//       let existingDbDiagnosis = visitData.dbDiagnosis;
//       if (typeof existingDbDiagnosis === "string") {
//         existingDbDiagnosis = JSON.parse(existingDbDiagnosis);
//       }
//       existingDbDiagnosis.diagnosis = dbResult
//         ? JSON.parse(dbResult).isDiabetes
//           ? 1
//           : 0
//         : 0;
//       existingDbDiagnosis.doctorId = doctorId;
//       existingDbDiagnosis.doctorAcceptance = dbDiagnosis.doctorAcceptance;
//       // existingDbDiagnosis.doctorNotes = dbDiagnosis.doctorNotes;
//       existingDbDiagnosis.doctorNotes =
//         dbDiagnosis && dbDiagnosis.doctorNotes ? dbDiagnosis.doctorNotes : null;
//       existingDbDiagnosis.control = dbResult
//         ? JSON.parse(dbResult)?.glycaemicControl ?? null
//         : null;
//       // existingDbDiagnosis.recommendedAction = dbResult ? JSON.parse(dbResult).recommendation ?? null : null;
//       existingDbDiagnosis.notes = dbResult
//         ? JSON.parse(dbResult).notes ?? null
//         : null;

//       visitData.htDiagnosis = existingHtDiagnosis;
//       visitData.dbDiagnosis = existingDbDiagnosis;

//       // console.log("visitdata====", visitData)
//       let VisitData = JSON.stringify(visitData, replacer);
//       // console.log("Stringified VisitData---", VisitData);

//       sessionStorage.setItem("visitData", VisitData);

//       const response = await axios.post(
//         `${Config.base_url}PATIENT_VISIT_DATA`,
//         {
//           USER_ID: UserID,
//           VISIT_ID: visitData?.visitId?.toString(),
//           VISIT_DATA: VisitData,
//           STATUS: 2,
//           PRESCRIPTION_DATA: null,
//           CREATED_BY: visitData?.nurseId?.toString(),
//         },
//         {
//           headers: {
//             authorization: `Bearer ${token}`,
//             "Content-Type": "application/json",
//             "x-caller": encrypted,
//           },
//         }
//       );

//       //console.log("data post", response);
//       if (response.status === 201) {
//         //console.log("coing to if");
//         return true;
//       } else {
//         console.error("Error: Data not saved");
//         return false;
//       }
//     } catch (error) {
//       console.error("Error:", error);
//       return false;
//     }
//   };

//   const toggleAccordionHT = () => {
//     setIsOpenHT(!isOpenHT);
//     setIsOpenDB(false);
//   };

//   const toggleAccordionDB = () => {
//     setIsOpenDB(!isOpenDB);
//     setIsOpenHT(false);
//   };

//   const handleBackbutton = () => {
//     navigation("/patients/reviewprescription");
//   };

//   const handleAgreeDisagreeht = (action) => {
//     setHtDiagnosis((prevState) => ({
//       ...prevState,
//       doctorAcceptance: action,
//     }));
//   };
//   const handleAgreeDisagreedb = (action) => {
//     setDbDiagnosis((prevState) => ({
//       ...prevState,
//       doctorAcceptance: action,
//     }));
//   };

//   return (
//     <div className="diagnosis">
//       <div className="header">
//         <div style={{ marginLeft: "1%" }} onClick={handleBackbutton}>
//           {" "}
//           <img className="image" src={LeftArrow} alt="Logo" />
//         </div>
//         <h1 className="diagnosis-heading">DIAGNOSIS</h1>
//       </div>
//       <div className="dignosis-body">
//         <div className="diagnosis-confirm">
//           <button
//             className="confirm-daignosis"
//             onClick={async () => {
//               await sessionStorage.removeItem("addingDrugList");
//               handleTreatClick("agree");
//             }}
//           >
//             <div className="cbutton">
//               {isLoading
//                 ? "Proceeding to Prescription"
//                 : "Confirm diagnosis and proceed to treatment plan"}
//               {isLoading && <CircularProgress size={24} color="inherit" />}
//               <Snackbar
//                 anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
//                 open={snackbarOpen}
//                 autoHideDuration={6000}
//                 onClose={handleSnackbarClose}
//                 message="Please choose yes / no for recommendations to proceed."
//               />
//             </div>
//           </button>

//           <Accordion
//             isOpen={isOpenHT}
//             toggleAccordion={toggleAccordionHT}
//             diagnosisTitle="Hypertension"
//             diagnosisResult={HtResult}
//             controlRecommendation={controlRecommendationHT}
//             recommendedAction={recommendedActionHT}
//             notes={notesHT}
//             onAgree={() => handleAgreeDisagreeht("0")}
//             onDisagree={() => handleAgreeDisagreeht("1")}
//             onChangeReason={handleHtReasonChange}
//             setButtonState={setHtButtonState} 
//           />

//           <Accordion
//             isOpen={isOpenDB}
//             toggleAccordion={toggleAccordionDB}
//             diagnosisTitle="Diabetes"
//             diagnosisResult={DbResult}
//             controlRecommendation={controlRecommendationDB}
//             recommendedAction={recommendedActionDB}
//             notes={notesDB}
//             onAgree={() => handleAgreeDisagreedb("0")}
//             onDisagree={() => handleAgreeDisagreedb("1")}
//             onChangeReason={handleDbReasonChange}
//             setButtonState={setDbButtonState} 
//           />
//           <CvdRiskScore />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Diagnosis;
































import React, { useState, useEffect } from "react";
import "./Diagnosis.css";
import { useNavigate } from "react-router-dom";
import BackArrow from "../../../images/Vector 34.svg";
import RightArrow from "../../../images/Group 36.png";
import Expand from "../../../images/Group 38.png";
import DeDown from "../../../images/Group 39.png";
import { publicEncrypt, constants } from "crypto-browserify";
import { Buffer } from "buffer";
import Config from "../../../Config/config";
import axios from "axios";
import { Snackbar, Button, CircularProgress, Alert } from "@mui/material";
import LeftArrow from "../../../images/leftArrow.svg";

const Accordion = ({
  isOpen,
  toggleAccordion,
  diagnosisTitle,
  diagnosisResult,
  controlRecommendation,
  recommendedAction,
  notes,
  onAgree,
  onDisagree,
  onChangeReason,
  htDiagnosis,
  setButtonState,
}) => {
  const [buttonState, setButtonStateLocal] = useState(null);
  const [reason, setReason] = useState("");
  const handleButtonClick = (action) => {
    setButtonStateLocal(action);
    if (action === "agree") {
      onAgree(1);
    } else if (action === "disagree") {
      onDisagree(0);
    }
  };
  const handleReasonChange = (event) => {
    setReason(event.target.value);
    onChangeReason(event.target.value);
  };
  const visitData = sessionStorage.getItem("visitData");
  const trialvalue = visitData ? visitData.htDiagnosis?.doctorAcceptance : null;

  return (
    <div>
      <div className="accordion">
      {console.log(diagnosisTitle,"diagnosisTitle",diagnosisResult,"diagnosisResult")}
        <div className="accordion-header" onClick={toggleAccordion}>
          {diagnosisTitle} Diagnosis: {diagnosisResult}
          {console.log(diagnosisResult,"diagnosisResult..........")}
          {buttonState ? `(${buttonState})` : trialvalue}
          <div className="expand-icon">
            <img
              className="arrowSizeUD"
              src={isOpen ? Expand : DeDown}
              alt={isOpen ? "Expand" : "Dedown"}
            />
          </div>
        </div>
        {isOpen && (
          <div className="accordion-content">
            <div>
              <div className="control statement">
                {diagnosisTitle} Control/Recommendation:{" "}
              </div>
              <div>
                {controlRecommendation
                  ? `${controlRecommendation} - ${recommendedAction}`
                  : "GOOD- NO CHANGES"}
              </div>
              <div>Notes : </div>
              <div id="note">{notes || "NA"}</div>
              <div>
                <button
                  className={
                    buttonState === "agree" ? "button agree" : "buttondia"
                  }
                  onClick={() => handleButtonClick("agree")}
                >
                  Agree
                </button>
                <button
                  className={
                    buttonState === "disagree" ? "button disagree" : "buttondia"
                  }
                  onClick={() => handleButtonClick("disagree")}
                >
                  Disagree
                </button>
              </div>
              <div>
                <input
                  className="inputfield"
                  type="text"
                  placeholder="Reason to override suggestions"
                  value={reason}
                  onChange={handleReasonChange}
                />
                {console.log(buttonState,"buttonState")}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const CvdRiskScore = () => {
  const storedData = sessionStorage.getItem("cvdRiskScoreList");
  const cvdRiskScoreData = storedData ? JSON.parse(storedData) : [];

  const riskScore =
    cvdRiskScoreData.length > 0 &&
    cvdRiskScoreData[0] !== null &&
    cvdRiskScoreData[0].cvdScore !== null
      ? cvdRiskScoreData[0].cvdScore.toFixed(1)
      : 0;

  const getColor = (cvdRiskScore) => {
    if (cvdRiskScore < 5.0) {
      return "#81ee70";
    } else if (cvdRiskScore >= 5.0 && cvdRiskScore < 10.0) {
      return "#F7AB46";
    } else if (cvdRiskScore >= 10.0 && cvdRiskScore < 20.0) {
      return "orange";
    } else if (cvdRiskScore >= 20.0 && cvdRiskScore < 30.0) {
      return "deepOrange";
    } else {
      return "red";
    }
  };

  const backColor = (cvdRiskScore) => {
    if (cvdRiskScore < 5.0) {
      return "#81ee70";
    } else if (cvdRiskScore >= 5.0 && cvdRiskScore < 10.0) {
      return "#F7AB46";
    } else if (cvdRiskScore >= 10.0 && cvdRiskScore < 20.0) {
      return "orange";
    } else if (cvdRiskScore >= 20.0 && cvdRiskScore < 30.0) {
      return "deepOrange";
    } else {
      return "red";
    }
  };

  const getRiskLabel = (cvdRiskScore) => {
    if (cvdRiskScore < 5.0) {
      return "Very Low Risk";
    } else if (cvdRiskScore >= 5.0 && cvdRiskScore < 10.0) {
      return "Low Risk";
    } else if (cvdRiskScore >= 10.0 && cvdRiskScore < 20.0) {
      return "Medium Risk";
    } else if (cvdRiskScore >= 20.0 && cvdRiskScore < 30.0) {
      return "High Risk";
    } else {
      return "Very High Risk";
    }
  };

  const counselingTextFunction = (riskScore) => {
    if (riskScore >= 0 && riskScore < 5.0) {
      return "Counsel on diet, physical activity, smoking cessation, and avoiding harmful use of alcohol. Consider following up in 12 months.";
    } else if (riskScore >= 5.0 && riskScore < 10.0) {
      return `Counsel on diet, physical activity, smoking cessation, and avoiding harmful use of alcohol. Consider following up every 3 months until targets are met, then 6–9 months thereafter.`;
    } else if (riskScore >= 10.0 && riskScore < 20.0) {
      return `Counsel on diet, physical activity, smoking cessation, and avoiding harmful use of alcohol. If BP persists as ≥140/90 mmHg consider drugs. Consider following up every 3–6 months.`;
    } else {
      return `Counsel on diet, physical activity, smoking cessation, and avoiding harmful use of alcohol. If BP persists as ≥130/80, consider drugs. Give a statin. Consider following up every 3 months. If there is no reduction in cardiovascular risk after six months of follow-up, consider referring to the next level.`;
    }
  };

  return (
    <div>
      <div
        className="score"
        style={{
          color: getColor(parseFloat(riskScore)),
          backgroundColor: backColor(parseFloat(riskScore)),
        }}
      >
        <div className="risk">
          <div className="cvd">CVDRISKSCORE: {riskScore}%</div>
          <div>{getRiskLabel(parseFloat(riskScore))}</div>
        </div>
      </div>
      <div
        id="conditions"
        className="score"
        style={{ backgroundColor: backColor(parseFloat(riskScore)) }}
      >
        {counselingTextFunction(parseFloat(riskScore))}
      </div>
    </div>
  );
};

const Diagnosis = () => {
  const UserID = sessionStorage.getItem("RegUserId")
    ? JSON.parse(sessionStorage.getItem("RegUserId"))
    : sessionStorage.getItem("UserID");

  const doctorId = sessionStorage.getItem("RegUserId")
    ? JSON.parse(sessionStorage.getItem("RegUserId"))
    : JSON.parse(sessionStorage.getItem("UserID"));

  const publicKey = sessionStorage.getItem("RegpublicKey")
    ? sessionStorage.getItem("RegpublicKey")
    : JSON.parse(sessionStorage.getItem("publicKey"));

  const token = sessionStorage.getItem("Regtoken")
    ? sessionStorage.getItem("Regtoken")
    : JSON.parse(sessionStorage.getItem("token"));

  const padding = constants.RSA_PKCS1_PADDING;
  const data = Buffer.from(UserID.toString(), "utf-8");
  const encrypted = publicEncrypt(
    { key: publicKey, padding: padding },
    data
  ).toString("base64");

  const PATIENT_ID = JSON.parse(sessionStorage.getItem("PATIENT_ID"));
  // console.log("patieeeeeeeeeee", PATIENT_ID)

  const selectedPatientHistory = sessionStorage.getItem(
    "selectedPatientHistory"
  )
    ? JSON.parse(sessionStorage.getItem("selectedPatientHistory"))
    : [];
  // console.log("selectedPatientHistory----------", selectedPatientHistory.VISIT_DATE)

  const VISIT_ID = sessionStorage.getItem("VISIT_ID");

  function formatVisitDate(visitDate) {
    // Parse the initial visit date string into a Date object
    const initialDate = new Date(visitDate);

    // Extract the date and time components
    const year = initialDate.getFullYear();
    const month = String(initialDate.getMonth() + 1).padStart(2, "0");
    const day = String(initialDate.getDate()).padStart(2, "0");
    const hours = String(initialDate.getHours()).padStart(2, "0");
    const minutes = String(initialDate.getMinutes()).padStart(2, "0");
    const seconds = String(initialDate.getSeconds()).padStart(2, "0");
    const milliseconds = String(initialDate.getMilliseconds()).padStart(5, "0");

    // Construct the formatted visit date string
    const formattedVisitDateString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
    //console.log("======----formated", formattedVisitDateString);

    return formattedVisitDateString;
  }

  const [visitData, setVisitData] = useState();
  const [htButtonState, setHtButtonState] = useState(null);
  const [dbButtonState, setDbButtonState] = useState(null);

  const VISITDATA = {
    patientId: selectedPatientHistory.PATIENT_ID,
    mobile: selectedPatientHistory.MOBILE,
    nurseId: selectedPatientHistory.NURSE_USER_ID,
    doctorId: selectedPatientHistory.DOCTOR_USER_ID,
    visitId: selectedPatientHistory.VISIT_ID,
    visitDate: formatVisitDate(selectedPatientHistory.VISIT_DATE),
    medicalHistory: {
      isDiabetes: null,
      isHypertension: null,
      isCKD: null,
      isLiverFailure: null,
      isCOPD: null,
      isAsthma: null,
      isHeartDisease: null,
      isStroke: null,
      isPVD: null,
      isHeartAttack: null,
      isFamilyHypertension: null,
      isFamilyDiabetes: null,
      isFamilyCardioVascular: null,
      isFamilyOtherDisease: null,
      cheifComplaint: [],
      otherChiefComplaint: "",
    },
    drugHistory: {
      isHypertensiveDrugs: null,
      isDiabeticDrugs: null,
      isLipidDrugs: null,
      isAsprin: null,
      isInsulin: null,
      currentHTDrugs: [],
      currentDBDrugs: [],
      currentInsulin: [],
      currentAsprin: [],
      currentLipid: [],
    },
    personalHistory: {
      isPhysicalActivity: null,
      isSmoking: null,
      isDrinking: null,
      drinkingFrequency: null,
      isFamilyHistoryOfMI: null,
      isPolyuria: null,
      isPolydypsia: null,
      isWeightloss: null,
      isOnDialysis: null,
    },
    examinationHistory: {
      sbp: null,
      dbp: null,
      heartRate: null,
      sbp2: null,
      dbp2: null,
      sbp3: null,
      dbp3: null,
      averageSBP: null,
      averageDBP: null,
      fbs: null,
      ppbs: null,
      rbs: null,
      hba1c: null,
      waist: null,
      height: null,
      weight: null,
      bmi: null,
      ldl: null,
      creatinine: null,
      cholestrol: null,
      isEdema: null,
      isBreathlessness: null,
      isChestPain: null,
      isDeficit: null,
      isAlteredConciousness: null,
      isFundus: null,
      isGeneralizedSeizures: null,
      gfr: null,
    },
    htDiagnosis: {
      diagnosis: null,
      control: null,
      recommendation: null,
      notes: null,
      followup: null,
      doctorAcceptance: null,
      doctorNotes: null,
      doctorId: null,
      systemDecision: null,
    },
    dbDiagnosis: {
      diagnosis: null,
      control: null,
      recommendation: null,
      notes: null,
      followup: null,
      doctorAcceptance: null,
      doctorNotes: null,
      doctorId: null,
      systemDecision: null,
    },
    copdDiagnosis: [],
    tbDiagnosis: [],
    copdHistory: {
      isCopd: null,
      isProgressiveDyspnoea: null,
      isChronicCough: null,
      isChronicSputum: null,
      isChestPain: null,
      isFever: null,
      isWeightLoss: null,
      isOrthopnoea: null,
      isParoxysmalNocturnal: null,
      isHaemoptysis: null,
      isSpirometeryTest: null,
      spirometeryValue: null,
      isSmoking: null,
      isBiomassFuel: null,
      isPollution: null,
      mmrcGrade: null,
      isDifficultInBreathing: null,
      isExacerbation: null,
      isAdmitHospitalWithExacerbation: null,
      severeBreathingDifficulty: null,
      exacerbationSymptoms: [],
      severeExacerbationSymptoms: [],
      drugOptions: [],
    },

    tbHistory: {
      confirmedTBType: [],
      presumptiveTBType: [],
      suspectedTBSymptoms: [],
      suspectedComorbidities: [],
      suspectedOtherTBDrugs: [],
      tbPreviousDrugList: [],
      tbCurrentDrugList: [],
      tbAdditionalDrugList: [],
    },
    osteoArthritisHistory: {
      oaAComorbidList: [],
      oaPharmaList: [],
      oaLifestyleList: [],
      oaNonPharmaList: [],
    },
    orderTest: {
      clinicalPathology: [],
      radiology: [],
      bioChemistry: [],
      stoolAnalysis: [],
      serology: [],
      urineAnalysis: [],
      cardiology: [],
    },
  };

  const [htDiagnosis, setHtDiagnosis] = useState(VISITDATA.htDiagnosis);
  const [validateopt,setValidateopt] = useState({
    htDiagnosis:null,
    dbDiagnosis:null
  });
  //   {
  //   diagnosis: "",
  //   control: "",
  //   recommendation: "",
  //   notes: "",
  //   followup: "",
  //   doctorAcceptance: "",
  //   doctorNotes: "",
  //   doctorId: "",
  //   systemDecision: "",
  // })

  const [dbDiagnosis, setDbDiagnosis] = useState(VISITDATA.dbDiagnosis);
  // diagnosis: "",
  // control: "",
  // recommendation: "",
  // notes: "",
  // followup: "",
  // doctorAcceptance: "",
  // doctorNotes: "",
  // doctorId: "",
  // systemDecision: "",

  // });

  const [isOpenHT, setIsOpenHT] = useState(false);
  const [isOpenDB, setIsOpenDB] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigation = useNavigate();
  const htResult = sessionStorage.getItem("htResult");
  const dbResult = sessionStorage.getItem("dbResult");
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const HtResult = htResult
    ? JSON.parse(htResult).isHypertension
      ? "Yes"
      : "No"
    : "No";
  const DbResult = dbResult
    ? JSON.parse(dbResult).isDiabetes
      ? "Yes"
      : "No"
    : "No";
  const controlRecommendationHT = htResult
    ? JSON.parse(htResult).bloodPressureControl
    : "GOOD";
  const recommendedActionHT = htResult
    ? JSON.parse(htResult).recommendedAction
    : "No Changes";
  const notesHT = htResult ? JSON.parse(htResult).notes : "NA";

  const controlRecommendationDB = dbResult
    ? JSON.parse(dbResult).glycaemicControl
    : "GOOD";
  const recommendedActionDB = dbResult
    ? JSON.parse(dbResult).recommendedAction
    : "No Changes";
  const notesDB = dbResult ? JSON.parse(dbResult).notes : "NA";

  useEffect(() => {
    const fetchDataAndSetHTDBDiagnosisData = async () => {
      try {
        let data = JSON.stringify({
          userId: UserID,
          id: PATIENT_ID,
        });

        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${Config.apiURL}${Config.routes.getPatientVisitsAPI}`,
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "x-caller": encrypted,
          },
          data: data,
        };

        const response = await axios.request(config);
        //console.log("Response from ..:", response, response.data.data);

        const sessionVisitID = parseInt(VISIT_ID, 10);

        const filteredRecords = response.data.data.filter((record) => {
          return record.VISIT_ID === sessionVisitID;
        });
        //console.log("Filtered Records:", filteredRecords, typeof VISIT_ID);

        // if (response.data.data === null || response.data.data.length === 0 || response.data.data[0].VISIT_DATA === null) {
        //   setVisitData(VISITDATA);
        //   //console.log("VISITDATA",VISITDATA)
        // }
        if (filteredRecords[0].VISIT_DATA === null) {
          setVisitData(VISITDATA);
          //console.log("VISITDATA", VISITDATA);
        } else {
          let res = await filteredRecords[0].VISIT_DATA;
          //console.log("---res----", res);

          let parsedRes = !!res ? JSON.parse(res) : null;
          setVisitData(parsedRes);

          let htDiagnosisData = res ? JSON.parse(res).htDiagnosis : null;
          let dbDiagnosisData = res ? JSON.parse(res).dbDiagnosis : null;

          if (htDiagnosisData !== null) {
            const parsedHtDiagnosis = JSON.parse(htDiagnosisData);

            setHtDiagnosis({
              ...htDiagnosis,
              diagnosis: parsedHtDiagnosis.diagnosis,
              control: parsedHtDiagnosis.control,
              recommendation: parsedHtDiagnosis.recommendation,
              notes: parsedHtDiagnosis.notes,
              followup: parsedHtDiagnosis.followup,
              doctorAcceptance: parsedHtDiagnosis.doctorAcceptance,
              doctorNotes: parsedHtDiagnosis.doctorNotes,
              doctorId: parsedHtDiagnosis.doctorId,
              systemDecision: parsedHtDiagnosis.systemDecision,
            });
          }
          if (dbDiagnosisData !== null) {
            const parsedDbDiagnosis = JSON.parse(dbDiagnosisData);

            setDbDiagnosis({
              ...dbDiagnosis,
              diagnosis: parsedDbDiagnosis.diagnosis,
              control: parsedDbDiagnosis.control,
              recommendation: parsedDbDiagnosis.recommendation,
              notes: parsedDbDiagnosis.notes,
              followup: parsedDbDiagnosis.followup,
              doctorAcceptance: parsedDbDiagnosis.doctorAcceptance,
              doctorNotes: parsedDbDiagnosis.doctorNotes,
              doctorId: parsedDbDiagnosis.doctorId,
              systemDecision: parsedDbDiagnosis.systemDecision,
            });
          }
        }
      } catch (err) {
        console.log(err, "err");
      }
    };
    fetchDataAndSetHTDBDiagnosisData();
  }, [VISIT_ID]);

  const handleTreatClick = async (action) => {
    // console.log(
    //   "+++++++",
    //   htDiagnosis.doctorAcceptance,
    //   "=====",
    //   dbDiagnosis.doctorAcceptance
    // );
    console.log(htDiagnosis.doctorAcceptance,"htDiagnosis.doctorAcceptance",validateopt)
    console.log( dbDiagnosis.doctorAcceptance," dbDiagnosis.doctorAcceptance",validateopt)

    if ((
      htDiagnosis.doctorAcceptance !== "" &&
      htDiagnosis.doctorAcceptance !== null &&
      dbDiagnosis.doctorAcceptance !== "" &&
      dbDiagnosis.doctorAcceptance !== null)&&(validateopt.htDiagnosis!==null  && validateopt.dbDiagnosis!==null)
    ) {
      console.log( htDiagnosis.doctorAcceptance !== "" &&
        htDiagnosis.doctorAcceptance !== null &&
        dbDiagnosis.doctorAcceptance !== "" &&
        dbDiagnosis.doctorAcceptance !== null,"ifelsnull");
        console.log(validateopt.doctorAcceptance!==null  && validateopt.doctorAcceptance!==null,"validationopt",validateopt,validateopt.dbDiagnosis,validateopt.htDiagnosis);
      setIsLoading(true);
      const recordSaved = await saveRecord();
      if (recordSaved) {
        setIsLoading(false);
        navigation("/patients/treatmentplan");
      } else {
        //console.log("err");
      }
    } else {
      setSnackbarOpen(true);
    }
  };

  console.log("validateopt.doctorAcceptance",validateopt.doctorAcceptance);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };
  const handleHtReasonChange = (reason) => {
    setHtDiagnosis((prevState) => ({
      ...prevState,
      doctorNotes: reason,
    }));
  };

  const handleDbReasonChange = (reason) => {
    setDbDiagnosis((prevState) => ({
      ...prevState,
      doctorNotes: reason,
    }));
  };

  function replacer(key, value) {
    try {
      if (key.trim() == "") return value;

      // if (key.trim() === "drugHistory") {
      //   console.log("======================================================")
      //   let modifiedJsonString = value.replace(/\\"(\d+\.\d+)\\"/g, "$1");
      //   console.log("===3=====", modifiedJsonString)
      //   return modifiedJsonString;
      // }

      if (value && value !== null && typeof value === "object") {
        //console.log("value inside if", value);
        return value.replace(/"/g, '\\"'); // Replace " with \"  }   return value; }
      }
      return value;
    } catch (err) {
      console.log(
        "======key===",
        key,
        "===========value==",
        typeof value,
        "=======",
        value
      );
      // console.log(err);
      if (Array.isArray(value) && value.length === 0) return value;
      else if (
        key.trim() === "tbHistory" ||
        key.trim() === "osteoArthritisHistory"
      ) {
        let jsonString = JSON.stringify(value).replace(/"/g, '\\"');
        //console.log(jsonString, "1#####");
        jsonString = `\"${jsonString}\"`;
        //console.log(jsonString, "2#####");
        return jsonString;
      } else {
        return JSON.stringify(value);
      }
    }
  }

  const saveRecord = async () => {
    try {
      //console.log("****hi", visitData);
      let existingHtDiagnosis = visitData?.htDiagnosis;

      if (typeof existingHtDiagnosis === "string") {
        existingHtDiagnosis = JSON.parse(existingHtDiagnosis);
      }
      //console.log("******", typeof existingHtDiagnosis);
      existingHtDiagnosis.doctorId = doctorId;
      existingHtDiagnosis.doctorAcceptance = htDiagnosis.doctorAcceptance;
      // existingHtDiagnosis.doctorNotes = htDiagnosis?.doctorNotes;
      existingHtDiagnosis.doctorNotes =
        htDiagnosis && htDiagnosis.doctorNotes ? htDiagnosis.doctorNotes : null;
      existingHtDiagnosis.diagnosis = htResult
        ? JSON.parse(htResult).isHypertension
          ? 1
          : 0
        : 0;
      existingHtDiagnosis.control = htResult
        ? JSON.parse(htResult)?.bloodPressureControl ?? null
        : null;
      // existingHtDiagnosis.recommendedAction = htResult ? JSON.parse(htResult).recommendation ?? null : null;
      existingHtDiagnosis.notes = htResult
        ? JSON.parse(htResult).notes ?? null
        : null;

      let existingDbDiagnosis = visitData.dbDiagnosis;
      if (typeof existingDbDiagnosis === "string") {
        existingDbDiagnosis = JSON.parse(existingDbDiagnosis);
      }
      existingDbDiagnosis.diagnosis = dbResult
        ? JSON.parse(dbResult).isDiabetes
          ? 1
          : 0
        : 0;
      existingDbDiagnosis.doctorId = doctorId;
      existingDbDiagnosis.doctorAcceptance = dbDiagnosis.doctorAcceptance;
      // existingDbDiagnosis.doctorNotes = dbDiagnosis.doctorNotes;
      existingDbDiagnosis.doctorNotes =
        dbDiagnosis && dbDiagnosis.doctorNotes ? dbDiagnosis.doctorNotes : null;
      existingDbDiagnosis.control = dbResult
        ? JSON.parse(dbResult)?.glycaemicControl ?? null
        : null;
      // existingDbDiagnosis.recommendedAction = dbResult ? JSON.parse(dbResult).recommendation ?? null : null;
      existingDbDiagnosis.notes = dbResult
        ? JSON.parse(dbResult).notes ?? null
        : null;

      visitData.htDiagnosis = existingHtDiagnosis;
      visitData.dbDiagnosis = existingDbDiagnosis;

      // console.log("visitdata====", visitData)
      let VisitData = JSON.stringify(visitData, replacer);
      // console.log("Stringified VisitData---", VisitData);

      sessionStorage.setItem("visitData", VisitData);

      const response = await axios.post(
        `${Config.base_url}PATIENT_VISIT_DATA`,
        {
          USER_ID: UserID,
          VISIT_ID: visitData?.visitId?.toString(),
          VISIT_DATA: VisitData,
          STATUS: 2,
          PRESCRIPTION_DATA: null,
          CREATED_BY: visitData?.nurseId?.toString(),
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "x-caller": encrypted,
          },
        }
      );

      //console.log("data post", response);
      if (response.status === 201) {
        //console.log("coing to if");
        return true;
      } else {
        console.error("Error: Data not saved");
        return false;
      }
    } catch (error) {
      console.error("Error:", error);
      return false;
    }
  };

  const toggleAccordionHT = () => {
    setIsOpenHT(!isOpenHT);
    setIsOpenDB(false);
  };

  const toggleAccordionDB = () => {
    setIsOpenDB(!isOpenDB);
    setIsOpenHT(false);
  };

  const handleBackbutton = () => {
    navigation("/patients/reviewprescription");
  };

  const handleAgreeDisagreeht = (action) => {
    console.log(action,"setHtDiagnosis")
    setValidateopt((prevState) => ({
      ...prevState,
      htDiagnosis: action,
    }));
    setHtDiagnosis((prevState) => ({
      ...prevState,
      doctorAcceptance: action,
    }));
  };
  const handleAgreeDisagreedb = (action) => {
    console.log(action,"setDbDiagnosis")
    setValidateopt((prevState) => ({
      ...prevState,
      dbDiagnosis: action,
    }));
    setDbDiagnosis((prevState) => ({
      ...prevState,
      doctorAcceptance: action,
    }));
  };

  return (
    <div className="diagnosis">
      <div className="header">
        <div style={{ marginLeft: "1%" }} onClick={handleBackbutton}>
          {" "}
          <img className="image" src={LeftArrow} alt="Logo" />
        </div>
        <h1 className="diagnosis-heading">DIAGNOSIS</h1>
      </div>
      <div className="dignosis-body">
        <div className="diagnosis-confirm">
          <button
            className="confirm-daignosis"
            onClick={async () => {
              await sessionStorage.removeItem("addingDrugList");
               console.log(sessionStorage.removeItem("addingDrugList"),"addingDrugList");
              handleTreatClick("agree");
            }}
          >
            <div className="cbutton">
                   {isLoading
                  ? "Proceeding to Prescription"
                  : "Confirm diagnosis and proceed to treatment plan"}
              {isLoading && <CircularProgress size={24} color="inherit" />}{" "}
              <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message="Please choose yes / no for recommendataions to proceed. "
              />
              <div className="imager">
                <img src={RightArrow} alt="Logo" className="arrowSize"/>{" "}
              </div>
            </div>
          </button>
        </div>
        <div className="dignosis-body">
          <Accordion
            isOpen={isOpenHT}
            toggleAccordion={toggleAccordionHT}
            diagnosisTitle="Hypertension"
            diagnosisResult={HtResult}
            controlRecommendation={controlRecommendationHT}
            recommendedAction={recommendedActionHT}
            notes={notesHT}
            onAgree={() => handleAgreeDisagreeht(0)}
            onDisagree={() => handleAgreeDisagreeht(1)}
            onChangeReason={handleHtReasonChange}
            setButtonState={setHtButtonState}
          />

          <Accordion
            isOpen={isOpenDB}
            toggleAccordion={toggleAccordionDB}
            diagnosisTitle="Diabetes"
            diagnosisResult={DbResult}
            controlRecommendation={controlRecommendationDB}
            recommendedAction={recommendedActionDB}
            notes={notesDB}
            onAgree={() => handleAgreeDisagreedb(0)}
            onDisagree={() => handleAgreeDisagreedb(1)}
            onChangeReason={handleDbReasonChange}
            setButtonState={setDbButtonState} 
          />
          <CvdRiskScore />
        </div>
      </div>
    </div>
  );
};

export default Diagnosis;

