import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./CvdRiskScoreCalculator.css";

const CvdRiskScoreCalculator = () => {
  /******new code*****/

  const [inputs, setInputs] = useState({
    Cholesterolcount: "",
    CholesterolcountMMol: "",
    cholesterolValue: "",
    gender: "",
    age: "",
    cholesterol: "",
    bloodPressure: "",
    height: "",
    weight: "",
    hasDiabetes: "",
    isSmoking: "",
  });

  // const [variables, setVariables] = useState({
  //   age: "",
  //   issmoking: "no",
  //   gender: "",
  //   bloodPressure: "",
  //   isValidBloodPressure: true,
  //   isValidAge: true,
  // });

  const [nocholesterolvalues, setNoCholesterolValues] = useState({
    unit: "cm",
    heightincm: "",
    heightft: "",
    heightinch: "",
    weight: "",
    bloodPressure: "",
  });
  // const [calculateAligment, setCalculateAligment] = useState(false);
  const [result, setResult] = useState({
    riskResult: "",
    miRiskScore: "",
    strokeRiskScore: "",
    category: "",
    color: "",
    advice: "",
    outresult: true,
  });

  var cholesterolbmi;

  const handleChangecholesterol = (e) => {
    const { name, value } = e.target;
    if (name === "cholesterol") {
      if (value === "yes") {
      } else if (value === "no") {
      }
    }
    setInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
  };

  const calculateCVDRiskScoreData = (
    cholesterolValue,
    gender,
    age,
    cholesterol,
    sbp,
    height,
    weight,
    hasDiabetes,
    isSmoking
  ) => {};

  const getCvdResultCategory = (riskResult) => {
    let category = "";
    let color = "";
    let advice = "";
    if (riskResult > 0 && riskResult < 5.0) {
      category = "Very Low Risk";
      color = "#fdfa72";
      advice =
        "Counsel on diet, physical activity, smoking cessation and avoiding harmful use of alcohol. Consider following up in 12 months.";
    } else if (riskResult >= 5.0 && riskResult < 10.0) {
      category = "Low Risk";
      color = "green";
      advice =
        "Counsel on diet, physical activity, smoking cessation and avoiding harmful use of alcohol. Consider following up every 3 months until targets are met, then 6–9 months thereafter.";
    } else if (riskResult >= 10.0 && riskResult < 20.0) {
      category = "Medium Risk";
      color = "orange";
      advice =
        "Counsel on diet, physical activity, smoking cessation and avoiding harmful use of alcohol. If BP persists as ≥140/90 mmHg consider drugs. Consider following up every 3–6 months.";
    } else if (riskResult >= 20.0 && riskResult < 30.0) {
      category = "High Risk";
      color = "red";
      advice =
        "Counsel on diet, physical activity, smoking cessation and avoiding harmful use of alcohol. If BP persists as ≥130/80, consider drugs. Give a statin. Consider following up every 3 months. If there is no reduction in cardiovascular risk after six months of follow-up, consider referring to next level.";
    } else if (riskResult >= 30.0) {
      category = "Very High Risk";
      color = "red";
      advice =
        "Counsel on diet, physical activity, smoking cessation and avoiding harmful use of alcohol. If BP persists as ≥130/80, consider drugs. Give a statin. Consider following up every 3 months. If there is no reduction in cardiovascular risk after six months of follow-up, consider referring to next level.";
    }
    return { category, color, advice };
  };

  const handleMMChange = (e) => {
    const newValue = parseFloat(e.target.value);
    const isValid = newValue >= 0 && newValue <= 15;
    const mg = isValid ? (newValue * 38.67).toFixed(1) : inputs.Cholesterolcount;

    setInputs((prevValues) => ({
      ...prevValues,
      CholesterolcountMMol: isValid ? newValue : prevValues.CholesterolcountMMol,
      Cholesterolcount: mg,
    }));
  };

 const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;


    if(name === "bloodPressure") {
      if(value > 270) {
        return;
      }
    }

    let updatedInputs = {
      ...inputs,
      [name]: newValue,
    };


    if (name === "Cholesterolcount") {
      if(value > 550) {
        return;
      }
      const mmolValue = parseFloat(value) / 38.67;
      updatedInputs = {
        ...updatedInputs,
        CholesterolcountMMol: mmolValue.toFixed(1),
      };
    }


    setInputs(updatedInputs);
  };

  const handleHeightRadiobuttonChange = (e) => {
    const { name, value } = e.target;
    setNoCholesterolValues({
      ...nocholesterolvalues,
      unit: value,
    });
  };

  // const handleMMChange = (e) => {
  //   const newValue = parseFloat(e.target.value);
  //   const isValid = newValue >= 0 && newValue <= 15;
  //   const mg = isValid ? (newValue * 38.67).toFixed(1) : inputs.Cholesterolcount;

  //   setInputs((prevValues) => ({
  //     ...prevValues,
  //     CholesterolcountMMol: isValid ? newValue : prevValues.CholesterolcountMMol,
  //     Cholesterolcount: mg,
  //   }));
  // };

  const CalculateCvdRiskScore = () => {
    // console.log(
    //   inputs,
    //   "inputsinputsinputsinputsinputsinputsinputsinputs",
    //   nocholesterolvalues
    // );

    // Height conversion from inch to cms
    const feetToInches = parseFloat(nocholesterolvalues.heightft) * 12;
    const totalInches =
      feetToInches + parseFloat(nocholesterolvalues.heightinch);
    const centimeters = totalInches * 2.54;
    //console.log(centimeters, "centimeters");

    if (inputs.cholesterol === "yes") {
      cholesterolbmi = 0;
    } else if (inputs.cholesterol === "no") {
      if (
        nocholesterolvalues.heightincm != null &&
        nocholesterolvalues.weight != null
      ) {
        if (nocholesterolvalues.weight !== 0) {
          cholesterolbmi =
            (10000 * nocholesterolvalues.weight) /
            (nocholesterolvalues.heightincm * nocholesterolvalues.heightincm);
        } else {
          cholesterolbmi = 0;
        }
      } else {
        cholesterolbmi = 0;
      }
    }
    //console.log("Bmi", cholesterolbmi);

    if (inputs.cholesterol === "yes") {
      calculateCVDRiskScoreData(
        parseInt(inputs.gender),
        parseInt(inputs.age),
        parseInt(inputs.Cholesterolcount),
        parseInt(inputs.bloodPressure),
        parseInt(inputs.hasDiabetes),
        parseInt(inputs.isSmoking)
      );
    } else if (inputs.cholesterolValue === "1") {
      calculateCVDRiskScoreData(
        parseInt(inputs.cholesterolValue),
        inputs.gender,
        parseInt(inputs.age),
        parseInt(inputs.Cholesterolcount),
        parseInt(nocholesterolvalues.bloodPressure),
        parseInt(nocholesterolvalues.heightincm),
        parseInt(nocholesterolvalues.weight),
        inputs.hasDiabetes,
        inputs.isSmoking
      );
    }

    const lab = inputs.cholesterol == "yes" ? true : false;
    //console.log(lab, "lab");

    // console.log(
    //   `Gender: ${inputs.gender},
    //   Age: ${inputs.age},
    //   Cholesterol Count: ${inputs.Cholesterolcount},
    //   Blood Pressure: ${inputs.bloodPressure},
    //   Has Diabetes: ${inputs.hasDiabetes},
    //   Is Smoking: ${inputs.isSmoking}`
    // );

    // console.log(nocholesterolvalues, "nocholesterolvalues...........");

    let labStrokeRiskCoefficient = 0;
    let labMiRiskCoefficient = 0;
    let nonLabMiRiskCoefficient = 0;
    let nonLabStrokeRiskCoefficient = 0;
    let labMiRisk = 0;
    let labStrokeRisk = 0;
    let labCvdRisk = 0;
    let nonLabMiRisk = 0;
    let nonLabStrokeRisk = 0;
    let nonLabCvdRisk = 0;

    let ageDifferenceFromBase = parseInt(inputs.age) - 60;
    let cholesterolDifferenceFromBase =
      parseInt(inputs.Cholesterolcount) * 0.02586 - 6;

    let sbpdifference = lab
      ? parseInt(inputs.bloodPressure)
      : parseInt(nocholesterolvalues.bloodPressure);

    let sbpDifferenceFromBase = sbpdifference - 120;
    let bmiDifferenceFromBase = cholesterolbmi - 25;
    let diabetesHistory = inputs.hasDiabetes == "yes" ? 1 : 0;
    let smokingHistory = inputs.isSmoking == "yes" ? 1 : 0;

    // console.log(
    //   `ageDifferenceFromBase:${ageDifferenceFromBase},cholesterolDifferenceFromBase:${cholesterolDifferenceFromBase},sbpDifferenceFromBase:${sbpDifferenceFromBase},bmiDifferenceFromBase:${bmiDifferenceFromBase},diabetesHistory:${diabetesHistory},smokingHistory:${smokingHistory}`
    // );

    const labMIData = [
      {
        age: 0.0719227,
        cholesterol: 0.2284944,
        bloodPressure: 0.0132183,
        dbHistory: 0.6410114,
        smokingHistory: 0.5638109,
        cholesterolInteraction: -0.0045806,
        sbpInteraction: -0.0001576,
        dbInteraction: -0.0124966,
        smokingInteraction: -0.0182545,
        baselineSurvival: 0.954,
      },
      {
        age: 0.1020713,
        cholesterol: 0.2050377,
        bloodPressure: 0.015823,
        dbHistory: 1.070358,
        smokingHistory: 1.053223,
        cholesterolInteraction: -0.0051932,
        sbpInteraction: -0.0001378,
        dbInteraction: -0.0234174,
        smokingInteraction: 0.0332666,
        baselineSurvival: 0.989,
      },
    ];

    const labStrokeData = [
      {
        age: 0.0986578,
        cholesterol: 0.0986578,
        bloodPressure: 0.0222629,
        dbHistory: 0.6268712,
        smokingHistory: 0.4981217,
        cholesterolInteraction: 0.00142,
        sbpInteraction: -0.0004147,
        dbInteraction: -0.026302,
        smokingInteraction: -0.0150561,
        baselineSurvival: 0.985,
      },
      {
        age: 0.1056632,
        cholesterol: 0.0257782,
        bloodPressure: 0.0206278,
        dbHistory: 0.8581998,
        smokingHistory: 0.7443627,
        cholesterolInteraction: -0.0021387,
        sbpInteraction: -0.0004897,
        dbInteraction: -0.0209826,
        smokingInteraction: -0.0200822,
        baselineSurvival: 0.989,
      },
    ];

    const nonLabMIData = [
      {
        age: 0.073593,
        bmi: 0.0337219,
        bloodPressure: 0.0133937,
        smokingHistory: 0.5954767,
        bmiInteraction: -0.0010432,
        sbpInteraction: -0.0001837,
        smokingInteraction: -0.0200831,
        baselineSurvival: 0.954,
      },
      {
        age: 0.1049418,
        bmi: 0.0257616,
        bloodPressure: 0.016726,
        smokingHistory: 1.093132,
        bmiInteraction: -0.0006537,
        sbpInteraction: -0.0001966,
        smokingInteraction: -0.0343739,
        baselineSurvival: 0.989,
      },
    ];

    const nonLabStrokeData = [
      {
        age: 0.097674,
        bmi: 0.0159518,
        bloodPressure: 0.0227294,
        smokingHistory: 0.4999862,
        bmiInteraction: -0.0003516,
        sbpInteraction: -0.0004374,
        smokingInteraction: -0.0153895,
        baselineSurvival: 0.985,
      },
      {
        age: 0.1046105,
        bmi: 0.0036406,
        bloodPressure: 0.0216741,
        smokingHistory: 0.7399405,
        bmiInteraction: -0.0000129,
        sbpInteraction: -0.0005311,
        smokingInteraction: -0.0203997,
        baselineSurvival: 0.989,
      },
    ];

    let genderIndex = inputs.gender === "male" ? 0 : 1;
    console.log(genderIndex, "genderIndex");

    labMiRiskCoefficient =
      labMIData[genderIndex]["age"] * ageDifferenceFromBase +
      labMIData[genderIndex]["cholesterol"] * cholesterolDifferenceFromBase +
      labMIData[genderIndex]["bloodPressure"] * sbpDifferenceFromBase +
      labMIData[genderIndex]["dbHistory"] * diabetesHistory +
      labMIData[genderIndex]["smokingHistory"] * smokingHistory +
      labMIData[genderIndex]["cholesterolInteraction"] *
        cholesterolDifferenceFromBase *
        ageDifferenceFromBase +
      labMIData[genderIndex]["sbpInteraction"] *
        sbpDifferenceFromBase *
        ageDifferenceFromBase +
      labMIData[genderIndex]["dbInteraction"] *
        diabetesHistory *
        ageDifferenceFromBase +
      labMIData[genderIndex]["smokingInteraction"] *
        smokingHistory *
        ageDifferenceFromBase;

    labStrokeRiskCoefficient =
      labStrokeData[genderIndex]["age"] * ageDifferenceFromBase +
      labStrokeData[genderIndex]["cholesterol"] *
        cholesterolDifferenceFromBase +
      labStrokeData[genderIndex]["bloodPressure"] * sbpDifferenceFromBase +
      labStrokeData[genderIndex]["dbHistory"] * diabetesHistory +
      labStrokeData[genderIndex]["smokingHistory"] * smokingHistory +
      labStrokeData[genderIndex]["cholesterolInteraction"] *
        cholesterolDifferenceFromBase *
        ageDifferenceFromBase +
      labStrokeData[genderIndex]["sbpInteraction"] *
        sbpDifferenceFromBase *
        ageDifferenceFromBase +
      labStrokeData[genderIndex]["dbInteraction"] *
        diabetesHistory *
        ageDifferenceFromBase +
      labStrokeData[genderIndex]["smokingInteraction"] *
        smokingHistory *
        ageDifferenceFromBase;

    nonLabMiRiskCoefficient =
      nonLabMIData[genderIndex]["age"] * ageDifferenceFromBase +
      nonLabMIData[genderIndex]["bmi"] * bmiDifferenceFromBase +
      nonLabMIData[genderIndex]["bloodPressure"] * sbpDifferenceFromBase +
      nonLabMIData[genderIndex]["smokingHistory"] * smokingHistory +
      nonLabMIData[genderIndex]["bmiInteraction"] *
        bmiDifferenceFromBase *
        ageDifferenceFromBase +
      nonLabMIData[genderIndex]["sbpInteraction"] *
        sbpDifferenceFromBase *
        ageDifferenceFromBase +
      nonLabMIData[genderIndex]["smokingInteraction"] *
        smokingHistory *
        ageDifferenceFromBase;

    nonLabStrokeRiskCoefficient =
      nonLabStrokeData[genderIndex]["age"] * ageDifferenceFromBase +
      nonLabStrokeData[genderIndex]["bmi"] * bmiDifferenceFromBase +
      nonLabStrokeData[genderIndex]["bloodPressure"] * sbpDifferenceFromBase +
      nonLabStrokeData[genderIndex]["smokingHistory"] * smokingHistory +
      nonLabStrokeData[genderIndex]["bmiInteraction"] *
        bmiDifferenceFromBase *
        ageDifferenceFromBase +
      nonLabStrokeData[genderIndex]["sbpInteraction"] *
        sbpDifferenceFromBase *
        ageDifferenceFromBase +
      nonLabStrokeData[genderIndex]["smokingInteraction"] *
        smokingHistory *
        ageDifferenceFromBase;

    // lab cvd calculations
    if (lab) {
      labMiRisk =
        1 -
        Math.pow(
          labMIData[genderIndex]["baselineSurvival"],
          Math.exp(labMiRiskCoefficient)
        );
      labStrokeRisk =
        1 -
        Math.pow(
          labStrokeData[genderIndex]["baselineSurvival"],
          Math.exp(labStrokeRiskCoefficient)
        );
      labCvdRisk = 1 - (1 - labMiRisk) * (1 - labStrokeRisk);
    } else {
      // non lab cvd calculations
      nonLabMiRisk =
        1 -
        Math.pow(
          nonLabMIData[genderIndex]["baselineSurvival"],
          Math.exp(nonLabMiRiskCoefficient)
        );
      nonLabStrokeRisk =
        1 -
        Math.pow(
          nonLabStrokeData[genderIndex]["baselineSurvival"],
          Math.exp(nonLabStrokeRiskCoefficient)
        );
      nonLabCvdRisk = 1 - (1 - nonLabMiRisk) * (1 - nonLabStrokeRisk);
    }

    // Output and log the results

    //console.log(`lab MI Risk Coefficient= ${labMiRiskCoefficient}`);
    //console.log(`lab Stroke Risk Coefficient = ${labStrokeRiskCoefficient}`);
    //console.log(`lab MI Risk: ${labMiRisk}`);
    //console.log(`lab Stroke Risk: ${labStrokeRisk}`);
    //console.log(`lab CVD RISK SCORE: ${labCvdRisk}`);
    //console.log(`non lab MI Risk Coefficient= ${nonLabMiRiskCoefficient}`);
    // console.log(
    //   `non lab Stroke Risk Coefficient = ${nonLabStrokeRiskCoefficient}`
    // );
    //console.log(`non lab MI Risk: ${nonLabMiRisk}`);
    //console.log(`non lab Stroke Risk: ${nonLabStrokeRisk}`);
    //console.log(`non lab CVD RISK SCORE: ${nonLabCvdRisk}`);


    let roundedmiRiskScore = lab ? labMiRisk * 100 : nonLabMiRisk * 100;
    let miRiskScore = parseFloat(roundedmiRiskScore.toFixed(1));

    let roundedstrokeRiskScore = lab ? labStrokeRisk * 100 : nonLabStrokeRisk * 100;
    let strokeRiskScore =  parseFloat(roundedstrokeRiskScore.toFixed(1));


    let roundedRiskResult = lab ? labCvdRisk * 100 : nonLabCvdRisk * 100;
    let riskResult = parseFloat(roundedRiskResult.toFixed(1));
    // console.log(riskResult, "riskResult");

    //MAIN RESULT OF CVD, MI, RISK SCORE
    //console.log(`Over all MI Risk Score: ${miRiskScore}`);
    //console.log(`Over all STROKE Risk Score: ${strokeRiskScore}`);
    //console.log(`Over all CVD Risk Score: ${riskResult}`);

    // Display appropriate messages
    if (riskResult > 0 && riskResult < 5.0) {
      // console.log(
      //   "Counsel on diet, physical activity, smoking cessation and avoiding harmful use of alcohol. Consider following up in 12 months."
      // );
    } else if (riskResult >= 5.0 && riskResult < 10.0) {
      // console.log(
      //   "Counsel on diet, physical activity, smoking cessation and avoiding harmful use of alcohol. Consider following up every 3 months until targets are met, then 6–9 months thereafter."
      // );
    } else if (riskResult >= 10.0 && riskResult < 20.0) {
      // console.log(
      //   "Counsel on diet, physical activity, smoking cessation and avoiding harmful use of alcohol. If BP persists as ≥140/90 mmHg consider drugs. Consider following up every 3–6 months."
      // );
    } else if (riskResult >= 20.0 && riskResult < 30.0) {
      // console.log(
      //   "Counsel on diet, physical activity, smoking cessation and avoiding harmful use of alcohol. If BP persists as ≥130/80, consider drugs. Give a statin. Consider following up every 3 months. If there is no reduction in cardiovascular risk after six months of follow-up, consider referring to next level."
      // );
    } else if (riskResult >= 30.0) {
      // console.log(
      //   "Counsel on diet, physical activity, smoking cessation and avoiding harmful use of alcohol. If BP persists as ≥130/80, consider drugs. Give a statin. Consider following up every 3 months. If there is no reduction in cardiovascular risk after six months of follow-up, consider referring to next level."
      // );
    }

    const { category, color, advice } = getCvdResultCategory(riskResult);
    setResult({
      riskResult,
      miRiskScore,
      strokeRiskScore,
      category,
      color,
      advice,
    });

    return riskResult;
  };

  const checkCalculateCvdRiskScore = () => {
    if (
      inputs.cholesterol === "yes" &&
      inputs.Cholesterolcount !== "" &&
      inputs.gender != "" &&
      inputs.age !== "" &&
      inputs.bloodPressure !== "" &&
      inputs.hasDiabetes !== "" &&
      inputs.isSmoking !== ""
    ) {
      CalculateCvdRiskScore();
    } else if (
      inputs.cholesterol === "no" &&
      nocholesterolvalues.bloodPressure !== ""
    ) {
      CalculateCvdRiskScore();
    } else {
      //console.log(inputs,nocholesterolvalues);
      toast.error("Enter the fields");
    }
  };

  return (
    <>
      <div className="calcRightContainer">
        <div className="HeadingBmiCalculator">
          <p className="BmiCalculatorHeading">CVD Risk Score Calculator</p>
        </div>
        <div
          style={{
            padding: "2%",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <div className="cvdradioButtons">
            <p className="CalculatorLabel">Cholesterol Values?</p>
            <label className="radiobuttonlabel">
              <input
                type="radio"
                name="cholesterol"
                value="yes"
                checked={inputs.cholesterol === "yes"}
                onChange={handleChangecholesterol}
              />
              Yes
            </label>
            <label className="radiobuttonlabel">
              <input
                type="radio"
                name="cholesterol"
                value="no"
                checked={inputs.cholesterol === "no"}
                onChange={handleChangecholesterol}
              />
              No
            </label>
          </div>
          <div className="genderageclasscvd">
            <div className="SelectTagcvd">
              <p className="CalculatorLabel">Gender</p>
              <select
                className="cvdSelectTag"
                name="gender"
                value={inputs.gender}
                onChange={handleChange}
              >
                <option>Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                {/* <option value="others">Other</option> */}
              </select>
            </div>
            <div className="buttonstyles" style={{ width: "24%" }}>
              <p className="CalculatorLabel">Age</p>
              <input
                className="agebox"
                type="number"
                min={0}
                max={120}
                name="age"
                // placeholder="Age"
                value={inputs.age}
                onChange={handleChange}
              />
              {inputs.age > 120 && (
                <p style={{ color: "red" }}>Age range 0 and 120</p>
              )}
            </div>
          </div>
          {inputs.cholesterol === "yes" && (
            <div
              style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            >
              <div className="buttonstyleCVD">
                <div
                  className="ButtonCVD"
                  style={{

                  }}
                >
                  <div
                    style={{
                      gap: "10px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <p className="CalculatorLabel">Blood Pressure</p>
                    <input
                      type="number"
                      min={70}
                      max={270}
                      name="bloodPressure"
                      value={inputs.bloodPressure}
                      onChange={handleChange}
                      style={{
                        width: "40%",
                      }}
                    />
                    { inputs.bloodPressure &&
                      ((inputs.bloodPressure < 70 || inputs.bloodPressure > 270) && (
                        <p style={{ color: "red" }}>Range is 70-270</p>
                      ))}
                  </div>
                  <div
                    style={{
                      gap: "10%",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div
                      style={{
                        gap: "10px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <p className="CalculatorLabel">Cholesterol(mg/dL)</p>
                      <input
                        type="number"
                        min={50}
                        name="Cholesterolcount"
                        max={550}
                        value={inputs.Cholesterolcount}
                        onChange={handleChange}
                      />
                      {inputs.Cholesterolcount &&
                        ((inputs.Cholesterolcount < 50 || inputs.Cholesterolcount > 550) && (
                          <p style={{ color: "red" }}>Range is 50-550</p>
                        ))}
                    </div>
                    <div
                      style={{
                        gap: "10px",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <p className="CalculatorLabel">Cholesterol (mmol/L)</p>
                      <input
                        type="number"
                        min={50}
                        name="Cholesterolcountmmol"
                        max={550}
                        value={inputs.CholesterolcountMMol}
                        onChange={handleMMChange}
                      />
                      {inputs.CholesterolcountMMol < 0 ||
                        (inputs.CholesterolcountMMol > 15 && (
                          <p style={{ color: "red" }}>Range is 0-15</p>
                        ))}
                    </div>
                  </div>
                </div>

                <div className="cvdradioButton">
                  <p className="CalculatorLabel">Diabetes?</p>
                  <label className="radiobuttonlabel">
                    <input
                      type="radio"
                      name="hasDiabetes"
                      value="yes"
                      checked={inputs.hasDiabetes === "yes"}
                      onChange={handleChange}
                    />
                    Yes
                  </label>
                  <label className="radiobuttonlabel">
                    <input
                      type="radio"
                      name="hasDiabetes"
                      value="no"
                      checked={inputs.hasDiabetes === "no"}
                      onChange={handleChange}
                    />
                    No
                  </label>
                </div>
              </div>
            </div>
          )}
          {inputs.cholesterol === "no" && (
            <div>
              <div
                className="CVDradioButtons"
                style={{ marginTop: "3%", display: "flex" }}
              >
                <p className="CalculatorLabel" style={{ marginRight: "10px" }}>
                  Height :
                </p>
                <label className="radiobuttonlabel">
                  <input
                    type="radio"
                    name="unit"
                    value="cm"
                    checked={nocholesterolvalues.unit === "cm"}
                    onChange={handleHeightRadiobuttonChange}
                  />
                  CM
                </label>
                <label className="radiobuttonlabel">
                  <input
                    type="radio"
                    name="unit"
                    value="ft"
                    checked={nocholesterolvalues.unit === "ft"}
                    onChange={handleHeightRadiobuttonChange}
                  />
                  FT
                </label>
              </div>

              <div className="hwrow">
                {nocholesterolvalues.unit === "cm" && (
                  <div className="buttonstyles">
                    <p className="CalculatorLabel">cm</p>
                    <input
                      type="number"
                      name="heightincm"
                      value={nocholesterolvalues.heightincm}
                      onChange={(e) => {
                          if(e.target.value > 350) {
                            return;
                          }
                          setNoCholesterolValues({
                          ...nocholesterolvalues,
                          heightincm: e.target.value,
                        })
                      }}
                    />
                    { nocholesterolvalues.heightincm && 
                      ( (nocholesterolvalues.heightincm <= 0 || nocholesterolvalues.heightincm > 350) && (
                        <p style={{ color: "red" }}>Range is 0-350</p>
                      ))}
                  </div>
                )}

                <div className="buttonstyles">
                  <p className="CalculatorLabel">Weight(kgs)</p>
                  <input
                    type="number"
                    name="weight"
                    value={nocholesterolvalues.weight}
                    onChange={(e) =>
                      setNoCholesterolValues({
                        ...nocholesterolvalues,
                        weight: e.target.value,
                      })
                    }
                  />
                  { nocholesterolvalues.weight && 
                    ((nocholesterolvalues.weight <= 0 || nocholesterolvalues.weight > 300) && (
                      <p style={{ color: "red" }}>Range is 0-300</p>
                    ))}
                </div>

                {nocholesterolvalues.unit === "ft" && (
                  <div className="buttonstyles">
                    <div>
                      <p className="CalculatorLabel">Feet</p>
                      <input
                        type="number"
                        name="heightft"
                        value={nocholesterolvalues.heightft}
                        onChange={ (e) => {
                          if(e.target.value > 12) {
                            return;
                          }

                          setNoCholesterolValues({
                            ...nocholesterolvalues,
                            heightft: e.target.value,
                          })
                        }}
                      />
                      {nocholesterolvalues.heightft && 
                        ((nocholesterolvalues.heightft <= 0 || nocholesterolvalues.heightft > 12) && (
                          <p style={{ color: "red" }}>Range is 0-12</p>
                        ))}
                    </div>
                    <div>
                      <p className="CalculatorLabel">Inches</p>
                      <input
                        type="number"
                        name="heightinch"
                        value={nocholesterolvalues.heightinch}
                        onChange={(e) => {
                          if(e.target.value > 12) {
                            return;
                          }
                          
                          setNoCholesterolValues({
                            ...nocholesterolvalues,
                            heightinch: e.target.value,
                          }) 
                        }}
                      />
                      {nocholesterolvalues.heightinch &&
                        ((nocholesterolvalues.heightinch <= 0 || nocholesterolvalues.heightinch > 12) && (
                          <p style={{ color: "red" }}>Range is 0-12</p>
                        ))}
                    </div>
                  </div>
                )}

                <div className="buttonstyles">
                  <p className="CalculatorLabel">Blood Pressure</p>
                  <input
                    type="number"
                    min={70}
                    max={300}
                    name="bloodPressure"
                    value={nocholesterolvalues.bloodPressure}
                    onChange={ (e) => {
                      if(e.target.value > 270) {
                        return;
                      }
                      setNoCholesterolValues({
                        ...nocholesterolvalues,
                        bloodPressure: e.target.value,
                      })
                    }
                  }
                  />
                  {nocholesterolvalues.bloodPressure &&
                    ((nocholesterolvalues.bloodPressure < 70 || nocholesterolvalues.bloodPressure > 270) && (
                      <p style={{ color: "red" }}>Range is 70-270</p>
                    ))}
                </div>
              </div>
            </div>
          )}
          <div className="cvdradioButton">
            <p className="CalculatorLabel">Smoking?</p>
            <label className="radiobuttonlabel">
              <input
                type="radio"
                name="isSmoking"
                value="yes"
                checked={inputs.isSmoking === "yes"}
                onChange={handleChange}
              />
              Yes
            </label>
            <label className="radiobuttonlabel">
              <input
                type="radio"
                name="isSmoking"
                value="no"
                checked={inputs.isSmoking === "no"}
                onChange={handleChange}
              />
              No
            </label>
          </div>
        </div>

        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className="CalBtnPosition">
            <button
              className="cvdCalculateButtonCalculate"
              onClick={checkCalculateCvdRiskScore}
            >
              Calculate
            </button>
            {!result.outresult && (
              <div
                style={{
                  backgroundColor: "#c0ffd2",
                  color: "rgba(82, 68, 125, 1)",
                  padding: "2%",
                  borderRadius: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    backgroundColor: result.color,
                    justifyContent: "space-between",
                    padding: "2% 1%",
                    borderRadius: "10px",
                    color: "black",
                  }}
                >
                  <p>CVD RISK SCORE: {result.riskResult}%</p>
                  <p>{result.category}</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",

                    padding: "1%",
                  }}
                >
                  <p>MI Risk Score: {result.miRiskScore}%</p>
                  <p>STROKE Risk Score: {result.strokeRiskScore}%</p>
                </div>
                <p>{result.advice}</p>
              </div>
            )}
          </div>
        </div>
        <ToastContainer position="top-right" />
      </div>
    </>
  );
};

export default CvdRiskScoreCalculator;
