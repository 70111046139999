// Production URLS
// ----------------------------------------
//  const  apiURL = 'mpowerprd.clinally.com'   , // Clinally version 2 production URL
//  const  base_url = "https://mpowerprd.clinally.com/user/"   , // Clinally version 2 production URL

// QA URLS
//--------------------------------------------

// const base_url = "https://mpowerqa.clinally.com/user/"; // QA URL
// const apiURL = "https://mpowerqa.clinally.com"; // QA URL
//const abhaBaseUrl = "https://mpowerqa.clinally.com/abha/";
const base_url = "https://incentivedashboard.clinally.com/user/"; // Test QA URL
const apiURL = "https://incentivedashboard.clinally.com"; // QA URL
const abhaBaseUrl = "https://incentivedashboard.clinally.com/abha/";

//LOCAL RUN
// const base_url = "http://localhost:4545/user/";
// const apiURL = "http://localhost:4545";
// const abhaBaseUrl = "http://localhost:4545/abha/";

// DEV URLS
//------------------------------------------------------
//  const  apiURL = 'mpowerhealth.pragmatiqinc.com'   , //Pragmatiq Dev URL
//  const  base_url = "https://mpowerhealth.pragmatiqinc.com/user/"   , // Pragmatiq Dev URL

// -------------------------
// const getEncodedString = async () => {
//   let encodedVal = "";
//   encodedVal = "Basic " + (await btoa("mpoweruser:Cl!nA11y123!@#"));
//   return encodedVal;
// };
const routes = {
  loginAPI: "/user/login",
  pinLogin: "/user/pinlogin",
  registerAPI: "/user/register",
  userProfileAPI: "/user/user-profile",
  resetPasswordAPI: "/resetpassword",
  resetPinAPI: "resetpin",
  getProfileAPI: "GETUSERDATA/",
  getAppointmentsAPI: "/hospital/appointmentsbyday",
  getHospitalsAPI: "/hospital/allhospitals",
  getUserHospitals: "/hospital/userhospitals/",
  getPatientsAPI: "/patient/allpatients",
  getPatientVisitsAPI: "/patient/patientvisits",
  addPatientVisitRecordAPI: "/patient/addpatientvisit",
  previousVisitDataAPI: "/patient/previousvisit",
  addHospitalAPI: "/hospital/setuphospital",
  addHospitalLogo: "/hospital/setuphospitalLogo",
  addHospitalImage: "/hospital/setuphospitalImage",
  getPatientAPI: "/patient/patientdata",
  saveCDSSAPI: "/user/SAVE_CDSS_DATA",
  getRecommendationsAPI: "/recommendation/lifestyle",
  addUserAPI: "/hospital/setuphospitalusers",
  getHospitalUsersAPI: "/hospital/hospitalusers",
  addHospitalToUserAPI: "/hospital/addhospitaltouser",
  removeHospitalFromUserAPI: "/hospital/removehospitalfromuser",
  approveHospitalRequestAPI: "/hospital/approveUserRequest",
  updateHFRID: "/v2/updateHFRId",
  getPatientRecordsAPI: "/patient/getrecords",
  getPatientChartDataAPI: "/patient/chartdata",
  userHospitalsAPI: "/hospital/userhospitals",
  getFollowupsAPI: "/patient/followups",
  getRemindersAPI: "/patient/reminders",
  getUserNotificationsAPI: "/notifications/usernotifications",
  getHelpVideos: "HELP_VIDEOS",

  addEducationAPI: "/v2/education/setupeducation",
  updateEducationAPI: "/v2/education/updateEducation",
  getHfrId: "v2/getHfr",
  getEducation: "/GET_QUALIFICATION",

  abhaLinkCareContext: "/v0.5/links/link/add-contexts",
  abhaLinkContextNotify: "/v0.5/links/context/notify",
  abhaPatientSMSNotify: "/v0.5/patients/sms/notify2",

  abhaHPRApi: "https://hpr.abdm.gov.in/en",
  abhaHFRApi: "https://facility.abdm.gov.in/",

  //------------ABHA PUBLIC VARIABLES------------
  // ABHA Sandbox URLS
  CLIENT_ID: "SBX_001741",
  //         CLIENT_SECRET    : '3f1141e5-7924-4fb4-b83a-7b2d5102c526'   ,

  // ABHA PRODUCTION URLS
  //           CLIENT_ID    : 'CPHSPL_001'   ,
  //           CLIENT_SECRET    : '3c455edc-0fc1-4b34-b778-ec9938f891d8'   ,

  // ABHA Sandbox URLS
  //         abhaSandBoxapiURL    : 'healthidsbx.abdm.gov.in'   , // ABHA sandbox API
  //         abhaSandBoxapiBaseURL    : 'https://healthidsbx.abdm.gov.in'   , // ABHA sandbox API with https
  //         abhaSandBoxGatewayURL    : 'https://dev.abdm.gov.in/gateway'   ,

  // ABHA PRODUCTION URLS
  //         abhaSandBoxapiURL    : 'healthid.abdm.gov.in'   , // ABHA PRODUCTION API
  //         abhaSandBoxapiBaseURL    : 'https://healthid.abdm.gov.in'   , // ABHA PRODUCTION API with https
  //         abhaSandBoxGatewayURL    : 'https://live.ndhm.gov.in/gateway/v0.5'   ,

  abhaSumasoftURL: "https://nha-suma-azb7fa3pfa-el.a.run.app",

  abhaCertificateAPI: "/api/v1/auth/cert",
  //         abhaSessionTokenAPI : 'https://dev.abdm.gov.in/gateway/v0.5/sessions'   ,// ABHA sandbox API
  //         abhaSessionTokenAPI : 'https://live.ndhm.gov.in/gateway/v0.5/sessions'   ,// ABHA PRODUCTION API
  getAbhaMobileOTPAPI: "/api/v2/registration/mobile/login/generateOtp",
  getAbhaSearchIdAuth: "/api/v1/search/searchByHealthId",
  verifyAadhaarAuthOtp: "/api/v1/auth/confirmWithAadhaarOtp",
  verifyMobileAuthOtp: "/api/v1/auth/confirmWithMobileOTP",
  generateAuthInit: "/api/v1/auth/init",
  abhaVerifyMobileOTPAPI: "/api/v2/registration/mobile/login/verifyOtp",
  getAbhaUserAuthorizedTokenAPI:
    "/api/v2/registration/mobile/login/userAuthorizedToken",
  getAbhaProfileAPI: "/api/v1/account/profile",
  getAbhaPNGCardAPI: "/api/v1/account/getPngCard",
  getAbhaCardAPI: "/api/v1/account/getCard",

  generateAbhaAadhaarOTPAPI: "/api/v1/registration/aadhaar/generateOtp",
  abhaVerifyAadhaarOTPAPI: "/api/v1/registration/aadhaar/verifyOTP",
  generateAbhaMobileOTPAPI: "/api/v1/registration/aadhaar/generateMobileOTP",
  verifyAbhaRegisteredMobileOTPAPI:
    "/api/v1/registration/aadhaar/verifyMobileOTP",
  createAbhaHealthIDPreverifiedAPI:
    "/api/v1/registration/aadhaar/createHealthIdWithPreVerified",

  generateABHAEmailOTPAPI: "/api/v2/account/email/verification/send/otp",
  validateABHAEmailOTPAPI: "/api/v2/account/email/verification/verify/otp",
  generateDeleteAccountMobileOTPAPI: "/api/v2/account/aadhaar/generateOTP",
  deleteABHAAccountAPI: "/api/v2/account/profile/delete",
  getABHAQRCodeAPI: "/api/v1/account/qrCode",

  ///ABHA M3 API calls
  subscriptionRequestAPI: "/v0.5/subscription-requests/cm/init",
  consnetFindAPI: "/v0.5/patients/find",
  consnetRequestAPI: "/v0.5/consent-requests/init",
  consnetStatusAPI: "/v0.5/consent-requests/status",
  consnetFetchAPI: "/v0.5/consents/fetch",
  //
  setHospitalId: "/hospital/setDefaultHospital",
  //
  //
  getInvoiceRecordsAPI:"/patient/getInvoiceRecords",
  apiAuthentication:
    // getEncodedString(),
    "Basic " + btoa("mpoweruser:Cl!nA11y123!@#"),
};

export default { routes, base_url, apiURL, abhaBaseUrl };
