import React, { useState } from "react";
import Navbar from "../components/modules/Navbar";
import { Container, Grid } from "@mui/material";
import VectorLine from "../images/Vector_line.svg";
import PersonalInfo from "../components/modules/PersonalInfo";
import "./Articles.css";
import DigitalHealth from "../components/modules/Articles/digitalHealth";
import Hypertension from "../components/modules/Articles/hypertension";
import Covid from "../components/modules/Articles/Covid";
import Immunization from "../components/modules/Articles/immunization";
import Diabetes from "../components/modules/Articles/diabetes";
import Depression from "../components/modules/Articles/depression";
import TopNavBar from "../components/modules/TopNavBar";

const Article = () => {
  const [selectArticleSection, setselectArticleSection] =
    useState("digitalHealth");
  const toggleSection = (selectName) => {
    setselectArticleSection(selectName);
  };

  return (
    <>
      {/* <div className="Articles-MainContainer">
        <Navbar />
        <div style={{ width: "100%" }}>
          <TopNavBar /> */}
         
            <div className="ArticlesHeadingContainer">
              <div className="ArticlesHeading">
                <h3 className="MainHeadingCalculator">ARTICLES</h3>
              </div>
              <div className="ArticleSection">
                <div className="AritclesHeadingcontainer">
                  <div className="articlesection">
                    <div className="sidemenuArticles">
                      <div style={{width:"100%"}}>
                        <div
                          className={` sectionsNameArticle ${
                            selectArticleSection === "digitalHealth"
                              ? "selectArticleSection  "
                              : "activeHeader"
                          }`}
                          onClick={() => toggleSection("digitalHealth")}
                        >
                          <p className="ArticlesIteams">Digital Health</p>
                          <hr
                            style={{
                              border: "1px solid rgba(214, 229, 255, 1)",
                            }}
                          />

                        </div>
                        <div
                          className={` sectionsNameArticle ${
                            selectArticleSection === "hypertension"
                              ? "selectArticleSection  "
                              : "activeHeader"
                          }`}
                          onClick={() => toggleSection("hypertension")}
                        >
                          <p className="ArticlesIteams">Hypertension</p>
                          <hr
                            style={{
                              border: "1px solid rgba(214, 229, 255, 1)",
                            }}
                          />
                        </div>
                        <div
                          className={` sectionsNameArticle ${
                            selectArticleSection === "diabetes"
                              ? "selectArticleSection  "
                              : "activeHeader"
                          }`}
                          onClick={() => toggleSection("diabetes")}
                        >
                          <p className="ArticlesIteams">Diabetes</p>
                          <hr
                            style={{
                              border: "1px solid rgba(214, 229, 255, 1)",
                            }}
                          />
                        </div>
                        <div
                          className={` sectionsNameArticle ${
                            selectArticleSection === "covid"
                              ? "selectArticleSection  "
                              : "activeHeader"
                          }`}
                          onClick={() => toggleSection("covid")}
                        >
                          <p className="ArticlesIteams">Covid</p>
                          <hr
                            style={{
                              border: "1px solid rgba(214, 229, 255, 1)",
                            }}
                          />
                        </div>
                        <div
                          className={` sectionsNameArticle ${
                            selectArticleSection === "immunization"
                              ? "selectArticleSection  "
                              : "activeHeader"
                          }`}
                          onClick={() => toggleSection("immunization")}
                        >
                          <p className="ArticlesIteams">Immunization</p>
                          <hr
                            style={{
                              border: "1px solid rgba(214, 229, 255, 1)",
                            }}
                          />
                        </div>
                        <div
                          className={` sectionsNameArticle ${
                            selectArticleSection === "depression"
                              ? "selectArticleSection  "
                              : "activeHeader"
                          }`}
                          onClick={() => toggleSection("depression")}
                        >
                          <p className="ArticlesIteams">Depression</p>
                        </div>
                      </div>
                      <hr
                        style={{ border: "1px solid rgba(214, 229, 255, 1)" }}
                      />
                    </div>
                    {selectArticleSection === "digitalHealth" && (
                      <DigitalHealth />
                    )}
                    {selectArticleSection === "hypertension" && (
                      <Hypertension />
                    )}
                    {selectArticleSection === "diabetes" && <Diabetes />}
                    {selectArticleSection === "covid" && <Covid />}
                    {selectArticleSection === "immunization" && (
                      <Immunization />
                    )}
                    {selectArticleSection === "depression" && <Depression />}
                  </div>
                </div>
              </div>
            </div>
          
        {/* </div>
      </div> */}
    </>
  );
};

export default Article;
