import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import axios from "axios";
import { DateTime } from "luxon";
import { publicEncrypt, constants } from "crypto-browserify";
import UpdateHospitalsAssociation from "../modules/UpdateHospitalsAssociation";
import profileCamera from "../../images/profileCamera.svg";
import { Buffer } from "buffer";
import search from "../../images/search.svg";
import vectorProfile from "../../images/VectorProfile.svg";
import debounce from "lodash.debounce";
import PatientsTreated from "../../images/patientsTreated.svg";
import menuDots from "../../images/menu-dots.svg";
import backBtn from "../../images/ArrowLeft.svg";
import back from "../../images/Back.png";
import YearsOfExperience from "../../images/yearsOfExperience.svg";
import VerticalLine from "../../images/verticalLine.svg";
import "../modules/HospitalDetails.css";
import Close from "../../images/Group 411.svg";
import HospitalImage from "../../images/hosiptal-img.png";
import LinkABDM from "../modules/ABDM";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Box, Paper, Typography, Button, Divider } from "@mui/material";
import { CircularProgress } from "@mui/material";
import Config from "../../Config/config";
import { color } from "echarts";

const UpdateHospital = ({ toggleSection }) => {
  const [hospitalList, setHospitalList] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [approvePopup, setApprovePopup] = useState(false);
  const [permission, setPermission] = useState(false);
  const [deleteUser, setDeleteUser] = useState([]);
  const [hospitalId, setHospitalId] = useState(0);
  const [selectUser, setSelectUser] = useState([]);
  const [approveUser, setApproveUser] = useState([]);
  const [datadata, setdatadata] = useState(false);
  const [file, setFile] = useState(null);
  const [selectedfile, setSelectedFile] = useState(null);
  const [showEditForm, setShowEditForm] = useState(false);
  const [SeletedHospitalDetails, setSeletedHospitalDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isStateDropdownLoading, setStateDropdownLoading] = useState(false);

  const [variables, setVariables] = useState({
    searchQuery: "",
    addHospital: false,
    selectedState: "",
    doctorcount: "",
    usercount: "",
    patientcount: "",
    hospitalName: "",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    city: "",
    pinCode: "",
    userProfileDetails: "",
    selectedHospital: null,
    state: "",
  });

  const [formData, setFormData] = useState({
    hospitalPhoto: "",
    hospitalName: "",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    city: "",
    state: "",
    pinCode: "",
    id: "",
  });

  const UserID = sessionStorage.getItem("RegUserId")
    ? JSON.parse(sessionStorage.getItem("RegUserId"))
    : sessionStorage.getItem("UserID");

  const publicKey = sessionStorage.getItem("RegpublicKey")
    ? sessionStorage.getItem("RegpublicKey")
    : JSON.parse(sessionStorage.getItem("publicKey"));
  const token = sessionStorage.getItem("Regtoken")
    ? sessionStorage.getItem("Regtoken")
    : JSON.parse(sessionStorage.getItem("token"));
  const padding = constants.RSA_PKCS1_PADDING;
  const data = Buffer.from(UserID.toString(), "utf-8");
  const encrypted = publicEncrypt(
    { key: publicKey, padding: padding },
    data
  ).toString("base64");
  const MobileNum = sessionStorage.getItem("UserMOBILE");

  useEffect(() => {
    fetchHospitals();
  }, []);

  const fetchHospitals = async () => {
    const authentication = "Basic " + btoa("mpoweruser:Cl!nA11y123!@#");
    setIsLoading(true);
    var options = {
      method: "GET",
      url: `${Config.apiURL}/hospital/allhospitals/${UserID}`,
      headers: {
        authorization: authentication,
        "Content-Type": "application/json",
        "x-caller": encrypted,
      },
    };

    axios
      .request(options)
      .then(function (response) {
        console.log("setHospitalList", response.data.data);
        setHospitalList(response.data.data);
        setIsLoading(false);
      })
      .catch(function (error) {
        setIsLoading(false);
        console.error(error);
      });

    var options = {
      method: "GET",
      url: `${Config.base_url}v2/GETUSERPROFILE/${UserID}`,
      headers: {
        authorization: authentication,
        "x-caller": encrypted,
      },
    };

    axios
      .request(options)
      .then(function (response) {
        // console.log(response.data.recordset[0]);
        setVariables((prevVariables) => ({
          ...prevVariables,
          userProfileDetails: response.data.recordset[0],
        }));
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const filteredData = hospitalList.filter((user) => {
    const fullName = `${user.HOSPITAL_NAME}`.toLowerCase();
    const hfrAddress = `${user.HOSPITAL_HFR_ADDRESS}`.toLowerCase();
    const searchQueryLower = variables.searchQuery.toLowerCase();
    return (
      fullName.includes(searchQueryLower) ||
      hfrAddress.includes(searchQueryLower)
    );
  });

  const handleButtonClick = () => {
    // Toggle the value of addHospital
    setVariables((prevVariables) => ({
      ...prevVariables,
      addHospital: !prevVariables.addHospital,
      selectedHospital: null,
    }));
  };

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const handlePinCodeChange = (pinCode) => {
    setStateDropdownLoading(true);
    let config = {
      method: "get",
      url: `https://api.postalpincode.in/pincode/${pinCode}`,
    };

    axios.request(config).then((response) => {
      let stateOption = { value: "Select a State", label: "Select a State" };
      if (response.data[0]?.Status === "Success") {
        let state = response.data[0].PostOffice[0]?.State;
        stateOption = { value: state, label: state };
      }
      handleStateChange(stateOption);
      setStateDropdownLoading(false);
    });
  };

  const handleInputChange = (event, field) => {
    const { value } = event.target;
    if (field === "pinCode") {
      if (!/^\d*$/.test(value)) {
        return;
      }
      if (value && value.length === 6) {
        handlePinCodeChange(value);
      }
    } else  if(field === "hospitalName" || field === "city") {
      if (!(/^[a-zA-Z\s]*$/.test(value))) {
        return;
      }
    }
    setVariables((prevVariables) => ({
      ...prevVariables,
      [field]: value,
    }));
  };

  //add new hospital
  const hospitalSetUp = () => {
    const UserID = JSON.parse(sessionStorage.getItem("UserID"));
    const MobileNum = sessionStorage.getItem("UserMOBILE");
    console.log("number", MobileNum);

    if (!variables.hospitalName || !variables.addressLine1 || !variables.city) {
      toast.error("Please enter Hospital Name, Address, and City");
      return;
    }

    var data = {
      name: variables.hospitalName,
      address1: variables.addressLine1,
      address2: variables.addressLine2,
      address3: variables.addressLine3,
      city: variables.city,
      state: variables.selectedState,
      pin: variables.pinCode,
      mode: "add",
      userid: UserID,
      mobile: MobileNum,
    };

    var options = {
      method: "POST",
      url: `${Config.apiURL}${Config.routes.addHospitalAPI}`,

      headers: {
        authorization: `Bearer ${token}`,
        "content-type": "application/json",
        "x-caller": encrypted,
      },
      data: { data: JSON.stringify(data) },
    };

    console.log("data UpdateHospitals", options.data);

    axios
      .request(options)
      .then(function (response) {
        console.log(response.data);
        fetchHospitals();
      })
      .catch(function (error) {
        console.error(error);
      });

    toast.success("Added hospital successfully");
    setTimeout(function () {
      setVariables((prevVariables) => ({
        ...prevVariables,
        addHospital: !prevVariables.addHospital,
        selectedHospital: null,
      }));
    }, 500);
  };

  const states = [
    { value: "Select a State", label: "Select a State" },
    { value: "Andhra Pradesh", label: "Andhra Pradesh" },
    { value: "Arunachal Pradesh", label: "Arunachal Pradesh" },
    { value: "Assam", label: "Assam" },
    { value: "Bihar", label: "Bihar" },
    { value: "Chhattisgarh", label: "Chhattisgarh" },
    { value: "Goa", label: "Goa" },
    { value: "Gujarat", label: "Gujarat" },
    { value: "Haryana", label: "Haryana" },
    { value: "Himachal Pradesh", label: "Himachal Pradesh" },
    { value: "Jharkhand", label: "Jharkhand" },
    { value: "Karnataka", label: "Karnataka" },
    { value: "Kerala", label: "Kerala" },
    { value: "Madhya Pradesh", label: "Madhya Pradesh" },
    { value: "Maharashtra", label: "Maharashtra" },
    { value: "Manipur", label: "Manipur" },
    { value: "Meghalaya", label: "Meghalaya" },
    { value: "Mizoram", label: "Mizoram" },
    { value: "Nagaland", label: "Nagaland" },
    { value: "Odisha", label: "Odisha" },
    { value: "Punjab", label: "Punjab" },
    { value: "Rajasthan", label: "Rajasthan" },
    { value: "Sikkim", label: "Sikkim" },
    { value: "Tamil Nadu", label: "Tamil Nadu" },
    { value: "Telangana", label: "Telangana" },
    { value: "Tripura", label: "Tripura" },
    { value: "Uttar Pradesh", label: "Uttar Pradesh" },
    { value: "Uttarakhand", label: "Uttarakhand" },
    { value: "West Bengal", label: "West Bengal" },
    {
      value: "Andaman and Nicobar Islands (UT)",
      label: "Andaman and Nicobar Islands (UT)",
    },
    { value: "Chandigarh (UT)", label: "Chandigarh (UT)" },
    {
      value: "Dadra & Nagar Haveli and Daman & Diu (UT)",
      label: "Dadra & Nagar Haveli and Daman & Diu (UT)",
    },
    { value: "Delhi (UT)", label: "Delhi (UT)" },
    { value: "Jammu and Kashmir (UT)", label: "Jammu and Kashmir (UT)" },
    { value: "Lakshadweep (UT)", label: "Lakshadweep (UT)" },
    { value: "Puducherry (UT)", label: "Puducherry (UT)" },
    { value: "Ladakh (UT)", label: "Ladakh (UT)" },
  ];

  const handleStateChange = (selectedOption) => {
    setVariables((prevVariables) => ({
      ...prevVariables,
      selectedState: selectedOption,
      isOpen: false,
    }));
  };

  const handleSearch = debounce((value) => {
    setVariables({
      ...variables,
      searchQuery: value,
    });
  }, 300);

  const handleChange = (event) => {
    const { value } = event.target;
    handleSearch(value);
  };

  const handleHospitalClick = (index, user) => {
    console.log("INDEX", index, "user", user);
    setFile(null);
    if (user.USER_STATUS == "ACCEPTED" && user.ADMIN > 0) {
      setVariables({
        ...variables,
        selectedHospital: user,
        addHospital: false,
      });
      sessionStorage.setItem("HOSPITAL_ID", JSON.stringify(user.HOSPITAL_ID));
      setPermission(true);
      getUsersList(user.HOSPITAL_ID);
      // console.log("search hospital", user.HOSPITAL_ID);
      setHospitalId(user.HOSPITAL_ID);
      setSeletedHospitalDetails(user);
    }
  };
  const handleHospitalClickDisassociate = async (user) => {
    console.log("user", user);
    setSelectUser(user);
    await fetchHospitals();

    setdatadata(true);
  };

  async function getUsersList(data) {
    // console.log("Hospital_Id", data);
    try {
      const body = JSON.stringify({
        id: data,
      });
      setIsLoading(true);

      const response = await axios.post(
        `${Config.apiURL}${Config.routes.getHospitalUsersAPI}`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
            "x-caller": encrypted,
          },
        }
      );

      if (response.status == 200) {
        // console.log("response from api", response);
        setUsersList(response.data.data);
        // console.log("setUsersList",setUsersList);
        setIsLoading(false);
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      return "Error";
    }
  }

  function AnalysisPopup() {
    // console.log("entering into Analsys ")
    return (
      <div className="hospitalpopup">
        <div className="hospitalpopup-inner">
          <h2>Remove User</h2>
          <p>
            Removing this user from your hospital will revoke access to your
            patient data.
          </p>
          <p>Are you sure you want to continue</p>

          <div className="hospitalpopup-buttons">
            <button onClick={removeUsersList}>Continue</button>
            <button onClick={closePopup}>Cancel</button>
          </div>
        </div>
      </div>
    );
  }

  function ApprovePopup() {
    // console.log("entering into Analsys ")
    return (
      <div className="hospitalpopup">
        <div className="hospitalpopup-inner">
          <h2>Approve User Request</h2>
          <p>
            Accepting this user as part of your hospital will give access to
            your patient data.
          </p>
          <p>Are you sure you want to continue</p>

          <div className="hospitalpopup-buttons">
            <button onClick={ApproveUsersList}>Continue</button>
            <button onClick={ApproveclosePopup}>Cancel</button>
          </div>
        </div>
      </div>
    );
  }
  //Remove User API
  async function removeUsersList() {
    console.log("Entering into removeUsersList() API ", deleteUser);

    try {
      // console.log("entred into try block");

      const body = JSON.stringify({
        patientID: deleteUser.USER_ID,
        mobile: deleteUser.MOBILE_NUMBER,
        hospitalID: deleteUser.HOSPITAL_ID,
        userID: UserID,
        mode: "Delete",
        status: "DELETED",
      });

      const response = await axios.post(
        `${Config.apiURL}${Config.routes.removeHospitalFromUserAPI}`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
            "x-caller": encrypted,
          },
        }
      );
      console.log(response);
      if (response.status == 200) {
        console.log("response from api", response);

        // setUsersList(response.data.data);
        await getUsersList(hospitalId);
        setShowPopup();
      }
    } catch (error) {
      console.error(error);
      return "Error";
    }
  }

  //Approve Users API
  async function ApproveUsersList() {
    console.log("Entering into removeUsersList() API ", approveUser);

    try {
      console.log("entred into try block");

      const body = JSON.stringify({
        doctorID: approveUser.USER_ID,
        mobile: approveUser.MOBILE_NUMBER,
        hospitalID: approveUser.HOSPITAL_ID,
        userID: UserID,
        mode: "approve",
        status: "ACCEPTED",
      });

      const response = await axios.post(
        `${Config.apiURL}${Config.routes.approveHospitalRequestAPI}`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
            "x-caller": encrypted,
          },
        }
      );
      console.log(response);
      if (response.status == 200) {
        console.log("response from api", response);
        // setUsersList(response.data.data);
        await getUsersList(hospitalId);
        // setShowPopup()
      }
    } catch (error) {
      console.error(error);
      return "Error";
    }
  }

  const getFile = async (event) => {
    try {
      const selectedfile = event.target.files[0];
      console.log("selected file", selectedfile);

      if (selectedfile) {
        const reader = new FileReader();
        reader.onload = async () => {
          setFile(reader.result);
        };
        reader.readAsDataURL(selectedfile);
      }
      setSelectedFile(selectedfile);
      await uploadHospitalLog(selectedfile);
    } catch (error) {
      console.error("Error while processing file:", error);
    }
  };

  const uploadHospitalLog = async (selectedfile) => {
    try {
      // console.log("Entering uploadImage function", selectedfile);

      const user = variables.selectedHospital;

      const formData = new FormData();

      formData.append("id", user.HOSPITAL_ID);

      formData.append("hospitalPhoto", selectedfile);
      formData.append("userid", UserID);
      const mobileNumber = MobileNum.replace(/\D/g, "");
      formData.append("mobile", mobileNumber);

      const additionalData = {
        id: user.HOSPITAL_ID,
        hospitalPhoto: selectedfile,
        userid: UserID,
        mobile: mobileNumber,
      };

      const jsonData = JSON.stringify(additionalData);
      formData.append("data", jsonData);

      formData.forEach((value, key) => {
        console.log(key, value);
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${Config.apiURL}${Config.routes.addHospitalLogo}`,
        headers: {
          authorization: `Bearer ${token}`,
          "content-type": "multipart/form-data",
          "x-caller": encrypted,
        },
        data: formData,
      };
      const response = await axios(config);

      if (response.status === 200) {
        console.log("File uploaded successfully.");
      } else {
        console.error("Failed to upload file.", response.status);
      }
    } catch (error) {
      console.error("Error while uploading file:", error);
    }
  };

  const defaultHospital = async () => {
    console.log("Entering into defaultHospital");

    try {
      const user = variables.selectedHospital;

      const body = {
        hospitalId: user.HOSPITAL_ID,
        userId: UserID,
      };

      console.log("request body", body);

      const response = await axios.post(
        `${Config.apiURL}${Config.routes.setHospitalId}`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
            "x-caller": encrypted,
          },
        }
      );
      console.log(response);

      if (response.status === 200) {
        console.log("Hospital updated as Default Hospital.");
        toast.success("Default hospital has been updated successfully");
      } else {
        console.error("Failed to setup Default Hospital.", response.status);
      }
    } catch (error) {
      console.error("Error while setup the Default Hospital:", error);
    }
  };

  //For Remove closing Popup
  const closePopup = () => {
    setShowPopup(!showPopup);
  };

  const ApproveclosePopup = () => {
    setApprovePopup(!approvePopup);
  };

  // Back Button
  const handleBackClick = async () => {
    await fetchHospitals();
    setPermission(!permission);
    setShowEditForm(false);
  };

  const handleAnalysisButtonClick = (user) => {
    setShowPopup(!showPopup);
    setDeleteUser(user);
    console.log("User ID:", user.USER_ID);
    console.log("Mobile Number:", user.MOBILE_NUMBER);
    console.log("Hospital ID:", user.HOSPITAL_ID);
    console.log("Mode:", "delete");
    console.log("Status:", "DELETED");
  };

  const handleApproveUser = (user) => {
    console.log("entering into handleApproveUser");
    setApprovePopup(!approvePopup);
    setApproveUser(user);
    console.log("Hospital ID:", user.HOSPITAL_ID);
    console.log("User ID:", user.USER_ID);
    console.log("Mobile Number:", user.MOBILE_NUMBER);
    console.log("Mode:", "approve");
    console.log("Status:", "ACCEPTED");
  };

  const updateParentState = (value) => {
    setdatadata(!datadata);
  };

  const handleEditDetailsClick = () => {
    setFormData({
      hospitalName: variables.selectedHospital.HOSPITAL_NAME,
      addressLine1: variables.selectedHospital.HOSPITAL_ADDRESS1,
      city: variables.selectedHospital.HOSPITAL_CITY,
      pinCode: variables.selectedHospital.HOSPITAL_PIN,
      state: variables.selectedHospital.HOSPITAL_STATE,
      id: variables.selectedHospital.HOSPITAL_ID,
    });
    console.log(setFormData, "setFormData");
    console.log("Type of setFormData:", typeof setFormData);
    // console.log(variables.selectedHospital.HOSPITAL_NAME,"hospitalName: variables.selectedHospital.HOSPITAL_NAME");
    setShowEditForm(true);
  };

  //update hospital
  const handleFormSubmission = () => {
    console.log(formData, "formData ...........");
    const mobileNumber = MobileNum.replace(/\D/g, "");

    var data = {
      name: formData.hospitalName,
      id: formData.id,
      hospitalPhoto: "null",
      address1: formData.addressLine1,
      address2: SeletedHospitalDetails.HOSPITAL_ADDRESS3,
      address3: SeletedHospitalDetails.HOSPITAL_ADDRESS3,
      city: formData.city,
      state: formData.state,
      pin: formData.pinCode,
      status: "0",
      createdBy: "1",
      mode: "edit",
      userid: UserID,
      mobile: mobileNumber,
      hfrAddress: SeletedHospitalDetails.HOSPITAL_HFR_ADDRESS,
      hipAddress: SeletedHospitalDetails.HOSPITAL_HIP_ADDRESS,
    };

    console.log("update data", data, SeletedHospitalDetails);

    var options = {
      method: "POST",
      url: `${Config.apiURL}${Config.routes.addHospitalAPI}`,

      headers: {
        authorization: `Bearer ${token}`,
        "content-type": "application/json",
        "x-caller": encrypted,
      },
      data: { data: JSON.stringify(data) },
    };

    console.log("data UpdateHospitals", options.data);

    axios
      .request(options)
      .then(function (response) {
        console.log(response.data);
        setIsLoading(false);
        setShowEditForm(false);
        fetchHospitals();
        handleBackClick();
      })
      .catch(function (error) {
        console.error(error);
        setIsLoading(false);
      });
  };

  // const handleHospitalChange = (e, ctrlName) => {
  //   const value = e.target.value;
  //   console.log("Input Value:", value);

  //   if (ctrlName === "pinCode") {
  //     if (value.length <= 6 && /^[0-9]*$/.test(value)) {
  //       setFormData({
  //         ...formData,
  //         [ctrlName]: value,
  //       });
  //     }
  //   } else {
  //     // No length or number restrictions for other fields
  //     setFormData({
  //       ...formData,
  //       [ctrlName]: value,
  //     });
  //   }
  // };

  const handleHospitalChange = (e, ctrlName) => {
    const value = e.target.value;
    const name =e.target.name;

    // if (/^[a-zA-Z\s]*$/.test(value)) {
    //   setFormData({
    //     ...formData,
    //     [name]: value,
    //   });
    // }
    console.log(ctrlName,"ctrlName.....................",name,"uhuiui")
    if ((name === "hospitalName" || name === "city")) {
      
      // Allow only letters and spaces
      if (/^[a-zA-Z\s]*$/.test(value)) {
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    } else {
      // Handle other fields as needed
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };
  
  
  
  
  
  const handleHospitalAddressChange = (e, ctrlName) => {
    const value = e.target.value;
  
    // Allow only letters, numbers, and spaces
    if (/^[a-zA-Z0-9\s]*$/.test(value)) {
      setFormData({
        ...formData,
        [ctrlName]: value,
      });
    }
  };
  
  const handleHospitalPincodeChange = (e, ctrlName) => {
    const value = e.target.value;
  
    // Only allow numbers and limit to 6 digits
    if (/^\d{0,6}$/.test(value)) {
      setFormData({
        ...formData,
        [ctrlName]: value,
      });
    }
  };
  

  const handleSelectChange = (selectedOption, ctrlName) => {
    const value = selectedOption ? selectedOption.value : null;
    console.log("Selected Value:", value);
    setFormData({
      ...formData,
      [ctrlName]: value,
    });
  };

  const handleCloseClick = () => {
    setShowEditForm(false);
  };

  const handleLinkAbdm = (e) => {
    e.stopPropagation();
    toggleSection("ABDM");
  };

  const closeHospitalSetup = () => {
    setVariables((prevVariables) => ({
      ...prevVariables,
      addHospital: !prevVariables.addHospital,
      selectedHospital: null,
    }));
  };

  return (
    <div style={{ display: "flex", flexGrow: 1 }}>
      <div style={{ width: "100%" }}>
        {permission ? (
          variables.selectedHospital.USER_STATUS === "ACCEPTED" &&
          variables.selectedHospital && (
            <div style={{ display: "flex", width: "100%" }}>
              <div style={{ display: "flex", width: "100%" }}>
                <div className="Hospitalboard-main">
                  <div className="hospitalmain-details-container">
                    <div className="backBtn" onClick={handleBackClick}>
                      {/* Assuming you have defined handleBackClick */}
                      <img src={backBtn} alt="image not found" width="16" height="17" />
                      <p style={{marginTop:"0.1%"}}>Back</p>
                      {/* <img src={back} alt="image not found" /> */}
                    </div>

                    <div className="hospital-dashboard">
                      <div className="bg-hospital">
                        <div className="hospital-imagecontainer">
                          <div className="hospitalimagelabelContainer">
                            <label
                              className="hospitalimagelabel"
                              htmlFor="uploadImage"
                            >
                              <img src={profileCamera} alt="" />
                            </label>
                          </div>
                          <div className="hospital_image">
                            <input
                              type="file"
                              accept=".jpg, .jpeg, .png"
                              multiple={false}
                              id="uploadImage"
                              style={{ display: "none" }}
                              onChange={getFile}
                            />

                            {/* Render uploaded image or default hospital image */}
                            {file ? (
                              <img src={file} alt="hospitalImagefile" />
                            ) : (
                              // eslint-disable-next-line jsx-a11y/img-redundant-alt
                              <img
                                className="hospital-log"
                                src={
                                  variables.selectedHospital &&
                                  variables.selectedHospital.HOSPITAL_LOGO
                                    ? `${Config.apiURL}${variables.selectedHospital.HOSPITAL_LOGO}`
                                    : HospitalImage
                                }
                                alt="Default Image"
                                crossOrigin="anonymous"
                              />
                            )}
                          </div>
                        </div>
                        <div className="hospital-buttons">
                          <button onClick={handleEditDetailsClick}>
                            Edit Details
                          </button>
                          <button
                            className="setdefaultbutton"
                            onClick={() => defaultHospital()}
                          >
                            Default
                          </button>
                        </div>
                      </div>
                      <div className="hospital-information">
                        <div className="hosptial-count">
                          {/* Render user, doctor, and patient counts */}
                          <div className="hospital-user">
                            <p className="user-section">Users</p>
                            <hr />
                            <h3>{`${variables.selectedHospital.USER_COUNT}`}</h3>
                          </div>
                          <div className="hospital-user">
                            <p className="user-section">Doctors</p>
                            <hr />
                            <h3>{`${variables.selectedHospital.DOCTOR_COUNT}`}</h3>
                          </div>
                          <div className="hospital-user">
                            <p className="user-section">Patients</p>
                            <hr />
                            <h3>{`${variables.selectedHospital.PATIENT_COUNT}`}</h3>
                          </div>
                        </div>
                        <div className="personal-info-section upper-padding">
                          <img
                            className="vector-profile-img"
                            src={vectorProfile}
                            alt="vector-profile-img"
                          />
                          <p className="personal-info">Hospital Information</p>
                        </div>
                      </div>
                    </div>

                    <div className="hospital-Name">
                      <div className="main-section">
                        <div className="hospitaldetailscontainer">
                          <label
                            htmlFor="details-section"
                            className="hospital-label"
                          >
                            Hospital Details
                          </label>
                          <input
                            id="details-section"
                            className="hospitalname-inputfeild"
                            value={`${variables.selectedHospital.HOSPITAL_NAME}`}
                          />
                        </div>
                        <div className="hospitaldetailscontainer">
                          <label
                            htmlFor="details-section-city"
                            className="hospital-label"
                          >
                            City
                          </label>
                          <input
                            id="details-section-city"
                            className="hospitalname-inputfeild"
                            value={`${variables.selectedHospital.HOSPITAL_CITY}`}
                          />
                        </div>
                      </div>
                      {/* {variables.selectedHospital.HOSPITAL_ADDRESS2 || ""}
                        {variables.selectedHospital.HOSPITAL_ADDRESS3 || ""} */}

                      <div className="main-section">
                        <div className="hospitaldetailscontainer">
                          <label
                            htmlFor="details-section-pincode"
                            className="hospital-label"
                          >
                            Pin Code
                          </label>
                          <input
                            id="details-section-pincode"
                            className="hospitalname-inputfeild"
                            value={`${variables.selectedHospital.HOSPITAL_PIN}`}
                          />
                        </div>
                        <div className="hospitaldetailscontainer">
                          <label
                            htmlFor="details-section-state"
                            className="hospital-label"
                          >
                            State
                          </label>
                          <input
                            id="details-section-state"
                            className="hospitalname-inputfeild"
                            value={variables.selectedHospital.HOSPITAL_STATE}
                          />
                        </div>
                      </div>
                      <div className="main-section">
                        <div className="hospitaldetailscontainer bg-width-container">
                          <label
                            htmlFor="details-section-address"
                            className="hospital-label"
                          >
                            Address
                          </label>
                          <input
                            id="details-section-address"
                            className="hospitalname-inputfeild"
                            value={variables.selectedHospital.HOSPITAL_ADDRESS1}
                          />
                        </div>
                      </div>
                      {/* <p>
                          {`${variables.selectedHospital.HOSPITAL_CITY}, `}
                          {`${variables.selectedHospital.HOSPITAL_STATE}-${variables.selectedHospital.HOSPITAL_PIN}`}
                        </p> */}
                    </div>
                  </div>
                  {/* Display users list */}
                  <div className="innerupdatehospitalsection">
                    <div className="user">Users List</div>
                    <div className="Hospital-cards">
                      <div className="columnsNames">
                        <p>Name</p>
                        <p>Status</p>
                        <p>Mobile Number</p>
                        <p>Role</p>
                      </div>
                      {isLoading ? (
                        <div className="spinningLoader">
                          <CircularProgress size={48} color="inherit" />
                        </div>
                      ) : (
                        <>
                          {usersList.map((user, id) => (
                            <div key={id} className="Hospital-card">
                              {/* <div className="shortname">
                                <h3>
                                  Display user initials based on first and last name
                                  {user.LAST_NAME.length === 0
                                    ? user.FIRST_NAME.substring(0, 2).toUpperCase()
                                    : `${user.FIRST_NAME.substring(
                                        0,
                                        1
                                      ).toUpperCase()}${user.LAST_NAME.substring(
                                        0,
                                        1
                                      ).toUpperCase()}`}
                                </h3>
                              </div> */}
                              <p className="hospitalcardfirstname">{`${user.FIRST_NAME} ${user.LAST_NAME}`}</p>
                              <p className="hospitalcardstatus">
                                {user.STATUS}
                              </p>
                              <p className="hospitalcardmobilenumber">
                                {user.MOBILE_NUMBER}
                              </p>
                              {/* Render different buttons based on user role and status */}
                              {user.ROLE === 4 ? (
                                <button
                                  className="hospitalcardadmin"
                                  type="button"
                                >
                                  Admin
                                </button>
                              ) : (
                                <>
                                  {user.STATUS === "REQUESTED" ? (
                                    <>
                                      <button
                                        className="hospitalcardapproved"
                                        type="button"
                                        onClick={() => handleApproveUser(user)}
                                      >
                                        Approve
                                      </button>
                                      {approvePopup && <ApprovePopup />}
                                    </>
                                  ) : (
                                    <>
                                      <button
                                        className="hospitalcardadmin"
                                        type="button"
                                        onClick={() =>
                                          handleAnalysisButtonClick(user)
                                        }
                                        style={{
                                          backgroundColor: "#32de84",
                                          cursor: "pointer",
                                        }}
                                      >
                                        Remove
                                      </button>
                                      {showPopup && <AnalysisPopup />}
                                    </>
                                  )}
                                </>
                              )}
                            </div>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                {/* Display Edit Form when showEditForm is true */}
                {showEditForm && (
                  <div className="hospitalpopup">
                    <div className="hospitalModalSection">
                      <div className="hospitalModalHeading">
                        <div className="edit-headingdiv">
                          <p className="edit-heading">EDIT HOSPITAL DETAILS</p>
                        </div>
                        <span className="close-icon" onClick={handleCloseClick}>
                          <img
                            src={Close}
                            style={{
                              height: "20px",
                              width: "20px",
                              cursor: "pointer",
                            }}
                          />
                        </span>
                      </div>
                      <div
                        className="hospitalFormContainer"
                        style={{ paddingBottom: "1%" }}
                      >
                        {/* <div>
                      <p style={{ fontSize: "x-large", marginBottom:"20px" }}>
                        Please fill the edit details
                      </p>
                    </div> */}
                        <div
                          // style={{
                          //   display: "flex",
                          //   justifyContent: "space-between",
                          // }}
                          className="hospitalFormInnerSecondContainer"
                        >
                          <div className="inner-container">
                            <div className="label-container">
                              <label className="name-description space">
                                Hospital Name
                              </label>
                              <input
                                type="text"
                                name="hospitalName"
                                className="popup-input-text"
                                placeholder="Hospital Name"
                                value={formData.hospitalName}
                                onChange={handleHospitalChange}
                              />
                            </div>
                          </div>
                          <div className="inner-container">
                            <div className="label-container">
                              <label className="name-description space">
                                Hospital Address
                              </label>
                              <input
                                type="text"
                                className="popup-input-text"
                                placeholder="Hospital Address Line 1"
                                value={formData.addressLine1}
                                onChange={(e) =>
                                  handleHospitalAddressChange(e, "addressLine1")
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          // style={{
                          //   display: "flex",
                          //   justifyContent: "space-between",
                          // }}
                          className="hospitalFormInnerSecondContainer"
                        >
                          <div className="inner-container">
                            <div className="label-container">
                              <label className="name-description space">
                                Pincode
                              </label>
                              <input
                                type="number"
                                max="999999"
                                className="popup-input-text"
                                placeholder="Pin Code"
                                value={formData.pinCode}
                                onChange={(e) =>
                                  handleHospitalPincodeChange(e, "pinCode")
                                }
                              />
                            </div>
                          </div>

                          <div className="inner-container">
                            <div className="label-container">
                              <label className="name-description space">
                                Hospital City
                              </label>
                              <input
                                type="text"
                                name="city"
                                className="popup-input-text"
                                placeholder="City"
                                value={formData.city}
                                onChange={handleHospitalChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            // display: "flex",
                            width: "84%",
                            marginTop: "3%",
                            gap: "1%",
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: "1%",
                          }}
                          // className="inner-container"
                        >
                          <label className="name-description space">
                            State
                          </label>
                          <Select
                            id="stateDropdown"
                            options={states}
                            className="next-space"
                            defaultValue={formData.state}
                            onChange={(selectedOption) =>
                              handleSelectChange(selectedOption, "state")
                            }
                            placeholder={formData.state}
                            isSearchable
                            styles={{
                              menu: (provided) => ({
                                ...provided,
                                maxHeight: "300px",
                                zIndex: 9999,
                              }),
                            }}
                          />
                        </div>
                        {/* <p className="horizontal-line"></p> */}
                        <button
                          className="add-button"
                          onClick={handleFormSubmission}
                          style={{ height: "2rem" }}
                        >
                          SUBMIT
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <ToastContainer />
            </div>
          )
        ) : (
          <>
            {datadata ? (
              <div>
                <UpdateHospitalsAssociation
                  selectUser={selectUser}
                  userListUPdate={fetchHospitals}
                  updateParentState={updateParentState}
                />
              </div>
            ) : (
              <div style={{ width: "100%" }}>
                <div
                  className="profileRightContainer"
                  style={{ display: "flex", gap: "1rem" }}
                >
                  <div className="leftContainer" style={{ width: "70%" }}>
                    <div
                      style={{
                        display: "none",
                        // justifyContent: "center",
                        // alignItems: "center",
                      }}
                    >
                      <img
                        // src={`https://mpowerqa.clinally.com/user/GETUSERDATA/${variables.userProfileDetails.USER_PHOTO}`}
                        src={`${Config.apiURL}${variables.userProfileDetails.USER_PHOTO}`}
                        alt="User"
                        style={{ maxWidth: "200px", maxHeight: "200px" }}
                        crossOrigin="true"
                      />
                      {/* {console.log(
                        variables.userProfileDetails,
                        "variables.userProfileDetailsvariables.userProfileDetails"
                      )}*/}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          textAlign: "center",
                        }}
                      >
                        <p>
                          {variables.userProfileDetails.TITLE} {""}{" "}
                          {variables.userProfileDetails.FIRST_NAME}
                        </p>
                        <h6>
                          {variables.userProfileDetails.EXPERIENCE
                            ? variables.userProfileDetails.EXPERIENCE
                            : ""}
                        </h6>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            textAlign: "center",
                            gap: "1rem",
                          }}
                        >
                          <div>
                            <img
                              src={PatientsTreated}
                              alt=""
                              style={{ height: "50px", width: "40px" }}
                            />
                            <p>{variables.userProfileDetails.PATIENT_COUNT}</p>
                            <p>Patients Treated</p>
                          </div>
                          <img src={VerticalLine} alt="" />
                          <div>
                            <img
                              src={YearsOfExperience}
                              alt=""
                              style={{ height: "50px", width: "40px" }}
                            />
                            <p>
                              <b>
                                {/* {variables.userProfileDetails.EXPERIENCE_DETAILS} */}
                                {variables.userProfileDetails.YEARS_OF_EXP
                                  ? `${variables.userProfileDetails.YEARS_OF_EXP}`
                                  : 0}
                              </b>{" "}
                              Years
                            </p>
                            <p>Experience</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div
            style={{ background: "#AEAEAE", width: "100%", height: "1px" }}
          ></div> */}
                    <div
                      className="profile-bottomContainer"
                      // style={{ height: "80%", width: "100%" }}
                    >
                      <div className="headingContainer">
                        <div className="searchContainerHosp">
                          <input
                            type="text"
                            placeholder="Search"
                            onChange={handleChange}
                          />
                          {/*Search with patients name or...*/}
                          <img src={search} alt="" />
                        </div>

                        <button
                          className="addAppointmentsBtn"
                          // style={{
                          //   Background: "#52447d !important",
                          //   // boxShadow: "0px 3px 16px 1px #52447D75",
                          // }}
                          onClick={handleButtonClick}
                        >
                          + Add Hospital
                        </button>
                      </div>
                      {isLoading ? (
                        <div className="spinningLoader">
                          <CircularProgress size={48} color="inherit" />
                        </div>
                      ) : (
                        <>
                          <div className="list-column-names">
                            {/* <div className="containerHospitalsList">
                        <div className="hospiotalListItem">

                        </div>
                      </div> */}
                            <h1 className="hospital-d-th hd-profile">
                              Profile
                            </h1>
                            <h1 className="hospital-d-th name">Name</h1>
                            <h1 className="hospital-d-th hfr">HFR ID</h1>
                            <h1 className="hospital-d-th hpr">HPR ID</h1>
                            <div className="hospital-d-th hd-link">
                              <h1 className="hospital-d-th hd-link-child">
                                ABHA
                              </h1>
                              <h1 className="hospital-d-th hd-link-child">
                                Link
                              </h1>
                            </div>
                          </div>
                          <div
                            style={{ /*height: "47vh",*/ overflow: "scroll" }}
                          >
                            {filteredData.map((user, index) => (
                              <div
                                key={index}
                                className="hospitald-containerHospitalsList"
                                onClick={() => handleHospitalClick(index, user)}
                              >
                                <div className="hospitald-hospNameContainer hospital-d-td hd-profile">
                                  <p className="shortNameHosp">
                                    {user.HOSPITAL_NAME
                                      ? user.HOSPITAL_NAME.substring(
                                          0,
                                          2
                                        ).toUpperCase()
                                      : ""}
                                  </p>
                                  <p className="verticalLine"></p>
                                </div>
                                <div class="hospitald-hospNameContainer hospital-d-td name">
                                  <p>{user.HOSPITAL_NAME}</p>
                                  {/* <p className="hospCityName">
                                  {user.HOSPITAL_CITY}
                                </p> */}
                                </div>
                                <div class="hospitald-hospNameContainer hospital-d-td hfr">
                                  <p>{user.HOSPITAL_HFR_ADDRESS}</p>
                                </div>

                                <div class="hospitald-hospNameContainer hospital-d-td hpr">
                                  <p>{user.HOSPITAL_HIP_ADDRESS}</p>
                                </div>

                                {user.USER_STATUS === null ||
                                user.USER_STATUS === "" ? (
                                  <div className="manageButtons hospital-d-td hd-link">
                                    <div className="hospital-d-td hd-link-child"></div>
                                    <div className="associate hospital-d-td hd-link-child">
                                      <button
                                        onClick={() =>
                                          handleHospitalClickDisassociate(user)
                                        }
                                      >
                                        ASSOCIATE
                                      </button>
                                    </div>
                                  </div>
                                ) : user.USER_STATUS === "ACCEPTED" ? (
                                  <div className="manageButtons hospital-d-td hd-link">
                                    <div className="hospital-d-td hd-link-child">
                                      {user &&
                                      user.HOSPITAL_HFR_ADDRESS === null  ? (
                                        <button
                                          onClick={handleLinkAbdm}
                                          className="linkAbdm linkAbdm-detail"
                                        >
                                          Link ABDM
                                        </button>
                                      ) : (
                                        <button style={{backgroundColor:"#87A96B",cursor:"none"}}
                                          disabled
                                          onClick={(e) => e.stopPropagation()}
                                          className="linkAbdm linkAbdm-detail"
                                        >
                                          ABDM Linked
                                        </button>
                                      )}
                                    </div>

                                    <div className="disassociate hospital-d-td hd-link-child">
                                      <button
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleHospitalClickDisassociate(user);
                                        }}
                                        className="disassociate-detail"
                                      >
                                        DISASSOCIATE
                                      </button>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="manageButtons hospital-d-td hd-link">
                                    <div className="hospital-d-td hd-link-child"></div>
                                    <div className="cancelRequest hospital-d-td hd-link-child">
                                      <button
                                        onClick={() =>
                                          handleHospitalClickDisassociate(user)
                                        }
                                        className="cancel-request-detail"
                                      >
                                        Cancel Request
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                    </div>
                  </div>

                  {variables.addHospital ? (
                    <div className="hospitalpopup">
                      <div className="hospitalModalSection">
                        <p className="hospitalModalHeading">
                          + Add Hospital Details
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={closeHospitalSetup}
                          >
                            <img
                              src={Close}
                              style={{
                                height: "20px",
                                width: "20px",
                                color: "#FFFFFF",
                              }}
                            />
                          </div>
                        </p>
                        <div className="hospitalFormContainer">
                          <div className="hospitalFormInnerContainer">
                            {/* <p
                              style={{
                                color: "#7E7E7E",
                                fontFamily: "Varela Round",
                                fontSize: "1rem",
                                fontStyle: "normal",
                                fontWeight: 400,
                                lineHeight: "normal",
                                letterSpacing: "0.125rem",
                              }}
                            >
                              Please fill the following details
                            </p> */}
                            <div className="inner-container">
                              <div className="label-container">
                                <label
                                  htmlFor="form-first-name"
                                  className="name-description space"
                                >
                                  Hospital Setup
                                </label>
                                <input
                                  type="text"
                                  className="popup-input-text"
                                  placeholder="Hospital Name"
                                  value={variables.hospitalName}
                                  onChange={(e) =>
                                    handleInputChange(e, "hospitalName")
                                  }
                                />
                              </div>
                              <div className="label-container">
                                <label
                                  htmlFor="form-first-name"
                                  className="name-description space"
                                >
                                  city
                                </label>
                                <input
                                  type="text"
                                  className="popup-input-text"
                                  placeholder="City"
                                  value={variables.city}
                                  onChange={(e) => handleInputChange(e, "city")}
                                />
                              </div>
                            </div>
                            <div className="inner-container">
                              <div className="label-container">
                                <label
                                  htmlFor="form-first-name"
                                  className="name-description"
                                >
                                  Pincode
                                </label>
                                <input
                                  type="text"
                                  maxlength="6"
                                  className="popup-input-text"
                                  placeholder="Pin Code"
                                  value={variables.pinCode}
                                  onChange={(e) =>
                                    handleInputChange(e, "pinCode")
                                  }
                                />
                              </div>
                              <div className="label-container width-space">
                                <label
                                  htmlFor="form-first-name"
                                  className="name-description"
                                >
                                  Select State
                                </label>
                                <Select
                                  className=""
                                  id="stateDropdown"
                                  value={variables.selectedState}
                                  onChange={handleStateChange}
                                  options={states}
                                  placeholder="Select a State"
                                  isSearchable
                                  isLoading={isStateDropdownLoading}
                                  styles={{
                                    control: (provided) => ({
                                      ...provided,
                                      height: "45px",
                                      borderRadius: "10px",
                                    }),
                                    menu: (provided, state) => ({
                                      ...provided,
                                      maxHeight: "300px", // Adjust the height as needed
                                      zIndex: 9999,
                                    }),
                                  }}
                                />
                              </div>
                            </div>

                            <label
                              htmlFor="form-first-name"
                              className="name-description space"
                            >
                              Hospital Address
                            </label>
                            <input
                              type="text"
                              className="popup-input-text next-space"
                              placeholder="Hospital Address Line 1"
                              value={variables.addressLine1}
                              onChange={(e) =>
                                handleInputChange(e, "addressLine1")
                              }
                            />
                          </div>
                          <p className="horizontal-line"></p>
                          <div className="buttons-section">
                            <button
                              className="addAppointmentsBtn clearButton"
                              style={{
                                Background: "#52447d !important",
                                // boxShadow: "0px 3px 16px 1px #52447D75",
                                margin: "0 1rem",
                                fontSize: "18px;",
                                color: "#52447D",
                              }}
                              onClick={closeHospitalSetup}
                            >
                              Close
                            </button>
                            <button
                              className="addAppointmentsBtn closeButton"
                              style={{
                                Background: "#52447d !important",
                                // boxShadow: "0px 3px 16px 1px #52447D75",
                                margin: "0 1rem",
                                fontSize: "18px;",
                              }}
                              onClick={hospitalSetUp}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                        <ToastContainer position="top-right" />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
export default UpdateHospital;
