import React from "react";
import { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from "../components/modules/Navbar";
// import "./Examination.css";
import "../components/modules/Visits/TreatmentPlan.css";
import TestVisits from "../components/modules/TestVisits";
 import Diagnosis from "../components/modules/Visits/Diagnosis";

const Examination = () => {
    // const [disabledTabs, setDisabledTabs] = useState( new Array(2).fill(false));
  return (
    
    <div>
      {/* <Navbar /> */}
      <div className="examination-main">
        {/*<TestVisits />*/}
        <Diagnosis />
      </div>
    </div>
  );
};

export default Examination;
