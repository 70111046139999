import React, { useState, useEffect } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { publicEncrypt, constants } from "crypto-browserify";
import "./ABHA.css";
import { Buffer } from "buffer";
import Config from "../../../Config/config";
import Axios from "axios";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";

const AbhaMobileValidation = () => {
  const navigate = useNavigate();

  const [variables, setVariables] = useState({
    abhaAddress: "",
    mobileNumber: "",
    showAlert: false,
    alertTitle: "",
    alertMessage: "",
    otp: "",
    mobileOtp: false,
  });
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);

  const UserID = sessionStorage.getItem("RegUserId")
    ? JSON.parse(sessionStorage.getItem("RegUserId"))
    : sessionStorage.getItem("UserID");

  const publicKey = sessionStorage.getItem("RegpublicKey")
    ? sessionStorage.getItem("RegpublicKey")
    : JSON.parse(sessionStorage.getItem("publicKey"));
  const token = sessionStorage.getItem("Regtoken")
    ? sessionStorage.getItem("Regtoken")
    : JSON.parse(sessionStorage.getItem("token"));

  const padding = constants.RSA_PKCS1_PADDING;
  const data = Buffer.from(UserID.toString(), "utf-8");
  const encrypted = publicEncrypt(
    { key: publicKey, padding: padding },
    data
  ).toString("base64");

  const PATIENT_ID = sessionStorage.getItem("PATIENT_ID");

  const ShowAlert = ({ title, message, onClose }) => {
    return (
      <div className="popup">
        <div className="popup-inner">
          <p
            style={{ fontSize: "24px", fontWeight: "600", fontFamily: "bold" }}
          >
            {title}
          </p>
          <p>{message}</p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "2rem",
              padding: "4%",
              justifyContent: "flex-end",
            }}
          >
            <button
              className="ContinueBtn"
              onClick={
                variables.type === "registerSuccessfull"
                  ? uploadAbhaData
                  : onClose
              }
              style={{
                background: "#52447d",
                width: "20%",
              }}
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    getPublickey();
  });

  const getPublickey = async () => {
    Axios.get(`${Config.abhaBaseUrl}publicKey/${UserID}`, {
      headers: {
        authorization: `Bearer ${token}`,
        "x-caller": await encrypted,
      },
    })
      .then((response) => {
        // console.log("response :", response);
        if (response.status == 200) {
          // console.log("PUBLICKEY :", response.data);
          sessionStorage.setItem("ABDMPublicKey", response.data);
          return response;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSessionTokenAPI = async () => {
    if (variables.abhaAddress.trim() !== "") {
      setShowLoading(true);
      const dataBody = {
        healthId: variables.abhaAddress, // + '@' + 'abdm',
        USER_ID: UserID,
      };

      console.log("dataBody", dataBody);
      await Axios.post(`${Config.abhaBaseUrl}getSessionToken`, dataBody, {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
          "x-caller": encrypted,
        },
      })
        .then(async (response) => {
          console.log("res in sessiontoken", response);
          sessionStorage.setItem("accessToken", response.data.accessToken);
          sessionStorage.setItem("abhaAddress", dataBody.healthId);

          if (response.status === 422) {
            await generateMobileOTP(variables.mobileNumber);
            console.log("Otp getting response.status === 422 ");
          } else if (
            response.data.status === "ACTIVE" ||
            response.data.status === 200
          ) {
            setVariables((prevState) => ({
              ...prevState,
              showAlert: true,
              alertTitle: "Sorry!!!",
              alertMessage:
                "This name is already taken as ABHA Adrress. Please try with new address.",
            }));
          } else {
            let details = response.data.details[0];
            setVariables((prevState) => ({
              ...prevState,
              showAlert: true,
              alertTitle: response.data.code,
              alertMessage:
                details && details.length > 0
                  ? details.message
                  : response.data.message,
            }));
            setShowLoading(false);
          }
        })
        .catch(async (error) => {
          console.log("error", error);
          sessionStorage.setItem("abhaAddress", dataBody.healthId);
          var status = await generateMobileOTP();
          console.log("Otp is coming through catch block");
          return status;
        });
    } else {
      setSnackbarMessage("Please enter a both the values.");
      setShowSnackbar(true);
    }
  };

  const generateMobileOTP = async (mobile) => {
    await getPublickey();
    try {
      const accessToken = await sessionStorage.getItem("accessToken");
      const transactionId = await sessionStorage.getItem("transactionId");
      const dataBody = {
        USER_ID: UserID,
        accessToken: accessToken,
        txnId: transactionId,
        mobile: variables.mobileNumber,
      };
      const response = await Axios.post(
        `${Config.abhaBaseUrl}generateMobileOTP`,
        dataBody,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
            "x-caller": encrypted,
          },
        }
      );

      // console.log("response in generateMobileOtp", response, "status", response.status);

      if (response.status === 422) {
        let details = response.data.details[0];
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: response.data.code,
          alertMessage:
            details && details.length > 0
              ? details.message
              : response.data.message,
        }));
        setShowLoading(false);
      } else if (response.status === 200) {
        sessionStorage.setItem("transactionId", response.data.txnId);
        sessionStorage.setItem("abhaAddress", variables.abhaAddress);
        setShowLoading(false);
        setVariables((prevState) => ({
          ...prevState,
          mobileOtp: true,
        }));
      } else {
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: "ABHA Server down!!!",
          alertMessage:
            "The ABHA server is down at this time. Please try after sometime.",
        }));
      }
    } catch (error) {
      console.log(error);
    }
    setShowLoading(false);
  };

  const VerifyMobileOtp = async () => {
    setShowLoading(true);
    try {
      const accessToken = await sessionStorage.getItem("accessToken");
      const txnId = await sessionStorage.getItem("authTransactionId");
      const otpDataBody = {
        txnId: txnId || "",
        otp: variables.otp,
        USER_ID: UserID,
        token: accessToken,
      };

      // console.log("otp databody", otpDataBody)

      const response = await Axios.post(
        `${Config.abhaBaseUrl}verifyMobileOtp`,
        otpDataBody,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
            "x-caller": encrypted,
          },
        }
      );

      //console.log("response in verifyMobileOtp", response, "status", response.status);

      if (response.status === 422) {
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: response.data.code,
          alertMessage:
            response.data.details.length > 0
              ? response.data.details[0].message
              : response.data.message,
        }));
      } else if (response.status === 200) {
        sessionStorage.setItem("transactionId", response.data.txnId);
        await createHealthID(accessToken);
      } else {
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: "ABHA Server down!!!",
          alertMessage:
            "The ABHA server is down at this time. Please try after sometime.",
        }));
      }
      setShowLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 422) {
        let details = error.response.data.details[0];
        console.log(details.message, "===", error.response.data.message);
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: error.response.data.code,
          alertMessage:
            details && details.message
              ? details.message
              : error.response.data.message,
        }));
      } else {
        setVariables((prevState) => ({
          ...prevState,
          showAlert: true,
          alertTitle: "ABHA Server down!!!",
          alertMessage:
            "The ABHA server is down at this time. Please try after sometime.",
        }));
      }
    }
  };

  const createHealthID = async (accessToken) => {
    // var accessToken = await sessionStorage.getItem('accessToken') ?? '';
    var transactionId = (await sessionStorage.getItem("transactionId")) ?? "";
    var abhaAddress = (await sessionStorage.getItem("abhaAddress")) ?? "";

    const payload = {
      healthId: abhaAddress,
      txnId: transactionId,
      accessToken: accessToken,
      USER_ID: UserID,
    };
    console.log(payload, "createHealthID payload");
    await Axios.post(`${Config.abhaBaseUrl}createHealthID`, payload, {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
        "x-caller": encrypted,
      },
    })
      .then(async (response) => {
        console.log("response in createHealthID", response);
        if (response.status === 422) {
          let details = response.data.details[0];
          setVariables((prevState) => ({
            ...prevState,
            showAlert: true,
            alertTitle: response.data.code,
            alertMessage:
              details && details.length > 0
                ? details.message
                : response.data.message,
          }));
          setShowLoading(false);
        } else if (response.status === 200) {
          sessionStorage.setItem("loginData", response.data["token"]);
          await readLoginData(response.data);
        } else {
          setVariables((prevState) => ({
            ...prevState,
            showAlert: true,
            alertTitle: "ABHA Server down!!!",
            alertMessage:
              "The ABHA server is down at this time. Please try after sometime.",
          }));
        }
      })
      .catch((err) => {
        console.log("err in createid", err);
      });
  };

  const readLoginData = async (loginData) => {
    var loginData = loginData ? loginData : sessionStorage.getItem("loginData");
    //console.log("login data", loginData)

    var abhaCard = await getPNGCard(loginData.token);

    // console.log("abhacard", abhaCard)

    await getUserDetails(loginData.token);

    setVariables((prevState) => ({
      ...prevState,
      showAlert: true,
      alertTitle: "Registration Successful!",
      alertMessage:
        "Thank you for integrating with ABHA.Please setup your profile.",
      type: "registerSuccessfull",
    }));
    setShowLoading(false);
  };

  const getPNGCard = async (token) => {
    var accessToken = (await sessionStorage.getItem("accessToken")) ?? "";
    var token = token ? token : sessionStorage.getItem("loginData") ?? "";

    const payload = {
      accessToken: accessToken,
      token: token,
      USER_ID: UserID,
    };

    const response = await Axios.post(
      `${Config.abhaBaseUrl}getPNGCard`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
          "x-caller": encrypted,
        },
      },
      { responseType: "arraybuffer" }
    );

    //console.log("response in getPNGCard", response);

    if (response.status === 422) {
      let details = response.data.details[0];
      setVariables((prevState) => ({
        ...prevState,
        showAlert: true,
        alertTitle: response.data.code,
        alertMessage:
          details && details.length > 0
            ? details.message
            : response.data.message,
      }));
      setShowLoading(false);
    } else if (response.status === 200) {
      let base64ImageString = Buffer.from(response.data, "utf8").toString(
        "base64"
      );
      return base64ImageString;
    } else {
      setVariables((prevState) => ({
        ...prevState,
        showAlert: true,
        alertTitle: "ABHA Server down!!!",
        alertMessage:
          "The ABHA server is down at this time. Please try after sometime.",
      }));
    }
  };

  const getUserDetails = async (token) => {
    var accessToken = (await sessionStorage.getItem("accessToken")) ?? "";
    var token = token ?? "";

    const payload = {
      accessToken: accessToken,
      token: token,
      USER_ID: UserID,
    };

    const response = await Axios.post(
      `${Config.abhaBaseUrl}getUserDetails`,
      payload,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
          "x-caller": encrypted,
          responseType: "arraybuffer",
        },
      }
    );

    //console.log("response in getUserDetails", response);

    if (response.status === 422) {
      let details = response.data.details[0];
      setVariables((prevState) => ({
        ...prevState,
        showAlert: true,
        alertTitle: response.data.code,
        alertMessage:
          details && details.length > 0
            ? details.message
            : response.data.message,
      }));
      setShowLoading(false);
    } else if (response.status === 200) {
      sessionStorage.setItem("profileData", JSON.stringify(response.data));
    } else {
      setVariables((prevState) => ({
        ...prevState,
        showAlert: true,
        alertTitle: "ABHA Server down!!!",
        alertMessage:
          "The ABHA server is down at this time. Please try after sometime.",
      }));
    }
  };

  const uploadAbhaData = async () => {
    let abhaProfileData = await sessionStorage.getItem("profileData");
    const abhaData = JSON.parse(abhaProfileData);

    const body = {
      USER_ID: UserID,
      PATIENT_ID: PATIENT_ID,
      ABHA_NUMBER: abhaData?.healthIdNumber,
      ABHA_ADDRESS: abhaData?.healthId,
    };

    //console.log(body,"me/ABHA_DATA Call data ....")
    const response = await Axios.post(`${Config.apiURL}/me/ABHA_DATA`, body, {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
        "x-caller": encrypted,
      },
    });

    //console.log("response in uplaod abha data", response)
    if (response.status === 201) {
      await handleNavigateToDemographics();
    } else if (response.status === 404) {
      setVariables((prevState) => ({
        ...prevState,
        showAlert: true,
        alertTitle: "Sorry!!!",
        alertMessage: "Data is not submitted Please submit again",
      }));
    } else {
      setVariables((prevState) => ({
        ...prevState,
        showAlert: true,
        alertTitle: "Error!!!",
        alertMessage:
          "An unknown error occurred.Please try again after sometime.",
      }));
    }
  };

  const handleNavigateToDemographics = () => {
    navigate("/abha/demographics");
  };

  const handleInputChange = (e, ctrlName) => {
    const { value } = e.target;

    setVariables({
      ...variables,
      [ctrlName]: value,
    });
  };

  const handleAlertClose = () => {
    setVariables((prevState) => ({
      ...prevState,
      showAlert: false,
    }));
  };

  const handleChange = (otp) => {
    setVariables((prevState) => ({
      ...prevState,
      otp: otp,
    }));
  };

  const handleCancel = () => {
    navigate("/patients");
  };

  return (
    <div className="abhaContainer" style={{ width: "50%" }}>
      <div className="abhaContainerLeft">
        {!variables.mobileOtp && (
          <>
            <p className="abhaTtile"> ABHA Services</p>
            <p className="content">
              Step 2: Choose ABHA Address & mobile to continue.
            </p>
            <div className="Abhanumfield">
              <div
                style={{
                  marginRight: "10px",
                  display: "flex",
                  flexDirection: "row",
                  gap: "0.65rem",
                  alignItems: "center",
                }}
              >
                <input
                  style={{
                    display: "block",
                    width: "70%",
                    marginBottom: "10px",
                  }}
                  className="abhanoInfoInput"
                  type="text"
                  placeholder="Choose your ABHA Address"
                  value={variables.abhaAddress}
                  onChange={(e) => handleInputChange(e, "abhaAddress")}
                />
                <Typography style={{ fontSize: "15px" }}>@abdm</Typography>
              </div>
              <div style={{ padding: "0 0 15px 0" }}>
                <Typography
                  variant="caption"
                  style={{
                    color: "grey",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                >
                  Conditions for setting ABHA Address:
                </Typography>
                <Typography
                  variant="caption"
                  style={{ color: "grey", fontStyle: "italic" }}
                >
                  Example123@abdm,
                </Typography>
                <Typography
                  variant="caption"
                  style={{ color: "grey", fontStyle: "italic" }}
                >
                  4-32 characters alphabets and numbers only,
                </Typography>
                <Typography
                  variant="caption"
                  style={{ color: "grey", fontStyle: "italic" }}
                >
                  it should not start with a number or dot(.),
                </Typography>
              </div>

              <input
                style={{
                  display: "block",
                  width: "70%",
                }}
                className="abhanoInfoInput"
                type="text"
                placeholder="Mobile Number"
                value={variables.mobileNumber}
                onChange={(e) => handleInputChange(e, "mobileNumber")}
              />
            </div>

            <>
              <div className="Adharandmobile">
                <div
                  className={"editButton2"}
                  style={{
                    width: "30%",
                    margin: "0 auto",
                    height: "40px",
                  }}
                  onClick={() => handleCancel()}
                >
                  <p className="OtpBtn">Cancel</p>
                </div>

                <div
                  className={"editButton2"}
                  style={{
                    width: "30%",
                    margin: "0 auto",
                    height: "40px",
                  }}
                  onClick={() => getSessionTokenAPI()}
                >
                  {showLoading && (
                    <CircularProgress size={24} color="inherit" />
                  )}
                  <p className="OtpBtn">Continue</p>
                </div>
              </div>
            </>
          </>
        )}

        {variables.showAlert && (
          <ShowAlert
            title={variables.alertTitle}
            message={variables.alertMessage}
            onClose={handleAlertClose}
          />
        )}

        {variables.mobileOtp && (
          <>
            <h2 style={{ textAlign: "center" }}>ABHA OTP REQUEST</h2>
            <h3 style={{ textAlign: "center" }}>
              Verify OTP to access your ABHA Account
            </h3>
            {variables.mobileNumber && (
              <p>
                Enter the code sent to Mobile number which is linked with your
                Aadhar
                <h4
                  style={{
                    color: "blue",
                    display: "inline",
                    marginLeft: "5px",
                    marginRight: "5px",
                  }}
                >
                  {variables.mobileNumber}
                </h4>
              </p>
            )}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <OtpInput
                value={variables.otp}
                onChange={handleChange}
                numInputs={6}
                renderSeparator={<span>{""}</span>}
                renderInput={(props) => <input {...props} type="tel" />}
                skipDefaultStyles={true}
                inputStyle="otp-input"
                inputType="number"
              />
            </div>

            <div className="Adharandmobile">
              <button
                className={"editButton2"}
                style={{
                  marginRight: "10px",
                  width: "30%",
                  margin: "0 auto",
                  height: "40px",
                  color: "white",
                }}
                onClick={VerifyMobileOtp}
              >
                {showLoading && <CircularProgress size={24} color="inherit" />}
                Verify Otp
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AbhaMobileValidation;
