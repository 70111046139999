// styles.js
import { StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
        width: '100%'
    },
    title: {
        fontSize: 24,
        textAlign: "center",
    },
    user: {
        flexDirection: 'row',
    },
    userInfo: {
        marginRight: '25%',
    },
    image: {
        width: '80px',
        height: '80px',
    },
    dctrname: {
        marginTop: 25,
        fontSize: 30,
        color: 'darkviolet',
        fontWeight: 'bold',
    },
    email: {
        marginTop: 8,
        fontSize: 15,
        color: 'darkviolet',
        fontWeight: 'bold',
    },
    hr: {
        marginTop: 15,
        width: '100%',
        borderBottom: 2,
        borderStyle: 'solid',
        borderColor: 'black',
    },
    presc: {
        marginTop: 15,
        color: 'darkviolet',
        fontSize: 20,
        fontWeight: '900w',
    },

    container: {
        marginTop: 7,
        backgroundColor: 'black',
        flexDirection: 'row',
        marginBottom: 10,
    },
    billingcontainer: {
        marginTop: 7,
        // backgroundColor: 'black',
        flexDirection: 'row',
        marginBottom: 10,
    },
    patient: {
        padding: 10,
        color: 'white',
        fontSize: 13,
    },
    billing: {
        padding: 10,
        color: 'black',
        fontSize: 13,
    },

    Vitals: {
        fontWeight: 'bold',
        fontSize: 20,
    },
    table: {
        display: "table",
        width: "auto",
        borderWidth: 1,
        borderColor: "violet",
        marginTop: 10,
    },
    tableRow: {
        flexDirection: "row",
    },
    tableCol: {
        fontSize: 10,
        flex: 1,
        paddingVertical: 5,
        paddingHorizontal: 10,
        borderRightWidth: 1,
        borderRightColor: "violet", // or any color you prefer
        //borderBottomWidth: 1,
        borderBottomColor: "violet",
    },
    tableColHeader: {
        flex: 1,
        fontSize: 10,
        paddingVertical: 5,
        paddingHorizontal: 10,
        fontWeight: "bold",
        borderRightWidth: 1,
        borderRightColor: "violet", // or any color you prefer
        borderBottomWidth: 1,
        borderBottomColor: "violet",
    },
    hd: {
        marginTop: '4%',
        fontSize: 15,
    },
    advice: {
        marginTop: '1%',
        fontSize: 14,
    },
    totalAmount: {
        marginTop: '1%',
        fontSize: 14,
        textAlign:'right'
    },
    hr1: {
        marginTop: 15,
        width: '100%',
        borderBottom: 2,
        borderStyle: 'solid',
        borderColor: 'lightgrey',
    },
    pageNumber: {
        position: "absolute",
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: "center",
        color: "grey",
    },

    // second table
    table2: {
        display: "table",
        width: "auto",
        // borderWidth: 1,
        // borderColor: "violet",
        marginTop: 10,
    },
    tableRow2: {
        flexDirection: 'row',
    },
    tableColHeader2: {
        flex: 1,
        fontSize: 10,
        paddingVertical: 5,
        paddingHorizontal: 10,
        fontWeight: "bold",
        // borderRightWidth: 1,
        borderRightColor: "darkviolet", // or any color you prefer
        // borderBottomWidth: 1,
        borderBottomColor: "darkviolet",
        backgroundColor: "darkviolet",
        color: "white"
    },
    tableCol2: {
        fontSize: 12,
        flex: 1,
        paddingVertical: 10,
        paddingHorizontal: 10,
        color:"grey",
        borderRightWidth: 1,
        borderRightColor: "violet", // or any color you prefer
        //borderBottomWidth: 1,
        // borderBottomColor: "violet",
    },
});

export default styles;
