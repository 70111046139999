import React, { useState } from "react";
import Navbar from "../../modules/Navbar";
// import "./Examination.css";
import "../../modules/Visits/TreatmentPlan.css";
import TestVisits from "../../modules/TestVisits";
import TreatmentPlan from "../../modules/Visits/TreatmentPlan";


const ExaminationTreatment = () => {

  return (
    <div>
      {/* <Navbar /> */}
      <div className="examination-main">

        <TreatmentPlan
        />
      </div>
    </div>
  );
};

export default ExaminationTreatment;
