import React from "react";
import PropTypes from "prop-types";
import { MDBTable, MDBTableBody, MDBTableHead, MDBBtn } from "mdb-react-ui-kit";
import "./Tablecomponent.css";



const isValidUrl = (string) => {
  try {
    new URL(string);
    return true;
  } catch (error) {
    return false;
  }
};


const TableComponent = ({
  data,
  columns,
  onRowClick,
  LinkAbhaBtn,
  ManageAbhaBtn,
}) => {
  return (
    <div className="TableComponent">
      <MDBTable align="middle" className="custom-table">
        <MDBTableHead light>
          <tr>
            {columns.map((col, index) => (
              <th key={index}>{col.label}</th>
            ))}
            <th>Action</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          {data.map((row, index) => (
            <tr
              key={index}
              onClick={() => onRowClick(row)}
              className="custom-row"
            >
            {columns.map((col, colIndex) => (
              <td key={colIndex}>
              {col.field === 'profile' && isValidUrl(row[col.field]) ? (
                <img src={row[col.field]} alt="Profile" className="profile-pic" />
              ) : (
                row[col.field]
              )}
            </td>
            ))}
              <td>
                {row.userdetails.ABHA_NUMBER === "" ||
                row.userdetails.ABHA_NUMBER === null ? (
                  <button
                    className="linkAbha"
                    onClick={(event) => {
                      event.stopPropagation();
                      LinkAbhaBtn(index, row.userdetails, "linkAbha");
                    }}
                  >
                    Link ABHA
                  </button>
                ) : (
                  <button
                    className="manageAbha"
                    onClick={(event) => {
                      event.stopPropagation();
                      ManageAbhaBtn(index, row.userdetails, "Manage");
                    }}
                  >
                    Manage ABHA
                  </button>
                )}
              </td>
            </tr>
          ))}
        </MDBTableBody>
      </MDBTable>
    </div>
  );
};

// Define PropTypes for better validation and documentation
TableComponent.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      field: PropTypes.string.isRequired,
    })
  ).isRequired,
  // onRowClick: PropTypes.func.isRequired,
  // onButtonClick: PropTypes.func.isRequired,
  LinkAbhaBtn: PropTypes.func.isRequired,
  ManageAbhaBtn: PropTypes.func.isRequired,
};

// Provide default props for columns and data
TableComponent.defaultProps = {
  data: [
    {
      // profile: "https://via.placeholder.com/40", // Placeholder for profile image
      // name: "Eswar Sampath",
      // gender: "Male",
      // mobile: "+91 999 999 99 99",
      // lastVisited: "08 May, 2024",
    },
  ],
  columns: [
    // { label: "Profile", field: "profile" },
    // { label: "Name", field: "name" },
    // { label: "Gender", field: "gender" },
    // { label: "Mobile Number", field: "mobile" },
    // { label: "Last Visited", field: "lastVisited" },
  ],
};

export default TableComponent;
