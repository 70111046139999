import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import { useNavigate } from "react-router-dom";
import BackArrow from "../../../images/Vector 34.svg";
import Checkbox from "@mui/material/Checkbox";
// import Button from "@mui/material/Button";
import { Button, CircularProgress } from "@mui/material";
import axios from "axios";
import Config from "../../../Config/config";
import { publicEncrypt, constants } from "crypto-browserify";

const LabTests = () => {
  const [variables, setVariables] = useState({
    aimHealthyUsers: [],
    aimSearch: "",
    searchQuery: "",
    selectedPatient: null,
    showPopup: false,
  });

  const UserID = sessionStorage.getItem("RegUserId")
    ? JSON.parse(sessionStorage.getItem("RegUserId"))
    : sessionStorage.getItem("UserID");

  const publicKey = sessionStorage.getItem("RegpublicKey")
    ? sessionStorage.getItem("RegpublicKey")
    : JSON.parse(sessionStorage.getItem("publicKey"));
  const token = sessionStorage.getItem("Regtoken")
    ? sessionStorage.getItem("Regtoken")
    : JSON.parse(sessionStorage.getItem("token"));

  const padding = constants.RSA_PKCS1_PADDING;
  const data = Buffer.from(UserID.toString(), "utf-8");
  const encrypted = publicEncrypt(
    { key: publicKey, padding: padding },
    data
  ).toString("base64");

  const PATIENT_ID = JSON.parse(sessionStorage.getItem("PATIENT_ID"));
  // console.log("patieeeeeeeeeee", PATIENT_ID)

  const selectedPatientHistory = sessionStorage.getItem(
    "selectedPatientHistory"
  )
    ? JSON.parse(sessionStorage.getItem("selectedPatientHistory"))
    : [];
  //console.log("selectedPatientHistory----------", selectedPatientHistory.VISIT_DATE)

  const VISIT_ID = sessionStorage.getItem("VISIT_ID");
  const [visitData, setVisitData] = useState();

  function formatVisitDate(visitDate) {
    // Parse the initial visit date string into a Date object
    const initialDate = new Date(visitDate);

    // Extract the date and time components
    const year = initialDate.getFullYear();
    const month = String(initialDate.getMonth() + 1).padStart(2, "0");
    const day = String(initialDate.getDate()).padStart(2, "0");
    const hours = String(initialDate.getHours()).padStart(2, "0");
    const minutes = String(initialDate.getMinutes()).padStart(2, "0");
    const seconds = String(initialDate.getSeconds()).padStart(2, "0");
    const milliseconds = String(initialDate.getMilliseconds()).padStart(5, "0");

    // Construct the formatted visit date string
    const formattedVisitDateString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
    //console.log("======----formated", formattedVisitDateString)

    return formattedVisitDateString;
  }

  const VISITDATA = {
    patientId: selectedPatientHistory.PATIENT_ID,
    mobile: selectedPatientHistory.MOBILE,
    nurseId: selectedPatientHistory.NURSE_USER_ID,
    doctorId: selectedPatientHistory.DOCTOR_USER_ID,
    visitId: selectedPatientHistory.VISIT_ID,
    visitDate: formatVisitDate(selectedPatientHistory.VISIT_DATE),
    medicalHistory: {
      isDiabetes: null,
      isHypertension: null,
      isCKD: null,
      isLiverFailure: null,
      isCOPD: null,
      isAsthma: null,
      isHeartDisease: null,
      isStroke: null,
      isPVD: null,
      isHeartAttack: null,
      isFamilyHypertension: null,
      isFamilyDiabetes: null,
      isFamilyCardioVascular: null,
      isFamilyOtherDisease: null,
      cheifComplaint: [],
      otherChiefComplaint: "",
    },
    drugHistory: {
      isHypertensiveDrugs: null,
      isDiabeticDrugs: null,
      isLipidDrugs: null,
      isAsprin: null,
      isInsulin: null,
      currentHTDrugs: [],
      currentDBDrugs: [],
      currentInsulin: [],
      currentAsprin: [],
      currentLipid: [],
    },
    personalHistory: {
      isPhysicalActivity: null,
      isSmoking: null,
      isDrinking: null,
      drinkingFrequency: null,
      isFamilyHistoryOfMI: null,
      isPolyuria: null,
      isPolydypsia: null,
      isWeightloss: null,
      isOnDialysis: null,
    },
    examinationHistory: {
      sbp: null,
      dbp: null,
      heartRate: null,
      sbp2: null,
      dbp2: null,
      sbp3: null,
      dbp3: null,
      averageSBP: null,
      averageDBP: null,
      fbs: null,
      ppbs: null,
      rbs: null,
      hba1c: null,
      waist: null,
      height: null,
      weight: null,
      bmi: null,
      ldl: null,
      creatinine: null,
      cholestrol: null,
      isEdema: null,
      isBreathlessness: null,
      isChestPain: null,
      isDeficit: null,
      isAlteredConciousness: null,
      isFundus: null,
      isGeneralizedSeizures: null,
      gfr: null,
    },
    htDiagnosis: {
      diagnosis: null,
      control: null,
      recommendation: null,
      notes: null,
      followup: null,
      doctorAcceptance: null,
      doctorNotes: null,
      doctorId: null,
      systemDecision: null,
    },
    dbDiagnosis: {
      diagnosis: null,
      control: null,
      recommendation: null,
      notes: null,
      followup: null,
      doctorAcceptance: null,
      doctorNotes: null,
      doctorId: null,
      systemDecision: null,
    },
    copdDiagnosis: [],
    tbDiagnosis: [],
    copdHistory: {
      isCopd: null,
      isProgressiveDyspnoea: null,
      isChronicCough: null,
      isChronicSputum: null,
      isChestPain: null,
      isFever: null,
      isWeightLoss: null,
      isOrthopnoea: null,
      isParoxysmalNocturnal: null,
      isHaemoptysis: null,
      isSpirometeryTest: null,
      spirometeryValue: null,
      isSmoking: null,
      isBiomassFuel: null,
      isPollution: null,
      mmrcGrade: null,
      isDifficultInBreathing: null,
      isExacerbation: null,
      isAdmitHospitalWithExacerbation: null,
      severeBreathingDifficulty: null,
      exacerbationSymptoms: [],
      severeExacerbationSymptoms: [],
      drugOptions: [],
    },

    tbHistory: {
      confirmedTBType: [],
      presumptiveTBType: [],
      suspectedTBSymptoms: [],
      suspectedComorbidities: [],
      suspectedOtherTBDrugs: [],
      tbPreviousDrugList: [],
      tbCurrentDrugList: [],
      tbAdditionalDrugList: [],
    },
    osteoArthritisHistory: {
      oaAComorbidList: [],
      oaPharmaList: [],
      oaLifestyleList: [],
      oaNonPharmaList: [],
    },
    orderTest: {
      clinicalPathology: [],
      radiology: [],
      bioChemistry: [],
      stoolAnalysis: [],
      serology: [],
      urineAnalysis: [],
      cardiology: [],
    },
  };

  const [isLoadingSave, setIsLoadingSave] = useState(false);
  const navigate = useNavigate();

  const [selectedTests, setSelectedTests] = useState(VISITDATA.orderTest);
  //   clinicalPathology: [],
  //   radiology: [],
  //   bioChemistry: [],
  //   stoolAnalysis: [],
  //   serology: [],
  //   urineAnalysis: [],
  //   cardiology: [],
  // });

  const labTestsData = {
    clinicalPathology: [
      "Hemoglobin Estimation(Hb)",
      "Total Leukocyte count(TLC)",
      "Differential Leukocyte Count(DLC)",
      "MO(Slide method)",
      "ESR",
      "Prothrombin Time Test and INR (Clotting Time)",
      "Blood Group(ABO-RH typing)",
      "Platelet count by cell counter",
    ],
    radiology: [
      "Chest X-Ray",
      "Magnetic Resonance Imaging(MRI)",
      "Magnetic Resonance Angiogram(MRA)",
      "Ultrasound",
      "CT - Scan",
      "Mammography",
      "PET CT Scan",
      "Fluoroscopy",
      "Biopsy",
    ],
    bioChemistry: [
      "Random Blood Sugar",
      "Fasting Blood Sugar",
      "Postprandial Blood Sugar",
      "HbA1C",
      "Serum Creatinine",
      "S. Bilirubin(T)",
      "Bilirubin(D)",
      "Lipid Profile",
    ],
    stoolAnalysis: ["Stool for OVA and Cyst"],
    serology: [
      "Rapid Plasma Reagin(RPR) kit test",
      "HIV Rapid Test – ELISA",
      "Dengue (Rapid test)",
      "Malaria(Rapid test)",
      "Sputum for AFB",
    ],
    urineAnalysis: [
      "Urine Sugar and Albumin",
      "Urine Pregnancy test(UPT)",
      "Proteinuria",
      "Urine Creatinine",
    ],
    cardiology: ["Electrocardiogram(ECG)", "Echocardiogram", "Stress test"],
  };

  useEffect(() => {
    const fetchDataAndSetLabTests = async () => {
      try {
        let data = JSON.stringify({
          userId: UserID,
          id: PATIENT_ID,
        });
        //console.log("data in visits", data)
        let config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${Config.apiURL}${Config.routes.getPatientVisitsAPI}`,

          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "x-caller": encrypted,
          },
          data: data,
        };

        const response = await axios.request(config);
        //console.log("Response from API:", response, response.data.data);

        const sessionVisitID = parseInt(VISIT_ID, 10);

        const filteredRecords = response.data.data.filter((record) => {
          return record.VISIT_ID === sessionVisitID;
        });
        //console.log("Filtered Records:", filteredRecords, typeof VISIT_ID);

        if (filteredRecords[0].VISIT_DATA === null) {
          // console.log("cmng to if")
          setVisitData(VISITDATA);
        } else {
          let res = await filteredRecords[0].VISIT_DATA;
          //console.log("---res----", res)

          let LabTestsData = res ? JSON.parse(res).orderTest : null;
          //console.log("data===", LabTestsData)

          let parsedRes = !!res ? JSON.parse(res) : null;
          //console.log("---parsedRes----", parsedRes)
          setVisitData(parsedRes);

          if (LabTestsData !== null) {
            const parsedLabData = JSON.parse(LabTestsData);

            //console.log("revathi---", parsedLabData)

            setSelectedTests({
              ...selectedTests,
              clinicalPathology: parsedLabData.clinicalPathology,
              radiology: parsedLabData.radiology,
              bioChemistry: parsedLabData.bioChemistry,
              stoolAnalysis: parsedLabData.stoolAnalysis,
              serology: parsedLabData.serology,
              urineAnalysis: parsedLabData.urineAnalysis,
              cardiology: parsedLabData.cardiology,
            });
          }
        }
      } catch (err) {
        console.log(err, "err");
      }
    };

    fetchDataAndSetLabTests();
  }, [VISIT_ID]);

  const handleTestSelection = (category, test) => {
    setSelectedTests((prevSelectedTests) => {
      const newSelectedTests = { ...prevSelectedTests };
      if (newSelectedTests[category]?.includes(test)) {
        newSelectedTests[category] = newSelectedTests[category].filter(
          (selectedTest) => selectedTest !== test
        );
      } else {
        newSelectedTests[category] = [
          ...(newSelectedTests[category] || []),
          test,
        ]; // Ensure array is initialized
      }
      return newSelectedTests;
    });
  };

  const handleBackClick = () => {
    //console.log("hello")
    navigate(
      `/patients/testvisits/${
        PATIENT_ID || variables.selectedPatient.USER_ID
      }?source=patients`
    );
  };

  function replacer(key, value) {
    try {
      if (key.trim() == "") return value;
      if (value && value !== null && typeof value === "object") {
        // console.log("value inside if", value)
        return value.replace(/"/g, '\\"'); // Replace " with \"  }   return value; }
      }
      return value;
    } catch (err) {
      // console.log("======key===", key, "===========value==", typeof value, "=======", value)
      // console.log(err);
      if (Array.isArray(value) && value.length === 0) return value;
      else if (
        key.trim() === "tbHistory" ||
        key.trim() === "osteoArthritisHistory"
      ) {
        let jsonString = JSON.stringify(value).replace(/"/g, '\\"');
        // console.log(jsonString, '1#####');
        jsonString = `\"${jsonString}\"`;
        // console.log(jsonString, '2#####');
        return jsonString;
      } else return JSON.stringify(value);
    }
  }

  const saveRecord = async () => {
    //console.log("hiee");
    try {
      // console.log("vist========", visitData)
      if (visitData) {
        visitData.orderTest = JSON.stringify(selectedTests);

        let VisitData = JSON.stringify(visitData, replacer);
        //console.log("Stringified VisitData---", VisitData);

        //console.log("stringifiedVisitData---", VisitData)
        sessionStorage.setItem("visitData", VisitData);
        setIsLoadingSave(true);
        //console.log("commin")
        const response = await axios.post(
          `${Config.base_url}PATIENT_VISIT_DATA`,
          {
            USER_ID: UserID,
            VISIT_ID: visitData?.visitId?.toString(),
            VISIT_DATA: VisitData,
            STATUS: 2,
            PRESCRIPTION_DATA: null,
            CREATED_BY: visitData?.nurseId?.toString(),
          },
          {
            headers: {
              authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              "x-caller": encrypted,
            },
          }
        );

        if (response.status === 201) {
          setIsLoadingSave(false);
          // await fetchData();
          navigate(
            `/patients/testvisits/${
              PATIENT_ID || variables.selectedPatient.USER_ID
            }?source=patients`
          );
          return true;
        } else {
          console.error("Error: Data not saved");
          return false;
        }
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error:", error);
      return false;
    }
  };

  // console.log("==selectedTests==", selectedTests)

  return (
    <>
      {/* <Navbar /> */}
      <div
        style={{
          // marginTop: "64px",
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          padding: "20px",
          zIndex: 1,
          position: "sticky",
        }}
      >
        <div
          style={{
            backgroundColor: "#8873C9",
            width: "100%",
            padding: "10px",
            marginBottom: "10px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            position: "sticky",
            top: 0,
            zIndex: 1,
          }}
        >
          <div style={{ cursor: "pointer", flex: 1 }} onClick={handleBackClick}>
            <img
              src={BackArrow}
              alt="Back"
              style={{ width: "20px", height: "20px" }}
            />
          </div>
          <h3 style={{ margin: 0, color: "#FFFFFF" }}>ORDER TESTS</h3>
          <div style={{ flex: 1 }}></div>
        </div>
        <div
          style={{
            overflowY: "auto",
            width: "100%",
            height: "100%",
            backgroundColor: "#FFFFFF",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {Object.entries(labTestsData).map(([category, tests]) => (
            <div key={category} style={{ marginBottom: "20px", width: "100%" }}>
              <h2
                style={{
                  marginBottom: "10px",
                  backgroundColor: "#8873C9",
                  color: "#FFFFFF",
                  padding: "10px",
                }}
              >
                {category.charAt(0).toUpperCase() + category.slice(1)}
              </h2>
              {tests.map((test) => (
                <div
                  key={test}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "5px",
                  }}
                >
                  <Checkbox
                    checked={selectedTests[category].includes(test)}
                    onChange={() => handleTestSelection(category, test)}
                  />
                  <span style={{ marginLeft: "5px", width: "100%" }}>
                    {test}
                  </span>
                </div>
              ))}
            </div>
          ))}

          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <Button
              style={{ backgroundColor: "#8873C9", marginTop: "20px" }}
              onClick={saveRecord}
              disabled={isLoadingSave}
              variant="contained"
            >
              Save
              {isLoadingSave && <CircularProgress size={24} color="inherit" />}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default LabTests;
