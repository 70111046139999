import React, { useEffect, useState } from "react";
import Axios from "axios";
import { publicEncrypt, constants } from "crypto-browserify";
import { Buffer } from "buffer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import downArrow from "../../images/Group 102.svg";
import Config from "../../Config/config.jsx";
import Select from "react-select";
import DegreeImg from "../../images/education-graduation-learning-school-study 3.svg";
import DeleteIcon from "../../images/delete 8.svg";

const RegEducation = ({ setActiveSection,EducationDetailsCount }) => {
  const [variables, setVariables] = useState({
    listOfCategories: [],
    showOtherInput: false,
    showOtherSpeciality: false,
  });

  const [degree, setDegree] = useState({
    selectedDegree: "",
    otherDegree: "",
  });
  const [speciality, setSpeciality] = useState({
    selectedSpeciality: "",
    otherSpeciality: "",
  });

  // const [activeSection, setActiveSection] = useState("Education");
  // const [medicalRegistration, setMedicalRegistration] = useState({
  //   registrationCouncil: "",
  //   registrationYear: "",
  //   registrationNumber: "",
  // });

  const [listOfQualification, setListOfQualification] = useState([]);

  const UpdatdListOfQualifications = listOfQualification.map((item) => ({
    degreeType: item.speciality.selectedSpeciality,
    degree: item.degree.selectedDegree,
    year: item.year,
  })); //to display the list of items added
  const RegMobileNum = sessionStorage.getItem("RegMobileNum");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const UserID = JSON.parse(sessionStorage.getItem("RegUserId"));
        const publicKey = sessionStorage.getItem("RegpublicKey");
        const token = sessionStorage.getItem("Regtoken");
        const padding = constants.RSA_PKCS1_PADDING;
        const data = Buffer.from(UserID.toString(), "utf-8");
        const encrypted = publicEncrypt(
          { key: publicKey, padding: padding },
          data
        ).toString("base64");
        // console.log("encrypted", encrypted);

        const response = await Axios.get(Config.base_url + "GET_DEGREE", {
          headers: {
            authorization: `Bearer ${token}`,
            "x-caller": encrypted,
          },
        });
        //console.log(response.data);
        // const categories = response.data.recordset.map((item) => item.CATEGORY);
        // setListOfCategories(categories);
        setVariables((prevState) => ({
          ...prevState,
          listOfCategories: response.data.recordset,
        }));
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const degrees = [
    { label: "MBBS", value: "MBBS" },
    { label: "BDS", value: "BDS" },
    { label: "BAMS", value: "BAMS" },
    { label: "BHMS", value: "BHMS" },
    { label: "BUMS", value: "BUMS" },
    { label: "BSMS", value: "BSMS" },
    { label: "BNYS", value: "BNYS" },
    { label: "Others", value: "Others" },
  ];

  useEffect(() => {
    if (Object.keys(speciality, degree, variables).length > 0) {
      CheckStatusCount();
    }
  }, []);

  const calculateCount = (obj, type) => {
    const numberOfFields = Object.keys(obj).length;
    const percentageIncrement = 100 / numberOfFields;
    let newTotalCount = 0;

    Object.keys(obj).forEach((key) => {
      if (obj[key] === "") {
        // console.log(
        //   `The field ${key} in ${type} is empty, percentage: ${percentageIncrement}`
        // );
      } else {
        newTotalCount += percentageIncrement;
        // console.log(
        //   `The field ${key} in ${type} is not empty, value: ${obj[key]}, accumulated percentage: ${newTotalCount}`
        // );
      }
    });

    return newTotalCount;
  };

  const CheckStatusCount = () => {
    const specialityCount = calculateCount(speciality, "speciality");
    const degreeCount = calculateCount(degree, "degree");
    const variablesCount = calculateCount(variables, "variables");

    const totalCount = (specialityCount + degreeCount + variablesCount) / 3;
    EducationDetailsCount(totalCount,"EducationDetailsCount")
    //console.log(totalCount, "totalCount");
  };

  const handleDegreeChange = (deg) => {
    //console.log("deg", deg);
    const selectedDegree = deg.value;
    if (deg.value === "Others") {
      setVariables({
        ...variables,
        showOtherInput: true,
        filteredCategories: [],
      });
      setDegree({ selectedDegree: "" });
      setSpeciality({ selectedSpeciality: "" });
    } else {
      const categoryOptions = variables.listOfCategories.filter(
        (item) => item.DEGREE === selectedDegree
      );
      
      if(categoryOptions && categoryOptions.length > 0) {
          categoryOptions.push({ DEGREE: selectedDegree, CATEGORY: "Others" });
      }
      setVariables({
        ...variables,
        showOtherInput: false,
        filteredCategories: categoryOptions,
      });
      setDegree({ selectedDegree: deg });
      setSpeciality({ selectedSpeciality: "" });
    }
  };

  const getYears = (startYear) => {
    const currentYear = new Date().getFullYear();
    const yearsTilPresent = [];

    while (startYear <= currentYear) {
      yearsTilPresent.push({
        label: startYear.toString(),
        value: startYear.toString(),
      });
      startYear++;
    }

    return yearsTilPresent;
  };

  const years = getYears(1994);

  const handleYearChange = (year) => {
    setVariables({ ...variables, selectedYear: year });
  };

  const handleSpecalityChange = (category) => {
    
    if(category.value === "Others") {
      setVariables({ ...variables, showOtherSpeciality: true});
      setSpeciality({ selectedSpeciality: "" });
    } else {
      setVariables({ ...variables, showOtherSpeciality: false});
      setSpeciality({ selectedSpeciality: category });
    }
  };

  const handleOtherDegreeInputChange = (e) => {
    setDegree({ ...degree, otherDegree: e.target.value });
  };

  const addEducation = () => {
    if (degree.selectedDegree !== "" && variables.selectedYear !== undefined && variables.selectedYear !== "") {
      const data = {
        degree: degree,
        year: variables.selectedYear,
        speciality: speciality,
      };
      setListOfQualification([...listOfQualification, data]);
      setVariables({ ...variables, selectedYear: "", showOtherInput: false, showOtherSpeciality: false });
      setDegree({ selectedDegree: "", otherDegree: "" });
      setSpeciality({ selectedSpeciality: "", otherSpecality: "" });
      CheckStatusCount();
    } else {
      toast.warning("Please fill the mandatory fields");
    }
  };

  // console.log("listof", listOfQualification, UpdatdListOfQualifications)

  const removeEducation = (indexToRemove) => {
    const updatedEducationList = listOfQualification.filter(
      (_, index) => index !== indexToRemove
    );
    setListOfQualification(updatedEducationList);
  };

  const DropdownIndicator = (props) => {
    return (
      <svg
        width="40px"
        height="30px"
        viewBox="0 0 24 24"
        cursor="pointer"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"
          fill="black" // Set your desired arrow color here
        />
      </svg>
    );
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderColor: "rgba(82, 68, 125, 1)", // Set your desired border color here
      borderRadius: "10px",
      borderWidth: "2px", // Set` your desired border thickness here
      // width: "350px", // Set your desired width here, e.g., "200px"
      height: "50px",
      boxShadow: "none",
      "&:hover": {
        borderColor: "black", // option hover color here
        color: "rgba(82, 68, 125, 1)", // option text color for hover
      },
      backgroundColor: "#FFF",
      border: "1px solid #DFDFDF",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      //  color: "black", // Set your desired arrow color here
      color: "transparent",
    }),
    //   indicatorsContainer: (provided) => ({
    //     ...provided,
    //     width: "50px", // Set your desired arrow width here
    //     height: "50px", // Set your desired arrow height here
    //   }),
    option: (provided, state) => ({
      ...provided,
      "&:hover": {
        backgroundColor: "white", // option hover color here
        color: "black", // option text color for hover
      },
      backgroundColor: state.isSelected
        ? "blue" // Set your desired selected option color here
        : "white",
        color:"Black"
    }),
  };

  const handleNext = async () => {
    if (listOfQualification.length === 0) {
      toast.warning("Please Add Your Degree Details");
      return;
    }
    sessionStorage.setItem(
      "UpdatdListOfQualifications",
      JSON.stringify(UpdatdListOfQualifications)
    );
    setActiveSection("Medical");
  };

  return (
    <>
      <div
        className="profileRightContainer"
        style={{ display: "flex", gap: "1rem" }}
      >
        <div className="leftContainer" style={{ width: "70%" }}>
          <div className="topContainer">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
                alignItems: "center",
              }}
            >
              <label
                className="headerEdu"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "0.75rem",
                }}
              >
                Education Qualification
              </label>
            </div>
          </div>
          <div style={{ background: "#AEAEAE", width: "100%", height: "2px" }}>
            <p style={{ height: "2px" }}></p>
          </div>

          <div className="rege-bottomContainer">
            
            <>
              <div
                className="personalInfoContainer"
                style={{
                  display: "flex",
                  // width:"70%",
                  height:"19vh"
                }}
              >
              <div className="rege-headingContainer">
                <p className="InfoHeading">
                  Please enter your basic level Qualification
                </p>
              </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "60%",
                  }}
                >
                  <Select
                    id="stateDropdown"
                    value={degree.selectedDegree}
                    onChange={handleDegreeChange}
                    options={degrees}
                    placeholder="Degree*"
                    isSearchable
                    // isClearable
                    styles={customStyles}
                    components={{ DropdownIndicator }}
                  />
                </div>

                {variables.showOtherInput && (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "60%",
                      }}
                    >
                      <input
                        style={{
                          display: "block",
                          width: "100%",
                        }}
                        className="otherInput"
                        type="text"
                        value={variables.otherDegree}
                        onChange={handleOtherDegreeInputChange}
                        placeholder="Enter Other Degree"
                      />
                    </div>
                  </>
                )}

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "60%",
                  }}
                >
                  <Select
                    id="stateDropdown"
                    value={variables.selectedYear}
                    onChange={handleYearChange}
                    options={years}
                    placeholder="Year of Completion*"
                    isSearchable
                    styles={customStyles}
                    components={{ DropdownIndicator }}
                  />
                </div>

                {variables.showOtherInput ? (
                  <>
                    <input
                      type="text"
                      value={variables.otherSpecality}
                      onChange={(e) =>
                        setSpeciality({
                          ...speciality,
                          otherSpecality: e.target.value,
                        })
                      }
                      placeholder="Enter Specialty"
                      style={{
                        display: "block",
                        width: "60%",
                      }}
                      className="otherInput"
                    />
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "60%",
                      }}
                    >
                      {degree.selectedDegree !== "" &&
                        variables.selectedYear !== "" && (
                          <Select
                            id="specialityDropdown"
                            value={speciality.selectedSpeciality}
                            onChange={handleSpecalityChange}
                            options={variables.filteredCategories.map(
                              (item) => ({
                                label: item.CATEGORY,
                                value: item.CATEGORY,
                              })
                            )}
                            placeholder="Choose the Speciality"
                            isSearchable
                            styles={customStyles}
                            components={{ DropdownIndicator }}
                          />
                          
                        )}
                        {variables.showOtherSpeciality && (
                          <>
                          <input
                            type="text"
                            value={variables.otherSpecality}
                            onChange={(e) =>
                              setSpeciality({
                                ...speciality,
                                otherSpecality: e.target.value,
                              })
                            }
                            placeholder="Enter Specialty"
                            style={{
                              display: "block",
                              width: "100%",
                              marginTop: "20px"
                            }}
                            className="otherInput"
                          />
                          </>
                        )}
                    </div>
                  </>
                )}
                {/* <div
                  style={{
                    background: "#AEAEAE",
                    width: "100%",
                    height: "1px",
                  }}
                ></div> */}

                

                {/* {listOfQualification.every(
                  (education) =>
                    education.speciality &&
                    (education.speciality.selectedSpeciality ||
                      education.speciality.otherSpecality) &&
                    education.degree &&
                    (education.degree.selectedDegree ||
                      education.degree.otherDegree) &&
                    education.year &&
                    education.year.value
                ) &&
                  listOfQualification.map((education, index) => (
                    <div
                      key={index}
                      style={{ height: "9vh" }}
                      className="educationCard"
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "6px",
                        }}
                      >
                        <div className="logo">
                          <img src={DegreeImg} />
                        </div>
                        <div className="degreeName">
                          <p>
                            {education.speciality?.selectedSpeciality?.value ||
                              education.speciality?.otherSpecality ||
                              ""}
                          </p>
                          <div className="degreeName">
                            <p>
                              {((education.degree?.selectedDegree?.value ||
                                education.degree?.otherDegree) &&
                                (education.year?.value
                                  ? ` (${education.year.value})`
                                  : "")) ||
                                null}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        className="deleteicon"
                        onClick={() => removeEducation(index)}
                      >
                        <img src={DeleteIcon} />
                      </div>
                    </div>
                  ))} */}
                                <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "1rem",
                  width: "60%"
                }}
              >
                <div
                  style={{
                    width: "30%",
                    height: "45px",
                    background: "#52447d",
                  }}
                  className={"editButton2"}
                  onClick={addEducation}
                >
                  <p
                    style={{
                      color: "white",
                      fontSize: "14px",
                      fontFamily: "Verdana",
                      fontWeight: "400",
                      lineHeight: "18.38px",
                      letterSpacing: "15%",
                    }}
                  >
                    ADD
                  </p>
                </div>
                <div
                  className={"editButton2"}
                  style={{ width: "30%" }}
                  onClick={handleNext}
                >
                  <p style={{ color: "white" }}>Next</p>
                </div>
              </div>
              </div>
              <div className="regEducards-cardsCols">
              {listOfQualification.map((education, index) => (
                  <>
                    <div
                      key={index}
                      className="educationCard educationd-cardContainer"
                    >
                      <div className="educationd-cardImgContainer">
                      <div className="educationd-cardImg">
                        <img src={DegreeImg} />
                      </div>
                      <div
                        className="innerdeleteicon"
                        onClick={() => removeEducation(index)}
                      >
                        <img src={DeleteIcon} alt="Delete" />
                      </div>
                    </div>
                    <div
                      className="educationd-cardContentContainer">
                      <p>Degree: {(education.degree?.selectedDegree?.value ||
                                education.degree?.otherDegree)}</p>
                      <p>Year: {(education.year?.value
                                  ? ` (${education.year.value})`
                                  : "")}</p>
                      <p>Specialty: {education.speciality?.selectedSpeciality?.value ||
                            (education.speciality?.otherSpecality && (
                              <p>
                                {education.speciality?.selectedSpeciality
                                  ?.value ||
                                  education.speciality?.otherSpecality}
                              </p>
                            ))}</p>
                    </div>
                        {/* <div className="degreeName">
                          {education.speciality?.selectedSpeciality?.value ||
                            (education.speciality?.otherSpecality && (
                              <p>
                                {education.speciality?.selectedSpeciality
                                  ?.value ||
                                  education.speciality?.otherSpecality}
                              </p>
                            ))}
                          <div className="degreeName">
                            <p>
                              {(education.degree?.selectedDegree?.value ||
                                education.degree?.otherDegree) +
                                (education.year?.value
                                  ? ` (${education.year.value})`
                                  : "")}
                            </p>
                          </div>
                        </div> */}
                    </div>
                  </>
                ))}
              </div>
            </>
           
          </div>
          <ToastContainer />
        </div>
      </div>
    </>
  );
};
export default RegEducation;
