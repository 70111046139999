import React from "react";
import "./ArticleData.css";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import config from "../../../Config/config";

const Covid = () => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const CovidUrls = [
    {
      title: "Covidurls",
      urls: [
        {
          type: "image",
          url: `${config.apiURL}/uploads/articlespdfs/home_care_2_covid_22_03_en.jpg`,
        },
        {
          type: "image",
          url: `${config.apiURL}/uploads/articlespdfs/hc_children_covid_07_04.jpg`,
        },
        {
          type: "link",
          url: "https://www.who.int/news-room/questions-and-answers/item/coronavirus-disease-covid-19",
          title: "Coronavirus Disease (COVID-19)",
          description:
            "WHO is continuously monitoring and responding to this pandemic. This questions and answers page will be ...",
          image:
            "https://www.who.int/images/default-source/departments/ncds/cardiovascular-diseases/who-005446-orig.tmb-1366v.jpg?Culture=en&sfvrsn=27864450_8%201366w",
        },
        {
          type: "pdf",
          url: `${config.apiURL}/uploads/articlespdfs/UNI308972.pdf`,
        },
        {
          type: "link",
          url: "https://www.unicef.org/jamaica/infographics-parenting-covid-19",
          title: "Infographics:on Parenting in COVID-19 | UNICEF Jamaica",
          description:
            "Developed with the Jamaica Council for Persons with Disabilities (JCPD) to help families cope during COVID-19",
          image:
            "https://www.unicef.org/jamaica/sites/unicef.org.jamaica/files/styles/hero_extended/public/Jamaica-00000276.jpg.webp?itok=BX23Stm3://www.unicef.org/jamaica/sites/unicef.org.jamaica/files/styles/media_banner/public/2020-09/Jamaica_COVID_19_Infographics_1280px-x-720px_02.png?itok=fuETi1AG",
        },
        {
          type: "pdf",
          url: `${config.apiURL}/uploads/articlespdfs/PSS-COVID19-Manual-ChildLine.pdf`,
        },
      ],
    },
  ];

  return (
    <>
    <div className="Articles-digitalHealth">
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js" />

        {CovidUrls[0].urls.map((item, index) => {
          if (item.type === "pdf") {
            return (
              <div className="articles-hyper-pdf" key={index}>
                <Viewer
                  fileUrl={item.url}
                  plugins={[defaultLayoutPluginInstance]}
                />
              </div>
            );
          } else if (item.type === "image") {
            return (
              <div className="articles-hyper-image" key={index}>
                <img
                  crossorigin="anonymous"
                  src={item.url}
                  alt={`Image ${index}`}
                />
              </div>
            );
          } else if (item.type === "link") {
            return (
              <div className="articles-hyper-link">
                <a href={item.url} target="_blank" rel="noopener noreferrer">
                  <div>
                    <p>{item.url}</p>
                  </div>
                </a>
                <h3>{item.title}</h3>
                <p>{item.description}</p>
                <img src={item.image} alt="Link Image" />
              </div>
            );
          }
          return null;
        })}
      </div>
    </>
  );
};

export default Covid;
