import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import TestProfile from "../../../images/testVisitsProfile.svg";
import AddDocument from "../../../images/addDocument.svg";
import ConsolidatedReport from "../../../images/consolidatedReport.svg";
import Emergency from "../../../images/emergency.svg";
import InitialAssesments from "../../../images/initialAssesment.svg";
import ReviewAssessments from "../../../images/reviewsnassessments.svg";
import Arrow from "../../../images/arrow.svg";
import downArrow from "../../../images/downArrow.svg";
import leftArrow from "../../../images/leftArrow.svg";
import { useNavigate } from "react-router-dom";
import BackArrow from "../../../images/leftArrow.svg";
import ReactEcharts from "echarts-for-react";
import "echarts/lib/component/tooltip";
import { publicEncrypt, constants } from "crypto-browserify";
import { Buffer } from "buffer";
import Axios from "axios";
import Config from "../../../Config/config";
import { act } from "@testing-library/react";

const Graphs = () => {

  const navigate = useNavigate();

  const [chartData, setChartData] = useState([]);
  const UserID = sessionStorage.getItem("RegUserId")
    ? JSON.parse(sessionStorage.getItem("RegUserId"))
    : sessionStorage.getItem("UserID");

  const publicKey = sessionStorage.getItem("RegpublicKey")
    ? sessionStorage.getItem("RegpublicKey")
    : JSON.parse(sessionStorage.getItem("publicKey"));
  const token = sessionStorage.getItem("Regtoken")
    ? sessionStorage.getItem("Regtoken")
    : JSON.parse(sessionStorage.getItem("token"));

  const padding = constants.RSA_PKCS1_PADDING;
  const data = Buffer.from(UserID.toString(), "utf-8");
  const encrypted = publicEncrypt(
    { key: publicKey, padding: padding },
    data
  ).toString("base64");
  const patient_id = sessionStorage.getItem("PATIENT_ID");
  //console.log("patient_id", patient_id);


  const handleBackClick = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const sourcePage = urlParams.get('source');
    const tab = urlParams.get('tab');

    if (sourcePage === 'followups') {
      if (tab === 'reminders') {
        navigate("/followups?tab=reminders");
      } else {
        navigate("/followups");
      }
    }
    else if (sourcePage === 'appointments') {
      navigate("/appointments");
    }
    else {
      navigate("/patients");
    }
  };

  const patientDetails = JSON.parse(
    sessionStorage.getItem("selectedPatientDetails")
  );
  // console.log("patientDetails", patientDetails.FIRST_NAME);

  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${Config.apiURL}${Config.routes.getPatientChartDataAPI}?userId=${UserID}&id=${patient_id}`,
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        "x-caller": encrypted,
      },
    };

    Axios.request(config)
      .then((response) => {
        //console.log("response in graphs", response);
        setChartData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // console.log("chartdata", chartData);S

  const GraphContainer = () => {
    const commonSeriesOptions = {
      smooth: true,
      lineStyle: {
        width: 3,
      },
      itemStyle: {
        borderWidth: 2,
      },
      connectNulls: true,
    };

    const xAxisData = chartData.length
      ? chartData.map((item) => {
        const date = new Date(item.visit_date);
        const options = { month: "short", day: "2-digit", year: "numeric" };
        return date.toLocaleDateString("en-US", options);
      })
      : [];

    const seriesData = [
      {
        name: "SBP",
        data: chartData.length ? chartData.map((item) => item.SBP) : [], //chartData.map((item) => item.SBP),
        type: "line",
        smooth: true,
        ...commonSeriesOptions,
        lineStyle: {
          color: "#69ecff",
          width: 3,
        },
        itemStyle: {
          borderColor: "#69ecff",
        },

        connectNulls: true,
      },
      {
        name: "DBP",
        data: chartData.length ? chartData.map((item) => item.DBP) : [], //chartData.map((item) => item.DBP),
        type: "line",
        ...commonSeriesOptions,
        connectNulls: true,
        smooth: true,
        lineStyle: {
          color: "#ff9ffe",
          width: 3,
        },
        itemStyle: {
          borderColor: "#ff9ffe",
        },
      },
    ];
    const seriesData2 = [
      {
        name: "PPBS",
        data: chartData.length ? chartData.map((item) => item.PPBS) : [], //chartData.map((item) => item.PPBS),
        type: "line",
        ...commonSeriesOptions,
        lineStyle: {
          color: "#25f1f5",
          width: 3,
        },
        itemStyle: {
          borderColor: "#25f1f5",
        },
      },
      {
        name: "FBS",
        data: chartData.length ? chartData.map((item) => item.FBS) : [], //chartData.map((item) => item.FBS),
        type: "line",
        ...commonSeriesOptions,
        smooth: true,
        lineStyle: {
          color: "#ff9ffe",
          width: 3,
        },
        itemStyle: {
          borderColor: "#ff9ffe",
        },
      },
      {
        name: "RBS",
        data: chartData.map((item) => item.RBS),
        type: "line",
        ...commonSeriesOptions,
        smooth: true,
        lineStyle: {
          color: "#ffb800",
          width: 3,
        },
        itemStyle: {
          borderColor: "#ffb800",
        },
      },
    ];
    const seriesData3 = [
      {
        name: "HbA1C",
        data: chartData.map((item) => item.HbA1C),
        type: "line",
        ...commonSeriesOptions,
        lineStyle: {
          color: "#25f1f5",
          width: 3,
        },
      },
    ];

    return (
      <>
        <div
          style={{
            display: "flex",
            backgroundColor: "#F5F5F5",
            width: "90vw",
            flexDirection: "column",
            gap: "1rem",
            overflow: "scroll",
            backgroundColor:"white",
          }}
        >
          <div
            style={{
              backgroundColor: "rgba(227, 237, 255, 1)",
              display: "flex",
              position: "sticky",
              // flexDirection:'row'
            }}
          >
            <div
              style={{
                display: "flex",
                gap: "5px",
                height: "10px",
                alignItems: "center",
                cursor: "pointer",
                marginTop: "1.05rem",
                paddingLeft: "10px",
              }}
              onClick={handleBackClick}
            >
              <img
                src={BackArrow}
                style={{ width: "1rem", height: "0.8rem" }}
              />
            </div>
            <button className="review">
              <h3>
                {patientDetails && patientDetails.FIRST_NAME
                  ? patientDetails.FIRST_NAME.toUpperCase()
                  : patientDetails.first_name.toUpperCase()}
                'S CHARTS
              </h3>
            </button>
          </div>

          <div>
            <h3 className="graphHeader">Blood Pressure Chart</h3>
            <div className="graphContainer">
              <ReactEcharts
                option={{
                  chart: {
                    height: 350,
                    type: "line",
                  },
                  stroke: {
                    curve: "smooth",
                  },
                  datalabels: {
                    enabled: false,
                  },
                  title: {
                    text: "mm Hg",
                    align: "left",
                    textStyle: {
                      color: "#4AFFE9",
                    },
                  },
                  xAxis: {
                    type: "category",
                    data: xAxisData,
                    axisLine: {
                      lineStyle: {
                        color: "#4AFFE9",
                      },
                    },
                  },
                  yAxis: {
                    type: "value",
                    axisLabel: {
                      color: "#4AFFE9",
                    },
                  },
                  legend: {
                    position: "top",
                    horizontalAlign: "right",
                    floating: true,
                    offsetY: -25,
                    offsetX: -5,
                    textStyle: {
                      color: "#4AFFE9",
                    },
                  },
                  series: seriesData,
                  tooltip: {
                    trigger: "axis",
                    axisPointer: {
                      animation: false,
                    },
                  },
                }}
              />
            </div>
          </div>

          <div>
            <h3 className="graphHeader">Blood Sugar Levels</h3>
            <div className="graphContainer">
              <ReactEcharts
                option={{
                  chart: {
                    height: 350,
                    type: "line",
                  },
                  stroke: {
                    curve: "smooth",
                  },
                  datalabels: {
                    enabled: false,
                  },
                  title: {
                    text: "mm Hg",
                    align: "left",
                    textStyle: {
                      color: "#4AFFE9",
                    },
                  },
                  xAxis: {
                    type: "category",
                    data: xAxisData,
                    axisLine: {
                      lineStyle: {
                        color: "#4AFFE9",
                      },
                    },
                  },
                  yAxis: {
                    type: "value",
                    axisLabel: {
                      color: "#4AFFE9",
                    },
                  },
                  legend: {
                    position: "top",
                    horizontalAlign: "right",
                    floating: true,
                    offsetY: -25,
                    offsetX: -5,
                    textStyle: {
                      color: "#4AFFE9",
                    },
                  },
                  series: seriesData2,
                  tooltip: {
                    trigger: "axis",
                    axisPointer: {
                      animation: false,
                    },
                  },
                }}
              />
            </div>
          </div>

          <div>
            <h3 className="graphHeader">Blood Sugar (HbA1c) Chart</h3>
            <div className="graphContainer">
              <ReactEcharts
                option={{
                  chart: {
                    height: 350,
                    type: "line",
                  },
                  stroke: {
                    curve: "smooth",
                  },
                  datalabels: {
                    enabled: false,
                  },
                  title: {
                    text: "mm Hg",
                    align: "left",
                    textStyle: {
                      color: "#4AFFE9",
                    },
                  },
                  xAxis: {
                    type: "category",
                    data: xAxisData,
                    axisLine: {
                      lineStyle: {
                        color: "#4AFFE9",
                      },
                    },
                  },
                  yAxis: {
                    type: "value",
                    axisLabel: {
                      color: "#4AFFE9",
                    },
                  },
                  legend: {
                    position: "top",
                    horizontalAlign: "right",
                    floating: true,
                    offsetY: -25,
                    offsetX: -5,
                    textStyle: {
                      color: "#4AFFE9",
                    },
                  },
                  series: seriesData3,
                  tooltip: {
                    trigger: "axis",
                    axisPointer: {
                      animation: false,
                    },
                  },
                }}
              />
            </div>
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      {/* <Navbar /> */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: "100vh",
            overflow: "scroll",
            gap: "1rem",
          

            width: "100%"
          }}
        >
          <GraphContainer />
        </div>
      </div>
    </>
  );
};

export default Graphs;
