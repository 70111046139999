import React from "react";
import "./ArticleData.css";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import config from "../../../Config/config";

const diabetes = () => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const diabetesUrls = [
    {
      title: "Diabetes",

      urls: [
        {
          type: "pdf",
          url: `${config.apiURL}/uploads/articlespdfs/Diabetes Infographics_0.pdf`,
        },
        {
          type: "link",
          url: "https://www.medindia.net/health-infographics/diabetes.htm",
          title: "Infographic on Diabetes",
          description:
            "Diabetes infographic showcasesthe information depicting the alarming rise of this metabolic condition that affects 42...",
          image:
            "https://www.medindia.net/health-infographics/images/diabetes.jpg",
        },
        {
          type: "link",
          url: "https://www.paho.org/en/topics/diabetes",
          title: "Diabetes-PAHO/WHO| Pan American Health Organization",
          description:
            "Use the following buttons to navigate the different tabs.OVERVIEW SyMPtoms TREATMENT",
          image:
            "https://www.paho.org/sites/default/files/styles/top_hero/public/2018-07/diabetes-testing-1600x759.jpg?h=b0c51a6c&itok=NSvodmnc://www.who.int/images/default-source/departments/ncds/cardiovascular-diseases/who-005446-orig.tmb-1366v.jpg?Culture=en&sfvrsn=27864450_8%201366w",
        },
      ],
    },
  ];

  return (
    <>
    <div className="Articles-digitalHealth">
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js" />

        {diabetesUrls[0].urls.map((item, index) => {
          if (item.type === "pdf") {
            return (
              <div className="articles-hyper-pdf">
                <Viewer
                  key={index}
                  fileUrl={item.url}
                  plugins={[defaultLayoutPluginInstance]}
                />
              </div>
            );
          } else if (item.type === "image") {
            return item.urls.map((imageUrl, imageIndex) => (
              <div className="articles-hyper-image">
                <img key={imageIndex} crossorigin="anonymous" src={imageUrl} />
              </div>
            ));
          } else if (item.type === "link") {
            return (
              <div className="articles-hyper-link">

                <a href={item.url} target="_blank" rel="noopener noreferrer">
                  <div>
                    <p>{item.url}</p>
                  </div>
                </a>
                <h3>{item.title}</h3>
                <p>{item.description}</p>
                <img src={item.image} alt="Link Image" />

              </div>
            );
          }
          return null;
        })}
      </div>
    </>
  );
};

export default diabetes;
