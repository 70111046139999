import React, { useState } from "react";
import Navbar from "../components/modules/Navbar";
import { Container, Grid } from "@mui/material";
import VectorLine from "../images/Vector_line.svg";
import PersonalInfo from "../components/modules/PersonalInfo";
import "./Calculator.css";
import BmiCalculator from "../components/modules/BmiCalculator";
import CvdRiskScoreCalculator from "../components/modules/CvdRiskScoreCalculator";
// import CvdRiskScoreCalculator from "../components/modules/CvdRiskScoreCalculator2";
import DiabetesRiskAssesment from "../components/modules/DiabetesRiskAssesment";
import TopNavBar from "../components/modules/TopNavBar";

const Calculator = () => {
  const [selectSection, setSelectSection] = useState("BMICalculator");
  const togglebar = (selectName) => {
    setSelectSection(selectName);
  };

  return (
    <>
      {/* <div className="calculator-MainContainer">
        <Navbar />
        <div style={{ width: "100%" }}>
          <TopNavBar /> */}
          {/* , backgroundColor: "#f9fbff" */}
          <div >
          {/*<div style={{ padding: "3% 0" , height: "92vh"
            // backgroundColor: "rgb(249, 251, 255)"
            }}>*/}
            <div className="calcsection">
              <div style={{ backgroundColor: "rgba(247, 240, 255, 1)" }}>
                <h3 className="MainHeadingCalculator">CALCULATORS</h3>
              </div>
              <div className="CalculatorContainer">
                <div className="sidemenuContainer">
                  <div>
                    {" "}
                    <div
                      className={`sectionsName ${
                        selectSection === "BMICalculator" ? "selectSection" : ""
                      }`}
                      onClick={() => togglebar("BMICalculator")}
                    >
                      <p className="calculatorIteams">BMI Calculator </p>
                      <hr style={{border: "1px solid rgba(214, 229, 255, 1)"}}></hr>
                    </div>
                    <div
                      className={`sectionsName ${
                        selectSection === "CVDRiskScoreCalculator"
                          ? "selectSection"
                          : ""
                      }`}
                      onClick={() => togglebar("CVDRiskScoreCalculator")}
                    >
                      <p className="calculatorIteams">
                        CVD Risk Score Calculator
                      </p>
                      <hr style={{border: "1px solid rgba(214, 229, 255, 1)"}}></hr>
                    </div>
                    <div
                      className={`sectionsName ${
                        selectSection === "DiabetesRiskAssesment"
                          ? "selectSection"
                          : ""
                      }`}
                      onClick={() => togglebar("DiabetesRiskAssesment")}
                    >
                      <p className="calculatorIteams">
                        Diabetes Risk Assessment
                      </p>

                    </div>
                  </div>
                  <hr style={{border: "1px solid rgba(214, 229, 255, 1)"}}></hr>
                </div>

                {selectSection === "BMICalculator" && <BmiCalculator />}
                {selectSection === "CVDRiskScoreCalculator" && (
                  <CvdRiskScoreCalculator />
                )}
                {selectSection === "DiabetesRiskAssesment" && (
                  <DiabetesRiskAssesment />
                )}
              </div>
            </div>
          </div>
        {/* </div>
      </div> */}
    </>
  );
};

export default Calculator;
