import React from 'react';

const ImageComponent = ({ base64String }) => {
  const [image, setImage] = React.useState(null);

  React.useEffect(() => {
    const imageData = base64String;
    const imageBuffer = Buffer.from(imageData, 'base64');
    const imageSource = `data:image/png;base64,${imageBuffer.toString('base64')}`;
    setImage(imageSource);
  }, [base64String]);

  return (
    <img src={image} alt="Image" />
  );
};

export default ImageComponent;