import React, { useEffect, useState } from "react";
import Axios from "axios";
import { publicEncrypt, constants } from "crypto-browserify";
import { Buffer } from "buffer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import downArrow from "../../images/Group 102.svg";
import Config from "../../Config/config.jsx";
import Select from "react-select";
import DegreeImg from "../../images/education-graduation-learning-school-study 3.svg";
import DeleteIcon from "../../images/delete 8.svg";

const RegExperience = ({ toggleSectionTab, ExperienceDetailsCount }) => {
  const RegMobileNum = sessionStorage.getItem("RegMobileNum");
  const [formData, setFormData] = useState({
    role: "",
    hospitalName: "",
    city: "",
    startYear: "",
    startingMonth: "",
    endingYear: "",
    endingMonth: "",
    listofExperinces: [],
  });
  const [endYears, setEndYears] = useState([]);
  const UserID = JSON.parse(sessionStorage.getItem("RegUserId"));
  const publicKey = sessionStorage.getItem("RegpublicKey");
  const token = sessionStorage.getItem("Regtoken");
  const padding = constants.RSA_PKCS1_PADDING;
  const data = Buffer.from(UserID.toString(), "utf-8");
  const encrypted = publicEncrypt(
    { key: publicKey, padding: padding },
    data
  ).toString("base64");
  // console.log("encrypted", encrypted);

  const DropdownIndicator = (props) => {
    return (
      <svg
        width="40px"
        height="30px"
        viewBox="0 0 24 24"
        fill="none"
        cursor="pointer"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"
          fill="black" // Set your desired arrow color here
        />
      </svg>
    );
  };

  const customStyles1 = {
    control: (provided) => ({
      ...provided,
      borderColor: "yourBorderColor", // Set your desired border color here
      width: "yourWidth", // Set your desired width here, e.g., "200px"
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "yourArrowColor", // Set your desired arrow color here
    }),
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderColor: "rgba(82, 68, 125, 1)",
      borderRadius: "6px",
      borderWidth: "2px",
      height: "50px",
      boxShadow: "none",
      "&:hover": {
        borderColor: "black",
        color: "rgba(82, 68, 125, 1)",
      },
      backgroundColor: "white",
      border: "1px solid  #dfdfdf",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "rgba(82, 68, 125, 1)",
      "&:hover": {
        color: "black",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      "&:hover": {
        color: "black",
      },
      backgroundColor: state.isSelected ? "lightblue" : "white",
      color: state.isSelected ? "black" : "rgba(82, 68, 125, 1)",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "rgba(82, 68, 125, 1)",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "rgba(82, 68, 125, 1)", // Ensuring the placeholder text is the same color
    }),
    input: (provided) => ({
      ...provided,
      color: "rgba(82, 68, 125, 1)", // Ensuring the input text is the same color
    }),
  };

  const handleInputChange = (event, field) => {
    const input = event.target.value;
    const lettersOnly = input.replace(/[^a-zA-Z\s]/g, '');
    setFormData((prevVariables) => ({
        ...prevVariables,
        [field]: lettersOnly,
    }));
};

  const getYears = (startYear) => {
    const currentYear = new Date().getFullYear();
    const yearsTilPresent = [];

    while (startYear <= currentYear) {
      yearsTilPresent.push({
        label: startYear.toString(),
        value: startYear.toString(),
      });
      startYear++;
    }
    return yearsTilPresent;
  };
  const years = getYears(1900);

  const handleStartYearChange = (year) => {
    setFormData({ ...formData, startYear: year });
    const validEndYears = getYears(year);
    // console.log("valida", validEndYears);
    setEndYears(validEndYears);
  };

  const handleEndYearChange = (year) => {
    if (year > formData.startYear) {
      setFormData({ ...formData, endingYear: year });
    } else {
      toast.warning(
        "The end year must be greater than or equal to the start year."
      );
      setFormData({ ...formData, endingYear: "" });
    }
  };

  const months = [
    { value: "1", label: "January" },
    { value: "2", label: "February" },
    { value: "3", label: "March" },
    { value: "4", label: "April" },
    { value: "5", label: "May" },
    { value: "6", label: "June" },
    { value: "7", label: "July" },
    { value: "8", label: "August" },
    { value: "9", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];

  const handleStartMonthChange = (selectedOption) => {
    setFormData({ ...formData, startingMonth: selectedOption });
  };

  const handleEndMonthChange = (selectedOption) => {
    setFormData({ ...formData, endingMonth: selectedOption });
  };

  const addExperience = async () => {
    try {
      //console.log("formadate", formData);
      if (
        formData.role !== "" &&
        formData.hospitalName !== "" &&
        formData.city !== "" &&
        formData.startYear !== "" &&
        formData.endingYear !== "" &&
        formData.startingMonth !== "" &&
        formData.endingMonth !== ""
      ) {
        const newExperience = {
          USER_ID: UserID,
          specialization: formData.role,
          hospitalName: formData.hospitalName,
          city: formData.city,
          startingYear: formData.startYear,
          startingMonth: formData.startingMonth.value,
          endingYear: formData.endingYear.value,
          endingMonth: formData.endingMonth.value,
        };
        //console.log("exper", newExperience);
        const response = await Axios.post(
          Config.base_url + "v2/USER_EXPERIENCE",
          newExperience,
          {
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${token}`,
              "x-caller": encrypted,
            },
          }
        );
        //console.log("ressss", response);

        if (response.status === 200 || response.status === 201) {
          //console.log("Experience added successfully");
          toast.success("Experience added successfully");
          setTimeout(() => {
            toggleSectionTab("Identity");
          }, 1000);
         // toggleSectionTab("Identity");
        } else if (response.status === 404) {
          //console.log(response.data);
          toast.error(
            "Your experience is not linked with the Profile. Please submit again."
          );
        } else {
          //console.log(response.data);
          toast.error("An unknown error occurred. Please try again later.");
        }

        setFormData({
          role: "",
          hospitalName: "",
          city: "",
          startYear: "",
          startingMonth: "",
          endingYear: "",
          endingMonth: "",
        });
      } else {
        toast.warning("Please fill in all required fields");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again.");
    }
  };

  //to retrive experinces
  //  api - /v2/GETUSEREXPERIENCE/:USER_ID

  const CheckStatusCount = () => {
    const numberOfFields = Object.keys(formData).length-1;
    const percentageIncrement = 100 / numberOfFields;
    let newTotalCount = 0;

    Object.keys(formData).forEach((key) => {
      if (
        formData[key] === "" ||
        (Array.isArray(formData[key]) && formData[key].length === 0)
      ) {
        // console.log(
        //   `The field ${key} is empty, percentage: ${percentageIncrement}`
        // );
      } else {
        newTotalCount += percentageIncrement;
        // console.log(
        //   `The field ${key} is not empty, value: ${formData[key]} ${newTotalCount}`
        // );
      }
    });

    // ExperienceDetailsCount(newTotalCount, "ExperienceDetailsCount");

    ExperienceDetailsCount(newTotalCount, "ExperienceDetailsCount");
    //console.log(newTotalCount, "totalCount");
  };

  // useEffect(() => {
  //   if (Object.keys(formData).length > 0) {
  //     CheckStatusCount();
  //   }
  // }, [formData]);

  return (
    <>
      <div
        className="profileRightContainer"
        style={{ display: "flex", gap: "1rem" }}
      >
        <div className="leftContainer" style={{ width: "70%" }}>
          <div className="topContainer">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "0.5rem",
                alignItems: "center",
              }}
            >
              <label
                className="headerEdu"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "0.75rem",
                }}
              >
                Experiences
              </label>
            </div>
          </div>
          <div style={{ background: "#AEAEAE", width: "100%", height: "2px" }}>
            <p style={{ height: "2px" }}></p>
          </div>

          <div className="regm-bottomContainer">
            {/* <div className="headingContainer">
              <p className="InfoHeading">
                Please enter your basic level Qualification
              </p>
            </div> */}

            {/* <div
              style={{ background: "#AEAEAE", width: "100%", height: "1px" }}
            ></div> */}
            <>
              <div
                className="personalInfoContainer"
                style={{
                  display: "flex",
                  gap: "2rem",
                  flexWrap: "wrap",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "40%",
                  }}
                >
                  <input
                    style={{
                      padding: "0.7rem 1rem",
                      display: "block",
                      width: "100%",
                    }}
                    className="experinceInput"
                    type="text"
                    placeholder="Enter  Your Roles Title*"
                    value={formData.role}
                    onChange={(e) => handleInputChange(e, "role")}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "40%",
                  }}
                >
                  <input
                    style={{
                      padding: "0.7rem 1rem",
                      display: "block",
                      width: "100%",
                    }}
                    className="experinceInput"
                    type="text"
                    placeholder="Enter Hospital/Clinic Name*"
                    value={formData.hospitalName}
                    onChange={(e) => handleInputChange(e, "hospitalName")}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "84%",
                  }}
                >
                  <input
                    style={{
                      padding: "0.7rem 1rem",
                      display: "block",
                      width: "100%",
                    }}
                    className="experinceInput"
                    type="text"
                    placeholder="City*"
                    value={formData.city}
                    onChange={(e) => handleInputChange(e, "city")}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "40%",
                  }}
                >
                  <Select
                    id="stateDropdown"
                    value={years.find(
                      (year) => year.value === formData.startYear
                    )}
                    onChange={(selectedOption) =>
                      handleStartYearChange(selectedOption.value)
                    }
                    options={years}
                    placeholder="Starting Year*"
                    isSearchable
                    // isClearable
                    styles={customStyles}
                    components={{ DropdownIndicator }}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "40%",
                  }}
                >
                  <Select
                    id="stateDropdown"
                    value={formData.startingMonth}
                    onChange={handleStartMonthChange}
                    options={months}
                    placeholder="Month*"
                    isSearchable
                    styles={customStyles}
                    components={{ DropdownIndicator }}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "40%",
                  }}
                >
                  <Select
                    id="stateDropdown"
                    value={formData.endingYear}
                    onChange={handleEndYearChange}
                    options={endYears}
                    placeholder="Till Year*"
                    isSearchable
                    // isClearable
                    styles={customStyles}
                    components={{ DropdownIndicator }}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "40%",
                  }}
                >
                  <Select
                    id="stateDropdown"
                    value={formData.endingMonth}
                    onChange={handleEndMonthChange}
                    options={months}
                    placeholder="Month*"
                    isSearchable
                    // isClearable
                    styles={customStyles}
                    components={{ DropdownIndicator }}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "1rem",
                  marginTop: "80px",
                }}
              >
                {/* <div
                  style={{
                    width: "30%",
                    height: "45px",
                    background: "white",
                  }}
                  className={"editButton2"}
                >
                  <p
                    style={{
                      color: "#52447d",
                      fontSize: "14px",
                      fontFamily: "Verdana",
                      fontWeight: "400",
                      lineHeight: "18.38px",
                      letterSpacing: "15%",
                    }}
                  >
                    Add Experience
                  </p>
                </div> */}
                <div
                  className={"editButton2"}
                  style={{
                    width: "30%",
                    height: "45px",
                    background: "rgba(82, 68, 125, 1)",
                  }}
                  onClick={addExperience}
                >
                  <p style={{ color: "white" }}>ADD EXPERIENCE</p>
                </div>
              </div>
            </>
            <ToastContainer />
          </div>
        </div>
      </div>
    </>
  );
};
export default RegExperience;
