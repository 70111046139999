import React, { useState, useEffect } from "react";

const QuestionsAnswers = ({
  question,
  onClickButton,
  isRequired,
  historyData, //medicalHistory
  backendData,
  questionState,
}) => {
  const handleOptionClick = (option, questionState) => {
    onClickButton(option, questionState);
  };

  const initialOption =
    backendData !== undefined && backendData !== null ? backendData : null;

  // Use initialOption as the default value for selectedOptions
  const [currentSelectedOption, setCurrentSelectedOption] = useState(
    historyData !== undefined && historyData !== null
      ? historyData
      : initialOption
  );

  // Update the selected option when the parent component updates its state
  useEffect(() => {
    setCurrentSelectedOption(
      historyData !== undefined && historyData !== null
        ? historyData
        : initialOption
    );
  }, [historyData, initialOption, backendData]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "0.7rem",
        minWidth: "50%",
        marginBottom: "1.2rem"
      }}
    >
      <p key={questionState}>{question}</p>
      <div style={{ display: "flex", gap: "1.7rem" , alignItems: "center"}}>
        <button
          onClick={() => {
            handleOptionClick(0, questionState);
            setCurrentSelectedOption(0);
          }}
          style={{
            backgroundColor:
              currentSelectedOption === 0 ? "rgba(82, 68, 125, 1)" : "rgba(243, 234, 255, 1)",
            color: currentSelectedOption === 0 ? "white" : "rgba(82, 68, 125, 1)",
            border: "none",
            padding: "0.3rem 1.8rem",
            borderRadius: "1rem",
            cursor: "pointer",

            // fontWeight:"bold"
          }}
        >
          Yes
        </button>
        <button
          onClick={() => {
            handleOptionClick(1, questionState);
            setCurrentSelectedOption(1);
          }}
          style={{
            backgroundColor:
              currentSelectedOption === 1 ? "rgba(82, 68, 125, 1)" : "rgba(243, 234, 255, 1)",
            border: "none",
            padding: "0.3rem 1.8rem",
            borderRadius: "1rem",
            color: currentSelectedOption === 1 ?"white" : "rgba(82, 68, 125, 1)",
            cursor: "pointer",
          }}
        >
          No
        </button>
        {isRequired ? (
          <button
            onClick={() => {
              handleOptionClick(2, questionState);
              setCurrentSelectedOption(2);
            }}
            style={{
              backgroundColor:
                currentSelectedOption === 2 ? "rgba(82, 68, 125, 1)" : "rgba(243, 234, 255, 1)",
              border: "none",
              padding: "0.3rem 1.2rem",
              borderRadius: "1rem",
              color: currentSelectedOption === 2 ? "white" : "rgba(82, 68, 125, 1)",
              cursor: "pointer",
            }}
          >
            Don't know
          </button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default QuestionsAnswers;
