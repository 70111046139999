import React from "react";
import { useState, useEffect } from "react";
import Navbar from "../Navbar";
import "./ManageABHASubscription.css";
import { MDBDataTable } from "mdbreact";
import CustomButton from "../../CustomButton";
// import NextButton from "../../../../src/images/arrow-right 1.png";
import RightArrow from "../../../images/Group 36.png";
import BackArrow from "../../../../src/images/leftArrow.svg";

import Config from "../../../../src/Config/config";
import axios from "axios";
import { Link } from "react-router-dom";
import { publicEncrypt, constants } from "crypto-browserify";
import { Buffer } from "buffer";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import Axios from "axios";
import config from "../../../Config/config";
import moment from 'moment';
import { v4 as uuidv4 } from "uuid";

const ManageABHASubscription = () => {

  const selectedPatientDetails = JSON.parse(
    sessionStorage.getItem("selectedPatientDetails")
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState(false);
  const [rowsData, setrowsData] = useState([]);
  const initialFormData = {
    patientIdentifier: selectedPatientDetails.ABHA_ADDRESS,
    consentPurpose: "",
    FromDate: "",
    ToDate: "",
  };
  const [subscribedData, setSubscribedData] = useState([])
  const [formData, setFormData] = useState(initialFormData);

  const formatDate = (isoString) => {
    return isoString.split("T")[0];
  };

  const navigate = useNavigate();

  const UserID = sessionStorage.getItem("RegUserId")
    ? JSON.parse(sessionStorage.getItem("RegUserId"))
    : sessionStorage.getItem("UserID");

  // console.log("id", UserID);

  const publicKey = sessionStorage.getItem("RegpublicKey")
    ? sessionStorage.getItem("RegpublicKey")
    : JSON.parse(sessionStorage.getItem("publicKey"));
  const token = sessionStorage.getItem("Regtoken")
    ? sessionStorage.getItem("Regtoken")
    : JSON.parse(sessionStorage.getItem("token"));

  const padding = constants.RSA_PKCS1_PADDING;
  const data = Buffer.from(UserID.toString(), "utf-8");
  const encrypted = publicEncrypt(
    { key: publicKey, padding: padding },
    data
  ).toString("base64");

  const patientDetails = JSON.parse(
    sessionStorage.getItem("selectedPatientDetails")
  );
  const getSubscriptionList = async () => {
    try {
      const response = await Axios.get(
        `${config.base_url}SUBSCRIPTIONS/${selectedPatientDetails.ABHA_ADDRESS}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "x-caller": await encrypted,
          },
        }
      );
      if (response.status === 200) {

        const subscriptionData = response.data.recordset;

        const processedData = subscriptionData.map((entry, index) => {
          return {
            name: patientDetails.FIRST_NAME,
            ABHAId: entry.ABHA_ADDRESS,
            FromDate: formatDate(
              entry.SUBSCRIPTION_FROM
            ),
            ToDate: formatDate(
              entry.SUBSCRIPTION_TO
            ),
            ConsentPurpose: getConsentCode(entry.SUBSCRIPTION_PURPOSE),
            Requestedon: formatDate(entry.CREATED_DATE),
            Status: entry.STATUS,
            Action:
              (() => {
                switch (entry.STATUS) {
                  case "GRANTED":
                    return (
                      <img
                        // onClick={getSubscriptionData}
                        onClick={() => getSubscriptionData(entry.SUBSCRIPTION_ID, index)}
                        style={{ cursor: "pointer",width:'10px' }}
                        src={RightArrow}
                        alt="Description of image"
                      />
                    );
                  // case "DENIED":
                  //   return <span>No Action</span>;
                  // case "REQUESTED":
                  //   return <span>No Action</span>;
                  // case "EXPIRED":
                  //   return <span>No Action</span>;
                  // case "REVOKED":
                  //   return <span>No Action</span>;
                  default:
                    return <span></span>;
                }
              })(),
          };
        });
        setrowsData(processedData);
      }

    }
    catch (err) {
      console.log("err in subscriptions", err)

    }

  }
  //harshini code changes
  useEffect(() => {

    
    getSubscriptionList();
  }, []);

  //



  const handleCancel = () => {
    setIsModalOpen(false);
    setFormData(initialFormData)
  };

  const createConsentForm = () => {
    setIsModalOpen(true);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData((prevState) => ({
        ...prevState,
        healthInfoTypes: {
          ...prevState.healthInfoTypes,
          [name]: checked,
        },
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };


  const dataSubscription = {
    columns: [
      { label: "ABHA Id", field: "ABHAId", sort: "asc", width: 270 },
      { label: "From Date", field: "FromDate", sort: "asc", width: 200 },
      { label: "To Date", field: "ToDate", sort: "asc", width: 200 },
      {
        label: "Purpose of Request",
        field: "ConsentPurpose",
        sort: "asc",
        width: 150,
      },
      { label: "Reqested on", field: "Requestedon", sort: "asc", width: 100 },
      { label: "Status", field: "Status", sort: "asc", width: 100 },
      { label: "Action", field: "Action", sort: "asc", width: 100 },
    ],

    rows: rowsData.map((row) => ({
      ...row,
      Status: (
        <span
          // style={{
          //   color:
          //     row.Status === "GRANTED"
          //       ? "#66f556"
          //       : row.Status === "DENIED"
          //         ? "#ed7926"
          //         : row.Status === "REQUESTED"
          //           ? "#13e9ed"
          //           : row.Status === "EXPIRED"
          //             ? "red"
          //             : row.Status === "REVOKED"
          //               ? "#ed8b13"
          //               : "inherit",
          //   fontWeight: "bold",
          // }}
        >
          {row.Status}
        </span>
      ),
    })),
  };

  const getSubscriptionData = async (subscriptionId) => {

    try {
      const response = await Axios.get(
        `${config.base_url}SUBSCRIPTION_DATA/${subscriptionId}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
            "x-caller": encrypted,
          }
        }
      );

      if (response.status === 200) {
        //console.log("subscription fetch response", response.data.recordset);
        const extractData = response.data.recordset;

        const subscriptionDataArray = extractData.map(item => JSON.parse(item.SUBSCRIPTION_DATA));
        // console.log("subscriptionDataArray", subscriptionDataArray)

        setSubscribedData(subscriptionDataArray);
        setSubscriptionData(true);
      }
      else {
        setSubscribedData([])
      }
    }
    catch (error) {
      console.error('Error:', error);
    }

  };

  const closeSubscriptionData = () => {
    setSubscriptionData(false);
  };

  const navigateBack = () => {
    navigate(`/patients`);
  };

  //harshini code changes 

  function getConsentCode(value) {
    //console.log("value", value)
    let code = '';
    switch (value) {
      case 'CAREMGT':
        code = 'Care Management';
        break;
      case 'BTG':
        code = 'Break the Glass';
        break;
      case 'PUBHLTH':
        code = 'Public Health';
        break;
      case 'HPAYMT':
        code = 'Healthcare Payment';
        break;
      case 'DSRCH':
        code = 'Disease Specific Healthcare Research';
        break;
      case 'PATRQT':
        code = 'Self Requested';
    }
    return code;
  }

  function getConsentCodeForCreatingRequest(value) {
    //console.log("value", value)
    let code = '';
    switch (value) {
      case 'Care Management':
        code = 'CAREMGT';
        break;
      case 'Break the Glass':
        code = 'BTG';
        break;
      case 'Public Health':
        code = 'PUBHLTH';
        break;
      case 'Healthcare Payment':
        code = 'HPAYMT';
        break;
      case 'Disease Specific Healthcare Research':
        code = 'DSRCH';
        break;
      case 'Self Requested':
        code = 'PATRQT';
    }
    return code;
  }

  const timeZoneDate = (dateString) => {
    const date = moment(dateString, "YYYY-MM-DD");

    const now = moment();
    date.set({
      hour: now.hour(),
      minute: now.minute(),
      second: now.second(),
      millisecond: now.millisecond()
    });

    // Format the date using moment.js (or you can use other date formatting libraries)
    const formattedDate = date.format("YYYY-MM-DDTHH:mm:ss.SSS");

    return formattedDate;
  };

  const getPublickey = async () => {
    Axios.get(`${config.abhaBaseUrl}publicKey/${UserID}`, {
      headers: {
        authorization: `Bearer ${token}`,
        "x-caller": await encrypted,
      },
    })
      .then((response) => {
        // console.log("getPublickey response :", response);
        if (response.status == 200) {
          // console.log("PUBLICKEY :", response.data);
          sessionStorage.setItem("ABDMPublicKey", response.data);
          return response;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createSubscriptionRequest = async () => {
    const { patientIdentifier, consentPurpose, FromDate, ToDate } = formData;
    const consentCode = await getConsentCodeForCreatingRequest(consentPurpose);

    if (
      !formData.patientIdentifier ||
      !formData.consentPurpose ||
      !formData.ToDate ||
      !formData.FromDate
    ) {
      toast.error("Please enter the mandatory fields");
      return;
    }
    else {
      const v5 = uuidv4();
      const from = timeZoneDate(FromDate || new Date());
      const to = timeZoneDate(ToDate || new Date());

      const payload = {
        ABHA_ADDRESS: patientIdentifier,
        requestId: v5,
        purpose: consentCode,
        from: from,
        to: to,
      }
      //console.log("payload", payload)

      const headers = {
        authorization: `Bearer ${token}`,
        "x-caller": await encrypted,
      };

      const response = await Axios.post(`${config.base_url}ABHA_SUBSCRIPTION_REQUEST`, payload, { headers });
      //console.log("response", response)

      if (response.status === 201) {
        await abhaSubscriptionRequestInit(v5, consentCode, consentPurpose, selectedPatientDetails.ABHA_ADDRESS, from, to);

        getSubscriptionList();

      }
      else {
        //console.log("coming to else")
      }

    }
  };

  const abhaSubscriptionRequestInit = async (v5, purpose, purposeValue, abhaId, from, to) => {

    await getPublickey();
    const timestamp = moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSSSSS');

    const dataBody = {
      v5: v5,
      USER_ID: UserID,
      purpose: purpose,
      purposeValue: purposeValue,
      abhaId: abhaId,
      from: from,
      to: to,
      timestamp: timestamp
    };
    await Axios.post(`${config.abhaBaseUrl}abhaSubscriptionRequestInit`, dataBody, {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
        "x-caller": encrypted,
      },
    })
      .then(async (response) => {
        //console.log("response", response)
        if (response.status === 200) {
          setIsModalOpen(false);
        }
        else if (response.status === 202) {
          setIsModalOpen(false)
        }
        else {

        }
      })
      .catch((err) => {
        console.log("err", err)

      })

  }

  const getPublishedDates = (value) => {
    if (value && value !== '') {
      const dt = moment(value);
      return dt.isValid() ? dt.format("DD-MMM-yyyy, hh:mm A") : '';
    } else {
      return '';
    }
  };

  const renderSubscribedData = () => {
    if (subscribedData === null) {
      return (
        <div className="centered-content">
          <p>Fetching Data</p>
        </div>
      );
    }
    else if (subscribedData.length === 0) {
      return (
        <div className="centered-content">
          <p>No new patient files have been added</p>
        </div>
      );
    } else {
      return (
        // <div className="safe-area">
        //   {subscribedData.map((data, index) => (
        //     <div key={index} className="card-container">
        //       <div className="card">
        //         <div className="card-header">
        //           <p>Published on: {getPublishedDates(data.event.published)}</p>
        //         </div>
        //         <div className="card-body">
        //           <p>Category: {data.event.category}</p>
        //           <p>Care Contexts:</p>
        //           <ul>
        //             {data.event.content.context.map((context, idx) => (
        //               <li key={idx}>Visit Id: {context.careContext.careContextReference}</li>
        //             ))}
        //           </ul>
        //         </div>
        //       </div>
        //     </div>
        //   ))}
        // </div>
        <div className="safe-area">
          <table className="data-table">
            <thead>
              <tr>
                <th>Published Date</th>
                <th>Category</th>
                <th>Care Contexts Reference</th>
                <th>Patient Reference</th>
              </tr>
            </thead>
            <tbody>
              {subscribedData.map((data, index) => (
                <React.Fragment key={index}>
                  {data.event.content.context.map((context, idx) => (
                    <tr key={idx}>
                      {idx === 0 && (
                        <>
                          <td rowSpan={data.event.content.context.length}>
                            {getPublishedDates(data.event.published)}
                          </td>
                          <td rowSpan={data.event.content.context.length}>
                            {data.event.category}
                          </td>
                          <td rowSpan={data.event.content.context.length}>
                            {data.event.content.context.map((ctx, contextIdx) => (
                              <div key={contextIdx}>{ctx.careContext.careContextReference}</div>
                            ))}
                          </td>
                        </>
                      )}
                      <td>{context.careContext.patientReference}</td>
                    </tr>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>


      );
    }
  };



  return (
    <>
      {/* <Navbar /> */}
      <div className="main-ManageABHASubscription-container">
        <div className="ManageABHASubscription-container">
          <div className="Titleheading-Subscription">
            <img
              onClick={navigateBack}
              style={{ cursor: "pointer" }}
              src={BackArrow}
              alt="Description of image"
            />
            <h3 style={{ color: "rgba(9, 74, 115, 1)" }}> ABHA SUBSCRIPTIONS</h3>
          </div>
          <div className="ABHASubscription-table">
            <div className="ManageABHASubscription-maincontent">
              <>
                {/* <CustomButton
                  buttonText="Create Request"
                  onClick={createConsentForm}
                /> */}
                <Dialog
                  open={isModalOpen}
                  style={{ width: "100%", alignItems: "center" }}
                >
                  <DialogTitle>SUBSCRIPTION FORM</DialogTitle>
                  <DialogContent
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <TextField
                      label="Patient Identifier"
                      name="patientIdentifier"
                      value={selectedPatientDetails.ABHA_ADDRESS}
                      onChange={handleChange}
                      required
                      margin="dense"
                    />
                    <FormControl fullWidth>
                      <InputLabel id="consent-select-label">
                        Consent Purpose *
                      </InputLabel>
                      <Select
                        label="Consent Purpose"
                        name="consentPurpose"
                        value={formData.consentPurpose}
                        onChange={handleChange}
                        required
                        margin="dense"
                      >
                        <MenuItem value="Care Management">
                          Care Management
                        </MenuItem>
                        <MenuItem value="Break the Glass">
                          Break the Glass
                        </MenuItem>
                        <MenuItem value="Public Health">Public Health</MenuItem>
                        <MenuItem value="Disease Specific Healthcare Research">
                          Disease Specific Healthcare Research
                        </MenuItem>
                        <MenuItem value="Self Requested">
                          Self Requested
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <div className="ManageAbha-healthdata">
                      <TextField
                        label="Health Data From"
                        name="FromDate"
                        type="date"
                        value={formData.FromDate}
                        onChange={handleChange}
                        required
                        margin="dense"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          inputProps: { max: formData.expiryDate },
                        }}
                      />

                      <TextField
                        label="Health Data To"
                        name="ToDate"
                        type="date"
                        value={formData.ToDate}
                        onChange={handleChange}
                        required
                        margin="dense"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          inputProps: {
                            min: formData.FromDate,
                            max: formData.expiryDate,
                          },
                        }}
                      />
                    </div>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      style={{
                        background: "rgba(93, 74, 135, 1)",
                        color: "white",
                        marginLeft: "5%",
                        borderRadius: "10px",
                        width: "8vw",
                        height: "4vh",
                        border: "none",
                        cursor: "pointer",
                      }}
                      onClick={handleCancel}
                    >
                      CANCEL
                    </Button>
                    <Button
                      style={{
                        background: "rgba(93, 74, 135, 1)",
                        color: "white",
                        marginLeft: "5%",
                        borderRadius: "10px",
                        width: "8vw",
                        height: "4vh",
                        border: "none",
                        cursor: "pointer",
                      }}
                      onClick={createSubscriptionRequest}
                    >
                      REQUEST
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
              <>
                <Dialog
                  open={subscriptionData}
                  style={{ width: "100%", alignItems: "center" }}
                >
                  <DialogTitle>SUBSCRIPTION DATA</DialogTitle>
                  <DialogContent
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    {renderSubscribedData()}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      style={{
                        background: "rgba(93, 74, 135, 1)",
                        color: "white",
                        marginLeft: "5%",
                        borderRadius: "10px",
                        width: "8vw",
                        height: "4vh",
                        border: "none",
                        cursor: "pointer",
                      }}
                      onClick={closeSubscriptionData}
                    >
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            </div>
            <div className="consent-patients-container">
              {dataSubscription.rows.map((row, index) => (
                <div className="Consent-Patients" key={index}>
                  <div className="Patients">
                    <h3>Patients</h3>
                    <div style={{ alignItems: "center", display: "flex", gap: "2px" }}>
                      <button type="button" className="Consentbtn" data-mdb-ripple-init>
                        {row.Status}
                      </button>
                      <strong></strong>
                      {row.Action}
                    </div>
                  </div>
                  <div className="Patients-details">
                    <p><strong>ABHA Id:</strong> {row.ABHAId}</p>
                    <p><strong>From Date:</strong> {row.FromDate}</p>
                    <p><strong>To Date:</strong> {row.ToDate}</p>
                    <p><strong>Consent Purpose:</strong> {row.ConsentPurpose}</p>
                    <p><strong>Requested on:</strong> {row.Reqestedon}</p>
                    {/* <p><strong>Status:</strong> </p> */}
                    {/* <p><strong>Action:</strong> {row.Action}</p> */}
                  </div>
                </div>
              ))}
            </div>
            {/* <div className="MDB-concents">
              <MDBDataTable
                striped
                small
                data={dataSubscription}
                displayEntries={false}
                searching={false}
                paging={false}
                info={false}
                className="mdbtable"
              />
            </div> */}
          </div>
        </div>
        <div className="AbhaSubscriptionButtonouter">
          <CustomButton
            buttonText="Create Request"
            onClick={createConsentForm}
          />
        </div>
        <ToastContainer position="top-right" />
      </div>
    </>
  );
};

export default ManageABHASubscription;
