import React, { useState, useEffect } from "react";
import Axios from "axios";
import { publicEncrypt, constants } from "crypto-browserify";
import { Buffer } from "buffer";
import { ToastContainer, toast } from "react-toastify";
import Config from "../../Config/config.jsx";
import playbutton from "../../images/play-button.png";
import "../modules/help.css";

// const HelpPage = () => {
//   const [helpVideos, setHelpVideos] = useState([]);
//   const [selectedVideo, setSelectedVideo] = useState(null);
//   const [videoUrl, setVideoUrl] = useState('');
//   const UserID = sessionStorage.getItem("RegUserId")
//     ? JSON.parse(sessionStorage.getItem("RegUserId"))
//     : sessionStorage.getItem("UserID");

//   const publicKey = sessionStorage.getItem("RegpublicKey")
//     ? sessionStorage.getItem("RegpublicKey")
//     : JSON.parse(sessionStorage.getItem("publicKey"));
//   const token = sessionStorage.getItem("Regtoken")
//     ? sessionStorage.getItem("Regtoken")
//     : JSON.parse(sessionStorage.getItem("token"));

//   const padding = constants.RSA_PKCS1_PADDING;
//   const data = Buffer.from(UserID.toString(), "utf-8");
//   const encrypted = publicEncrypt(
//     { key: publicKey, padding: padding },
//     data
//   ).toString("base64");

//   const getVideos = async () => {
//     try {
//       const response = await Axios.get(
//         `${Config.base_url}${Config.routes.getHelpVideos}`,
//         {
//           headers: {
//             authorization: `Bearer ${token}`,
//             "x-caller": await encrypted,
//           },
//         }
//       );
//       if (response.status === 200) {
//         console.log("++++++++++", response.data.recordset);
//         setHelpVideos(response.data.recordset);
//       }
//     } catch (error) {
//       console.error("Error fetching help videos:", error);
//     }
//   };

//   const playVideo = async (videoFileName) => {
//     console.log("videoFileName", videoFileName);
//     try {
//       const response = await Axios.get(`${Config.apiURL}/webvideos/${videoFileName}?userId=${UserID}`, {
//         headers: {
//           authorization: `Bearer ${token}`,
//           "x-caller": encrypted,
//         },
//         responseType: 'arraybuffer'
//       });

//       if (response.status === 200) {
//         const blob = new Blob([response.data], { type: 'video/mp4' });
//         const url = URL.createObjectURL(blob);
//         setSelectedVideo(videoFileName);
//         setVideoUrl(url);
//       } else {
//         console.error("Failed to fetch video:", response.statusText);
//       }
//     }
//     catch (error) {
//       console.error("Error playing video:", error);
//     }
//   };

//   useEffect(() => {
//     getVideos();
//   }, []);

//   //
//   return (
//     <div className="profileRightContainer" style={{ display: "flex", gap: "1rem" }}>
//       <div className="leftContainer" style={{ width: "100%" }}>
//         <div className="topContainer">
//           <div style={{
//             display: "flex",
//             flexDirection: "column",
//             gap: "0.5rem",
//             alignItems: "center"
//           }}>
//             <label className="headerEdu"
//               style={{
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "center",
//                 gap: "0.75rem"
//               }}>
//               Explainer Videos
//             </label>
//           </div>
//         </div>
//         <div className="videocontainer">
//           {helpVideos.map((video, index) => (
//             <div key={index} className="videoItem">
//               <li
//                 key={index}
//                 onClick={() => playVideo(video.VIDEO_FILENAME)}
//                 style={{
//                   display: "flex",
//                   flexDirection: "row",
//                   gap: "1.5rem",
//                   cursor: "pointer"
//                 }}
//               >
//                 <img src={playbutton}
//                   style={{
//                     width: "30px",
//                     height: "30px",
//                     cursor: "pointer",
//                   }} />
//                 <div className="Vidtitle">{video.VIDEO_NAME}</div>
//               </li>
//             </div>
//           ))}
//         </div>
//       </div>
//       {selectedVideo && (
//         <div className="contentContainer" style={{ flex: 1 }}>
//           {/* Content on the right side */}
//         </div>
//       )}
//       {selectedVideo && (
//         <div className="videoPlayerContainer" >
//           <video id="videoPlayer"
//             width="624px" height="368px"
//             controls style={{
//               display: "block",
//               border: "1px solid #000000"
//             }}
//             crossOrigin="anonymous">
//             <source src={videoUrl} type="video/mp4" />
//           </video>
//         </div>
//       )}
//     </div>
//   );
// };

const HelpPage = () => {
  const [helpVideos, setHelpVideos] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [videoUrl, setVideoUrl] = useState("");
  const [loading, setLoading] = useState(false); // State for loading indicator

  const UserID = sessionStorage.getItem("RegUserId")
    ? JSON.parse(sessionStorage.getItem("RegUserId"))
    : sessionStorage.getItem("UserID");

  const publicKey = sessionStorage.getItem("RegpublicKey")
    ? sessionStorage.getItem("RegpublicKey")
    : JSON.parse(sessionStorage.getItem("publicKey"));
  const token = sessionStorage.getItem("Regtoken")
    ? sessionStorage.getItem("Regtoken")
    : JSON.parse(sessionStorage.getItem("token"));

  const padding = constants.RSA_PKCS1_PADDING;
  const data = Buffer.from(UserID.toString(), "utf-8");
  const encrypted = publicEncrypt(
    { key: publicKey, padding: padding },
    data
  ).toString("base64");

  const getVideos = async () => {
    try {
      const response = await Axios.get(
        `${Config.base_url}${Config.routes.getHelpVideos}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "x-caller": await encrypted,
          },
        }
      );
      if (response.status === 200) {
        //console.log("++++++++++", response.data.recordset);
        setHelpVideos(response.data.recordset);
        playVideo(response.data.recordset?.[0]?.VIDEO_FILENAME);
      }
    } catch (error) {
      console.error("Error fetching help videos:", error);
    }
  };

  const playVideo = async (videoFileName) => {
    setLoading(true); // Set loading to true when starting to fetch video
    //console.log("videoFileName", videoFileName);
    try {
      const response = await Axios.get(
        `${Config.apiURL}/webvideos/${videoFileName}?userId=${UserID}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "x-caller": encrypted,
          },
          responseType: "arraybuffer",
        }
      );

      if (response.status === 200) {
        const blob = new Blob([response.data], { type: "video/mp4" });
        const url = URL.createObjectURL(blob);
        setSelectedVideo(videoFileName);
        setVideoUrl(url);
      } else {
        console.error("Failed to fetch video:", response.statusText);
      }
    } catch (error) {
      console.error("Error playing video:", error);
    } finally {
      setLoading(false); // Set loading to false when video fetching is done
    }
  };

  useEffect(() => {
    getVideos();
  }, []);

  return (
    <div
      className="helpProfileRightContainer"
      style={{ display: "flex", gap: "1rem" }}
    >
      <div className="vidLeftCont">
        <div className="topContainer">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
              alignItems: "center",
            }}
          >
            <label
              className="headerEdu"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "0.75rem",
              }}
            >
              Explainer Videos
            </label>
          </div>
        </div>
        <div className="videocontainer">
          {helpVideos.map((video, index) => (
            <div key={index} className="videoItem">
              <li
                key={index}
                onClick={() => playVideo(video.VIDEO_FILENAME)}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1.5rem",
                  cursor: "pointer",
                  alignItems:"center"
                }}
              >
                <img
                  src={playbutton}
                  style={{
                    width: "30px",
                    height: "30px",
                    cursor: "pointer",
                  }}
                />
                <div className="Vidtitle">{video.VIDEO_NAME}</div>
              </li>
            </div>
          ))}
        </div>
      </div>
      {/*{selectedVideo && !loading && (
      <div className="contentContainer" style={{ flex: 1 }}></div>
      )}
      */}
      <div className="videoPlayerContainer">
        {loading ? ( // Show loading indicator while loading
          <div className="Loading">Loading...</div>
        ) : (
          selectedVideo && (
            <video
              id="videoPlayer"
              width="620px"
              height="350px"
              controls
              style={{
                display: "block",
                border: "1px solid #000000",
              }}
              crossOrigin="anonymous"
            >
              <source src={videoUrl} type="video/mp4" />
            </video>
          )
        )}
      </div>
    </div>
  );
};

export default HelpPage;
