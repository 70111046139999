import React, { useState, useEffect } from "react";
import { publicEncrypt, constants } from "crypto-browserify";
import { Buffer } from "buffer";
import Config from "../../Config/config";
import axios from "axios";
import NHA from "../../images/nha.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const RegABDM = ({ toggleSectionTab }) => {
  const [activeSection, setActiveSection] = useState("HPR");
  const [selectedOption, setSelectedOption] = useState("");
  const [variables, setVariables] = useState({
    hprId: "",
    hfrId: "",
  });

  useEffect(() => {
    const RegMobileNum = sessionStorage.getItem("RegMobileNum") || "";
    const UserID = sessionStorage.getItem("RegUserId") || "";
    const publicKey = sessionStorage.getItem("RegpublicKey") || "";
    const token = sessionStorage.getItem("Regtoken") || "";
    var padding = constants.RSA_PKCS1_PADDING;
    var data = Buffer.from(UserID.toString(), "utf-8");
    var encrypted = publicEncrypt(
      { key: publicKey, padding: padding },
      data
    ).toString("base64");
    try {
      axios
        .get(Config.apiURL + Config.routes.getUserHospitals + `${UserID}`, {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
            "x-caller": encrypted,
          },
        })
        .then((response) => {
          //console.log("response", response.data.data[0].HOSPITAL_ID);
          sessionStorage.setItem(
            "hospitalId",
            JSON.stringify(response.data.data[0].HOSPITAL_ID)
          );
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
      return "Error";
    }
  }, []);

  const handleNext = async () => {
    if (activeSection === "HPR") {
      setActiveSection("HFR");
      return;
    }
    const hospitalId = sessionStorage.getItem("hospitalId") || "";
    const UserID = sessionStorage.getItem("RegUserId") || "";
    const publicKey = sessionStorage.getItem("RegpublicKey") || "";
    const token = sessionStorage.getItem("Regtoken") || "";
    var padding = constants.RSA_PKCS1_PADDING;
    var data = Buffer.from(UserID.toString(), "utf-8");
    var encrypted = publicEncrypt(
      { key: publicKey, padding: padding },
      data
    ).toString("base64");
    try {
      const body = JSON.stringify({
        hospitalId: hospitalId,
        userId: UserID,
        hprId: variables.hprId,
        hfrId: variables.hfrId,
      });
      //console.log("dataforabdm", body);
      axios
        .post(Config.base_url + Config.routes.updateHFRID, body, {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
            "x-caller": encrypted,
          },
        })
        .then((response) => {
          //console.log("res", response);
          if (response.status == 200) {
            toast.success("ABDM Updated Sucessfully");
          }
        })

        .catch((error) => {
          console.error(error);
          toast.error("Server Error!");
        });
    } catch (error) {
      console.error(error);
      return "Error";
    }
  };

  const toggleSection = (sectionName) => {
    setActiveSection(sectionName);
  };
  const toggleOptions = (option) => {
    setSelectedOption(option);
  };

  const handleInputChange = (event, field) => {
    setVariables((prevVariables) => ({
      ...prevVariables,
      [field]: event.target.value,
    }));
  };

  const handleSkip = () => {
    toggleSectionTab("Education");
  };
  return (
    <>
      <div
        className="profileRightContainer"
        style={{ display: "flex", gap: "1rem",}}
      >
        <div className="leftContainer" style={{ width: "50%", gap: "1rem" }}>
          <div
            className="bottomContainer"
            style={{ gap: "1rem"}}
          >
            <p className="abdmHeader"> Ayushman Bharat Digital Mission</p>
            <div
              style={{
                padding: "0.5rem 1rem",
                background: "#E2EDFD",
                display: "flex",
                justifyContent: "center",
                margin: "0 auto",
                borderRadius: "10px",
              }}
            >
              <div
                className={`sectionsName ${
                  activeSection === "HPR" ? "activeSection" : ""
                }`}
                onClick={() => toggleSection("HPR")}
                style={{
                  border:
                    activeSection === "HPR" ? "1px solid #BDC3C7" : "none",
                  padding: "0.3rem 3rem",
                  backgroundColor: activeSection === "HPR" ? "white" : "",
                }}
              >
                <p>HPR ID</p>
              </div>
              <div
                className={`sectionsName ${
                  activeSection === "HFR" ? "activeSection" : ""
                }`}
                onClick={() => toggleSection("HFR")}
                style={{
                  border:
                    activeSection === "HFR" ? "1px solid #BDC3C7" : "none",
                  padding: "0.3rem 3rem",
                  backgroundColor: activeSection === "HFR" ? "white" : "",
                }}
              >
                <p>HFR ID</p>
              </div>
            </div>
            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <img src={NHA} alt="" />
              <p
                style={{
                  fontFamily: "Varela Round",
                  fontSize: "21px",
                  fontWeight: 600,
                  lineHeight: "26px",
                  letterSpacing: "0.1rem",
                  textAlign: "center",
                  // color: "#52447D",
                  padding: "0.75rem",
                }}
              >
                {activeSection === "HPR"
                  ? "Health Professional Registry Service"
                  : "Health Facility Registry Service"}
              </p>
            </div>
            {activeSection === "HPR" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                  }}
                >
                  <p
                    style={{
                      fontSize: "19px",
                      fontWeight: 400,
                      lineHeight: "24px",
                      letterSpacing: "0em",
                      // textAlign: "center",
                      // color: "#52447D",
                      justifyContent: "center",
                      textAlign:"center"
                    }}
                  >
                    Do you have Ayushman Bharat Digital Mission Health
                    Professional Registry ID ?
                  </p>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "2rem",
                      cursor: "pointer",
                    }}
                  >
                    <p
                      style={{
                        width: "140px",
                        height: "43px",
                        borderRadius: "12px",
                        border:
                          selectedOption === "Yes"
                            ? "1px solid #8873C9"
                            : " 1px solid #8873C9",
                        background:
                          selectedOption === "Yes" ? "#8873C9" : "white",
                        color: selectedOption === "Yes" ? "white" : "#8873C9",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "500",
                        fontSize: "16px",
                      }}
                      onClick={() => toggleOptions("Yes")}
                    >
                      Yes
                    </p>
                    <p
                      style={{
                        width: "140px",
                        height: "43px",
                        borderRadius: "12px",
                        border:
                          selectedOption === "No"
                            ? "1px solid #8873C9"
                            : " 1px solid #8873C9",
                        background:
                          selectedOption === "No"
                            ? "rgba(136, 115, 201, 1)"
                            : "white",
                        color:
                          selectedOption === "No"
                            ? "white"
                            : "rgba(136, 115, 201, 1)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "500",
                        fontSize: "16px",
                      }}
                      onClick={() => toggleOptions("No")}
                    >
                      No
                    </p>
                  </div>
                  {selectedOption === "Yes" && (
                    <input
                      style={{
                        cursor: "pointer",
                        width: "100%",
                        background: "#eeecf5",
                        // border: "1px solid black",
                        borderRadius: "9px",
                        height: "6vh",
                        padding: "0 1rem",
                        border: "none",
                      }}
                      className="personalInfoValue"
                      type="text"
                      placeholder="Enter HPR ID"
                      value={variables.hprId}
                      onChange={(e) => handleInputChange(e, "hprId")}
                    />
                  )}
                  {/* {selectedOption === "No" && (
                    <div
                      style={{
                        gap: "3rem",
                      }}
                    >
                      <p
                        style={{
                          fontWeight: "600",
                          fontSize: "19px",
                        }}
                      >
                        Do you want to complete HPR registration ?
                      </p>
                      <p
                        style={{
                          padding: "1rem",
                          fontWeight: "600",
                          fontSize: "19px",
                          textDecoration: "underline",
                          color: "gray",
                        }}
                      >
                        Benefits of Health Provider Registry
                      </p>
                      <ul
                        style={{
                          listStyleType: "disc",
                          paddingLeft: "1.5rem",
                          color: "gray",
                        }}
                      >
                        <li>i. Online presence and discoverability.</li>
                        <li>
                          ii. Faster Registration, License Renewal, and NOC
                          Issuance with councils and other governing bodies.
                        </li>
                        <li>iii. Digital Health Records</li>
                        <li>iv. Telemedicine</li>
                      </ul>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p style={{ fontWeight: "600" }}>
                          To create your HPR ID -
                        </p>
                        <p
                          style={{
                            width: "140px",
                            height: "43px",
                            borderRadius: "12px",
                            border: "1px solid #8873C9",
                            background: "#8873C9",
                            color: "white",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontWeight: "500",
                            fontSize: "16px",
                          }}
                        >
                          Click Here
                        </p>
                      </div>
                    </div>
                  )} */}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    paddingTop: "2rem",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Varela Round",
                      fontSize: "15px",
                      fontWeight: 400,
                      lineHeight: "18px",
                      letterSpacing: "0em",
                      textAlign: "center",
                      color: "#52447D",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={handleSkip}
                  >
                    Skip ABDM integration for now,
                  </p>
                  <div
                    className={"editButton2"}
                    style={{ width: "30%", margin: "0 auto", height: "40px" }}
                    onClick={handleNext}
                  >
                    <p style={{ color: "white" }}>Next</p>
                  </div>
                  {/* <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{
                        fontFamily: "Varela Round",
                        fontSize: "22px",
                        fontWeight: 400,
                        lineHeight: "26px",
                        letterSpacing: "0em",
                        textAlign: "center",
                        color: "#0B4876",
                      }}
                    >
                      APPROVED BY NHA
                    </p>
                    <img src={NHA} alt="" />
                  </div> */}
                </div>
              </div>
            )}
            {activeSection === "HFR" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                  }}
                >
                  <p
                    style={{
                      fontSize: "19px",
                      fontWeight: 400,
                      lineHeight: "24px",
                      letterSpacing: "0em",
                      // textAlign: "center",
                      // color: "#52447D",
                      justifyContent: "center",
                      textAlign:'center'
                    }}
                  >
                    Do you have Ayushman Bharat Digital Mission Health Facility
                    Registry ID ?
                  </p>
                  <div
                    style={{
                      display: "flex",
                      gap: "2rem",
                      cursor: "pointer",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      style={{
                        width: "140px",
                        height: "43px",
                        borderRadius: "12px",
                        border:
                          selectedOption === "YesHfr"
                            ? "1px solid #8873C9"
                            : " 1px solid #8873C9",
                        background:
                          selectedOption === "YesHfr" ? "#8873C9" : "white",
                        color:
                          selectedOption === "YesHfr" ? "white" : "#8873C9",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "500",
                        fontSize: "16px",
                      }}
                      onClick={() => toggleOptions("YesHfr")}
                    >
                      Yes
                    </p>
                    <p
                      style={{
                        width: "140px",
                        height: "43px",
                        borderRadius: "12px",
                        border:
                          selectedOption === "NoHfr"
                            ? "1px solid #8873C9"
                            : " 1px solid #8873C9",
                        background:
                          selectedOption === "NoHfr"
                            ? "rgba(136, 115, 201, 1)"
                            : "white",
                        color:
                          selectedOption === "NoHfr"
                            ? "white"
                            : "rgba(136, 115, 201, 1)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "500",
                        fontSize: "16px",
                      }}
                      onClick={() => toggleOptions("NoHfr")}
                    >
                      No
                    </p>
                  </div>
                  {selectedOption === "YesHfr" && (
                    <>
                      <input
                        style={{
                          cursor: "pointer",
                          width: "100%",
                          background: "#eeecf5",
                          // border: "1px solid black",
                          // borderRadius: "9px",
                          height: "6vh",
                          padding: "0 1rem",
                          border: "none",
                        }}
                        className="personalInfoValue"
                        type="text"
                        placeholder="Enter HFR ID"
                        value={variables.hfrId}
                        onChange={(e) => handleInputChange(e, "hfrId")}
                      />
                      {/* <p>
                        Please use below Bridge ID & URL to link Clinally mPower
                        with your Health Facility:
                      </p>
                      <p
                        style={{
                          display: "flex",
                          // justifyContent: "center",
                          alignItems: "center",
                          height: "9vh",
                          border: "2px solid rgba(136, 115, 201, 1)",
                          borderRadius: "12px",
                          background: "white",
                          padding: "0px 1rem",
                        }}
                      >
                        {" "}
                        <ul>
                          <li>Bridge ID : CPHSPL_001</li>
                          <li>
                            Callback URL : https://abhaservices.clinally.com
                          </li>
                        </ul>
                      </p>
                      <p
                        style={{
                          padding: "1rem",
                          fontWeight: "500",
                          fontSize: "19px",
                          textDecoration: "underline",
                          color: "gray",
                        }}
                      >
                        Steps for Linkage of Software From the dashboard
                      </p>
                      <ul style={{ paddingLeft: "1.5rem", color: "gray" }}>
                        <li>i. Login to HFR portal using HFR ID.</li>
                        <li>
                          ii. Click on Link your Software from your respective
                          facility.
                        </li>
                        <li>
                          iii. Enter the Bridge ID and call back URL provided to
                          your
                        </li>
                      </ul> */}
                    </>
                  )}
                  {/* {selectedOption === "NoHfr" && (
                    <div
                      style={{
                        gap: "3rem",
                      }}
                    >
                      <p
                        style={{
                          fontWeight: "600",
                          fontSize: "19px",
                        }}
                      >
                        Do you want to complete HPR registration ?
                      </p>
                      <p
                        style={{
                          padding: "1rem",
                          fontWeight: "600",
                          fontSize: "19px",
                          textDecoration: "underline",
                          color: "gray",
                        }}
                      >
                        Benefits of Health Facility Registry
                      </p>
                      <ul
                        style={{
                          listStyleType: "disc",
                          paddingLeft: "1.5rem",
                          color: "gray",
                        }}
                      >
                        <li>i. Unique & Trustable Identity.</li>
                        <li>ii. Online Presence & Discoverability.</li>
                        <li>iii. Ease of doing business</li>
                        <li>iv. Unified Digital Services</li>
                      </ul>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p style={{ fontWeight: "600" }}>
                          To create your HFR ID -
                        </p>
                        <p
                          style={{
                            width: "140px",
                            height: "43px",
                            borderRadius: "12px",
                            border: "1px solid #8873C9",
                            background: "#8873C9",
                            color: "white",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontWeight: "500",
                            fontSize: "16px",
                          }}
                        >
                          Click Here
                        </p>
                      </div>
                    </div>
                  )} */}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    paddingTop: "2rem",
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Varela Round",
                      fontSize: "15px",
                      fontWeight: 400,
                      lineHeight: "18px",
                      letterSpacing: "0em",
                      textAlign: "center",
                      color: "#52447D",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={handleSkip}
                  >
                    Skip ABDM integration for now,
                  </p>
                  <div
                    className={"editButton2"}
                    style={{ width: "30%", margin: "0 auto", height: "40px" }}
                    onClick={handleNext}
                  >
                    <p style={{ color: "white" }}>Next</p>
                  </div>
                  {/* <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{
                        fontFamily: "Varela Round",
                        fontSize: "22px",
                        fontWeight: 400,
                        lineHeight: "26px",
                        letterSpacing: "0em",
                        textAlign: "center",
                        color: "#0B4876",
                      }}
                    >
                      APPROVED BY NHA
                    </p>
                    <img src={NHA} alt="" />
                  </div> */}
                </div>
              </div>
            )}
            <ToastContainer />
          </div>
        </div>
        {/* <div
          className="bottomContainer"
          style={{ width: "50%", gap: "1rem", overflow: "scroll" }}
        >
          <p
            style={{
              fontFamily: "Varela Round",
              fontSize: "20px",
              fontWeight: 600,
              lineHeight: "29px",
              letterSpacing: "0.11em",
              textAlign: "left",
              color: "#52447D",
            }}
          >
            How to Register inHealth Facility Registry?
          </p>
          <div style={{ background: "#AEAEAE", width: "100%", height: "2px" }}>
            <p style={{ height: "2px" }}></p>
          </div>
          <div style={{ display: "flex", gap: "1rem" }}>
            <p
              style={{
                fontFamily: "Varela Round",
                fontSize: "15px",
                fontWeight: 400,
                lineHeight: "18px",
                letterSpacing: "0em",
                textAlign: "left",
                background: "#8873C9",
                color: "white",
                height: "40px",
                width: "40px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              1
            </p>
            <div>
              <p
                style={{
                  fontFamily: "Varela Round",
                  fontSize: "22px",
                  fontWeight: 400,
                  lineHeight: "18px",
                  letterSpacing: "0em",
                  textAlign: "left",
                  color: "#8873C9",
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Generate Healthcare Professional ID{" "}
              </p>
              <p
                style={{
                  fontFamily: "Varela Round",
                  fontSize: "19px",
                  fontWeight: 400,
                  lineHeight: "26px",
                  letterSpacing: "9%",
                }}
              >
                Register using Aadhar or other KYC means.
              </p>
            </div>
          </div>
          <div style={{ display: "flex", gap: "1rem" }}>
            <p
              style={{
                fontFamily: "Varela Round",
                fontSize: "15px",
                fontWeight: 400,
                lineHeight: "18px",
                letterSpacing: "0em",
                textAlign: "left",
                background: "#8873C9",
                color: "white",
                height: "40px",
                width: "75px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              2
            </p>
            <div>
              <p
                style={{
                  fontFamily: "Varela Round",
                  fontSize: "22px",
                  fontWeight: 400,
                  lineHeight: "18px",
                  letterSpacing: "0em",
                  textAlign: "left",
                  color: "#8873C9",
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Search your Facility{" "}
              </p>
              <p
                style={{
                  fontFamily: "Varela Round",
                  fontSize: "19px",
                  fontWeight: 400,
                  lineHeight: "26px",
                  letterSpacing: "9%",
                }}
              >
                Enter your facility name and select from search results. If no
                result is found, then register a new facility.
              </p>
            </div>
          </div>
          <div style={{ display: "flex", gap: "1rem" }}>
            <p
              style={{
                fontFamily: "Varela Round",
                fontSize: "15px",
                fontWeight: 400,
                lineHeight: "18px",
                letterSpacing: "0em",
                textAlign: "left",
                background: "#8873C9",
                color: "white",
                height: "40px",
                width: "45px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              3
            </p>
            <div>
              <p
                style={{
                  fontFamily: "Varela Round",
                  fontSize: "22px",
                  fontWeight: 400,
                  lineHeight: "18px",
                  letterSpacing: "0em",
                  textAlign: "left",
                  color: "#8873C9",
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Fill Registration Form{" "}
              </p>
              <p
                style={{
                  fontFamily: "Varela Round",
                  fontSize: "19px",
                  fontWeight: 400,
                  lineHeight: "26px",
                  letterSpacing: "9%",
                }}
              >
                Enter facility details, services offered and infrastructure
                details.
              </p>
            </div>
          </div>
          <div style={{ display: "flex", gap: "1rem" }}>
            <p
              style={{
                fontFamily: "Varela Round",
                fontSize: "15px",
                fontWeight: 400,
                lineHeight: "18px",
                letterSpacing: "0em",
                textAlign: "left",
                background: "#8873C9",
                color: "white",
                height: "40px",
                width: "45px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              4
            </p>
            <div>
              <p
                style={{
                  fontFamily: "Varela Round",
                  fontSize: "22px",
                  fontWeight: 400,
                  lineHeight: "18px",
                  letterSpacing: "0em",
                  textAlign: "left",
                  color: "#8873C9",
                  height: "40px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Submit Form{" "}
              </p>
              <p
                style={{
                  fontFamily: "Varela Round",
                  fontSize: "19px",
                  fontWeight: 400,
                  lineHeight: "26px",
                  letterSpacing: "9%",
                }}
              >
                Review and submit form for verification. Respond to queries (if
                any).
              </p>
            </div>
          </div>
        </div> */}
        {activeSection === "HPR" && selectedOption === "No" && (
          <div className="leftContainer" style={{ width: "50%", gap: "1rem" }}>
            <div
              className="bottomContainer"
              style={{
                display:'flex',
                gap: "1rem",
                height: "100%",
                overflow: "scroll",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  gap: "3rem",
                }}
              >
                <p
                  style={{
                    fontWeight: "600",
                    fontSize: "19px",
                  }}
                >
                  Do you want to complete HPR registration ?
                </p>
                <p
                  style={{
                    padding: "1rem",
                    fontWeight: "600",
                    fontSize: "19px",
                    textDecoration: "underline",
                    color: "gray",
                  }}
                >
                  Benefits of Health Provider Registry
                </p>
                <ul
                  style={{
                    listStyleType: "disc",
                    paddingLeft: "1.5rem",
                    color: "gray",
                  }}
                >
                  <li>i. Online presence and discoverability.</li>
                  <li>
                    ii. Faster Registration, License Renewal, and NOC Issuance
                    with councils and other governing bodies.
                  </li>
                  <li>iii. Digital Health Records</li>
                  <li>iv. Telemedicine</li>
                </ul>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <p style={{ fontWeight: "600" }}>To create your HPR ID -</p>
                  <p
                    style={{
                      width: "140px",
                      height: "43px",
                      borderRadius: "12px",
                      border: "1px solid #8873C9",
                      background: "#8873C9",
                      color: "white",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontWeight: "500",
                      fontSize: "16px",
                      cursor:"pointer"
                    }}
                  >
                    Click Here
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        {activeSection === "HFR" && selectedOption === "YesHfr" && (
          <div className="leftContainer" style={{ width: "50%", gap: "1rem" }}>
            <div
              className="bottomContainer"
              style={{
                gap: "1rem",
                justifyContent: "center",
              }}
            >
              <p>
                Please use below Bridge ID & URL to link Clinally mPower with
                your Health Facility:
              </p>
              <p
                style={{
                  display: "flex",
                  // justifyContent: "center",
                  alignItems: "center",
                  height: "9vh",
                  border: "2px solid rgba(136, 115, 201, 1)",
                  borderRadius: "12px",
                  background: "white",
                  padding: "0px 1rem",
                  
                }}
              >
                {" "}
                <ul style={{marginRight:'10px'}}>
                  <li>Bridge ID : CPHSPL_001</li>
                  <li>Callback URL : https://abhaservices.clinally.com</li>
                </ul>
              </p>
              <p
                style={{
                  padding: "1rem",
                  fontWeight: "500",
                  fontSize: "19px",
                  textDecoration: "underline",
                  color: "gray",
                }}
              >
                Steps for Linkage of Software From the dashboard
              </p>
              <ul style={{ paddingLeft: "1.5rem", color: "gray" }}>
                <li>i. Login to HFR portal using HFR ID.</li>
                <li>
                  ii. Click on Link your Software from your respective facility.
                </li>
                <li>
                  iii. Enter the Bridge ID and call back URL provided to your
                </li>
              </ul>
            </div>
          </div>
        )}
        {activeSection === "HFR" && selectedOption === "NoHfr" && (
          <div className="leftContainer" style={{ width: "50%", gap: "1rem" }}>
            <div
              className="bottomContainer"
              style={{
                gap: "1rem",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  gap: "3rem",
                }}
              >
                <p
                  style={{
                    fontWeight: "600",
                    fontSize: "19px",
                  }}
                >
                  Do you want to complete HPR registration ?
                </p>
                <p
                  style={{
                    padding: "1rem",
                    fontWeight: "600",
                    fontSize: "19px",
                    textDecoration: "underline",
                    color: "gray",
                  }}
                >
                  Benefits of Health Facility Registry
                </p>
                <ul
                  style={{
                    listStyleType: "disc",
                    paddingLeft: "1.5rem",
                    color: "gray",
                  }}
                >
                  <li>i. Unique & Trustable Identity.</li>
                  <li>ii. Online Presence & Discoverability.</li>
                  <li>iii. Ease of doing business</li>
                  <li>iv. Unified Digital Services</li>
                </ul>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <p style={{ fontWeight: "600" }}>To create your HFR ID -</p>
                  <p
                    style={{
                      width: "140px",
                      height: "43px",
                      borderRadius: "12px",
                      border: "1px solid #8873C9",
                      background: "#8873C9",
                      color: "white",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontWeight: "500",
                      fontSize: "16px",
                      cursor:"pointer"
                    }}
                  >
                    Click Here
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default RegABDM;
