import React, { useEffect, useState } from "react";
import Navbar from "../components/modules/Navbar";
import { useNavigate,useLocation } from "react-router-dom";
import { publicEncrypt, constants } from "crypto-browserify";
import { Buffer } from "buffer";



const Demographics = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const skipRoute = queryParams.get("action");
    const userDetails = JSON.parse(sessionStorage.getItem("UserData"))[0];
    const UserID = sessionStorage.getItem("RegUserId")
        ? JSON.parse(sessionStorage.getItem("RegUserId"))
        : sessionStorage.getItem("UserID");

    const publicKey = sessionStorage.getItem("RegpublicKey")
        ? sessionStorage.getItem("RegpublicKey")
        : JSON.parse(sessionStorage.getItem("publicKey"));

    const token = sessionStorage.getItem("Regtoken")
        ? sessionStorage.getItem("Regtoken")
        : JSON.parse(sessionStorage.getItem("token"));

    const padding = constants.RSA_PKCS1_PADDING;
    const data = Buffer.from(UserID.toString(), "utf-8");
    const encrypted = publicEncrypt(
        { key: publicKey, padding: padding },
        data
    ).toString("base64");

    // const PATIENT_ID = JSON.parse(sessionStorage.getItem("PATIENT_ID"));

    const profile_data = sessionStorage.getItem('profileData');
    const profileData = profile_data ? JSON.parse(profile_data) : null
    //console.log("profile data", profileData)

    const navigate = useNavigate();

    const handleContinue = () => {
        if(skipRoute !=="linkabha"){
            navigate('/patients/add-patients?action=abhatoaddpatient')
        }else(
            navigate('/patients')
        )
       
    }

    const TabsContainer = () => {
        return (
            <div
                style={{
                    display: "flex",
                    backgroundColor: "#F5F5F5",
                    width: "80vw",
                    flexDirection: "column",
                    gap: "1rem",
                    overflow: "scroll",
                }}
            >
                <div
                    style={{
                        backgroundColor: "#8873C9",
                        display: "flex",
                        position: "sticky",
                        // flexDirection:'row'
                    }}
                >

                    <button className="review">ABHA DEMOGRAPHICS</button>
                </div>

                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        // justifyContent: "space-between",
                        padding: "1rem",
                        gap: "10rem",
                    }}
                >
                    <div>
                        {/* <div className="patient_data_text">PATIENT DETAILS</div> */}
                        <div
                            style={{
                                display: "flex",
                                alignContent: "center",
                                flexDirection: "column",
                            }}
                        >
                            <p>
                                Please verify the details before continuing registration
                            </p>
                        </div>
                    </div>


                </div>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1.3rem' }}>
                    <div style={{ background: 'white', width: '60%', padding: '2%' }}>
                        ABHA NUMBER : {profileData.healthIdNumber}
                    </div>
                    <div style={{ background: 'white', width: '60%', padding: '2%' }}>
                        ABHA ADDRESS : {profileData.healthId}
                    </div>
                    <div style={{ background: 'white', width: '60%', padding: '2%' }}>
                        NAME :  {profileData.name}
                    </div>
                    <div style={{ background: 'white', width: '60%', padding: '2%', }}>
                        DATE OF BIRTH :  {`${profileData.dayOfBirth}/${profileData.monthOfBirth}/${profileData.yearOfBirth}`}
                    </div>
                    <div style={{ background: 'white', width: '60%', padding: '2%' }}>
                        ADDRESS :  {profileData.address
                        }
                    </div>
                    <div style={{ background: 'white', width: '60%', padding: '2%' }}>
                        STATE :   {profileData.stateName}
                    </div>
                    <div style={{ background: 'white', width: '60%', padding: '2%' }}>
                        GENDER :  {profileData.gender === 'M' ? 'Male' : 'Female'}
                    </div>
                </div>

                <div style={{ display: "flex", justifyContent: "center" }}>
                    <button
                        className="addAppointmentsBtn"
                        style={{
                            Background: "#52447d !important",
                            boxShadow: "0px 3px 16px 1px #52447D75",
                        }}
                        onClick={handleContinue}
                    >
                        Continue
                    </button>

                </div>
            </div >
        );
    };



    return (
        <>
            {/* <Navbar /> */}
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "86vh",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        height: "70vh",
                        overflow: "scroll",
                        gap: "1rem",
                    }}
                >
                    <TabsContainer />

                </div>
            </div>
        </>
    );
};

export default Demographics;
