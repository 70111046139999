import React, { useState, useEffect } from "react";
import Navbar from "../components/modules/Navbar";
import TopNavBar from "../components/modules/TopNavBar";
import PatientsInformation from "./patientsInformation";
import "./layout.css";
//main components lists
import ReviewPrescription from "../components/modules/Visits/ReviewPage";
import TestVisit from "./TestVisit";

import Landing from "./Landing";
import Appointments from "./Appointments";
import ResetPin from "./ResetPin";
import AddPatients from "./AddPatients";
import Patients from "./Patients";
import ResetPassword from "./ResetPassword";
import Profile from "./Profile";

import Test from "../components/modules/Test";

import BloodPressureGraph from "./graph";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ABHAIntegration from "../components/modules/ABHA/Abha_Integration";
import Graphs from "../components/modules/Visits/Graph";

import Document from "../components/modules/Visits/Document";
import ManageABHAConsents from "../components/modules/ABHA/ManageABHAConsents";
import ManageABHASubscription from "../components/modules/ABHA/ManageABHASubscription";
// import Treatment from './Treatment'
import DiagnosisComponent from "./DiagnosisComponent";
import Treatment from "../components/modules/Visits/TreatmentPlanMainPage";
import ConsolidatedReport from "../components/modules/Visits/ConsolidatedReport";
import LabTests from "../components/modules/Visits/LabTests";
import Prescription from "../components/modules/Visits/Prescription";
import Demographics from "./Demographics";
import PreviewPrescription from "../components/modules/Visits/PreviewPrescription";
import ManageAbha from "../components/modules/ABHA/ManageABHA";

function Layout({ ComponentName, type }) {
  const [navbarMapulation, setnavbarMapulation] = useState();
  const [state, setstate] = useState(true);
  const action = sessionStorage.getItem("action");
  useEffect(() => {
    // console.log(ComponentName, "........................data..............");
    // const navbarMapulation = sessionStorage.getItem("navbarMapulation");
    // console.log(navbarMapulation,"navbarMapulation........")
    // setnavbarMapulation(navbarMapulation);
  }, [navbarMapulation]);
  const navbarMapulationvalue = (value) => {
    setnavbarMapulation(!value);
  };
  const MainContent = () => {
    return ComponentName;
  };
  const setSelectedRowsendData = (data) => {
    //console.log(data, "........................data..............");
  };

  const setvisiblecontent = (data) => {
    setstate(data);
  };
  return (
    <div className="Patient-MainContainer">
      {console.log(action,"actionactionaction")}
      {action === "register" ? (
        <></>
      ) : (
        <Navbar navbarMapulationvalue={navbarMapulationvalue} />
      )}
      <div
        className="layoutRight"
        style={{ width: navbarMapulation ? "94.5%" : "84.5%" }}
      >
        <TopNavBar />
        <div className="layoutRight_1">
          <div className="layoutRight_1_1">
            {!type && (
              <PatientsInformation setvisiblecontent={setvisiblecontent} />
            )}

            {/* {state?( */}
            <div className="layoutRight_1_child_2">
              <MainContent setSelectedRowsendData={setSelectedRowsendData} />
            </div>
            {/* // ):(
            //   <div>
            //     <p>No Data</p>
            //   </div>
            // )} */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Layout;
