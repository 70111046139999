import React from "react";
import { useState, useEffect } from "react";
import Axios from "axios";
import Navbar from "../Navbar";
import "./ManageABHAConsents.css";
import { MDBDataTable } from "mdbreact";
import CustomButton from "../../CustomButton";
import RightArrow from "../../../images/Group 36.png";
import { ToastContainer, toast } from "react-toastify";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  makeStyles,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import config from "../../../Config/config";
import { publicEncrypt, constants } from "crypto-browserify";
import { Buffer } from "buffer";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { format } from "date-fns";
import Lottie from "react-lottie";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiPopover-paper": {
      top: "unset !important",
      bottom: "100% !important",
    },
  },
}));

const ManageABHAConsents = () => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const selectedPatientDetails = JSON.parse(
    sessionStorage.getItem("selectedPatientDetails")
  );

  const classes = useStyles();

  const [selectedSection, setSelectedSection] = useState("PENDING");
  const sections = ["PENDING", "GRANTED", "DENIED", "REVOKED", "EXPIRED"];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fechingConsent, setFechingConsent] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const initialFormData = {
    patientIdentifier: selectedPatientDetails.ABHA_ADDRESS,
    consentPurpose: "",
    healthDataFromDate: "",
    healthDataToDate: "",
    healthInfoTypes: {
      OpConsultation: false,
      DiagnosticReport: false,
      DischargeSummary: false,
      Prescription: false,
      ImmunizationRecord: false,
      HealthDocumentRecord: false,
      WellnessRecord: false,
    },
    expiryDate: new Date().toISOString().split("T")[0],
  };
  const [consentData, setConsentData] = useState([]);
  const [decryptedData, setDecryptedData] = useState(null);
  const [condition, setCondition] = useState(false);
  const [formData, setFormData] = useState(initialFormData);
  const [itemurl, setitemurl] = useState("");
  const [filename, setfilename] = useState("");

  const formatDate = (isoString) => {
    return isoString.split("T")[0];
  };

  const patientDetails = JSON.parse(
    sessionStorage.getItem("selectedPatientDetails")
  );

  const UserID = sessionStorage.getItem("RegUserId")
    ? JSON.parse(sessionStorage.getItem("RegUserId"))
    : sessionStorage.getItem("UserID");

  const publicKey = sessionStorage.getItem("RegpublicKey")
    ? sessionStorage.getItem("RegpublicKey")
    : JSON.parse(sessionStorage.getItem("publicKey"));
  const token = sessionStorage.getItem("Regtoken")
    ? sessionStorage.getItem("Regtoken")
    : JSON.parse(sessionStorage.getItem("token"));

  const padding = constants.RSA_PKCS1_PADDING;
  const data1 = Buffer.from(UserID.toString(), "utf-8");
  const encrypted = publicEncrypt(
    { key: publicKey, padding: padding },
    data1
  ).toString("base64");

  const getConsentsList = async () => {
    try {
      const response = await Axios.get(
        `${config.base_url}CONSENTS/${selectedPatientDetails.ABHA_ADDRESS}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "x-caller": await encrypted,
          },
        }
      );

      if (response.status === 200) {
        const consentData = response.data.recordset;
        //console.log("consent", consentData)
        const processedData = consentData.map((entry, index) => {
          const CONSENT_REQUEST = JSON.parse(entry.CONSENT_REQUEST);
          return {
            name: patientDetails.FIRST_NAME,
            ABHAId: entry.ABHA_ADDRESS,
            FromDate: formatDate(
              CONSENT_REQUEST.consent.permission.dateRange.from
            ),
            ToDate: formatDate(CONSENT_REQUEST.consent.permission.dateRange.to),
            EXPIREDAt: formatDate(
              CONSENT_REQUEST.consent.permission.dataEraseAt
            ),
            RequestedOn: formatDate(entry.CREATED_DATE),
            Status:
              entry.STATUS === "GRANTED" &&
              new Date(CONSENT_REQUEST.consent.permission.dataEraseAt) >=
                new Date()
                ? entry.STATUS
                : entry.STATUS === "GRANTED" &&
                  new Date(CONSENT_REQUEST.consent.permission.dataEraseAt) <
                    new Date()
                ? "EXPIRED"
                : entry.STATUS,
            // Status:"REQUESTED",
            Action:
              entry.STATUS === "GRANTED" &&
              new Date(CONSENT_REQUEST.consent.permission.dataEraseAt) >
                new Date() ? (
                <img
                  onClick={() =>
                    consentFetch(
                      entry.CONSENT_ARTEFACT_ID,
                      entry.ON_FETCH,
                      index
                    )
                  }
                  style={{ cursor: "pointer", width: "10%" }}
                  src={RightArrow}
                  alt="Description of image"
                />
              ) : (
                <span></span>
              ),
          };
        });
        //console.log(processedData,"lsdlksmlksdlkmlk total deta")
        const filtered = processedData.filter((row) => {
          switch (selectedSection) {
            case "PENDING":
              return row.Status === "REQUESTED";
            case "GRANTED":
              return row.Status === "GRANTED";
            case "DENIED":
              return row.Status === "DENIED";
            case "REVOKED":
              return row.Status === "REVOKED";
            case "EXPIRED":
              return row.Status === "EXPIRED";
            default:
              return true;
          }
        });

        setFilteredData(filtered);
      }
    } catch (error) {
      console.error("Error fetching subscription list:", error);
    }
  };
  useEffect(() => {
    getConsentsList();
  }, [selectedPatientDetails.ABHA_ADDRESS, selectedSection]);

  // const data = {
  //   columns: [
  //     { label: "Name", field: "name", sort: "asc", width: 150 },
  //     { label: "ABHA Id", field: "ABHAId", sort: "asc", width: 270 },
  //     { label: "From Date", field: "FromDate", sort: "asc", width: 200 },
  //     { label: "To Date", field: "ToDate", sort: "asc", width: 200 },
  //     { label: "EXPIRED At", field: "EXPIREDAt", sort: "asc", width: 150 },
  //     { label: "Requested on", field: "RequestedOn", sort: "asc", width: 100 },
  //     { label: "Status", field: "Status", sort: "asc", width: 100 },
  //     { label: "Action", field: "Action", sort: "asc", width: 100 },
  //   ],

  //   rows: filteredData.map((row) => ({
  //     ...row,
  //     Status: (
  //       <span
  //         style={{
  //           color:
  //             row.Status === "GRANTED"
  //               ? "#66f556"
  //               : row.Status === "DENIED"
  //                 ? "#ed7926"
  //                 : row.Status === "REQUESTED"
  //                   ? "#13e9ed"
  //                   : row.Status === "EXPIRED"
  //                     ? "red"
  //                     : row.Status === "REVOKED"
  //                       ? "#ed8b13"
  //                       : "inherit",
  //           fontWeight: "bold",
  //         }}
  //       >
  //         {row.Status}
  //       </span>
  //     ),
  //   })),
  // };
  const [data, setData] = useState([]);

  useEffect(() => {
    // Process and filter the data
    const processedData = filteredData.map((row) => ({
      ...row,
      Status: (
        <span
        // style={{
        //   color:
        //     row.Status === "GRANTED"
        //       ? "#66f556"
        //       : row.Status === "DENIED"
        //         ? "#ed7926"
        //         : row.Status === "REQUESTED"
        //           ? "#13e9ed"
        //           : row.Status === "EXPIRED"
        //             ? "red"
        //             : row.Status === "REVOKED"
        //               ? "#ed8b13"
        //               : "inherit",
        //   fontWeight: "bold",
        // }}
        >
          {row.Status}
        </span>
      ),
    }));
    setData(processedData);
  }, [filteredData]);

  const handleCancel = () => {
    setIsModalOpen(false);
    setFechingConsent(false);
  };

  const createConsentForm = () => {
    setIsModalOpen(true);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFormData((prevState) => ({
        ...prevState,
        healthInfoTypes: {
          ...prevState.healthInfoTypes,
          [name]: checked,
        },
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,

        [name]: value,
      }));
    }
  };

  //harshini code changes

  const timeZoneDate = (date, type) => {
    let newHour = new Date().getHours();
    let newMin;

    if (type === "expire") {
      newMin = new Date().getMinutes() + 10;
    } else {
      newMin = new Date().getMinutes();
    }

    let newSec = new Date().getSeconds();
    let newMilli = new Date().getMilliseconds();

    let value = moment(date)
      .hour(newHour)
      .minute(newMin)
      .second(newSec)
      .millisecond(newMilli)
      .format("YYYY-MM-DDTHH:mm:ss.SSS");

    return value;
  };

  const getConsentCode = async (value) => {
    let code = "";
    switch (value) {
      case "Care Management":
        code = "CAREMGT";
        break;
      case "Break the Glass":
        code = "BTG";
        break;
      case "Public Health":
        code = "PUBHLTH";
        break;
      case "Healthcare Payment":
        code = "HPAYMT";
        break;
      case "Disease Specific Healthcare Research":
        code = "DSRCH";
        break;
      case "Self Requested":
        code = "PATRQT";
        break;
      default:
        // Handle default case or invalid values
        break;
    }
    return code;
  };

  const handleConsentFormSubmit = async () => {
    try {
      const {
        patientIdentifier,
        consentPurpose,
        healthDataFromDate,
        healthDataToDate,
        healthInfoTypes,
        expiryDate,
      } = formData;

      const consentCode = await getConsentCode(consentPurpose);

      const isHealthInfoTypeSelected = Object.values(healthInfoTypes).some(
        (value) => value
      );

      if (
        !patientIdentifier ||
        !consentPurpose ||
        !healthDataFromDate ||
        !healthDataToDate ||
        !isHealthInfoTypeSelected ||
        !expiryDate
      ) {
        toast.error(
          "Please enter the mandatory fields and select at least one health information type."
        );
        return;
      } else {
        const v5 = uuidv4();
        const from = timeZoneDate(healthDataFromDate || new Date(), "start");
        const to = timeZoneDate(healthDataToDate || new Date(), "to");
        const expired = timeZoneDate(expiryDate || new Date(), "expire");
        const timestamp = moment().utc().format("YYYY-MM-DDTHH:mm:ss.SSSSSS");
        const firstName = sessionStorage.getItem("firstName");

        const hiTypes = Object.entries(healthInfoTypes)
          .filter(([key, value]) => value === true)
          .map(([key, value]) => key);

        const requestBody = {
          requestId: v5,
          timestamp: timestamp,
          consent: {
            purpose: {
              code: consentCode,
              text: consentPurpose,
            },
            patient: {
              id: selectedPatientDetails.ABHA_ADDRESS, // Assuming ABHA_ADDRESS is fetched from selectedPatientDetails
            },
            hiu: {
              id: "clinallyhiu",
            },
            requester: {
              name: firstName, // Assuming firstName is fetched from state
              identifier: {
                type: "REGNO",
                value: "MH1001",
                system: "https://www.mciindia.org",
              },
            },
            hiTypes: hiTypes, // Assuming selectedHealthTypes is an array of health types
            permission: {
              accessMode: "VIEW",
              dateRange: {
                from: from,
                to: to,
              },
              dataEraseAt: expired,
              frequency: {
                unit: "HOUR",
                value: 1,
                repeats: 2,
              },
            },
          },
        };

        const payload = {
          abhaAddress: selectedPatientDetails.ABHA_ADDRESS,
          requestId: v5,
          REQUEST: JSON.stringify(requestBody),
        };
        const headers = {
          authorization: `Bearer ${token}`,
          "x-caller": await encrypted,
        };

        const response = await Axios.post(
          `${config.base_url}ABHA_CONSENT_REQUEST`,
          payload,
          { headers }
        );
        // console.log("response", response)

        if (response.status === 201) {
          await abhaConsentRequestInit(
            v5,
            consentCode,
            consentPurpose,
            selectedPatientDetails.ABHA_ADDRESS,
            from,
            to,
            expired,
            hiTypes,
            firstName
          );
          getConsentsList();
        } else {
          //console.log("coming to else")
        }
      }
    } catch (err) {
      console.log("Err", err);
    }
  };

  const getPublickey = async () => {
    Axios.get(`${config.abhaBaseUrl}publicKey/${UserID}`, {
      headers: {
        authorization: `Bearer ${token}`,
        "x-caller": await encrypted,
      },
    })
      .then((response) => {
        // console.log("getPublickey response :", response);
        if (response.status == 200) {
          // console.log("PUBLICKEY :", response.data);
          sessionStorage.setItem("ABDMPublicKey", response.data);
          return response;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const abhaConsentRequestInit = async (
    v5,
    purpose,
    purposeValue,
    abhaId,
    from,
    to,
    expiry,
    hiTypes,
    doctorName
  ) => {
    await getPublickey();
    const timestamp = moment().utc().format("YYYY-MM-DDTHH:mm:ss.SSSSSS");

    const dataBody = {
      v5: v5,
      USER_ID: UserID,
      purpose: purpose,
      purposeValue: purposeValue,
      abhaId: abhaId,
      from: from,
      to: to,
      expiry: expiry,
      hiTypes: hiTypes,
      doctorName: doctorName,
      timestamp: timestamp,
    };
    //console.log(dataBody, 'body.................')
    await Axios.post(`${config.abhaBaseUrl}abhaConsentRequestInit`, dataBody, {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${token}`,
        "x-caller": encrypted,
      },
    })
      .then(async (response) => {
        // console.log("response", response)
        if (response.status === 200) {
          setIsModalOpen(false);
        } else {
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const consentFetch = async (consentId, consentDetails) => {
    //console.log("consentId", consentId, "consentDetails", consentDetails)
    if (consentDetails === null) {
      const v5 = uuidv4();
      await abhaConsentFetch(v5, consentId); // need to check with ganga
      getConsentData(consentId);
    } else {
      getConsentData(consentId);
    }
  };

  const abhaConsentFetch = async () => {};

  const getConsentData = async (consentId) => {
    try {
      const response = await Axios.get(
        `${config.base_url}getConsentData/${consentId}`,
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${token}`,
            "x-caller": encrypted,
          },
        }
      );

      if (response.status === 200) {
        //console.log("consent fetch response", response.data);
        setfilename(response.data.recordset?.[0]?.FILE_NAME);
        const extractData = response.data.recordset;
        setConsentData(extractData);

        if (extractData.length > 0 && !extractData[0]?.FILE_NAME) {
          // if (extractData.length > 0 ) {
          await getConsentData();
        } else {
          // if (extractData.length > 0) {
          const decryptedData = JSON.parse(extractData[0]?.DECRYPTION_DATA);
          setDecryptedData(decryptedData);
          setFechingConsent(true);
          // }
          //   else {
          //   setDecryptedData(null);
          //   setFechingConsent(true);
          // }
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getConsentDates = (value) => {
    if (value && value !== "") {
      const dt = new Date(value);
      return format(dt, "dd-MMM-yyyy, hh:mm a");
    } else {
      return "";
    }
  };

  const renderLoading = () => (
    <div style={{ textAlign: "center", marginTop: "20px" }}>
      <Lottie
        animationData="assets/images/loading.json"
        height={Math.floor(window.innerHeight * 0.2)}
      />
      <p style={{ fontWeight: "bold", fontSize: "12px" }}>Fetching Data</p>
    </div>
  );

  const renderPrescription = () => {
    //url used to render prescription file
    setitemurl(true);
    // https://mpowerqa.clinally.com/careContext/${take file name from consentdata varibale}
  };

  //card which dispalys when we click on arrow beside granted
  const renderMainContent = () => (
    <div>
      <div style={{ marginTop: "20px" }}>
        <p style={{ fontWeight: "bold", fontSize: "12px" }}>
          {consentData &&
            consentData[0] &&
            consentData[0].ON_FETCH &&
            JSON.parse(consentData[0].ON_FETCH).consent.consentDetail.hip.name}
        </p>
      </div>

      {consentData &&
        consentData[0] &&
        consentData[0].FILE_NAME &&
        decryptedData &&
        decryptedData.entry &&
        decryptedData.entry.length < 5 && (
          <div>
            <div style={{ marginTop: "10px", marginBottom: "10px" }}>
              <p style={{ fontSize: "15px", color: "grey" }}>Prescribed By:</p>
              <p style={{ fontSize: "15px", fontWeight: "bold" }}>
                {consentData[0].DOCTOR_NAME || ""}
              </p>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p style={{ fontSize: "13px", color: "grey" }}>Date:</p>
              <p style={{ fontSize: "13px" }}>
                {getConsentDates(
                  consentData[0].CREATED_DATE || new Date().toString()
                )}
              </p>
            </div>

            <div style={{ marginTop: "30px" }}>
              <p style={{ fontSize: "10px", color: "blue" }}>View Records</p>
            </div>

            <div style={{ marginTop: "15px" }}>
              <button
                onClick={() => {
                  renderPrescription();
                }}
              >
                <div
                  style={{
                    borderRadius: "10px",
                    padding: "10px",
                    cursor: "pointer",
                  }}
                >
                  <p
                    style={{
                      fontSize: "12px",
                      fontWeight: "bold",
                    }}
                  >
                    Prescription Record
                  </p>
                </div>
              </button>
            </div>

            <div>
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js" />
              {itemurl && (
                <div className="articles-hyper-pdf">
                  <Viewer
                    // key={index}
                    fileUrl={`https://incentivedashboard.clinally.com/uploads/${filename}`}
                    plugins={[defaultLayoutPluginInstance]}
                  />
                </div>
              )}
            </div>
          </div>
        )}

      {decryptedData &&
        decryptedData.entry &&
        decryptedData.entry.length >= 5 && (
          <div>
            <div style={{ marginTop: "10px", marginBottom: "10px" }}>
              <hr />
              {condition === false && (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p style={{ fontSize: "12px" }}>Presenting Problems</p>
                  <div onClick={() => setCondition(true)}>
                    <i className="material-icons" style={{ fontSize: "20px" }}>
                      add
                    </i>
                  </div>
                </div>
              )}

              {condition === true && (
                <div style={{ padding: "10px" }}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p style={{ fontSize: "12px", fontWeight: "bold" }}>
                      Presenting Problems
                    </p>
                    <div onClick={() => setCondition(false)}>
                      <i
                        className="material-icons"
                        style={{ fontSize: "20px" }}
                      >
                        remove
                      </i>
                    </div>
                  </div>
                  <p style={{ fontSize: "12px" }}>
                    {decryptedData.entry[5].resource.code.text || ""}
                  </p>
                  <div style={{ marginTop: "20px" }} />
                </div>
              )}
              <hr />

              <p style={{ fontSize: "10px" }}>Medications</p>

              <p style={{ fontSize: "12px", fontWeight: "bold" }}>
                {decryptedData.entry[4].resource.medicationCodeableConcept
                  .text || ""}
              </p>

              <p style={{ fontSize: "11px" }}>
                {decryptedData.entry[4].resource.dispenseRequest
                  .expectedSupplyDuration.value || 0}
                {decryptedData.entry[4].resource.dispenseRequest
                  .expectedSupplyDuration.unit || ""}
              </p>

              <div>
                {decryptedData.entry[4].resource.dosageInstruction.map(
                  (instruction, index) => (
                    <p key={index} style={{ fontSize: "12px" }}>
                      &#8226; {instruction.text}
                    </p>
                  )
                )}
              </div>

              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <div
                  style={{
                    padding: "8px",
                    borderRadius: "50px",
                    backgroundColor: "grey" + "0.2",
                  }}
                >
                  {decryptedData.entry[4].resource.dispenseRequest.quantity
                    .value || 0}{" "}
                  Tablet(s)
                </div>
              </div>
            </div>
          </div>
        )}
    </div>
  );

  return (
    <>
      {/* <Navbar /> */}

      <div className="main-ManageABHA-container">
        <div className="ManageABHA-container">
          <div className="ManageABHA-title">
            <div className="AbhaConsenTtitle">
              <p>ABHA CONSENT</p>
            </div>
            <div>
              <div className="ManageABHA-navcontents">
                {sections.map((section) => (
                  <button
                    key={section}
                    className={`consents-item ${
                      selectedSection === section ? "consents-selected" : ""
                    }`}
                    onClick={() => setSelectedSection(section)}
                  >
                    {section}
                  </button>
                ))}
              </div>
              <hr></hr>
            </div>
          </div>
          <div className="Consents-table">
            {/* {data.map((row, index) => (
        <div className="Consent-Patients" key={index}>
          <div className="Patients">
            <h3>Patients</h3>
            <button type="button" className="Consentbtn" data-mdb-ripple-init>
              Status
            </button>
          </div>
          <div className="Patients-details">
            <p>
              <strong>Name:</strong> {row.name}
            </p>
            <p>
              <strong>ABHA Id:</strong> {row.ABHAId}
            </p>
            <p>
              <strong>From Date:</strong> {row.FromDate}
            </p>
            <p>
              <strong>To Date:</strong> {row.ToDate}
            </p>
            <p>
              <strong>EXPIRED At:</strong> {row.EXPIREDAt}
            </p>
            <p>
              <strong>Requested on:</strong> {row.RequestedOn}
            </p>
            <p>
              <strong>Status:</strong> {row.Status}
            </p>
            <p>
              <strong>Action:</strong> {row.Action}
            </p>
          </div>
        </div>
      ))}
   */}

            <div className="consent-patients-container">
              {data.map((row, index) => (
                <div className="Consent-Patients" key={index}>
                  {/* {console.log(consentData,'..........................row....')} */}
                  <div
                    className="Patients"
                    onClick={() =>
                      consentFetch(row.CONSENT_ARTEFACT_ID, row.ON_FETCH, index)
                    }
                  >
                    <h3>Patients</h3>
                    <div
                      style={{
                        alignItems: "center",
                        display: "flex",
                        gap: "2px",
                      }}
                    >
                      <button
                        type="button"
                        className="Consentbtn"
                        data-mdb-ripple-init
                      >
                        <span className="Patientspan"></span>
                        {row.Status}
                      </button>
                      <strong></strong> {row.Action}
                    </div>
                  </div>
                  <div className="Patients-details">
                    <p>
                      <strong>Name:</strong>{" "}
                      <span className="Patientspan">{row.name}</span>
                    </p>
                    <p>
                      <strong>ABHA Id:</strong>{" "}
                      <span className="Patientspan">{row.ABHAId}</span>
                    </p>
                    <p>
                      <strong>From Date:</strong>{" "}
                      <span className="Patientspan">{row.FromDate}</span>
                    </p>
                    <p>
                      <strong>To Date:</strong>{" "}
                      <span className="Patientspan">{row.ToDate}</span>
                    </p>
                    <p>
                      <strong>Expired At:</strong>{" "}
                      <span className="Patientspan">{row.EXPIREDAt}</span>
                    </p>
                    <p>
                      <strong>Requested On:</strong>{" "}
                      <span className="Patientspan">{row.RequestedOn}</span>
                    </p>
                    <p>{/* <strong>Status:</strong>  */}</p>
                    <p></p>
                  </div>
                </div>
              ))}
            </div>
            <div className="ManageABHA-maincontent">
              {selectedSection && (
                <>
                  <Dialog
                    open={isModalOpen}
                    style={{ width: "100%", alignItems: "center" }}
                  >
                    <DialogTitle>Consent Request Form</DialogTitle>
                    <DialogContent
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "15%",
                        }}
                      >
                        <TextField
                          label="Patient Identifier"
                          name="patientIdentifier"
                          value={selectedPatientDetails.ABHA_ADDRESS}
                          onChange={handleChange}
                          required
                          margin="dense"
                        />
                        <FormControl fullWidth>
                          <InputLabel id="consent-select-label">
                            Consent Purpose *
                          </InputLabel>
                          <Select
                            label="Consent Purpose"
                            name="consentPurpose"
                            value={formData.consentPurpose}
                            onChange={handleChange}
                            required
                            margin="dense"
                          >
                            <MenuItem value="Care Management">
                              Care Management
                            </MenuItem>
                            <MenuItem value="Break the Glass">
                              Break the Glass
                            </MenuItem>
                            <MenuItem value="Public Health">
                              Public Health
                            </MenuItem>
                            <MenuItem value="Disease Specific Healthcare Research">
                              Disease Specific Healthcare Research
                            </MenuItem>
                            <MenuItem value="Self Requested">
                              Self Requested
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>

                      <div className="ManageAbha-healthdata">
                        <TextField
                          label="Health Data From"
                          name="healthDataFromDate"
                          type="date"
                          value={formData.healthDataFromDate}
                          onChange={handleChange}
                          required
                          margin="dense"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            inputProps: { max: formData.expiryDate },
                          }}
                        />

                        <TextField
                          label="Health Data To"
                          name="healthDataToDate"
                          type="date"
                          value={formData.healthDataToDate}
                          onChange={handleChange}
                          required
                          margin="dense"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            inputProps: {
                              min: formData.healthDataFromDate,
                              max: formData.expiryDate,
                            },
                          }}
                        />
                      </div>
                      <div>
                        <label className="ManageAbha-CheckboxLabel">
                          Health Info Type
                        </label>
                        <div className="ManageAbha-Checkbox">
                          {Object.keys(formData.healthInfoTypes).map((type) => (
                            <FormControlLabel
                              key={type}
                              control={
                                <Checkbox
                                  name={type}
                                  checked={formData.healthInfoTypes[type]}
                                  onChange={handleChange}
                                />
                              }
                              label={type.replace(/([A-Z])/g, " $1").trim()}
                            />
                          ))}
                        </div>
                      </div>
                      <TextField
                        label="Expiry Date"
                        name="expiryDate"
                        type="date"
                        value={formData.expiryDate}
                        onChange={handleChange}
                        required
                        margin="dense"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          inputProps: { min: formData.expiryDate },
                        }}
                        className={classes.root}
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button
                        style={{
                          background: "rgba(93, 74, 135, 1)",
                          color: "white",
                          marginLeft: "5%",
                          borderRadius: "10px",
                          width: "8vw",
                          height: "5vh",
                          border: "none",
                          cursor: "pointer",
                        }}
                        onClick={handleCancel}
                      >
                        CANCEL
                      </Button>
                      <Button
                        style={{
                          background: "rgba(93, 74, 135, 1)",
                          color: "white",
                          marginLeft: "5%",
                          borderRadius: "10px",
                          width: "9vw",
                          height: "5vh",
                          border: "none",
                          cursor: "pointer",
                        }}
                        onClick={handleConsentFormSubmit}
                      >
                        REQUEST
                      </Button>
                    </DialogActions>
                  </Dialog>
                </>
              )}
              {/*Consent Fetching popup*/}
              <>
                <Dialog
                  open={fechingConsent}
                  style={{ width: "100%", alignItems: "center" }}
                >
                  <DialogTitle>CONSENT FETCHING</DialogTitle>
                  <DialogContent
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    {/* <FormControl fullWidth>
                      <div className="fetching-drcontent">
                        <label className="ManageAbha-CheckboxLabel">
                          Clinally HIP
                        </label>
                        <div className="Consent-fetching">
                          <label>Prescribed By:</label>
                          <label style={{ color: "black" }}>Dr. Clinally</label>
                        </div>
                        <div className="Consent-fetching">
                          <label>Date:</label>
                          <label style={{ color: "black" }}>11-mar-2024</label>
                        </div>
                      </div>
                      <label
                        style={{ color: "rgba(82, 68, 125, 1)", padding: "2%" }}
                      >
                        View Records
                      </label>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          style={{
                            background: "rgba(93, 74, 135, 1)",
                            color: "white",

                            borderRadius: "10px",
                            width: "15vw",
                            height: "4vh",
                            border: "none",
                            cursor: "pointer",
                          }}
                          onClick={handleCancel}
                        >
                          Prescription Record
                        </Button>
                      </div>
                    </FormControl> */}

                    <main>
                      {consentData === null ||
                      (consentData &&
                        consentData[0] &&
                        consentData[0].ON_FETCH === null)
                        ? renderLoading()
                        : renderMainContent()}
                    </main>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      style={{
                        background: "rgba(93, 74, 135, 1)",
                        color: "white",
                        marginLeft: "5%",
                        borderRadius: "10px",
                        width: "8vw",
                        height: "4vh",
                        border: "none",
                        cursor: "pointer",
                      }}
                      onClick={handleCancel}
                    >
                      CLOSE
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            </div>

            {/* <div className="MDB-concents">
              <MDBDataTable
                striped
                small
                data={data}
                displayEntries={false}
                searching={false}
                paging={false}
                info={false}
                className="mdbtable"
              />
            </div> */}
          </div>
        </div>
        <div className="AbhaConsentButtonouter">
          <CustomButton
            className="AbhaConsentButton"
            buttonText="Create Consent Request"
            onClick={createConsentForm}
          />
        </div>

        <ToastContainer position="top-right" />
      </div>
    </>
  );
};

export default ManageABHAConsents;
