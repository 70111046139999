import React, { useEffect, useState } from "react";
import TopNavBar from "../components/modules/TopNavBar.jsx";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { CircularProgress } from "@mui/material";
// import "./Patients.css";
import Navbar from "../components/modules/Navbar";
import Box from "@mui/material/Box";
import Axios from "axios";
import FollowUps from "../components/modules/FollowUpsData";
import Reminders from "../components/modules/RemindersData";
import search from "../assets/search-alt.png";
import Config from "../Config/config.jsx";
import { publicEncrypt, constants } from "crypto-browserify";
import { Buffer } from "buffer";
import "./followUps.css";
import searchAlt from "../images/search-alt.png";
import { useLocation } from "react-router-dom";
// import FollowupPatient from "../images/search-alt.png";

const PatientsFollowUps = () => {
  const location = useLocation();
  // const [followUpsData, setFollowUpsData] = useState([]);
  const [value, setValue] = useState(0);
  const [followUpsData, setFollowUpsData] = useState([]);
  const [remindersData, setRemindersData] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [filteredFollowUps, setFilteredFollowUps] = useState([]);
  const [filteredReminders, setFilteredReminders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  /////

  const UserID = sessionStorage.getItem("RegUserId")
    ? JSON.parse(sessionStorage.getItem("RegUserId"))
    : sessionStorage.getItem("UserID");
  const publicKey = sessionStorage.getItem("RegpublicKey")
    ? sessionStorage.getItem("RegpublicKey")
    : JSON.parse(sessionStorage.getItem("publicKey"));
  const token = sessionStorage.getItem("Regtoken")
    ? sessionStorage.getItem("Regtoken")
    : JSON.parse(sessionStorage.getItem("token"));

  const handleChange = (event, newValue) => {
    setSearchInput("");
    handleFollowUpsSearchChange({ target: { value: "" } });
    handleRemindersSearchChange({ target: { value: "" } });
    setValue(newValue);
  };

  const padding = constants.RSA_PKCS1_PADDING;
  const data = Buffer.from(UserID.toString(), "utf-8");
  const encrypted = publicEncrypt(
    { key: publicKey, padding: padding },
    data
  ).toString("base64");

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tab = queryParams.get("tab");
    if (tab === "reminders") {
      setValue(1); // Set the value to 1 for Reminders tab
    } else {
      setValue(0); // Default to Follow ups tab
    }
  }, [location.search]);

  //followUps tab data
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    // url: `https://mpowerqa.clinally.com/patient/allpatients?userid=${UserID}`,
    url: `${Config.apiURL}${Config.routes.getFollowupsAPI}?userid=${UserID}`,
    headers: {
      authorization: `Bearer ${token}`,
      "content-type": "application/json",
      "x-caller": encrypted,
    },
  };

  useEffect(() => {
    //console.log(UserID);
    const fetchData = async () => {
      await Axios.request(config)
        .then((response) => {
          const sortedData = response.data.data.sort((a, b) => {
            return new Date(b.CREATED_DATE) - new Date(a.CREATED_DATE);
          });
          console.log("followps response...................", sortedData);
          setFollowUpsData(sortedData);
          setFilteredFollowUps(sortedData);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    };
    fetchData();
  }, []);

  //reminders tab data
  let config1 = {
    method: "get",
    maxBodyLength: Infinity,
    // url: `https://mpowerqa.clinally.com/patient/allpatients?userid=${UserID}`,
    url: `${Config.apiURL}${Config.routes.getRemindersAPI}?userid=${UserID}`,
    headers: {
      authorization: `Bearer ${token}`,
      "x-caller": encrypted,
      "content-type": "application/json",
    },
  };

  useEffect(() => {
    //console.log(UserID);
    const fetchData = async () => {
      await Axios.request(config1)
        .then((response) => {
          const sortedData = response.data.data.sort((a, b) => {
            return new Date(b.CREATED_DATE) - new Date(a.CREATED_DATE);
          });
          // console.log("remainders response...................", sortedData);
          setRemindersData(sortedData);
          setFilteredReminders(sortedData);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    };
    fetchData();
  }, []);

  const handleFollowUpsSearchChange = (event) => {
    const searchInput = event.target.value;
    setSearchInput(searchInput);
    const filteredData = followUpsData.filter((followUp) => {
      return followUp.FIRST_NAME.toLowerCase().includes(
        searchInput.toLowerCase()
      );
    });
    setFilteredFollowUps(filteredData);
  };

  const handleRemindersSearchChange = (event) => {
    const searchInput = event.target.value;
    setSearchInput(searchInput);
    const filteredData = remindersData.filter((reminder) => {
      return reminder.FIRST_NAME.toLowerCase().includes(
        searchInput.toLowerCase()
      );
    });
    setFilteredReminders(filteredData);
  };

  return (
    <div className="FolloupsHomeContainer">
      {/* <Navbar />
      <div style={{ width: "100%" }}>
        <TopNavBar /> */}
      <div className="FollowupsBackground">
        <div className="fllowups">
          <Box
            sx={{
              display: "flex",
              width: "100%",
              borderBottom: 2,
              borderColor: "#544380",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              classes={{ root: "tabs-root", indicator: "tabs-indicator" }}
            >
              <Tab label="Follow ups" className="tab-root" />
              <Tab label="Reminders" className="tab-root" />
            </Tabs>

            {/* <SearchBar onSearchChange={handleRemindersSearchChange} /> */}
          </Box>
          <div className="FolloupSearchMain">
            <div style={{ color: "#52447D" }}>
              {value === 0
                ? filteredFollowUps.length
                : filteredReminders.length}{" "}
              Patients
            </div>
            {/* <div className="PatientSearch">
                <img
                  src={searchAlt}
                  style={{ width: "20px" }}
                  alt="Search icon"
                />

                <input
                  className="FollowupPatient"
                  type="text"
                  placeholder="Search patients"
                  onChange={
                    value === 0
                      ? handleFollowUpsSearchChange
                      : handleRemindersSearchChange
                  }
                  value={searchInput}
                />
              </div> */}
            <div className="patient-search">
              <img
                src={searchAlt}
                style={{ width: "20px", marginLeft: "4%" }}
                alt="Search icon"
              />
              <input
                className="searchBox-patient"
                type="text"
                value={searchInput}
                placeholder="Search patients"
                onChange={
                  value === 0
                    ? handleFollowUpsSearchChange
                    : handleRemindersSearchChange
                }
              />
            </div>
          </div>
          <hr
            style={{ background: "#E4E4E4", height: "1px", border: "none" }}
          />

          {isLoading ? (
            <div className="spinningLoader">
              <CircularProgress size={48} color="inherit" />
            </div>
          ) : (
            <div className="followupsScroll">
              {value === 0 && <FollowUps datavalue={filteredFollowUps} />}
              {value === 1 && <Reminders datavalue={filteredReminders} />}
              </div>
          )}
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default PatientsFollowUps;
