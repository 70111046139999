import React from "react";
import "./ArticleData.css";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import config from "../../../Config/config";

const depression = () => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const deprssionUrls = [
    {
      title: "ImmunizationUrls",
      urls: [
        {
          type: "link",
          url: "https://www.mhanational.org/infographic-life-depression",
          title: "Infographic:Life with Depression | Mental Health America",
          description:
            "This infographic was developed for Mental Health Month 2016.Life with Depression",
        },
        {
          type: "pdf",
          url: `${config.apiURL}/uploads/articlespdfs/NAMI-You-Are-Not-Alone-FINAL.pdf`,
        },
        {
          type: "pdf",
          url: `${config.apiURL}/uploads/articlespdfs/Stress and Anxiety Tips.pdf`,
        },
        {
          type: "pdf",
          url: `${config.apiURL}/uploads/articlespdfs/Postpartum PSI and ADAA English.pdf`,
        },
        {
          type: "pdf",
          url: `${config.apiURL}/uploads/articlespdfs/ADAA and SAVE Infographic 2021.pdf`,
        },
        {
          type: "pdf",
          url: `${config.apiURL}/uploads/articlespdfs/How to Know If You Should Talk with Your Healthcare Provider About PTSD.pdf`,
        },
      ],
    },
  ];

  return (
    <>
    <div className="Articles-digitalHealth">
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js" />

        {deprssionUrls[0].urls.map((item, index) => {
          if (item.type === "pdf") {
            return (
              <div className="articles-hyper-pdf" key={index}>
                <Viewer
                  fileUrl={item.url}
                  plugins={[defaultLayoutPluginInstance]}
                />
              </div>
            );
          } else if (item.type === "image") {
            return (
              <div className="articles-hyper-image" key={index}>
                <img
                  crossorigin="anonymous"
                  src={item.url}
                  alt={`Image ${index}`}
                />
              </div>
            );
          } else if (item.type === "link") {
            return (
              <div className="articles-hyper-link">
                <a href={item.url} target="_blank" rel="noopener noreferrer">
                  <div>
                    <p>{item.url}</p>
                  </div>
                </a>
                <h3>{item.title}</h3>
                <p>{item.description}</p>
              </div>
            );
          }
          return null;
        })}
      </div>
    </>
  );
};

export default depression;
