// import React from "react";
// import { Line } from "react-chartjs-2";

// const BloodPressureGraph = () => {
//   // Static sample data
//   const data = [
//     {
//       visit_date: "2024-01-18T09:12:54.810Z",
//       SBP: 150,
//       DBP: 109,
//     },
//     {
//       visit_date: "2024-01-19T00:00:00.000Z",
//       SBP: 129,
//       DBP: 26,
//     },
//     // Add more data as needed
//   ];

//   // Extracting SBP and DBP data
//   const sbpData = data.map((visit) => ({
//     x: new Date(visit.visit_date),
//     y: visit.SBP,
//   }));
//   const dbpData = data.map((visit) => ({
//     x: new Date(visit.visit_date),
//     y: visit.DBP,
//   }));

//   // Chart data
//   const chartData = {
//     labels: data.map((visit) => visit.visit_date),
//     datasets: [
//       {
//         label: "SBP",
//         data: sbpData,
//         borderColor: "rgba(255, 99, 132, 0.6)",
//         backgroundColor: "rgba(255, 99, 132, 0.2)",
//       },
//       {
//         label: "DBP",
//         data: dbpData,
//         borderColor: "rgba(54, 162, 235, 0.6)",
//         backgroundColor: "rgba(54, 162, 235, 0.2)",
//       },
//     ],
//   };

//   // Chart options
//   const chartOptions = {
//     scales: {
//       xAxes: [
//         {
//           type: "time",
//           time: {
//             unit: "day",
//           },
//           scaleLabel: {
//             display: true,
//             labelString: "Date",
//           },
//         },
//       ],
//       yAxes: [
//         {
//           scaleLabel: {
//             display: true,
//             labelString: "Blood Pressure",
//           },
//         },
//       ],
//     },
//   };

//   return (
//     <div>
//       <h2>Blood Pressure Graph</h2>
//       <Line data={chartData} options={chartOptions} />
//     </div>
//   );
// };

// export default BloodPressureGraph;

import React from "react";
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend } from "recharts";

const BloodPressureGraph = () => {
  // Static sample data
  const data = [
    {
      visit_date: "2023-12-24T00:00:00.000Z",
      SBP: 120,
      DBP: 80,
    },
    {
      visit_date: "2023-12-30T00:00:00.000Z",
      SBP: 110,
      DBP: 75,
    },
    {
      visit_date: "2024-01-01T00:00:00.000Z",
      SBP: 115,
      DBP: 78,
    },
    {
      visit_date: "2024-01-31T00:00:00.000Z",
      SBP: 54,
      DBP: 64,
    },
    // Add more data as needed
  ];

  // Custom tick formatter for x-axis
  const formatXAxisTick = (tickItem) => {
    // Format tickItem (date string) to display only date (without time)
    return new Date(tickItem).toLocaleDateString();
  };

  return (
    <div>
      <h2>Blood Pressure Graph</h2>
      <LineChart width={800} height={400} data={data}>
        <XAxis
          dataKey="visit_date"
          label={{ value: "Date", position: "bottom" }}
          tickFormatter={formatXAxisTick}
        />
        <YAxis
          label={{
            value: "Blood Pressure",
            angle: -90,
            position: "insideLeft",
          }}
        />
        <Tooltip />
        <Legend />
        <Line
          type="monotone"
          dataKey="SBP"
          stroke="red"
          dot={{ stroke: "red", strokeWidth: 2 }}
        />
        <Line
          type="monotone"
          dataKey="DBP"
          stroke="blue"
          dot={{ stroke: "blue", strokeWidth: 2 }}
        />
      </LineChart>
    </div>
  );
};

export default BloodPressureGraph;
