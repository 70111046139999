import React, { useState } from "react";
import Logo from "../images/logo.png";
import aiimsLogo from "../images/aiims_logo.png";
import ccdcLogo from "../images/CCDC-new-logo-1-1.png";
import "./Login.css";
// import "./Sample.css";
// import Doctor from "../images/doctor.png";
import Computer from "../images/computer.gif";
import Doctor from "../images/loginDoctorLogo.png";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { auth } from "../components/firebase/firebase.js";
import { signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ResetPin = () => {
  {
    /*To Determine the State of Login and Register */
  }
  const [users, setUsers] = useState({
    phoneNumber: "",
    pin: "",
    rePin: "",
  });
  const [isPass, setIsPass] = useState(false);
  // const [phone, setPhone] = useState("");
  const [hasFilled, setHasFilled] = useState(false);
  const [otp, setOtp] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const INTEGER_SIX_DIGITS_REGEX = /^\d{0,6}$/;


  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(auth, "recaptcha", {
      size: "invisible",
      callback: (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        // ...
      },
    });
  };

  //console.log(users.phoneNumber);

  const handleSend = (event) => {
    if (users.phoneNumber.length === 10) {
      event.preventDefault();

      generateRecaptcha();
      const phone = "+91" + users.phoneNumber;
      let appVerifier = window.recaptchaVerifier;
      //Reset Pin enter otp
      setHasFilled(true);
      signInWithPhoneNumber(auth, phone, appVerifier)
        .then((confirmationResult) => {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          window.confirmationResult = confirmationResult;

          console.log(confirmationResult);
          setIsLoading(true);
        })

        .catch((error) => {
          // Error; SMS not sent
          console.log(error);
        });
    } else {
      toast.error("Enter valid phone number");
    }
  };

  const handleResend = (event) => {
    event.preventDefault();
    // generateRecaptcha();
    const phone = "+91" + users.phoneNumber;
    let appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, phone, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        //console.log(confirmationResult);
      })
      .catch((error) => {
        // Error; SMS not sent
        console.log(error);
      });
  };

  const verifyOtp = (e) => {
    e.preventDefault();
    if (otp.length === 6) {
      // verifu otp
      let confirmationResult = window.confirmationResult;
      confirmationResult
        .confirm(otp)
        .then((result) => {
          // User signed in successfully.
          // let user = result.user;
          //Reset Pin state
          setIsPass(!isPass);
          //console.log(user);
          // alert("Password Updated Successfully");
          // ...
        })
        .catch((error) => {
          console.log(error);
          toast.error("Please enter a valid OTP.");
        });
    }
  };

  const handleSubmitPassword = () => {
    console.log(setUsers.pin, setUsers.rePin, "pinsetup");
    if (users.pin === users.rePin) {
      console.log(users.password, users.rePassword, "passwordCorrection");
      navigate("/login");
    } else {
      toast.error("Passwords do not match. Please enter the same password.");
    }
  };

  const handleClick = () => {
    navigate("/login");
  };

  //console.log("Hello world", isPass);
  //console.log("isPass>>", isPass);
  return (
    <div className="login">
      {/* <img src={Doctor} className="doctor-image" alt="" /> */}
      <div className="headerReset">
        <div className="logo-Resetpin">
          <img
            src={Logo}
            className="clinally-logo"
            alt=""
            style={{ width: "6rem", cursor: "pointer" }}
            onClick={handleClick}
          />
          <p className="clinallytext">CLINALLY mPOWER HEALTH</p>
        </div>
        <div className="heading"></div>
      </div>
      <div className="body">
        <div className="image-container-Reset">
          <div className="clinallyPragposition">
            Clinally mPower Health is designed to assist and support doctors to
            provide best possible care to their patients along with managing the
            patient's visit records. It has a proprietary AI based clinical
            decision support system to provide recommendations based clinical
            parameters of the patinets.
          </div>

          <img src={Computer} className="computer-image" alt="" />
        </div>
        <div>
          <h1 style={{ color: "#52447D", paddingLeft: "25%" }}>Reset Pin</h1>
          <form className="Reset-form" action="">
            {/*Mobile Number*/}
            {!hasFilled ? (
              <>
                <input
                  type="number"
                  name="phone"
                  className="input"
                  maxLength={10}
                  value={users.phoneNumber}
                  onChange={(e) => {
                    const { value } = e.target;
                    const numericValue = value.replace(/\D/g, "").slice(0, 10);
                    setUsers((prev) => ({
                      ...prev,
                      phoneNumber: numericValue,
                    }));
                  }}
                  placeholder="Enter Mobile Number"
                  required
                />
                <div style={{ display: "flex", gap: "20px" }}>
                  <button
                    className="send-otp"
                    onClick={() => navigate("/login")}
                    name="send"
                    style={{ height: "44px" }}
                  >
                    Back
                  </button>
                  <button
                    className="send-otp"
                    onClick={handleSend}
                    name="send"
                    style={{ height: "44px" }}
                  >
                    Send OTP
                    {isLoading && (
                      <CircularProgress size={24} color="inherit" />
                    )}
                  </button>
                </div>
              </>
            ) : (
              <>
                {isPass ? (
                  <>
                    <input
                      type="password"
                      placeholder="Pin"
                      className="input-pin"
                      value={users.pin}
                      maxLength={6}
                      onChange={(e) =>
                      {
                        let pin = e.target.value;
                        pin = pin.replace(/\D/g, '');
                        setUsers((prev) => ({
                          ...prev,
                          pin: pin,
                        }))
                      }
                      }
                      required
                    />
                    <input
                      type="password"
                      placeholder="Re-Enter Pin"
                      className="input"
                      maxLength={6}
                      value={users.rePin}
                      onChange={(e) =>
                        {
                          let rePin = e.target.value;
                          rePin = rePin.replace(/\D/g, '');
                          setUsers((prev) => ({
                          ...prev,
                          rePin: rePin,
                        }))
                      }
                      }
                      required
                    />
                    <button
                      className="send-otp"
                      onClick={handleSubmitPassword}
                      name="send"
                    >
                      Update Pin
                    </button>
                  </>
                ) : (
                  <>
                    <input
                      type="number"
                      className="input"
                      value={otp}
                      onChange={(e) => {
                        if( !INTEGER_SIX_DIGITS_REGEX.test(e.target.value) ) {
                            return;
                        }
                        setOtp(e.target.value);
                      }}
                      placeholder="Enter OTP"
                      required
                    />
                    <button
                      className="resend-otp"
                      onClick={handleResend}
                      name="resend"
                    >
                      Resend OTP
                    </button>
                    <button
                      className="send-otp"
                      onClick={verifyOtp}
                      name="send"
                    >
                      Verify OTP
                    </button>
                  </>
                )}
              </>
            )}
          </form>
          <div className="resetpasscontainer">
            {/* <p> powered by</p> */}
            {/* <img src={Logo} className="logo" alt="" width="50%" /> */}
            <div className="AIIMSCDCCImgGap">
              <h2 style={{ textAlign: "center" }}>
                CDSS Developed in Collaboration
              </h2>
              <div className="AIIMSCDCCImgposition">
                <div className="AIIMSCDCCImgGap">
                  <img
                    style={{ height: "8vh" }}
                    src={aiimsLogo}
                    alt="aiimsLogo"
                  />

                  <label className="About" style={{ fontSize: "18px" }}>
                    AIIMS
                  </label>
                </div>
                <div className="AIIMSCDCCImgGap">
                  <img
                    style={{ height: "8vh", width: "100%" }}
                    src={ccdcLogo}
                    alt="ccdcLogo"
                  />
                  <label className="About" style={{ fontSize: "18px" }}>
                    CCDC
                  </label>
                </div>
              </div>

              {/* <p style={{ fontSize: "16px", textAlign: "center" }}>
                By continuing you agree to our <a href=""> Terms of service </a>{" "}
                and <a href="">Privacy policy</a>
              </p> */}
            </div>
          </div>
        </div>
      </div>
      <div id="recaptcha"></div>
      <ToastContainer position="top-right" />
    </div>
  );
};

export default ResetPin;
